.a-to-z-container{
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 2px;
    z-index: 999;
    right: 3px;
    top: 50%;
    transform: translateY(-50%);

    button{
        color: rgba(0, 0, 0, 0.25);
        font-family: "SF Pro Text";
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.408px;
        text-transform: uppercase;
        background: white;
        &:hover{
            color: #FFBC0D;
        }
    }
}