.connection-confirmation-popup {
  max-width: 520px !important;
  width: fit-content !important;
  min-width: 428px;

  .ant-modal-content {
    padding: 0 !important;

    .modal-container {

      .header-content-wrapper {
        width: 100%;
        min-height: 129px;
        position: relative;
        gap: 20px;
        display: flex;
        flex-direction: column;
      }
      .header-container {
        position: relative;
        padding: 48px 48px 32px 48px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .header-content {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .modal-header-title {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;

            .type {
              color: #f5222d;
            }
          }

          .content {
            color: #344056;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .action-buttons {
          .cancel-btn {
            width: 160px;
            padding: 10px 20px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: #000;
            border-radius: 14px;
            background: #f2f2f7;
          }
          .blockAndCancel-btn {
            min-width: 160px;
            background-color: #f5222d;
            border-radius: 14px;
            color: #fff;
            padding: 10px 20px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            &:disabled {
              opacity: 0.7;
              cursor: default;
            }
          }
        }
      }

      .order-details-container {
        max-height: 50vh;
        overflow: auto;
        background: #f9fafb;
        padding: 48px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        scroll-behavior: smooth;

        .place-order-title {
          color: #101828;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px;
          padding-bottom: 16px;

          .ongoing-text {
            color: #d0d5dd;
          }
        }

        .order-card {
          cursor: pointer;
          padding: 10px 0px;
          border-bottom: 1px solid #f0f0f0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:last-of-type {
            border-bottom: none;
          }

          .order-number {
            color: #101729;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
          }

          .items {
            color: #0169e6;
            font-size: 13.091px;
            font-style: normal;
            font-weight: 500;
            line-height: 19.636px;
            padding: 4px 0px;
          }

          .order-date {
            color: rgba(0, 0, 0, 0.65);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }

          .order-status {
            // svg {
            //   path {
            //     stroke: #ff9500;
            //   }
            // }
            // color: #ff9500;
            gap: 4px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            justify-content: end;
          }
        }

        .view-order-btn {
          width: 100%;
          padding: 10px;
          border-radius: 14px;
          border: 1px solid rgba(0, 0, 0, 0.25);
          display: flex;
          align-items: center;
          gap: 10px;
          justify-content: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: #000;

          &:disabled {
            opacity: 0.7;
            cursor: default;
          }
        }
      }
    }
  }
}
