.common-modal-container {
  .ant-modal-content {
    padding: 20px !important;
    overflow: auto;
    max-height: 90vh;
    min-height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ant-modal-body {
      height: 100%;
      .vendors-modal-container {
        height: 100%;
        .no-data-wrapper {
          height: 200px;
        }
      }
    }
  
    .modal-button {
      width: 120px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  
    .ant-modal-footer {
      margin-top: 32px;
    }
  }
  
  .header-title {
    text-align: center;
    color: var(--light-colorText);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
  }

  .header-info {
    margin-top: 8px;
    color: #475467;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
}
