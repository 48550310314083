.company-list-popover {
	padding-top: 1px;
	.user-box {
		align-items: start;

		.user-name {
			display: flex;
			align-items: start;
		}

		.customer-info {
			span {
				text-align: start !important;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 143px;
				width: 100%;
				display: inline-block;
			}
		}

		.img-box {
			width: 32px !important;
			min-width: 32px !important;
			height: 32px !important;
			min-height: 32px !important;
			margin-top: 5px;

			svg {
				width: 15px;
				height: 15px;
			}
		}
	}

	.selected-company-box {
		width: 100%;
		background: none;
		padding: 1px 6px;

		.company-box {
			padding: 9px 10px;
			border-radius: 6px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			transition: all 0.2s ease-out;
			&:hover {
				background-color: rgba(0, 0, 0, 0.02);
			}

			button {
				&.icon-button {
					min-width: 28px;
					min-height: 28px;
					width: 28px;
					height: 28px;
					z-index: 999;
					transition: all 0.2s ease-out;
					border-radius: 4px;
					border: 1px solid transparent;

					svg {
						width: 20px;
						height: 20px;
					}
					&:hover {
						background-color: var(--light-button-colorTextLightSolid);
						border: 1px solid var(--light-colorBorderSecondary);
					}
				}
			}
		}
	}

	.switch-company-container {
		padding: 1px 6px;
		border-bottom: 1px solid var(--light-colorBorderSecondary);

		.switch-company-box {
			color: var(--light-colorTextTertiary);
			padding: 9px 10px;
			display: flex;
			align-items: center;
			font-size: 10px;
			font-style: normal;
			font-weight: 600;
			line-height: 22px;
			text-transform: uppercase;
		}
	}

	.switch-company-profile-container {
		background: none;
		width: 100%;
		padding: 6px 0;
		border-bottom: 1px solid var(--light-colorBorderSecondary);
		display: flex;
		align-items: center;
		justify-content: space-between;
		&:last-of-type {
			border-bottom: none;
		}
		.inner-box {
			position: relative;
			padding: 4px;
			background: none;
			width: 100%;
			border-radius: 6px;
			text-align: start;

			&:hover {
				background: rgba(0, 0, 0, 0.02);
			}

			.count-capsule-badge {
				min-width: 20px;
				position: relative;
				top: auto;
				left: auto;
				bottom: auto;
				right: auto;
			}
		}

		div {
			&.icon-button {
				min-width: 28px;
				min-height: 28px;
				width: 28px;
				height: 28px;
				z-index: 999;
				transition: all 0.2s ease-out;
				border-radius: 4px;
				border: 1px solid transparent;
				display: flex;
				align-items: center;
				justify-content: center;

				svg {
					width: 20px;
					height: 20px;
				}
				&:hover {
					background-color: var(--light-button-colorTextLightSolid);
					border: 1px solid var(--light-colorBorderSecondary);
				}
			}
		}
	}

	button {
		&.add-company-container {
			padding: 1px 6px;
			width: 100%;
			background: none;

			.inner-box {
				color: var(--light-primary);
				padding: 9px 10px;
				display: flex;
				align-items: center;
				gap: 8px;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
			}

			svg {
				path {
					fill: var(--light-primary);
				}
			}
		}
		&.logout-button {
			padding: 0px 6px;
			width: 100%;
			background: #FFFFFF;
			position: sticky;
			bottom: -1px;
			z-index: 1000;

			.flex {
				padding: 9px 10px;
				background: none;
				width: 100%;
				border-radius: 6px;
				text-align: start;
				svg {
					height: 22px;
					width: 18px;
					margin-right: 2px;
					path {
						color: var(--light-colorError);
						stroke-width: 4px;
					}
				}
				&:hover {
					background-color: rgba(0, 0, 0, 0.02);
				}
			}

			span {
				&.button-text {
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 22px; /* 157.143% */
					color: var(--light-colorError);
				}
			}
		}
	}

	.company-profile-setting-container {
		.setting-container {
			padding: 1px 6px;

			.item-box-button {
				width: 100%;
				background: none;
				color: var(--light-colorText);
				padding: 9px 10px;
				border-radius: 6px;
				gap: 8px;
				display: flex;
				align-items: center;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: 22px;

				&:hover {
					background: rgba(0, 0, 0, 0.02);
				}
			}
		}
	}
}
