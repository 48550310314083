.user_roll_page {
    width: 100%;
    height: calc(100vh - 206px);
    overflow: auto;
    .user_roll_header {
        h3 {
            &.title-3 {
                color: #000;
                font-family: "Plus Jakarta Sans";
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 8px;
            }
        }
        p {
            &.caption {
                color: #344056;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
    .cards-container {
        margin-top: 24px;
        flex-wrap: wrap;
        .settings-card {
            max-width: 337px;
            height: 234px;
            width: 100%;
            padding: 20px;
            position: relative;
            border-radius: 14px;
            border: 1px solid #F2F2F7;
            overflow: hidden;
            transition: all 0.3s ease;
            button {
                &.more-option {
                    right: 20px;
                    top: 20px;
                    position: absolute;
                    line-height: 20px;
                    width: 20px;
                    height: 20px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                }
            }
            .user-empty-card {
                flex-wrap: wrap;
                height: 100%;
                button {
                    &.add-button {
                        background-color: #F0F0F0;
                        width: 72px;
                        height: 72px;
                        border-radius: 100%;
                        svg {
                            width: 32px;
                            height: 32px;
                            path {
                                fill: var(--light-primary);
                            }
                        }
                    }
                }
            }
            &:hover {
                background-color: #F9FAFB;
            }
        }
        .userRole-card {
            max-width: 337px;
            height: 234px;
            width: 100%;
            padding: 20px;
            position: relative;
            border-radius: 14px;
            border: 1px solid #F2F2F7;
            overflow: hidden;
            cursor: pointer;
            transition: all 0.3s ease;
            button {
                &.more-option {
                    right: 20px;
                    top: 20px;
                    position: absolute;
                    line-height: 20px;
                    width: 20px;
                    height: 20px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                }
            }
            .card-data {
                flex-wrap: wrap;
                height: 100%;
                button {
                    &.profile {
                        background-color: #F0F0F0;
                        width: 72px;
                        height: 72px;
                        border-radius: 100%;
                        color: #64748B;
                        text-align: center;
                        font-size: 29.25px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 34.5px; /* 117.949% */
                    }
                }
                .designation-button {
                    border-radius: 14px;
                    border: 0.5px solid #FFBC0D;
                    background: rgba(255, 188, 13, 0.10);
                    padding: 7px 10px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 10px;
                }
            }
            &:hover {
                background-color: #F9FAFB;
            }
        }
    }
}