.stat-card {
    width: 100%;
    position: relative;

    &__values_icon_container {
        display: flex;
        align-items: center;
        gap: 20px;
        
        button {
            &.icon-button {
                padding: 10px;
                border-radius: 14px;
                background: rgba(255, 188, 13, 0.10);
                border: 0.5px solid #FFBC0D;
                width: auto;
                height: auto;
                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }

    &__values {
        h3 {
            &.count {
                color: var(--Primary, #000);
                // font-family: "Plus Jakarta Sans";
                font-size: 28px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%; /* 42px */
            }
        }
        p {
            &.caption {
                color: #465468;
                font-family: "SF Pro Text";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 150% */
            }
        }
    }

    &__progress {
        color: var(--Secondary, #7C8DB5);
        font-family: "SF Pro Text";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        margin-top: 12px;
    }
    &__percentage {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */

        &--green {
            color: var(--colors-text-text-success-primary-600, #079455);
        }
        &--red {
            color: var(--colors-text-text-success-primary-600, #D92D20);
        }
    }
}