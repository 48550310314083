.user-box {
  .img-box {
    min-width: 44px;
    min-height: 44px;
    max-height: 44px;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.563px solid rgba(0, 0, 0, 0.08);
    .profile-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: 100% 100%;
      object-position: center;
    }
  }

  .customer-info {
    color: var(--light-colorText);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: start;
    
    .user-name {
      color: var(--light-colorSecondary);
      font-weight: 400;
      font-size: 12px;
      text-align: start;
    }
  }
}
