.notification-popover {
  min-width: 396px;
  width: 396px;
  max-width: 396px;
  min-height: 100px;
  height: fit-content;
  border-radius: 12px;
  border: 1.5px solid #fff;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  // overflow: auto;
  padding: 10px;

  .ant-popover-inner {
    padding: 10px 0;
  }

  .notification-popover-content {
    .notification-header-container {
      padding: 0 10px;
      width: 100%;

      .title {
        color: var(--light-colorDarkText);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }

      .count {
        min-width: 17px;
        min-height: 22px;
        height: 22px;
        padding: 6px;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 17px;
        border: 0.848px solid #0a6ee7;
        background: var(--Colors-Brand-Primary-colorPrimary, #000000);
        color: var(--Colors-Neutral-colorWhite, #fff);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 17.965px;
      }

      .actions-container {
        display: flex;
        gap: 10px;
        align-items: center;

        .mark-all-read {
          background: transparent;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          color: var(--light-primary);
        }

        .setting-btn,
        .close-btn {
          background-color: transparent;
          border-radius: 4px;
          svg {
            width: 24px;
            height: 24px;
          }
          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
          }
        }
      }
    }


  }
}

.notifcation-card-container {
  padding: 8px 10px;
  cursor: default;
  border-bottom: 1px solid var(--light-colorBorderSecondary);
  
  &.un-read {
    cursor: pointer;
    background-color: rgba(1, 105, 230, 0.06);
  }
  // &:hover {
  // }

  .notification-header {
    h3 {
      color: var(--light-primary);
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    .new-label {
      min-width: 17px;
      min-height: 14px;
      height: 14px;
      padding: 0 4px;
      width: fit-content;
      display: block;
      border-radius: 17px;
      border: 0.727px solid var(--light-colorError);
      background: var(--light-colorError);
      color: #fff;
      text-align: center;
      font-size: 8.727px;
      font-style: normal;
      font-weight: 600;
      line-height: 13.091px;
    }
  }

  .image-container {
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
    overflow: hidden;
    border-radius: 100%;
    margin-top: 4px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .notification-info-container {
    .header-box {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      .title {
        color: var(--light-colorText);
      }
      .info {
        color: var(--light-colorSecondary);
      }
      .product-notification {
        b {
          color: var(--light-colorText);
        }
      }
    }

    .action-container {
      button {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 12px;
        height: 32px;
        border-radius: 4px;
      }
      .reject-btn {
        color: var(--light-colorText);
        border: 1px solid var(--light-colorBorder);
        background-color: #fff;
        &.order-reject {
          background-color: #ff4d4f;
          border: 1px solid #ff4d4f;
          color: #fff;
          
          &:disabled {
            background-color: #ff4d50c2;
            border: 1px solid #ff4d50c2;
          }
        }

        &:disabled {
          background-color: #ffffffa3;
          border: 1px solid var(--light-colorBorderSecondary);
        }
      }

      .accept-btn {
        color: #fff;
        background-color: var(--light-primary);
        &.order-accept {
          background-color: #52c41a;
          color: #fff;
          
          &:disabled {
            background-color: #53c41ac4;
          }
        }

        &:disabled {
          background-color: #00000099;
        }
      }
    }

    .notification-time-text {
      color: var(--light-colorSecondary);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .red-text {
    color: var(--light-colorError) !important;
  }
}