@mixin gap($count) {
    @for $i from 1 through $count {
        .gap-#{$i} {
            gap: #{4 * $i}px;
            @media (min-width: 1920px) {
                gap: #{5 * $i}px;
            }
            @media (max-width: 1440px) {
                gap: #{2.5 * $i}px;
            }
        }
    }
}

@mixin padding($count) {
    @for $i from 0 through $count {
        .p-#{$i} {
            padding: #{$i}px;
        }
    }
}

@mixin title($count) {
    @for $i from 1 through $count {
        .title-#{$i} {
            font-size: #{64 / $i}px;
            line-height: 137.5%;
            font-style: normal;
            letter-spacing: -0.64px
        }
    }
}

@mixin BorderRadius($count) {
    @for $i from 1 through $count {
        .rounded-#{$i} {
            border-radius: #{$i}px;
        }
    }
}

@mixin FontWeight($count) {
    @for $i from 1 through $count {
        .fontWeight-#{$i * 100} {
            font-weight: #{$i * 100};
        }
    }
}