.create_user_roll_form {
    width: 100%;
    height: 100%;
    overflow: auto;
    .form-title-container {
        width: 100%;
        border-bottom: 1px solid #EAECF0;
        padding-bottom: 20px;
        button {
            &.back-button {
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
            }
        }
        h3 {
            &.form-title {
                font-family: "Plus Jakarta Sans";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px; /* 155.556% */
            }
        }
        .buttons-container {
            .delete-button {
                overflow: hidden;
                transition: all 0.2s ease-in-out;
                &:hover {
                    background: #FFF1F0;
                }
                button {
                    color: var(--light-colorError);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 142.857% */
                    svg {
                        path {
                            stroke: var(--light-colorError);
                        }
                    }
                }
            }
        }
    }
    .form-input-container {
        margin-top: 16px;
        max-width: 755px;
        width: 100%;
        .input-wrapper {
            height: 32px;
            border-radius: 8px;
            background-color: #F9FAFB;
            overflow: hidden;
            input {
                background-color: transparent;
                height: auto !important;
            }
        }
        input {
            background-color: #F9FAFB !important;
            height: 32px !important;
            border-radius: 8px !important;
        }
        .bg-light {
            .input-wrapper {
                background-color: #FFF;
            }
            input {
                background-color: #FFF !important;
            }
        }
    }
    .form-footer {
        margin-top: 24px;
        // padding-bottom: 24px;
        .buttons-container {
            margin: 20px 0;
            button {
                &.submit {
                    padding: 10px 20px;
                }
                &.cancel {
                    background-color: #F2F2F7;
                    padding: 10px 20px;
                }
            }
        }
    }
}