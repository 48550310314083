.recent-activity {
  &__wrapper {
    border-radius: 24px;
    border: 1px solid #f2f2f7;
    background: var(--Colors-Background-bg-primary, #fff);
    // padding: 16px;
    margin-top: 20px;
    overflow: hidden;
  }
  &__title {
    padding: 20px;
    border-bottom: 1px solid #f2f2f7;
    background-color: #fbfbfd;

    h2 {
      &.title-text {
        color: #101828;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }
    }
  }
  &__cards {
    padding: 0 20px 20px 20px;
    width: 100%;
    // height: 406px;
    min-height: 406px;
    max-height: 734px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;

    .no-data-container {
        min-height: 406px;
    }

    .activity-date {
      padding: 10px 0px 4px 8px;
      color: rgba(0, 0, 0, 0.45);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .card-container {
      display: flex;
      padding: 8px;
      align-items: center;
      gap: 8px;
      border-radius: 14px;
      background: #fff;

      &:hover {
        border-radius: 14px;
        background: #f1f4f9;

        .icon-container {
          background-color: #fff;
        }
      }

      .icon-container {
        min-height: 44px;
        max-height: 44px;
        min-width: 44px;
        max-width: 44px;
        border-radius: 100%;
        border: 0.5px solid #94a2b8;
        background: #f1f4f9;
      }

      .activity-info {
        color: #465468;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        b {
          font-weight: 700;
        }
      }

      .activity-time {
        color: #94a2b8;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .action-button {
        padding: 6px 8px;
        border-radius: var(--radius-md, 8px);
        border: 1px solid #d9d9d9;
        background: #fff;
        white-space: nowrap;

        &:hover {
          border: 1px solid #000;
          background: #000;
          color: #fff;
        }
      }
    }
  }
}
