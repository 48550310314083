.no-data-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;

  .no-data-icon {
    width: 44px;
    height: 44px;
    background-color: #f1f4f9;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .caption {
    color: #465468;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
