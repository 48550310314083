.list-of-customers {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    width: 100%;
  }

  &__header {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    display: flex;
    align-items: center ;
    gap: 8px;

    .back-btn {
      background-color: transparent;
      svg {
        width: 24px;
        height: 24px;
        rotate: 180deg;

        path {
          stroke :#000
        }
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    overflow: auto;

    .no-data-wrapper {
      padding-bottom: 43px;
    }

    .customers-card {
      padding: 10px;
      border-radius: 14px;
      padding: 0 10px;

      &:hover,
      &.active {
        background-color: #f1f4f9;
      }

      &__details {
        width: 100%;
        padding: 10px 0;
        background-color: transparent;
      }

      &__data {
        display: flex;
        padding: 10px 0;

        .amount {
          display: flex;
          flex-direction: column;
          gap: 4.857px;
          width: 100%;
          border-right: 1px dashed #afafaf;

          &-value {
            font-size: 19.429px;
            font-style: normal;
            font-weight: 600;
            line-height: 25.905px;
            color: #080808;
          }

          &-label {
            color: rgba(0, 0, 0, 0.65);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }
        }
        .orders {
          display: flex;
          flex-direction: column;
          gap: 4.857px;
          width: 100%;
          padding-left: 24px;

          &-value {
            font-size: 19.429px;
            font-style: normal;
            font-weight: 600;
            line-height: 25.905px;
            color: #080808;
          }

          &-label {
            color: rgba(0, 0, 0, 0.65);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }
        }

        // .details-box {
        //   width: 100%;
        //   display: flex;
        //   flex-direction: column;
        //   gap: 30px;
        //   padding: 0 10px;
        //   border-right: 1px dashed #afafaf;

        //   &:nth-of-type(1) {
        //     padding-left: 0 !important;
        //   }
        //   &:nth-of-type(3) {
        //     border-right: none;
        //   }

        //   &__amount {
        //     display: flex;
        //     flex-direction: column;

        //     &-value {
        //       font-size: 19.429px;
        //       font-style: normal;
        //       font-weight: 600;
        //       line-height: 25.905px;
        //       color: #080808;
        //     }

        //     &-label {
        //       color: rgba(0, 0, 0, 0.65);
        //       font-size: 12px;
        //       font-style: normal;
        //       font-weight: 500;
        //       line-height: 20px;
        //     }
        //   }

        //   &__percentage {
        //     display: flex;
        //     align-items: center;
        //     gap: 3.238px;

        //     &-icon {
        //       width: 14.571px;
        //       height: 14.571px;
        //       &--increase {
        //         svg {
        //           path {
        //             fill: #17b26a;
        //           }
        //         }
        //       }
        //       &--decrease {
        //         svg {
        //           rotate: 180deg;
        //           path {
        //             fill: #f5222d;
        //           }
        //         }
        //       }
        //     }

        //     &-value {
        //       font-size: 12.952px;
        //       font-style: normal;
        //       font-weight: 500;

        //       &--increase {
        //         color: #17b26a; // Green color for increase percentage
        //       }

        //       &--decrease {
        //         color: #f5222d; // Red color for decrease percentage if needed
        //       }
        //     }
        //   }

        //   &__progress {
        //     height: 19.429px;
        //     background-color: #ffbc0d;
        //     border-radius: 4px;
        //     border: 1px solid #fff;
        //   }
        // }

        // .divider {
        //   width:
        // }
      }
    }
  }
}
