.customfield-select {
	.ant-select-selector {
		border: 1px solid var(--light-colorBorder) !important;
	}
}

.customfield-popup {
	width: 366px !important;
	.action-btn {
		background: none;
		padding: 4px;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			background-color: #fff;
		}
		&.delete-icon {
			svg {
				path {
					stroke: var(--light-colorError);
				}
			}
		}
	}
	.ant-space-item {
		span {
			&:not(.ant-checkbox-inner) {
				max-width: 240px;
				overflow: auto;
				width: 100%;
				display: block;
			}
		}
	}
}
.managefield-container {
	width: 200px;
	gap: 10px;
	justify-content: flex-end;
	.selectWrapper {
		width: 100%;
	}
	.ant-select-selection-overflow {
		.ant-select-selection-overflow-item-rest {
			display: none !important;
		}
	}

	.ant-select-selection-item {
		display: none !important;
	}

	button {
		&.close-icon {
			width: 16px;
			height: 16px;
			background-color: transparent;
		}
	}
}
.ant-select-item-option-content {
	display: flex;
	align-items: center;
	gap: 4px;
	span {
		&.create-badge {
			color: var(--Colors-Brand-Primary-colorPrimary, #000000);
			text-align: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
			border-radius: var(--radius-xs, 4px);
			border: 1px solid var(--Colors-Brand-Primary-colorPrimaryBorderHover, #69b1ff);
			background: var(--Colors-Brand-Primary-colorPrimaryBg, #f2f7fe);
			padding: 2px 10px;
			max-width: 240px;
			overflow: auto;
			width: fit-content;
			display: inline-block;
		}
	}
}
