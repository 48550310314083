.vendor_name {
	&__suggestion_container {
		max-height: 190px;
		overflow: auto;
		width: 100%;
		background-color: #fff;
		position: absolute;
		top: 60px;
		left: 0;
		border-radius: 8px;
		border: 1px solid #eaecf0;
		box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
		  0px 4px 6px -2px rgba(16, 24, 40, 0.03);
		padding: 4px 0;
		z-index: 1001;
	}
	&__list {
		padding: 1px 6px;
		display: flex;
		align-items: center;
		flex-direction: column;
	
		li {
			color: var(--light-colorDarkText);
			padding: 4px 8px 4px 8px;
			width: 100%;
			border-radius: 6px;
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 8px;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
	
			&:hover {
			background-color: rgba(0, 0, 0, 0.02);
			}
			&.active {
			background-color: rgba(0, 0, 0, 0.02);
			}
		}
	}
}