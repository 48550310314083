@import "./subscribeForm.scss";
@import "./subscriptionDetails.scss";

.subscription-page {
    padding-top: 0 !important;
    overflow: auto;
    margin-top: 0 !important;
    .subscription-settings-header {
        // margin-bottom: 24px;
        h3 {
            .title-3 {
                color: #000;
                font-family: "Plus Jakarta Sans";
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 8px;
            }
        }
        p {
            &.caption {
                color: #344056;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
    button {
        &.support-button {
            padding: 10px;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
    .subscription-settings-body {
        margin-top: 24px;
        .plans-container {
            position: relative;
            // height: calc(100vh - 220px);
            // position: sticky;
            // top: 0;
            .plans-header {
                width: 100%;
                border-bottom: 1px solid rgba(0, 0, 0, 0.10);
                align-items: flex-end;
                position: sticky;
                top: 0;
                background-color: #FFF;
                .w-25 {
                    max-width: 25%;
                    width: 100%;
                    h2 {
                        &.plan-title {
                            color: #000;
                            font-family: "Plus Jakarta Sans";
                            font-size: 48px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 50px; /* 104.167% */
                            padding: 24px;
                            @media (max-width: $desktop-mini) {
                                font-size: 42px;
                            }
                        }
                    }
                    .plan-box {
                        width: 100%;
                        padding: 24px 24px 20px 24px;
                        border-radius: 24px 24px 0px 0px;
                        background: #F8F9FC;
                        position: relative;
                        @media (max-width: $desktop-mini) {
                            padding: 16px 16px 12px 16px;
                        }
                        &.blue {
                            background: rgba(1, 105, 230, 0.05);
                        }
                        &.yellow {
                            background: rgba(255, 188, 13, 0.10);
                        }
                        .img-container {
                            width: 102.328px;
                            height: 105.006px;
                            margin: auto;
                            @media (max-width: $desktop-mini) {
                                width: 80px;
                                height: 80px;
                            }
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                                object-position: center;
                            }
                        }
                        p {
                            &.plan-price {
                                text-align: center;
                                margin-top: 22px;
                                @media (max-width: $desktop-mini) {
                                    margin-top: 12px;
                                }
                                span {
                                    &.price {
                                        font-size: 40px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 38px; /* 95% */
                                        color: #18181B;
                                        @media (max-width: $desktop-mini) {
                                            font-size: 32px;
                                        }
                                    }
                                    &.time {
                                        color: var(--sementics-color-fg-default, #18181B);
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 20px; /* 166.667% */
                                    }
                                }
                            }
                            &.plan-text {
                                text-align: center;
                                color: var(--sementics-color-fg-default, #18181B);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 22px; /* 157.143% */
                                margin-top: 22px;
                                max-width: 100%;
                                word-break: keep-all;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                text-align: center;
                                width: 100%;
                                @media (max-width: $desktop-mini) {
                                    margin-top: 12px;
                                }
                            }
                        }
                        &:not(.free) {
                            span {
                                &.help-text {
                                    color: var(--sementics-color-fg-default, #18181B);
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 20px; /* 166.667% */
                                    text-align: center;
                                    display: block;
                                    margin-top: 8px;
                                }
                            }
                        }
                        button {
                            &.plan-button {
                                width: 100%;
                                padding: 10px;
                                font-size: 14px;
                                border-radius: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 24px; /* 171.429% */
                                margin-top: 22px;
                                background: #000;
                                color: #FFFFFF;
                                @media (max-width: $desktop-mini) {
                                    margin-top: 12px;
                                    padding: 6px;
                                    font-size: 12px;
                                }
                                &.hidden {
                                    opacity: 0;
                                    cursor: default !important;
                                    visibility: hidden;
                                }
                                &.active {
                                    background: rgba(1, 105, 230, 0.05);
                                    color: #0169E6;
                                }
                                &:disabled {
                                    cursor: not-allowed;
                                   &:not(.active) {
                                        background: #344056;
                                        color: #FFFFFF;
                                   }
                                }
                            }
                        }
                    }
                }
            }
            .plans-body {
                width: 100%;
                // overflow: auto;
                .w-25 {
                    max-width: 25%;
                    width: 100%;
                }
                .plan-item {
                    height: 72px;
                    width: 100%;
                    padding: 16px 24px;
                    border-bottom: 1px solid #F2F2F7;
                    @media (max-width: $desktop-mini) {
                        height: 62px;
                    }
                    &:nth-of-type(2) {
                        background: #F8F9FC;
                    }
                    &:nth-of-type(3) {
                        background: rgba(1, 105, 230, 0.05);
                    }
                    &:nth-of-type(4) {
                        background: rgba(255, 188, 13, 0.10);
                    }
                    h3 {
                        &.heading-text {
                            color: #000;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 22px; /* 157.143% */
                        }
                    }
                    h3 {
                        &.heading-title {
                            color: #000;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 22px; /* 122.222% */
                        }
                    }
                    p {
                        &.text {
                            color: #344056;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px; /* 142.857% */
                            svg {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.skeleton-wrapper {
    max-width: 25%;
    width: 100%;
    height: 150px;
    span {
        width: 100%;
        height: 100%;
    }
}