.order-status-container {
    .inprogress-status {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        justify-content: end;
    }
    .accepted-status {
        color: #009656;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        justify-content: end;
    }
    .pending-status {
        color: rgba(0, 0, 0, 0.45);;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        justify-content: end;
    }
    .dispatch-status-wrapper {
        svg {
            path {
                stroke: #FFBC0D;
            }
        }
        .dispatch-status {
            color: #FFBC0D;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            justify-content: end;
        }
    }
}