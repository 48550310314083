.BreadcrumbContainer {
  ol {
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        &:hover {
          background: none;
        }
      }
    }
  }
  .item-title {
    padding: 4px 8px;
    border-radius: 6px;
    color: #344054;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.14px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: #fff;
    }
  }
  .BreadcrumbsButton {
    padding: 4px 8px;
    border-radius: 6px;
    background: #fff;
    color: #344054;
    /* SM/Strong */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.14px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
