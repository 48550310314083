$light-colors: (
  primary: #000,
  colorTextLightSolid: #FFFFFF,
  colorBorder: #d9d9d9,
  lightColor26: rgba(255, 255, 255, 0.15),
  lightColor40: rgba(255, 255, 255, 0.40),
  colorTextSecondary: rgba(223, 221, 234, 0.85),
  colorDarkText: #101828,
  colorSecondary: rgba(0, 0, 0, 0.65),
  colorTextQuaternary: rgba(255, 255, 255, 0.25),
  darkColor25: rgba(0, 0, 0, 0.25),
  colorTextTertiary: rgba(0, 0, 0, 0.45),
  colorBorderSecondary: #F0F0F0,
  colorBgContainer: #fff,
  colorText: rgba(0, 0, 0, 0.88),
  colorError: #F5222D,
  colorSuccess: #52C41A,
  brandSuccess: #17B26A,
  warningColor: #FAAD14,
  geekblue: #85A5FF,
  secondary: #0169E6,
);

$light-border-colors: (
  common: #e7e7e7,
);

$light-shadow-colors: (
  common: #e7e7e7,
);

$light-buttons-colors: (
  colorPrimary: #000,
  colorTextLightSolid: #FFFFFF,
  colorBorder: #F2F2F7,
  yellow900: #FF9500,
  colorError: #ED1A0B,
  colorGreen900: #009656
);

$dark-colors: (
  primary: #000000,
  colorBorder: #424242,
  colorTextLightSolid: #FFFFFF,
  lightColor26: rgba(0, 0, 0, 0.15),
  lightColor40: rgba(0, 0, 0, 0.40),
  colorTextSecondary: rgba(0, 0, 3, 0.65),
  colorSecondary: rgba(0, 0, 0, 0.65),
  colorTextQuaternary: rgba(0, 0, 0, 0.25),
  darkColor25: rgba(255, 255, 255, 0.25),
  colorTextTertiary:rgba(255, 255, 255, 0.45),
  colorBorderSecondary: #303030,
  colorBgContainer: #141414,
  colorText: rgba(255, 255, 255, 0.85),
  colorError: #D32029,
  colorSuccess:  #49AA19

);

$dark-buttons-colors: (
  primary: #333333,
  colorBorder: #424242,
  colorTextLightSolid: #FFFFFF
);

$desktop: 1920px;
$desktop-mini: 1680px;
$laptop-mini: 1440px;
$tablet: 850px;
$tablet-landscap: 991px;
$mobile: 576px;
/*

#000000
#FFFFFF
rgba(255, 255, 255, 0.15)
rgba(255, 255, 255, 0.40)
#D9D9D9
rgba(223, 221, 234, 0.85)
rgba(0, 0, 0, 0.25)

 
*/

@mixin generate-color-vars($colors, $prefix) {
  @each $name, $color in $colors { 
    --#{$prefix}-#{$name}: #{$color};
  }
}

@mixin generate-border-color-vars($colors, $prefix) {
  @each $name, $color in $colors { 
    --#{$prefix}-#{$name}: #{$color};
  }
}

@mixin generate-button-colors-vars($colors, $prefix) {
  @each $name, $color in $colors {
    --#{$prefix}-#{$name}: #{$color};
  }
}