.settings-page, .profile-settings-page {
    max-height: 100%;
    overflow: auto;
    padding-bottom: 20px;
    padding-top: 0;
    margin-top: 0 !important;
    .profile-settings-header {
        h3 {
            .title-3 {
                color: #000;
                font-family: "Plus Jakarta Sans";
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 8px;
            }
        }
        p {
            &.caption {
                color: #344056;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
    .settings-layout {
        margin-top: 32px;
        @media (max-width: 1280px) {
            flex-direction: column;
        }
        .text-container {
            max-width: 300px;
            width: 100%;
            @media (max-width: 1280px) {
                max-width: 100%;
            }
            h4 {
                &.heading-text {
                    color: var(--light-colorDarkText);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px; /* 171.429% */
                }
                &.heading-text-big {
                    color: var(--light-colorDarkText);
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 50px; 

                    .greater-text {
                        display: inline-block;
                        position: relative;
                        background-image: url('../../images/round-bg.png');
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        width: fit-content;
                        z-index: 999;
                    }
                }


            }
            p {
                &.caption {
                    &.small {
                        overflow: hidden;
                        color: var(--light-colorSecondary);
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px; /* 150% */
                    }
                }
            }
        }
        .setting-form-container {
            max-width: calc(100% - 300px);
            width: 100%;
            @media (max-width: 1280px) {
                max-width: 100%;
            }
            form {
                &.setting-form {
                    .profile-image-row {
                        margin-bottom: 10px;
                        .image-upload-input {
                            .file-upload {
                                &.ant-upload-wrapper {
                                    width: 120px;
                                    height: 120px;
                                    border-radius: 100%;
                                    overflow: hidden;
                                    .ant-upload-list {
                                        max-width: 100%;
                                        max-height: 100%;
                                        width: 100%;
                                        height: 100%;
                                        .ant-upload, .ant-upload-list-item-container {
                                            max-width: 100%;
                                            max-height: 100%;
                                            width: 100%;
                                            height: 100%;
                                            .ant-upload-list-item {
                                                padding: 0;
                                                overflow: hidden;
                                            }
                                        }
                                        &.ant-upload-list-picture-circle {
                                            .ant-upload-list-item {
                                                &::before {
                                                    width: 100%;
                                                    height: 100%;
                                                }
                                            }
                                        }
                                    }
                                }
                            } 
                        }
                    }
                    .fields-container {
                        flex-wrap: wrap;
                        .input {
                            width: 100%;
                            .input-container {
                                label {
                                    span {
                                        color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 22px; /* 183.333% */
                                        &.require-icon {
                                            color: var(--light-colorError);
                                        }
                                    }
                                    input {
                                        height: 32px;
                                        color: rgba(0, 0, 0, 0.88);
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 24px; /* 171.429% */
                                        letter-spacing: 0.14px;
                                        &::placeholder {
                                            color: var(--Color-Neutral-Text-colorTextPlaceholder, rgba(0, 0, 0, 0.25));
                                        }
                                    }
                                }
                            }
                        }
                        textarea {
                            &::placeholder {
                                color: var(--Color-Neutral-Text-colorTextPlaceholder, rgba(0, 0, 0, 0.25));
                            }
                        }
                    }
                    .buttons-container {
                        margin-top: 14px;
                        .button {
                            border-radius: 8px;
                            max-width: 120px;
                            button {
                                padding: 8px 16px;
                                width: 100%;
                                width: 120px;

                                &:disabled {
                                    // background-color: gray;
                                    opacity: 0.6;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}