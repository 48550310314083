.complete-profile {
    padding: 21px 25px;
    &__header {
        h4 {
            &.title-4 {
                color: #FFFFFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px; /* 155.556% */
                span {
                    color: rgba(255, 255, 255, 0.50);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 142.857% */
                }
            }
        }
        button {
            &.go-to-profile__icon {
                background-color: transparent;
                svg {
                    path {
                        stroke-width: 2px;
                        stroke: #FFFFFF;
                    }
                }
            }
        }
    }
    &__progress-bar {
        width: 100%;
        margin-top: 31px;
        margin-bottom: 10px;
        .progress-bar__step {
            max-width: 25%;
            width: 100%;
            &.active {
                .progress {
                    &__line {
                        background-color: #FFBC0D;
                    }
                    &__text {
                        color: #FFBC0D;
                    }
                }
            }
            .progress {
                &__line {
                    display: block;
                    width: 100%;
                    height: 4px;
                    background-color: #f2f2f7;
                }
                &__text {
                    color: #f2f2f7;
                    margin-top: 20px;
                    display: inline-block;
                }
            }
        }
    }
}