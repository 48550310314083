.editText-container {
  border-radius: 14px;
  background-color: #fff;
  position: relative;
  border: 1px solid transparent;
  &:focus-visible {
    outline: none;
  }
  p{
    font-size: 14px;
  }
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border: none;
  border-radius: 14px;
  border: 1px solid #D9D9D9 !important;
  transition: all 0.2s ease-out;

  &:focus-within {
    border: 1px solid var(--light-primary) !important;
    outline: none;
  }
}

.ql-toolbar.ql-snow {
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  border: none !important;
  z-index: 98;
  right: 0;
}

/* Editor area styles */
.ql-container.ql-snow {
  border-radius: 5px;
  min-height: 120px;
  height: 120px;
  max-height: 120px;
  padding-bottom: 37px;

  .ql-editor{
    padding: 12px;
  }
}

/* Customize toolbar buttons */
.ql-snow .ql-toolbar button {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.ql-snow .ql-toolbar button:hover,
.ql-snow .ql-toolbar button:focus {
  background-color: #f0f0f0;
}

/* Customize selected toolbar buttons */
.ql-snow .ql-toolbar button.ql-active {
  background-color: #e0e0e0;
  border-color: #ccc;
}
