.product-size-modal {
	min-width: 708px;
	position: relative;

	.ant-modal-content {
		min-height: 350px;
	}

	.product-size-modal-container {
		.modal-header {
			gap: 10px;

			.image-container {
				min-width: 100px;
				min-height: 100px;
				width: 100px;
				height: 100px;
				overflow: hidden;
				border-radius: 10.811px;
				border: 1.563px solid #eaecf0;
				position: relative;

				img {
					width: 100px;
					height: 100px;
					max-width: 100px;
					max-height: 100px;
					object-fit: cover;
					object-position: center;
				}

				.offer {
					background-image: url("../../svg/OfferBg.svg");
					z-index: 9;
					position: absolute;
					bottom: 2px;
					left: 2px;
					width: 47px;
					height: 47px;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #fff;
					font-size: 10.952px;
					font-style: normal;
					font-weight: 800;
					line-height: 12.169px;
					text-align: center;
				}
			}

			.product-name {
				color: var(--light-colorDarkText);
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: 30px;
				overflow: hidden;
			}
		}

		// Product Sizes Container

		.product-sizes-container {
			max-height: 50vh;
			min-height: 200px;
			overflow: auto;

			:nth-last-child(1) {
				&.size-box {
					border-bottom: none;
				}
			}

			.size-box {
				padding: 18px 8px 18px 8px;
				border-bottom: 1px solid #eaecf0;
				position: relative;

				.size {
					color: var(--light-colorText);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px;
					width: 200px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}

				.quantity-box {
					gap: 8px;
					width: 250px;
					justify-content: flex-start;

					.price-text {
						color: var(--light-colorText);
						font-size: 14px;
						font-style: normal;
						font-weight: 600;
						line-height: 22px;
						// width: 150px;
						width: fit-content;
						text-align: end;

						.unit-text {
							color: var(--light-colorTextTertiary);
						}
					}
				}
				.bulkrate-button {
					color: var(--light-primary);
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 22px;
					background: transparent;

					path {
						stroke: var(--light-primary);
					}

					&:disabled {
						opacity: 0.8;
						cursor: default;
					}

					// .bulkrate-list-popover-container {
					//   width: 317px;
					//   padding: 8px 0;
					// }
				}

				.add-size-button {
					width: 127px;
					border: 1px solid var(--light-primary);
					background: #fff;
					border-radius: 8px;
					height: 40px;
					padding: 4px 15px;
					color: var(--light-primary);
				}

				.counter-button {
					width: 127px;
					height: 40px;
					justify-content: space-between;
					input {
						max-width: 100%;
						width: 61px;
						font-size: 14px;
					}
				}
			}
		}
	}
	.unavailable-size {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		width: 100%;
		height: 100%;
		// position: absolute;
		left: 0;
		top: 0;
		background-color: #ffffff50;
		span {
			&.badge {
				background-color: #c3c3c3;
				color: #fff;
				text-align: center;
				font-family: "SF Pro Text";
				font-size: 11px;
				font-style: normal;
				font-weight: 500;
				line-height: 19.636px; /* 178.512% */
				padding: 2px 6px;
				border-radius: 4px;
				// margin-right: 10px;
			}
		}
	}
}

.bulkrate-list-popover-container {
	width: 317px;
	z-index: 100;

	.ant-popover-inner {
		padding: 0;
	}

	.bulkrate-inner-container {
		padding: 4px 8px;
		max-height: 300px;
		overflow: auto;

		.bulkrate-box {
			padding: 10px;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
			background: transparent;
			width: 100%;
			border-radius: 6px;
			cursor: pointer;

			&.selected {
				background: rgba(0, 0, 0, 0.02);
			}

			&:hover {
				background: rgba(0, 0, 0, 0.02);
			}

			.selected-icon {
				color: var(--light-primary);
			}
		}
	}
}
