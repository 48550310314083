.subscription-form {
    flex: 1;
    width: 975px !important;
    margin-top: 24px !important;
    .ant-modal-content {
        padding: 48px !important;
    }
    .form-container {
        .form-inputs-container {
            .checkbox-input-container {
                label {
                    &.checkbox {
                        width: fit-content;
                        cursor: pointer;
                        input {
                            cursor: pointer;
                        }
                    }
                }
                .company_name {
                    color: var(--light-colorSuccess);
                    font-size: 12px;
                    line-height: 1.5;
                }
            }
        }
        .form-footer {
            .subscribe-button {
                width: 100%;
                button {
                    width: 100%;
                }
            }
            .back-button {
                width: 100%;
                padding: 10px 20px;
                margin-top: 8px;
                button {
                    background: transparent;
                    width: 100%;
                    color: #000;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
    }
    .summary-section {
        border-radius: 24px;
        background: #F9FAFB;
        padding: 20px;
        max-width: 365px;
        .summary-content {
            .summary-header {
                button {
                    &.change-plan-button {
                        padding: 4px 10px;
                        background-color: transparent;
                        border: 1px solid #000000;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px; /* 166.667% */
                    }
                }
            }
            .summary-body {
                .plan-data-container {
                    padding-bottom: 16px;
                    margin-bottom: 16px;
                    border-bottom: 1px solid #E3E3E3;
                    .about-plan-details {
                        .img-container {
                            width: 50px;
                            height: 50px;
                            img {
                                width: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                    .plan-pricing-container {
                        p {
                            &.plan-price {
                                span {
                                    color: var(--sementics-color-fg-default, #18181B);
                                    &.price {
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 20px; /* 125% */
                                    }
                                    &.time {
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 20px; /* 166.667% */
                                    }
                                }
                            }
                        }
                    }
                }
                .bill-summary-details {
                    .subtotal {
                        &__title {
                            color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 22px; /* 157.143% */
                        }
                        &__amount {
                            color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
                            text-align: right;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 22px; /* 157.143% */
                        }
                    }
                    .gst {
                        padding-bottom: 8px;
                        &__title {
                            color: #667085;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 22px; /* 157.143% */
                        }
                        &__rate {
                            color: #667085;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 22px; /* 157.143% */
                        }
                    }
                    .grand-total {
                        border-top: 1px solid #F0F0F0;
                        padding-top: 16px;
                        border-bottom: 1px solid #E3E3E3;
                        padding-bottom: 16px;
                        margin-bottom: 16px;
                        h3 {
                            &.title_text {
                                color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 24px; /* 150% */
                            }
                            &.total_amount {
                                color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
                                text-align: right;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 24px; /* 120% */
                            }
                        }
                    }
                }
                p {
                    &.summary-text {
                        color: var(--sementics-color-fg-default, #18181B);
                        text-align: center;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 125%; /* 15px */   
                    }
                }
            }
        }
    }
}