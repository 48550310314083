.help {
  text-align: center;
  // margin: auto;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  // justify-content: center;

  &__title {
    color: #000;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 32px;
  }

  &__subtitle {
    color: #64748b;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;
    margin-top: 96px;
  }

  &__card {
    background-color: #f9fafb;
    border-radius: 24px;
    width: 400px;
    height: 290px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-icon {
      width: 40px;
      height: 40px;
      background-color: #fef7e8;
      border-radius: 14px;
      display: flex;
      align-items: center;
      justify-content: center;

      &--phone {
        width: 48px;
        height: 48px;
        border: 0.5px solid #ffbc0d;
        background: rgba(255, 188, 13, 0.1);
      }

      &--email {
        width: 48px;
        height: 48px;
        border: 0.5px solid #ffbc0d;
        background: rgba(255, 188, 13, 0.1);
      }
    }

    &-title {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      margin-top: 24px;
    }

    &-subtext {
      color: #64748b;
      margin-bottom: 24px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    &-contact {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }

  &__back-button {
    width: fit-content;
    padding: 4px 0px;
    margin-top: 64px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #adadad;
    background: none;
    border: none;
    cursor: pointer;
    border-bottom: 1px solid #adadad;

    svg {
      rotate: 90deg;

      path {
        stroke: #adadad;
      }
    }

    &:hover {
      color: #000;

      svg {
        path {
          stroke: #000;
        }
      }
    }
  }
}
