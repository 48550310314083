.RecentOrderCard {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-xl, 10px);
  border-radius: 12px;
  border-radius: var(--radius-xl, 12px);
  border: 1px solid var(--Colors-Border-border-secondary, #EAECF0);
  background: var(--Colors-Background-bg-primary, #FFF);
  width: calc((100% / 6) - (10px - (10px / 7)));

  @media (max-width: 1560px) {
    width: calc((100% / 5) - (10px - (10px / 6)));
  }

  @media (max-width: 1440px) {
    width: calc((100% / 4) - (10px - (10px / 5)));
  }

  @media (max-width: 1280px) {
    width: calc((100% / 3) - (10px - (10px / 4)));
  }

  .Content {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;

    .order-status-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      .order-text {
        color: #101828;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 142.857% */
        letter-spacing: 0.14px;
      }
      .status {
        display: flex;
        align-items: center;
        color: #dc6803;
        text-align: center;
        font-family: 'SF Pro Text';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.12px;
      }
    }

    .item-time-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      .item-text {
        color: #101828;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        letter-spacing: 0.12px;
      }

      .time-text {
        color: #667085;
        font-family: 'SF Pro Text';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        letter-spacing: 0.12px;
      }
    }
  }

  .reOrder-Button {
    width: 100%;
    height: 32px;
    min-height: 32px;
    padding: 0px 15px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: var(--radius-md, 8px);
    background: var(--Colors-Brand-25, #F2F8FF);
    color: #000000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 142.857% */
    letter-spacing: 0.14px;

    &:disabled {
      cursor: default;
      opacity: 0.8;
    }
  }
}
