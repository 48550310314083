@import "./vendorCard.scss";

.vendors-page {
  .custom-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    h2 {
      &.title-2 {
        color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px; /* 158.333% */
      }
    }
    .add-vendor-button {
      border-radius: 8px;

    }
  }
  .vendor-title-count  {
    display: flex;
    align-items: center;
    gap: 8px;
    h2 {
      &.title-2 {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px; /* 158.333% */
      }
    }
    button {
      &.vendors-count {
        height: 34px;
        padding: 0 8px;
        border-radius: var(--Style-borderRadiusLG, 8px);
        border: 1.5px solid #FFF;
        background: rgba(255, 255, 255, 0.60);
        backdrop-filter: blur(2px);
      }
    }

  }
  .vendors-container {
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.60);
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    max-height: calc(100% - 60px);
    overflow: auto;
  }
}

.recent-orders-drawer {
  .ant-drawer-content-wrapper {
    max-width: 444px;
    width: 444px !important;
    min-width: 444px;
    .ant-drawer-header {
      padding: 12px !important;
      .ant-drawer-header-title {
        flex-direction: row-reverse;
        justify-content: space-between;
        .ant-drawer-close {
          margin-right: 0;
        }
      }
    }
    .ant-drawer-content {
      border-radius: 16px;
    }
    .ant-drawer-body {
      padding: 24px 12px;
    }
  }
  .business-info {
    margin-bottom: 25px;
    display: flex;
    gap: 8px;
    .business-image {
      max-width: 44px;
      min-width: 44px;
      width: 100%;
      height: 44px;
      border-radius: 100%;
      background-color: rgba(0, 0, 0, 0.04);
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .business-text {
      h4 {
        &.title-4 {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px; /* 157.143% */
        }
      }
      p {
        &.caption {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 166.667% */
        }
      }
    }
  }
  .orders-container {
    .recent-order-wrapper {
      cursor: pointer;
      border-top: 1px solid #EAECF0;
      transition: all 0.2s ease;
      padding: 8px;
      padding-top: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      &:first-of-type {
        border-top: none;
        // padding-top: 0;
      }
      &:hover {
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.04);
        button {
          &.view-order {
            // transition: all 0.2s ease;
            &:hover {
              // border-color: #4096FF;
              // color: #4096FF;
            }
          }
        }
      }
      .order-summary {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .pricing {
          display: flex;
          align-items: center;
          gap: 8px;
          p {
            &.caption {
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 22px; /* 157.143% */
            }
          }
          span {
            &.seprator {
              display: block;
              font-weight: 600;
            }
          }
        }
        p {
          &.order-no {
            color: var(--Color-Neutral-Text-colorTextLabel, rgba(0, 0, 0, 0.65));
            /* SM/Normal */
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 166.667% */
          }
        }
        .status {
          p {
            text-align: right;
            font-family: "SF Pro Text";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 150% */
            &.green {
              color: var(--Colors-Brand-Success-colorSuccess, #17B26A);
            }
            &.Completed {
              color: #A140FF;
            }
            &.Progress{
              color: var(--light-primary)
            }
            &.Accepted{
              color: #17B26A
            }
            &.Dispatched{
              color: #FF9500
            }
            &.Cancelled{
              color: #ED1A0B
            }
          }
        }
      }
      .view-order-details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          &.order-date {
            color: var(--Color-Neutral-Text-colorTextDescription, rgba(0, 0, 0, 0.45));
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 166.667% */
          }
        }
        button {
          &.view-order {
            height: 40px;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px; /* 157.143% */
            border-radius: 8px;
            padding: 4px 15px;
            border: var(--Components-Button-Global-lineWidth, 1px) solid var(--Components-Button-Component-defaultBorderColor, #D9D9D9);
            background: var(--Components-Button-Component-defaultBg, #FFF);
          }
        }
      }
    }
    .order-details-container {
      .order-table-container {
        margin-bottom: 10px;
        .ant-table-container {
          max-height: 300px;
          overflow: auto;
          height: fit-content;
        }
        .product-img {
          max-width: 32px;
          height: 32px;
          min-width: 32px;
          width: 100%;
          border-radius: 4px;
          object-fit: cover;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
          svg {
            width: 100%;
            height: 100%;
          }
        }
        .ant-table-thead {
          tr {
            th {
              color: rgba(0, 0, 0, 0.45);
              background: #FFF;
              padding: 10.5px 16px;
            }
          }
        }
        .ant-table-content {
          table {
            border: 1px solid #F0F0F0;
            border-radius: 12px;
            overflow: hidden;
            tr {
              td {
                padding: 14.5px 16px;
              }
            }
          }
        }
        .ant-table-tbody .ant-table-row >.ant-table-cell-row-hover {
          background: rgba($color: #000000, $alpha: 0.04);
        }
        .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
          display: none;
        }
      }
      .buttons-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        button {
          max-width: calc(50% - 4px);
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 3px;
          border-radius: 8px;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px; /* 157.143% */
          &.download-button {
            color: #000000;
            border: var(--Components-Button-Global-lineWidth, 1px) solid var(--Color-Primary-colorPrimaryHover, #4096FF);
            background: var(--Components-Button-Component-defaultBg, #FFF);
            svg {
              // width: ;
            }
          }
          &.reOrder-button {
            color: #FFF;
            border: var(--Components-Button-Global-lineWidth, 1px) solid var(--Components-Button-Global-colorPrimary, #000000);
            background: var(--Components-Button-Global-colorPrimary, #000000);
          }
        }
      }
    }
  }
}