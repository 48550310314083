.cancel-order-modal {
  width: 500px !important;

  .cancel-modal-header-container {
    .cancel-modal-header {
      color: var(--light-colorText);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    .cancel-modal-info {
      color: #475467;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .cancel-order-reason-list {
    margin-top: 20px;
    label {
      padding: 8px;
      border-radius: 8px;
      &.cancel-order-label {
        display: flex;
        align-items: center;
        gap: 16px;
        cursor: pointer;
        input {
          width: 20px;
          min-width: 20px;
          height: 20px;
          min-height: 20px;
          outline: none;
        }
        span {
          word-break: break-all;
        }
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      &:active {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    .reason-box {
      width: 100%;
      .reason-text {
        color: var(--light-colorText);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
      }

      .reason-info {
        color: var(--light-colorSecondary);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }

      .other-reason-input {
        height: 32px;
        color: var(--light-colorText);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        width: 100%;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid var(--light-colorBorder);
        background: #fff;
      }
    }
  }
}
