.group-container {
  height: 100%;
  padding-right: 20px;
  position: relative;

  .a-to-z-container{
    top: 100%;
    transform: translateY(-105%);
  }

  .group-name-container {
    width: 100%;
    padding: 4px 12px 0px 12px;

    span {
      font-size: 12px;
    }

    input {
      height: 32px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 171.429% */
      letter-spacing: 0.14px;
    }
  }

  .show-to-customer-box {
    padding: 12px;
    border-top: 1px solid var(--light-colorBorderSecondary);
    border-bottom: 1px solid var(--light-colorBorderSecondary);
    margin: 12px 0 12px 0;

    .text-label {
      color: var(--light-colorText);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .products-container {
    .product-header-container {
      padding: 0 12px;
      margin-bottom: 16px;

      .selected-product-title {
        color: var(--light-colorText);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      .selected-product-count {
        color: var(--light-darkColor25);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
      }

      .search-bar {
        border-radius: 8px;
        height: 32px;
        padding: 4.571px 6.4px;

        .search-icon {
          width: 16px;
          height: 16px;
        }

        input {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 10.971px;
          /* 91.429% */
          letter-spacing: 0.12px;
        }
      }
    }

    .selected-products-container {
      padding: 12px;
      overflow: auto;
      padding-right: 20px;

      .selected-image-box {
        min-width: 56px;
        width: 56px;
        max-width: 56px;
        position: relative;

        .product-image {
          width: 56px;
          height: 56px;
          border-radius: 8px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .product-name {
          color: var(--light-colorText);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          white-space: nowrap;
        }

        .remove-product-icon {
          position: absolute;
          top: -9px;
          right: -9px;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background-color: #98a2b3;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .remove-product-icon svg path {
          stroke: #fff;
        }
      }
    }

    .products-list-container {
      position: relative;
      overflow-y: auto;
      max-height: calc(100vh - 345px);
      min-height: calc(100vh - 345px);
      &.product-selected {
        max-height: calc(100vh - 450px);
        min-height: calc(100vh - 450px);
      }

      &:has(.loading-container){
        overflow: hidden;
      }

      &::-webkit-scrollbar {
        width: 8px;
      }
      
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
      }
      
      &::-webkit-scrollbar-thumb {
    
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.09);
      }


      .product-group-header {
        color: var(--light-darkColor25);
        padding: var(--spacing-xs, 4px) 0px var(--spacing-xs, 4px) 12px;
        background-color: #f9fafb;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
      .checkbox-input-box {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: -1px;
        padding-left: 12px;
        .image-box {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 32px;
          width: 32px;
          height: 32px;
          border-radius: 4px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        .ant-checkbox-wrapper {
          padding: 0px 12px;
          display: flex !important;
          flex-direction: row-reverse;
          border-bottom: 1px solid #eaecf0;
          width: 100%;
          span {
            padding-left: 0;
            width: 17px;
            &:not(.ant-checkbox) {
              width: 100%;
            }
            .product-item {
              width: 100%;
            }
          }
        }
        &:hover {
          background-color: #f0f0f0;
        }
      }
      .product-item {
        display: flex;
        align-items: center;
        height: 48px;
        z-index: 99;
        flex: 1;

        .product-details {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          input[type='checkbox'] {
            transform: scale(1.5);
            cursor: pointer;
          }
          span {
            white-space: nowrap;
            max-width: 248px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
.group-sidebar {
  overflow-y: auto;
  position: relative;
  width: 100%;
  height: 100%;

  .header-container {
    padding: 10px 20px 10px 20px;
    border-bottom: 1px solid var(--light-colorBorder);
    position: sticky;
    top: 0;
    background-color: #fff;
    background: #fff;
    z-index: 9;

    .sidebar-title {
      font-size: 18px;
      color: #101828;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0.18px;
    }

    .create-button {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0.12px;
      color: var(--light-primary);
      background-color: transparent;
      display: flex;
      align-items: center;
      gap: 4px;
      border: 1px dashed transparent;
      transition: all 0.2s ease;
      padding: 2px 4px;
      border-radius: 4px;
      svg {
        width: 12px;
        height: 12px;
      }
      // &:hover {
      //   border-color: var(--light-primary);
      // }
    }
  }

  .search-bar {
    max-width: calc(100% - 32px);
    margin: 14px auto 4px auto;

    input {
      overflow: hidden;
      color: var(--Colors-Text-text-placeholder, #667085);
      text-overflow: ellipsis;
      font-size: 11.636px;
      font-style: normal;
      font-weight: 400;
      line-height: 17.455px;
      /* 150% */
      letter-spacing: 0.116px;
    }
  }

  .search-bar:focus-within {
    border: 0.682px solid
      var(--Component-colors-Utility-Brand-utility-brand-100, #b2d5ff);
  }

  .group-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    margin-top: 4px;

    .group-item {
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      line-height: 146%;
      font-weight: 500;
      align-items: flex-start;
      width: 100%;
      // height: 36px;
      // max-height: 36px;
      // height: 36px;
      min-height: 36px;
      padding: 8px 20px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      position: relative;
      gap: 2px;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 2px;
        height: 100%;
        background-color: transparent;
      }
      &.active {
        &::before {
          background-color: var(--light-primary);
        }
      }
      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.02);
        .threeDotsBox {
          display: flex;
        }
      }
      &.active,
      &:hover {
        color: var(--light-primary);
        .group-item-count {
          background: #e6f4ff; // Change the background color on hover
          color: var(--light-primary); // Change the text color on hover
        }

      }
      &:hover {
        color: var(--light-primary);
        .group-item-count {
          display: none;
        }

      }

      .group-item-count {
        font-size: 12px;
        height: 20px;
        min-width: 20px;
        width: fit-content;
        padding: 2px 6px;
        background: #d9d9d9;
        text-align: center;
        border-radius: 50px;
        color: #000000a6;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
        line-height: 23px;
        transition: all 0.2s ease;
      }

      .group-item-name-count {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        align-items: center;

        .group-item-name {
          border-left: 2px solid transparent;
          transition: all 0.2s ease;
          word-break: break-all;

          &.active {
            color: #000000;
            position: relative;

            &::after {
              content: '';
              border-left: 2px solid #000000;
              position: absolute;
              left: -22px;
              height: 100%;
              top: 0;
            }
          }
        }

      }

      .threeDotsBox {
        margin-left: 8px;
        cursor: pointer;
        display: none;
        justify-content: center;
        align-items: center;
        padding: 2px;
        border-radius: 4px;
        transition: all 0.2s ease;

        svg {
          transition: all 0.2s ease;
        }

        &:hover {
          background-color: #ffffff;

          svg {
            path {
              stroke: var(--light-primary);
            }
          }
        }

        &:focus {
          background-color: #ffffff;
        }
      }

      .threeDotsBox.always-visible {
        display: flex; /* Keep the button visible when popover is open */
      }
      .always-hide {
        display: none;
      }
      .always-visible {
        display: flex !important;
      }
    }
  }
}
.group-popover {
  max-width: 200px;
  width: 100%;
  height: fit-content;
  border-radius: 12px;
  background: #fff;
  padding: 8px;

  .ant-popover-content {
    // height: 100%;
    // padding: 10px;

    .ant-popover-inner {
      height: fit-content;
      padding: 0;
      background-color: transparent;
      box-shadow: none;
      border: 1px solid #e9e9e9;

      .ant-popover-inner-content {
        .edit,
        .delete {
          // width: 180px;
          height: 42px;
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 9px 10px;
          border-radius: 6px;
          span {
            color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
            /* Base/Strong */
            font-family: 'SF Pro Text';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            margin-left: 3px;
            /* 157.143% */
          }

          &:hover {
            background: #00000005;
          }
        }

        .delete {
          span {
            color: red;
          }
        }
      }
    }
  }
}

.create-group-drawer {
    .my-drawer-content {
      .my-drawer-header {
        padding: 12px !important ;
        .ant-drawer-header-title {
          flex-direction: row-reverse;
    
          .ant-drawer-title {
            color: var(--light-colorDarkText);
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
          }
        }
      }
      // padding: 8px;
      border-radius: 16px;
      .ant-drawer-body {
        padding: 0;
    
        .fields-container {
          padding: 24px 12px 12px 12px;
    
          .section-title {
            color: var(--light-colorText);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
            letter-spacing: 0.16px;
          }
    
          .fields-box {
            .field {
              padding: 12px;
              height: 48px;
    
              &:hover {
                border-radius: 8px;
                background-color: rgba(0, 0, 0, 0.04);
                .delete-box {
                  display: block;
    
                  &:hover path {
                    stroke: #f5222d;
                  }
                }
              }
    
              .field-name {
                overflow: hidden;
                color: var(--light-colorText);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                width: 100%;
              }
    
              .create-field {
                color: var(--light-primary);
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                background: none;
                &:disabled {
                  color: var(--light-darkColor25);
                }
              }
    
              .switch-style {
                margin-left: 8px;
              }
            }
          }
    
          .error-message {
            font-size: 12px;
            color: var(--light-colorError);
          }
        }
      }
    }
  
}