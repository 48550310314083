.choose-vendor-modal {
  position: relative;
  .vendors-modal-container {
    max-width: 500px;
    height: fit-content;
    
    .connections-list {
      display: flex;
      flex-direction: column;
      max-height: 50vh;
      overflow: auto;
    }

    .search-bar {
      margin-bottom: 20px;
    }
    .no-data {
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 200px;
    }
    .button-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .clear-all-button {
        width: fit-content;
        background-color: transparent;
        &.primary {
          color: #000000;
          margin-bottom: 8px;
        }
      }
    }
    .vendors-header-container {
      margin-bottom: 20px;
      .choose-vendor-header {
        color: var(--light-colorDarkText);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }

      .user-connections {
        color: #475467;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 157.143% */
      }

      .close-icon-button {
        background: none;
        padding: 8px;
      }
    }

    .invite-button {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 157.143% */
    }

    .connection-list-row {
      padding: 10px 8px;
      border-radius: 8px;
      cursor: pointer;
      width: 100%;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
      &:active {
        background-color: rgba(0, 0, 0, 0.04);
      }
      .user-data {
        .user-profile-icon {
          min-width: 44px;
          width: 44px;
          height: 44px;
          border-radius: 100%;
          overflow: hidden;
          background-color: rgba(0, 0, 0, 0.04);
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .user-details {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px; /* 157.143% */

          .company-name {
            color: var(--light-colorText);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px; /* 157.143% */
          }

          .caption {
            color: var(--light-colorSecondary);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
      button {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        border-radius: 4px;
        &.pending {
          border-radius: 4px;
          border: var(--Components-Button-Global-lineWidth, 1px) solid
            var(--Components-Button-Component-borderColorDisabled, #d9d9d9);
          background: var(
            --Components-Button-Global-colorBgContainerDisabled,
            rgba(0, 0, 0, 0.04)
          );
          padding: 2px 8px;
          color: #00000025;

          svg {
            path {
              color: #00000015;
            }
          }
        }

        &.customer {
          border: var(--Components-Button-Global-lineWidth, 1px) solid
            var(--Colors-Base-Magenta-3, #ffadd2);
          background: var(--Colors-Base-Magenta-1, #fff0f6);
          color: #c41d7f;
          padding: 1px 7px;
        }
      }
    }
  }
}
