.order-summary {
  &__wrapper {
    margin-top: 24px;
    // background-color: #fff;
    // border-radius: 12px;
    // border: 1px solid #eaecf0;
    // padding: 20px;
    // display: flex;
    // flex-direction: column;
    // gap: 20px;

    .recharts-responsive-container {
      min-height: 300px;
    }
  }

  &__center-frame {
    display: flex;
    gap: 20px;
    align-items: center;

    .left-arrow {
      background-color: transparent;
      svg {
        rotate: 180deg;
      }

      &:disabled {
        cursor: default;
        svg {
          stroke: #ced3db;

          circle {
            stroke: #ced3db;
          }
          path {
            stroke: #ced3db;
          }
        }
      }
    }

    .right-arrow {
      background-color: transparent;

      &:disabled {
        cursor: default;
        svg {
          stroke: #ced3db;

          circle {
            stroke: #ced3db;
          }
          path {
            stroke: #ced3db;
          }
        }
      }
    }
  }

  &__container {
    border-radius: 24px;
    border: 1px solid #F2F2F7;
    background-color: #fff;
    overflow: hidden;
  }

  &__header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    background-color: #fbfbfd;
    border-bottom: 1px solid #F2F2F7;

    .customers-dropdown {
      border-radius: 14px;
      border: 1px solid #e4e7ec;
      background: #fff;
      position: relative;
      min-width: 272px;
      width: 100%;
      max-width: 272px;
      cursor: pointer;

      &__label {
        display: flex;
        gap: 8px;
        align-items: center;
        height: 46px;
        padding: 8px;
        cursor: pointer;
        justify-content: center;
        overflow: auto;
      }

      .user-image {
        width: 30px;
        min-width: 30px;
        height: 30px;
        border-radius: 100%;
      }

      .company-name {
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: auto;
        display: flex;
        align-items: center;
        display: block;
        max-width: 212px;
      }

      .image-container {
        width: 30px;
        max-width: 30px;
        min-width: 30px;
        height: 30px;
        border-radius: 100%;
        overflow: hidden;
        border: 1px solid var(--light-colorBorder);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.0392156863);

        svg {
          width: 20px;
          height: 20px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      &__box {
        display: flex;
        flex-direction: column;
        gap: 2px;
        border-radius: 14px;
        border: 1px solid #e4e7ec;
        background-color: #fff;
        position: absolute;
        top: 50px;
        width: 100%;
        overflow: auto;
        max-height: 300px;
        padding: 10px;
        z-index: 999;

        &--item {
          display: flex;
          gap: 8px;
          border-radius: 14px;
          padding: 10px;
          cursor: pointer;

          &:hover, &.active {
            background-color: #f7f7f9;
          }

          .customer-name {
            color: rgba(0, 0, 0, 0.88);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }

    .title {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      display: flex;
      align-items: center;
      min-width: 200px;
    }

    .dashboard-dropdown {
      align-items: center;
      min-width: 200px;
      display: flex;
      justify-content: end;
      .options-container {
        right: 0;
      }
    }
  }

  &__body {
    height: 406px;
    display: flex;
    // gap: 20px;

    .total-orders {
      &__container {
        width: 100%;
      }
    }
  }
}
