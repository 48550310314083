.special-price-list-page {
    .special-price-list-container {
        height: 100%;
        gap: 10px;
        max-height: calc(100vh - 130px);
        overflow: auto;
        .sidebar {
            width: 100%;
            max-width: 260px;
            min-width: 260px;
            border-radius: 10px;
            background-color: rgba(255, 255, 255, 0.60);
            backdrop-filter: blur(2px);
            // height: 100%;
            border: 1px solid var(--light-colorBorder);
            overflow: auto;
            .sidebar-header {
                border-bottom: 1px solid var(--light-colorBorder);
                width: 100%;
                padding: 10px;
                h3 {
                    &.sidebar-title {
                        color: var(--light-colorText);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px; /* 150% */
                        letter-spacing: 0.16px;
                    }
                }
                .button {
                    &.text {
                        svg {
                            path {
                                fill: var(--light-primary);
                            }
                        }
                        button {
                            gap: 4px;
                            font-size: 14px;
                        }
                    }
                }
            }
            .search-bar-wrapper {
                max-width: 100%;
                width: 100%;
                .search-bar {
                    width: calc(100% - 32px);
                    margin: 16px 16px 0 16px;
                }
            }
            ul {
                &.sidebar-list-items {
                    margin-top: 4px;
                    width: 100%;
                    overflow: auto;
                    padding: 10px;
                    max-height: calc(100% - 100px);
                    .flex {
                        &.relative {
                            transition: all 0.2s ease-in-out;
                            border-radius: 8px;
                            margin-bottom: 10px;
                            &:hover {
                                background: rgba(0, 0, 0, 0.04);
                                a {
                                    &.list-item {
                                        &:hover {
                                            background-color: transparent;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    a {
                        &.list-item {
                            padding: 8px;
                            transition: 0.2s ease-in-out;
                            width: 100%;
                            cursor: pointer;
                            border-radius: 8px;
                            overflow: hidden;
                            width: 100%;
                            &:last-of-type {
                                margin-bottom: 0;
                            }
                            &.active {
                                background: rgba(0, 0, 0, 0.04);
                            }
                            &:hover {
                                background: rgba(0, 0, 0, 0.04);
                            }
                            .company-image {
                                overflow: hidden;
                                min-width: 44px;
                                max-width: 44px;
                                width: 44px;
                                height: 44px;
                                border-radius: 100%;
                                background-color: rgba(0, 0, 0, 0.04);
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }
                            .company-details {
                                width: 70%;
                                p {
                                    &.company-name {
                                        color: var(--light-colorText);
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 22px; /* 157.143% */
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        max-width: 170px;
                                        width: 100%;
                                    }
                                    &.profile-name {
                                        color: rgba(0, 0, 0, 0.65);
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 20px; /* 166.667% */
                                    }
                                }
                            }
                        }
                    }
                    .more-icon-button {
                        width: 20px;
                        height: 20px;
                        border-radius: 4px;
                        position: absolute;
                        right: 4px;
                        top: 8px;
                    }
                }
            }
        }
        .no-data-page {
            height: 100%;
        }
        .table-container {
            max-height: calc(100vh - 100px);
            overflow: hidden;
            height: 100% !important;
            position: relative;
            .ant-table-body {
                height: 100%;
            }
            .products-price-info-table {
                overflow: hidden;
                max-height: calc(100vh - 250px);
                height: 100%;

                .ant-table-body {
                    overflow: auto !important;
                }
            }
        }
    }
}
.products-popup {
    position: relative;
    .ant-modal-content {
        padding: 16px !important;
        padding-right: 20px !important;
        min-height: 500px;
        overflow: auto;
        .ant-modal-body {
            overflow: auto;
            .customer-details-container {
                position: sticky;
                top: 0;
                background-color: #FFFFFF;
                z-index: 79;
            }
           
        }
    }
    .customer-details-container {
        .flex {
            .company-image {
                overflow: hidden;
                min-width: 64px;
                max-width: 64px;
                width: 64px;
                height: 64px;
                border-radius: 100%;
                background-color: rgba(0, 0, 0, 0.04);
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
            .text-container {
                h3 {
                    &.title-3 {
                        color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 24px; /* 150% */
                        max-width: 340px;
                    }
                }
                p {
                    &.caption {
                        color: var(--Color-Neutral-Text-colorTextLabel, rgba(0, 0, 0, 0.65));
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px; /* 142.857% */
                    }
                }
            }
        }
    }
    .popup-header {
        padding-top: 12px;
        position: relative;
        background-color: #FFFFFF;
        z-index: 2;
        h3 {
            &.title-3 {
                color: var(--light-colorText);
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; /* 120% */
                span {
                    &.products-count {
                        color: var(--Colors-Neutral-Text-colorTextDescription, rgba(0, 0, 0, 0.45));
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 18px; /* 150% */
                    }
                }
            }
        }
    }
    .product-search-section {
        padding-top: 5px;
        z-index: 2;
        background-color: #FFFFFF;
    }
    .group-list{
        padding: 5px 0;
        display: flex;
        gap: 5px;
        overflow-x: scroll;
        background-color: #FFFFFF;
        z-index: 2;
        cursor: pointer;
        p{
            &.group-name{
                display: flex;
                padding:  8px;
                justify-content: center;
                align-items: center;
                gap:  4px;
                border-radius: 8px;
                border-radius:  8px;
                border: 1px solid #F0F0F0;
    
               
                span{
                    &.product-count{
                        display: flex;
                        padding:  2px 8px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 20px;
                        min-width: 20px;
                        background:  rgba(0, 0, 0, 0.04);
                    }
                }
                &.active{
                    border: 1px solid #B2D5FF;
                    background: #E6F1FF;
                    span{
                        background: #fff;
                    }
                }
        }
        }
    }
    section {
        &.product-list-container {
            margin: 16px 0;
            margin-bottom: 0;
            background-color: #FFFFFF;
            overflow: auto;
            height: calc(100vh - 400px);
            .list-header {
                padding: 0 12px;
                margin-bottom: 16px;
                h3 {
                    &.title-3 {
                        color: var(--light-colorText);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px; /* 150% */
                    }
                }
                label {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    cursor: pointer;
                    span {
                        &.text {
                            color: var(--light-primary);
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 22px; /* 157.143% */
                        }
                    }
                    input {
                        border-radius: var(--spacing-xs, 4px);
                        border: 1px solid var(--Color-Neutral-Borders-colorBorder, #D9D9D9);
                    }
                }
            }
            .products-list-wrapper {
                height: 100%;
                // overflow: auto;
                // padding-bottom: 110px;
            }
            ul {
                &.product-list-items {
                    // height: calc(100% - 100px);
                    .group-heading-container {
                        background-color: #F9FAFB;
                        padding: 4px 12px;
                        h2 {
                            &.product-group-text {
                                color: var(--light-darkColor25);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 20px; /* 142.857% */
                            }
                        }
                    }
                    li {
                        &.list-item {
                            .product-info {
                                transition: all 0.2s ease-in-out;
                                position: relative;
                                button {
                                    &.show-sizes {
                                        display: block;
                                        z-index: 100;
                                        background-color: transparent;
                                        width: 100%;
                                        height: 100%;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                    }
                                    &.active {
                                        display: none;
                                    }
                                    &.icon-button {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        svg {
                                            transition: all 0.1s ease;
                                            width: 12px;
                                            height: 12px;
                                        }
                                        &.active {
                                            svg {
                                                transform: rotate(180deg);
                                            }
                                        }
                                    }
                                }
                                &:hover {
                                    background: rgba(0, 0, 0, 0.04);
                                }
                                span {
                                    &.product-image-container {
                                        width: 32px;
                                        height: 32px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border-radius: 4px;
                                        overflow: hidden;
                                        border: 0.6px solid var(--light-colorBorderSecondary);
                                        margin-left: 12px;
                                        img {
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                            object-position: center;
                                        }
                                        svg {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                }
                                .borderBottom {
                                    border-bottom: 1px solid #EAECF0;
                                    padding: 8px 0 8px 0px;
                                }
                                p {
                                    &.product-name {
                                        color: var(--light-colorText);
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 20px; /* 142.857% */
                                        max-width: 340px;
                                        width: 100%;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        cursor: pointer;
                                    }
                                }
                                .checkbox-container {
                                    padding: 8px 12px;
                                    input[type=checkbox] {
                                        &.productCheckbox {
                                            width: 16px;
                                            height: 16px;
                                            border-color: #D9D9D9;
                                        }
                                    }
                                }
                            }
                            label {
                                &.product-checkbox-wrapper {
                                    cursor: pointer;
                                    width: 100%;
                                    input {
                                        cursor: pointer;
                                    }
                                }
                            }
                            &:last-of-type {
                                label {
                                    &.product-checkbox-wrapper {
                                        .borderBottom {
                                            border-bottom: none;
                                        }
                                    }
                                }
                            }

                            .product-size-section {
                                padding: 12px 12px;
                                .select-all-button-container {
                                    width: 100%;
                                    display: flex;
                                    justify-content: flex-end;
                                    margin-top: 8px;
                                    button {
                                        &.select-all-button {
                                            color: var(--light-secondary);
                                            background-color: transparent;
                                            width: fit-content;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 500;
                                            line-height: 22px; /* 157.143% */
                                        }
                                    }
                                }
                                ul {
                                    &.size-list-items {
                                        margin-top: 8px;
                                        li {
                                            &.list-item {
                                                label {
                                                    cursor: pointer;
                                                    color: var(--light-colorText);
                                                    font-size: 14px;
                                                    font-style: normal;
                                                    font-weight: 400;
                                                    line-height: 22px; /* 157.143% */
                                                    padding: 8px 8px;
                                                    transition: all 0.2s ease-in-out;
                                                    border-radius: 8px;
                                                    &:hover {
                                                        background: rgba(0, 0, 0, 0.04);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }


            &::-webkit-scrollbar {
                width: 8px;
              }
              
            &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 10px;
            }
              
            &::-webkit-scrollbar-thumb {
           
            border-radius: 10px;
            background: rgba(0, 0, 0, 0.09);
            }
        }
    }
}

.ant-popover-inner {
    padding: 6px !important;
    .remove-button {
        padding: 8px 16px;
        border-radius: 6px;
        font-weight: 600;
        background-color: transparent;
        color: #000000;
        transition: all 0.2s ease;
        &:hover {
            background-color: #F5222D10;
            color: #F5222D;
        }
    }
}

