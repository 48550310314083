.connection-card {
    border: 1px solid var(--light-colorBorder);
    background-color: #FFF;
    border-radius: 24px;
    overflow: hidden;
    border: 1px solid #F2F2F7;
    position: relative;

    &__container {
        padding: 20px;
    }

    .connection-card__title {
        padding: 20px;
        background-color: #FBFBFD;
        border-bottom: 1px solid #F2F2F7;
        
        .title-text {
            color: var(--colors-text-text-primary-900, #101828);
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
        }
    }
    .flex {
        .connection {
            &__count-container {
                // margin-top: 24px;
                max-width: 50%;
                width: 100%;
                h4 {
                    &.count {
                        color: var(--Primary, #000);
                        // font-family: "Plus Jakarta Sans";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 150%; /* 30px */
                    }
                }
                p {
                    &.caption {
                        color: #465468;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px; /* 150% */
                    }
                }
            }
        }
    }
    &__footer {
        border-radius: 14px;
        border: 0.5px solid #FFBC0D;
        background: rgba(255, 188, 13, 0.10);
        padding: 7px 10px;
        margin-top: 28px;
        .connection {
            &__items {
                position: relative;
                button {
                    &.view-all-button {
                        color: #000;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        background-color: transparent;
                        line-height: 1.5;
                        border-bottom: 2px solid #000;
                    }
                }
            }
            &__item {
                width: 44px;
                height: 44px;
                min-width: 44px;
                max-width: 44px;
                border-radius: 100%;
                overflow: hidden;
                border: 1px solid #d9d9d9;
                background-color: #F2F2F7;
                cursor: pointer;
                top: 0px;
                &:not(:first-of-type) {
                    position: absolute;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
        button {
            &.invite-button {
                margin-left: auto;
                padding: 14px;
                border-radius: 14px;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                background-color: #000;
            }
        }
    }
}