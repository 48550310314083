.product-analysis {
  &__wrapper {
    margin-top: 24px;
    border-radius: 24px;
    border: 1px solid #f2f2f7;
    background-color: #fff;
    overflow: hidden;
  }

  &__header-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .header-container {
      padding: 20px 20px 0 20px;
      display: flex;
      // flex-direction: column;
      gap: 20px;
      background-color: #fbfbfd;

      .header-text {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }

      .dashboard-dropdown {
        .options-container {
          right: 0;
        }
      }
    }
  }

  &__tabs-wrapper {
    .tabs-style {
      .ant-tabs-nav {
        margin: 0;
        .ant-tabs-nav-wrap {
          background-color: #fbfbfd;
          padding: 0 20px;
          border-bottom: 1px solid #f2f2f7;

          .ant-tabs-nav-list {
            .ant-tabs-tab-btn {
              color: #465468;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
            }
            .ant-tabs-tab-active {
              .ant-tabs-tab-btn {
                color: #000;
              }
            }

            .ant-tabs-ink-bar {
              background: #ffbc0d !important;
            }
          }
        }
      }

      .analysis-container {
        height: 100%;
        max-height: 436px;
        height: 436px;
        display: flex;
        gap: 20px;

        .most-selling-product {
          &__container {
            position: relative;
            height: 100%;
            width: 100%;
            max-width: 451px;
            padding: 20px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            gap: 16px;
            border-right: 1px solid #e4e7ec;
          }

          &__header {
            display: flex;
            justify-content: space-between;

            .title {
              display: flex;
              align-items: center;
              color: #000;
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: 28px;
            }
          }

          &__data {
            overflow: auto;
            display: flex;
            flex-direction: column;
            gap: 2px;
            height: 100%;
            width: 100%;
            max-width: 100%;
            
            .product-info-card-btn {
              border-radius: 8px;
              background-color: transparent;
              width: 100%;
              max-width: 100%;

              &:hover,
              &.active {
                background-color: #f1f4f9;
                cursor: pointer;
              }
            }
          }
        }

        .chart-container {
          width: 100%;
          height: 100%;
          padding: 20px;
          position: relative;
          display: flex;
          align-items: end;
          justify-content: center;
          .custom-tooltip {
            background-color: #000;
            border-radius: 25.2px;
            padding: 3.6px 12.96px;
            display: flex;
            align-items: flex-start;
            color: #fff;
          }
        }
      }
    }
  }
}
