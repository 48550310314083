.user-dashboard-page {
    max-width: 100%;
    .content-container {
        height: calc(100vh - 230px);
        border: 1px solid #F2F2F7;
        border-radius: 14px;
        svg {
            width: 133.445px;
            height: 120px;
        }
        h2 {
            &.title-2 {
                max-width: 332px;
                width: 100%;
                text-align: center;
                color: #000;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-top: 20px;
            }
        }
        p {
            &.caption {
                max-width: 332px;
                width: 100%;
                text-align: center;
                color: #64748B;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 16px;
            }
        }
    }
}