.header {
	height: 80px;
	// position: sticky;
	// top: 20px;
	// border-radius: 12px;
	background-color: white;
	// backdrop-filter: blur(2px);
	border-bottom: 1px solid #f2f2f7;
	z-index: 100;
	padding: 19px 0px;
	position: relative;

	.container {
		padding: 0px 10px;
		@media (min-width: 1920px) {
			padding: 0px 20px;
		}
		a {
			&.logo-icon {
				max-width: 110px;
				width: 100%;
				height: auto;
				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}
		.buttons-container {
			.get-premium-button {
				border-radius: 14px;
				border: 0.5px solid #ffbc0d;
				background: rgba(255, 188, 13, 0.1);
				padding: 7px 10px;
				cursor: pointer;
				span {
					white-space: nowrap;
				}
			}
			.invite-connection-button {
				position: relative;
				width: 28px;
				height: 28px;
				svg {
					width: 100%;
					height: 100%;
				}
				span {
					&.count-capsule-badge {
						min-width: 20px;
						left: 13px;
					}
				}
			}
			.selected-company-btn {
				min-height: 40px;
				position: relative;
				width: 100%;
				max-width: 258px;
				// border: 1px solid var(--light-colorBorder);
				background: #f1f4f9;
				// box-shadow: 0px 2px 5px 0px rgba(255, 255, 255, 0.15) inset,
				//   0px -8.759px 31.627px 0px rgba(255, 255, 255, 0.15) inset,
				//   0px -5.255px 17.517px 0px rgba(255, 255, 255, 0.15) inset;
				// backdrop-filter: blur(6.130982398986816px);

				display: flex;
				align-items: center;
				border-radius: 14px;
				text-align: center;
				line-height: 14px;
				padding: 4px 10px;

				.image-container {
					width: 30px;
					height: 30px;
					overflow: hidden;
					border-radius: 100%;
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						border-radius: 100%;
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
					}
				}

				.company-name {
					flex: 1; /* Ensure it takes up available space */
					white-space: nowrap; /* Prevent text from wrapping to the next line */
					overflow: hidden; /* Hide the overflow text */
					text-overflow: ellipsis;
					text-align: start;
					text-align: left;
					color: #344056;
					font-size: 12px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}

				.action-dropdown {
					width: 32px;
					height: 32px;
					display: flex;
					justify-content: center;
					align-items: center;
					&.active {
						svg {
							transform: rotate(180deg);
						}
					}
					svg {
						width: 16px;
						height: 16px;
						path: {
							stroke: #00000073;
						}
					}
				}
			}
			.button {
				width: 28px;
				height: 28px;
				border: 1px solid var(--light-colorBorder);
				background: var(--light-colorTextLightSolid);
				// overflow: hidden;
				transition: all 0.2s ease-in-out;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				a {
					display: flex;
					align-items: center;
					justify-content: center;
				}
				position: relative;
				svg {
					width: 14.2px;
					height: 14.2px;
					path {
						transition: all 0.2s ease-in-out;
					}
				}
				&.profileIcon {
					width: 28px;
					height: 28px;
					overflow: hidden;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
					}
				}
				&:hover,
				&:focus {
					border-color: var(--light-primary);
					svg {
						path {
							fill: var(--light-primary);
						}
					}
				}
			}
			.setting-icon {
				width: 28px;
				height: 28px;
			}
			.notification-icon {
				background-color: transparent;
				position: relative;
				cursor: pointer;
				width: 24px;
				height: 24px;
				display: flex;
				align-items: center;
				justify-content: center;
				svg {
					width: 100%;
					height: 100%;
				}
				span {
					&.count-capsule-badge {
						min-width: 20px;
						top: -4px;
						left: 15px;
					}
				}
				.count {
					position: absolute;
					top: -2px;
					left: 9px;
					min-width: 17px;
					min-height: 15.73px;
					height: 15.73px;
					padding: 0 4.364px;
					width: fit-content;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 17px;
					border: 0.855px solid #fff;
					background: var(--light-button-colorError);
					text-align: center;
					line-height: 14px;
					color: #fff;
					font-size: 8px;
					font-style: normal;
					font-weight: 700;
				}
			}
			.setting-icon {
				span {
					width: 12px;
					height: 12px;
					padding: 0;
					min-width: 1px;
					&.count-capsule-badge {
						min-width: 20px;
						left: 18px;
					}
				}
			}
		}
		nav {
			&.navbar {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				.button {
					button {
						padding: 8px 16px;
						display: flex;
						align-items: center;
						gap: 4px;
						span {
							font-size: 15px;
							line-height: 24px;
						}
					}
				}
			}
		}
		li {
			&.item {
				a {
					padding: 8px 16px;
					border-radius: 14px;
					font-size: 15px;
					font-style: normal;
					font-weight: 600;
					line-height: 22px;
					white-space: nowrap;
					color: #465468;
					@media (max-width: 1440px) {
						padding: 8px 8px;
					}
					&.active {
						background-color: var(--light-colorTextLightSolid);
						color: #ffffff;
						background: var(--light-primary);
						transition: all 0.2s ease-in-out;
						p {
							&::first-letter {
								color: #ffbc0d;
							}
						}
					}
					&:hover {
						&:not(.active) {
							color: var(--light-primary);
							background-color: rgba(242, 242, 247, 0.5);
						}
					}
				}
			}
		}
	}
}

.ant-popover-content {
	a {
		&.active {
			color: var(--light-primary) !important;
		}
	}
}

.profile-popover {
	.ant-popover-inner {
		padding: 10px 4px;
		min-width: 100px;
	}
	.ant-popover-content {
		.profile-popup {
			display: flex;
			flex-direction: column;
			gap: 6px;

			.profile-text {
				h4 {
					width: 200px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				p {
					width: 200px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
			button {
				&.logout-button {
					display: flex;
					align-items: center;
					gap: 4px;
					justify-content: center;
					background-color: transparent;
					padding: 8px 8px;
					border-radius: 8px;
					&:hover {
						background-color: #00000010;
					}
				}
			}
			.profile-info {
				padding: 4px 8px;
				display: flex;
				gap: 10px;
				align-items: flex-start;
				.profile-image {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 42px;
					height: 42px;
					overflow: hidden;
					border-radius: 100%;
					border: 1px solid #00000010;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
					}
				}
			}
		}
	}
}

.select-company-popover {
	.ant-popover-content {
		border-radius: 8px;
	}

	border: 1px solid var(--light-colorBorderSecondary);
	border-radius: 8px;
	.ant-popover-content {
		max-height: 50vh;
		overflow: auto;
		&::-webkit-scrollbar {
			width: 8px;
		}
		
		&::-webkit-scrollbar-track {
		background: #f1f1f1;
		border-radius: 10px;
		}
		
		&::-webkit-scrollbar-thumb {
	
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.09);
		}
	}

	.ant-popover-inner {
		padding: 0 !important;
		min-width: 250px;
		width: 250px;
		max-width: 250px;
	}
}
