.antd-input {
  width: 100%;
  position: relative;
  // overflow: hidden;
  .hidden-span {
    visibility: hidden;
    white-space: pre; /* Prevents collapsing of spaces */
    position: absolute;
    left: 10px;
  }
  label {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    position: relative;
  }
  .size-label {
    width: 100%;
    display: flex;
    color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
    font-family: 'SF Pro Text';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 183.333% */
    letter-spacing: 0.12px;
  }
  .active-border {
    border: 1px solid var(--light-primary);
    outline: 3px solid rgba(1, 105, 230, 0.1);
    // -webkit-box-shadow: 0px 0px 5px 1px rgba(1, 105, 230, 0.2);
    // -moz-box-shadow: 0px 0px 5px 1px rgba(1, 105, 230, 0.2);
    // box-shadow: 0px 0px 5px 1px rgba(1, 105, 230, 0.2);
    background: transparent;
    z-index: 99999;
    height: 32px;
    position: absolute;
    top: 25px;
    width: calc(100% - 2px);
    border-radius: 8px;
    z-index: 1;
  }

  .custom-suffix {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 26px;
    z-index: 40;
    padding: 0px;
    left: 0px;
    width: calc(100% - 2px);
    gap: 12px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    outline: none;
    transition: all 0.1s ease-in-out;
    cursor: text;

    .value-text {
      display: inline-block;
      line-height: 32px;
      color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.14px;
      width: fit-content;
      outline: none;
      border: none;
      border-radius: 0;
      padding: 0;
      height: 32px;
      min-width: 2px;
      max-width: calc(100% - 200px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      &.suffix-text {
        color: rgba(0, 0, 0, 0.4);
        line-height: 32px;
        font-size: 14px;
      }
      &.suffix-placeholder {
        line-height: 32px;
        color: rgba(0, 0, 0, 0.4);
        font-size: 14px;
      }
    }
  }

  .hide-input {
    input {
      visibility: hidden;
      opacity: 0;
    }

    .ant-select-selection-search {
      input {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  // .suffix-input {
  //   line-height: 100%;
  //   // position: absolute;
  //   // top: 26px;
  //   z-index: 99;
  //   background-color: white;
  //   // max-width: 244px;
  //   width: 100%;
  //   border-radius: 14px 0 0 14px;
  //   height: 34px;
  //   border: 1px solid #d9d9d9;

  //   &:focus,
  //   &.active,
  //   &:hover {
  //     border: 1px solid var(--light-primary);
  //     outline: none;
  //   }
  //   span {
  //     line-height: 32px;
  //   }
  //   .placeholder {
  //     user-select: none;
  //     color: #d9d9d9;
  //     padding-left: 8px;
  //   }
  //   .placeholder-text {
  //     user-select: none;
  //     color: #d9d9d9;
  //     font-size: 14px;
  //     padding-right: 10px;

  //     &.dark {
  //       color: var(--light-colorText);
  //     }
  //   }
  //   .ant-input-outlined {
  //     border: none;
  //     outline: none !important;
  //   }
  //   .hidden-span-2 {
  //     outline: none;
  //     max-width: 100%;
  //     height: 32px;
  //     padding-left: 8px;
  //     cursor: text;
  //   }
  //   input {
  //     visibility: hidden;
  //   }
  // }

  .suffix-input {
    input {
      &.ant-input {
        width: 4ch;
        max-width: 210px;
        padding-right: 0 !important;
      }
    }
    .focused ~ .ant-input-suffix {
      color: var(--light-primary) !important;
      font-weight: 600 !important;
    }
  }

  .no-border {
    border-color: transparent !important;
  }

  input {
    border: 1px solid #d9d9d9;
    box-shadow: none !important;
    &:focus, &:hover {
      border: 1px solid var(--light-primary);
    }
  }

  &.error {
    .suffix-input,
    input {
      border: 1px solid var(--light-colorError);

      &:focus, &:hover {
        border: 1px solid var(--light-colorError);
      }
    }
  }
}

.preview-text {
  color: var(--light-colorSecondary);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  .size-preview-text {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
}

.size-input-style {
  // max-width: calc(100% - 200px);
  // position: relative;
  .ant-select-selector {
    border-right-color: transparent !important;
    margin-right: 0;
    border-radius: 8px;
    border-color: transparent !important;
    &:hover {
      border-color: transparent !important;
    }
  }
  .ant-input-group-addon {
    z-index: 99;
  }
}
