.stock-table-wrapper {
	.stock-table {
		.ant-table-body {
			overflow: auto !important;
		}

		.ant-table-cell {
			white-space: nowrap;
			word-wrap: keep-all;
			padding-left: 8px;
			padding-right: 8px;
			&.ant-table-row-expand-icon-cell {
				width: 30px;
			}

			.arrow-icon {
				cursor: pointer;
				width: fit-content;
			}

			.product {
				&__image_container {
					max-width: 44px;
					height: 44px;
					width: 100%;
					overflow: hidden;
					background-color: #f2f2f7;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 4px;
					img {
						object-fit: cover;
						width: 100%;
						height: 100%;
					}

					svg {
						width: 24px;
						height: 24px;

						path {
							fill: #a9a9a9;
						}
					}
				}
				&__name_container {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					width: 90%;
					.title-4 {
						font-weight: 500;
					}
				}
				&__size {
					color: rgba(0, 0, 0, 0.65);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px;
					max-width: 100%;

					text-wrap: wrap;
					word-break: break-all;

					span {
						margin-right: 5px;
					}
				}
			}
			.stock-container {
				color: #000;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: 22px;
			}

			.button {
				&.add-stock-button {
					button {
						span {
							color: #17b26a;
						}
						svg {
							width: 20px;
							height: 20px;
							path {
								fill: #17b26a;
							}
						}
						border-color: #17b26a;
					}
				}
				button {
					padding: 2px 10px;
					height: auto;
					gap: 4px;
					span {
						font-size: 12px;
						font-style: normal;
						font-weight: 600;
						line-height: 28px; /* 166.667% */
					}
					svg {
						width: 16px;
						height: 16px;
					}
				}
			}
		}

		.ant-table-expanded-row {
			td.ant-table-cell:first-of-type {
				padding-left: 0 !important;
				padding-right: 0 !important;
				.expanded_table {
					.ant-table-body {
						overflow: auto !important;
					}
					.ant-table-header {
						display: none !important;
					}
					.ant-table-row {
						background-color: #f9fafb !important;
					}
					.ant-table {
						margin-left: 0 !important;
						margin-right: 0 !important;
					}
				}
			}
		}
	}

	.pagination-wrapper {
		margin-top: 16px;
		.ant-pagination {
			.ant-pagination-item-active {
				border-color: #000000;
				background-color: #000000;
				a {
					color: #ffffff;
				}
			}
			.anticon-double-right,
			.anticon-double-left {
				&.ant-pagination-item-link-icon {
					color: #000000;
				}
			}
			.ant-pagination-item {
				height: 32px;
				width: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			button {
				&.pagination-button {
					background-color: #ffffff;
					height: 32px;
					padding: 8px 20px;
					color: #000000;
					font-weight: 600;
					border: 1px solid #d9d9d9;
					border-radius: 6px;
				}
				&:disabled {
					opacity: 0.6;
					cursor: default;
				}
			}
		}
	}
}
