.unblock-connection-popup-modal {
    .ant-modal-content {
        padding: 48px !important;
    }
    .block-icon {
        height: 44px;
        width: 44px;
        padding: 7px 10px;
        border: 0.5px solid #FFBC0D;
        background: rgba(255, 188, 13, 0.10);
        svg {
            width: 24px;
            height: 24px;
            path {
                stroke: #000;
                stroke-opacity: 1;
            }
        }
    }
    h3 {
        &.title-3 {
            color: var(--Colors-Neutral-colorTextBase, #000);
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 100% */
        }
    }
    p {
        &.caption {
            color: #344056;
            text-align: center;
            font-family: "SF Pro Text";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            max-width: 332px;
        }
    }
    .buttons-container {
        margin-top: 16px;
    }
    button {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 10px 36px;
        &.blocked_button {
            color: #000;
            background-color: #F2F2F7;
        }
        &.secondry_button {
            color: #FFF;
            background-color: #52C41A;
        }
    }
}