.most-order-product {
  &__container {
    max-width: 454px;
    height: 100%;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }

    .dashboard-dropdown {
      .options-container {
        right: 0;
      }
    }
  }

  &__data {
    overflow: auto;
    height: 100%;
  }
}
