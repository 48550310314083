.stock_in_table {
	&__wrapper {
		padding-top: 32px;
	}
	&__page_header {
		margin-bottom: 16px;
		h3 {
			&.title-3 {
				white-space: nowrap;
			}
		}
		button {
			&.back_button {
				background: #EDEDED;
				padding: 7px;
				border-radius: 4.364px;
				svg {
					width: 16px;
					height: 16px;
				}
			}
		}
		.search-bar {
			max-width: 568px;
			background-color: #FFF;
		}
	}
	&__page_content {
		.product_details_container {
			.image_container {
				max-width: 40px;
				max-height: 40px;
				width: 40px;
				height: 40px;
				overflow: auto;
				border-radius: 4px;
				img {
					width: 100%;
					height: 100%;
					object-fit: 100% 100%;
					object-position: center;
				}
			}
			.product_name_container {
				.product_icon {
					width: 16px;
					height: 16px;
					svg {
						width: 100%;
						height: 100%;
					}
				}
				h4 {
					&.title-4 {
						color: #000;
						font-size: 14px;
						font-style: normal;	
						font-weight: 600;
						line-height: 22px; /* 157.143% */
					}
				}
				span {
					&.text {
						color: rgba(0, 0, 0, 0.65);
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px; /* 166.667% */
					}
				}
			}
		}
		button {
			&.icon-button {
				border-radius: 4px;
				svg {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
}