.edit-product-modal {
  .ant-modal-content {
    padding: 0 20px 20px 20px !important;
  }

  .edit-product-header-container {
    position: sticky;
    top: 0;
    z-index: 200;
    background-color: var(--light-colorTextLightSolid);
    .back-button {
      background-color: transparent;
      font-weight: 600;
      font-size: 22px;
    }
    .edit-product-header-text {
      color: var(--light-colorText);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
    }

    .edit-product-actions {
      .close-icon {
        padding: 8px;
        cursor: pointer;
        &:hover{
          background-color: rgb(235, 235, 235);
          border-radius: 8px;
        }
      }
    }
  }

  .edit-product {
    margin-top: 24px;
    overflow: auto;
    height: 100%;
    max-height: calc(100% - 80px);
  }
}
