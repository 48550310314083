.search-bar {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 100%;
  border-radius: 14px;
  border: 0.727px solid var(--Color-Neutral-Borders-colorBorderSecondary, #F0F0F0);
  background: #f9fafb;
  // box-shadow: 0px 0.727px 1.455px 0px rgba(16, 24, 40, 0.05);
  &:focus-within {
    border: 0.682px solid var(--light-primary);
  }
  .search-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
  }

  input {
    border: none;
    outline: none;
    flex: 1;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.16px;
    line-height: 10.971px; /* 91.429% */
    letter-spacing: 0.12px;
    color: #667085;
    background: transparent;

    &::placeholder {
      color: #667085;
      font-size: 12px;
    }
  }
}
