.select-input-wrapper {
  position: relative;
  width: 100%;
  label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    gap: 4px;
    span {
      &.label-text {
        font-size: 12px;
        line-height: 22px;
      }
    }
    .ant-select {
      width: 100%;
      height: 34px;
    }
    .ant-select-selector {
      border-radius: 8px;
      padding: 1px 12px 1px 8px !important;
      // border: 1px solid var(--light-colorBorder) !important;
      &.error {
        border: 1px solid var(--light-colorError);
      }
      &:focus {
        border: 1px solid var(--light-primary);
        outline: none;
      }
      .ant-select-selection-placeholder {
        color: #667085;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 200% */
        letter-spacing: 0.12px;
      }
      .ant-select-selection-overflow {
        .ant-select-selection-overflow-item {
          .ant-select-selection-item {
            border-radius: 4px;
            border: 1px solid #D9D9D9;
            background: #FFFFFF;
            .ant-select-selection-item-content {
              color: rgba(0, 0, 0, 0.88);
            }
            .ant-select-selection-item-remove {
              span {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
        .ant-select-selection-search {
          margin-inline-start: 0;
        }
      }
    }
  }

  .up-arrow {
    svg {
      rotate: 180deg;
    }
  }
}

.ant-select-selector {
  border: 1px solid var(--light-borderColor);
  &:hover {
    border: 1px solid var(--light-borderColor);
  }

  &:focus-within {
    border: 1px solid var(--light-primary) !important;
    outline: none;
    box-shadow: none !important;
    &:hover {
     border: 1px solid var(--light-primary) !important;
    }
  }
}

.ant-popover {
  &.ant-popconfirm {
    .ant-btn-primary {
      background-color: var(--light-primary);
      &:hover {
        background-color: var(--light-primary);
      }
      &:active {
        background-color: var(--light-primary);
      }
    }
  }
}

.ant-select-outlined { 
  &:hover .ant-select-selector{
    border: 1px solid #D9D9D9 !important;
  }
  &:focus-within {
    &:hover .ant-select-selector{
      border: 1px solid var(--light-primary) !important;
 
     }
  }
}