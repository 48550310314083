@import "./addProducts.scss";

.products-list-container {
    flex: 1;
    position: relative;
    gap: 10px;
    width: 100%;
    .sidebar-container {
        min-height: calc(100vh - 125px) !important;
        max-height: calc(100vh - 125px) !important;
        max-width: 256px;
        min-width: 256px;
        height: calc(100% - 10px);
        min-height: calc(100% - 10px);
        flex: 1;
        padding: 0;
        border-radius: 12px;
        border: 1.5px solid #fff;
        background: rgba(255, 255, 255, 0.6);
        position: relative;
        overflow-x: hidden;
        width: 100%;
    }
}