.counter-button {
  border: 1px solid var(--light-primary);
  border-radius: 8px;
  height: 100%;
  width: 100%;
  max-width: 127px;
  height: 32px;
  overflow: hidden;
  button {
    min-width: 25px;
    max-width: 25px;
    height: 100%;
    // border-radius: 8px 0px 0px 8px;
    background: var(--light-primary);
  }
  .minus {
    // border-right: 1px solid var(--light-primary);
  }
  input {
    height: 100%;
    border: none;
    padding: 4px 4px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    color: var(--light-primary);
    width: 100%;
    font-size: 12.091px;
    line-height: 19px; /* 157.143% */

    &:focus-visible {
      outline: none;
    }
  }
  .plus {
    // border-left: 1px solid var(--light-primary);
  }
}
