.custom-input-field {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;

	.label {
		display: flex;
		gap: 5px;
		color: "#344056";
		font-family: "SF Pro Text";
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin-bottom: 4px;

		.is-required {
			font-size: 14px;
			color: var(--light-colorError);
		}
	}

	.input-wrapper {
		display: flex;
		align-items: stretch;
		width: 100%;
		height: 100%;
		height: 32px;
		border: 1px solid #d9d9d9;
		outline: none;
		background: var(--light-colorTextLightSolid);
		border-radius: 14px;

		.custom-input {
			width: 100%;
			padding: 10px 8px;
			font-size: 14px;
			line-height: 200%;
			gap: 12px;
			border-radius: 14px;
			border: none;
			outline: none;
			background: var(--light-colorTextLightSolid);
			letter-spacing: 0.12px;
			transition: all 0.1s ease-in-out;
			&::placeholder {
				color: var(--Colors-Neutral-Bg-colorBgMask, rgba(0, 0, 0, 0.45));
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
		}
		&:focus-within:not(:disabled),
		&:hover:not(.is-disabled) {
			border: 1px solid var(--light-primary);
		}

		&.is-disabled {
			background-color: rgba(0, 0, 0, 0.08) !important;

			.custom-input {
				background: transparent;
			}
		}

		&.is-error {
			border: 1px solid var(--light-colorError) !important;
		}
	}

	.start-adornment {
		display: flex;
		align-items: center;
		padding: 0 6px;
		cursor: pointer;
	}

	.end-adornment {
		display: flex;
		align-items: center;
		padding: 0 6px;
		cursor: pointer;
	}

	.error-text {
		font-size: 14px;
		color: var(--light-colorError);
		margin-top: 5px;
	}
}
