.status-switch-wrapper {
  display: flex;
  width: fit-content;
  .switch {
    display: flex;
    // align-items: center;
    width: fit-content;
    gap: 1;
    border-radius: 4px;
    // border: 1px solid #f0f0f0;

    &.in-process-myorder {
      background-color: transparent;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: var(--light-primary);
      padding: 4px 6px;

      &.order-inprocess-switch {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--light-primary);
        border: 1px solid #fff;
        border-radius: 8px;
        color: #fff;
        width: 101px;
      }
    }
    button {
      background-color: transparent;
      padding: 4px 6px;
      color: inherit;
      font-size: 13.091px;
      font-style: normal;
      font-weight: 500;

      &.arrow-down-button {
        border-left: 1px solid #f0f0f0;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
    &.inProgress {
      // background-color: #faad14;
      background-color: var(--light-primary);
      color: var(--light-colorTextLightSolid);
      &.outlined {
        color: var(--light-primary);
      }
      svg {
        width: 12px;
        height: 12px;
        path {
          fill: var(--light-colorTextLightSolid);
        }
      }
    }
    &.completed {
      color: var(--light-button-colorTextLightSolid);
      background-color: #a140ff;
      button {
        font-weight: 400;
      }
    }
    &.accepted {
      background-color: var(--light-button-colorGreen900);
      color: var(--light-button-colorTextLightSolid);
      &.outlined {
        color: var(--light-button-colorGreen900);
        background-color: transparent;
      }

      .my-order-accepted {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          path {
            stroke: #00000073;
          }
        }
      }
    }
    &.dispatched {
      // background-color: #f2f7fe;
      color: var(--light-button-yellow900);

      &.order-detail-dispatched {
        background-color: #ff9500;
        color: #fff;
        width: 107px;
        height: 44px;
        justify-content: center;
        display: flex;
        align-items: center;
      }
      &.dispatched-bg {
        background-color: #ff9500;
        color: #fff;
        width: 117px;
        // height: 44px;
        justify-content: center;
        display: flex;
        align-items: center;
      }
    }
    &.pending {
      background-color: rgba(0, 0, 0, 0.04);
      color: var(--light-darkColor25);
    }
    &.partially-dispatched {
      background-color: var(--light-button-yellow900);
      color: var(--light-button-colorTextLightSolid);
    }
    &.rejected,
    &.cancelled {
      // background-color: #fff1f0;
      color: var(--light-button-colorError);
      button {
        cursor: not-allowed;
      }

      &.order-detail-cancelled {
        background-color: #ED1A0B;
        color: #fff;
        width: 107px;
        height: 44px;
        justify-content: center;
        display: flex;
        align-items: center;
      }
    }
    .outlined {
      // padding-left: 0;
    }
  }
  .status-popup {
    display: none;
    &.active {
      display: flex;
    }
  }
}

.status-popover {
  max-width: 200px;
  width: fit-content;
  .ant-popover-inner {
    padding: 4px 0;

    .status-box {
      padding: 1px 6px;

      &.in-progress {
        button {
          color: var(--light-primary);
          svg {
            g {
              path {
                stroke: var(--light-primary);
                stroke-opacity: 1;
              }
            }
          }
        }

        &.active {
          button {
            background: rgba(0, 0, 0, 0.02);
          }
        }
      }
      &.dispatch,
      &.partially-dispatch {
        white-space: nowrap;
        button {
          color: var(--light-button-yellow900);
          svg {
            path {
              stroke: var(--light-button-yellow900);
            }
          }
        }
      }
      &.cancel-order {
        button {
          color: var(--light-colorError);
        }
      }
      &.received,
      &.partially-received {
        white-space: nowrap;
        button {
          svg {
            path {
              stroke: #a140ff;
            }
          }
          color: #a140ff;
        }
      }

      button {
        display: flex;
        align-items: center;
        padding: 9px 10px;
        border-radius: 6px;
        gap: 12px;
        background: none;
        width: 100%;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;

        &:hover {
          background: rgba(0, 0, 0, 0.02);
        }

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
