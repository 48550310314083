.dispatch-order-modal {
  width: 668px !important;
  .dispatch-modal-container {
    position: relative;
    .ant-table-wrapper {
      .ant-table {
          .ant-table-container {
              .ant-table-content {
                  overflow: auto;
                  max-height: 43vh;

                  table {
                    .ant-table-thead {
                      position: sticky !important;
                      top: 0 !important;
                      background: white;
                      z-index: 12;
                    }
                  }
              }
          }
      }
    }
    .header-container {
      .title {
        color: var(--light-colorDarkText);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }

      .info-text {
        color: #475467;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .dispatch-details-table {
    
      table {
        border: 1px solid var(--light-colorBorderSecondary);
      }

      th {
        color: var(--light-colorTextTertiary);
        background-color: #fff;
        padding: 12px 8px;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }

      td {
        padding: 8px 8px;
      }

      .product-name-box {
        .product-image-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 54px;
          height: 54px;
          min-width: 54px;
          min-height: 54px;
          border-radius: 5.838px;
          border: 0.844px solid #eaecf0;
          overflow: hidden;
          .product-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
          svg {
            width: 24px;
            height: 24px;
  
            path {
              fill: #a9a9a9;
            }
          }
        }
        .product-name {
          color: var(--light-colorText);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          word-wrap: break-word;
          word-break: break-word;
        }

        .qty-dispatched {
          color: var(--light-button-yellow900);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
      .unit {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        color: var(--light-colorTextTertiary);
        word-wrap: break-word;
        word-break: break-word;
      }

      .size-text {
        color: var(--light-colorText);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        word-wrap: break-word;
        word-break: break-word;
      }

      .remaining-qty-text {
        white-space: nowrap;
        color: var(--light-colorSecondary);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .error {
        input {
          border: 1px solid var(--light-colorError);

          &:focus {
            outline: 3px solid rgba(255, 148, 148, 0.1) !important;
          }
        }
      }

      .dispatched-text {
        display: flex;
        align-items: center;
        gap: 6px;
        color: var(--light-button-yellow900);

        svg {
          path {
            stroke : var(--light-button-yellow900)
          }
        }
      }

      .remaining-exceed-qty-text {
        color: var(--light-colorError);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .qty-text {
        .quantity {
          color: var(--light-colorText);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
        .orderUnit {
          color: var(--light-colorTextTertiary);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .price-per-unit-text {
        .price {
          color: var(--light-colorText);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
        }
      }

      .subtotal-text {
        color: var(--light-colorText);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
      }

      .ant-table-cell {
        &::before {
          display: none;
        }
      }
    }

    .dispatch-modal-content {
      position: relative;
      min-height: 200px;
    }
  }

  .ant-modal-footer {
    margin-top: 20px !important;
  }
}
