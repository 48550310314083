.transportation-detail-modal {
  width: 668px !important;
  .transportation-modal-container {
    .header-container {
      .title {
        color: var(--light-colorDarkText);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }

      .info-text {
        color: #475467;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .transportation-modal-content {
      input {
        height: 32px;
        font-size: 14px;
        &:disabled {
          opacity: 0.7;
          background-color: rgba(0, 0, 0, 0.04);
          cursor: not-allowed;
          &:hover {
            border: 1px solid #D9D9D9;
          }
        }
        label span {
          font-size: 12px !important;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
      }
      label span {
        color: var(--light-colorText);
        font-size: 12px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }

      .transport-info {
        padding-bottom: 24px;
      }

      .input-field-box {
        margin-top: 10px;
      }

      .delivery-info-container {
        padding-top: 24px;
        border-top: 1px solid var(--light-colorBorderSecondary);

        .delivery-header-text {
          color: var(--light-colorDarkText);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }

        .delivery-info-text {
          color: var(--light-colorSecondary);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }

        .attach-file-box {
          background: transparent;
          margin-top: 10px;
          padding: 0 7px;

          .attach-file-text {
            color: var(--light-primary);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }
        }

        .attachments-files-container {
          margin-top: 10px;

          .selected-files-list {
            .selected-file {
              position: relative;
              width: 100px;

              .file-preview {
                .file-box {
                  width: 100px;
                  height: 100px;
                  border-radius: 8px;
                  border: 0.87px solid #eaecf0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  overflow: hidden;

                  img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                    object-position: center;
                  }
                }

                .file-name {
                  color: var(--light-colorText);
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: center;
                  display: inline-block; /* Ensure it's treated as a block element */
                  max-width: 100px; /* Set the maximum width */
                  white-space: nowrap; /* Prevent the text from wrapping to the next line */
                  overflow: hidden; /* Hide any overflowing content */
                  text-overflow: ellipsis;
                }
              }

              .delete-icon {
                background: transparent;
                position: absolute;
                top: 5px;
                right: 5px;
                z-index: 999;
              }
            }
          }
        }
      }
    }
  }
}
