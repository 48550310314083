.select-company-profile-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  // height: calc(100vh - 125px);
  width: 100%;
  height: 100%;
  // margin-top: 40px;
  height: 100%;
  .select-profile-card {
    height: 100%;
    width: 100%;
    .card-header {
      display: flex;
      flex-direction: column;
      gap: 10px;
      text-align: center;
      margin-bottom: 60px;
      h3 {
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 44px;
        &.title-welcome {
          background: linear-gradient(91deg, #2b94fe 0.21%, #e660b4 63.15%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        &.title-black {
          color: #000;
        }
      }

      span {
        &.select-profile-text {
          // color: var(--light-colorText);
          // font-size: 24px;
          // font-style: normal;
          font-weight: 600;
          // line-height: 38px;
          // margin-top: 32px;
        }
      }
    }
    .company-selection {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 10px;
      .company-card {
        cursor: pointer;
        width: 337px;
        height: 300px;
        padding: 50px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        border: 1px solid var(--light-colorBorder);
        background: rgba(0, 0, 0, 0.02);

        &:hover {
            background: #fff;
            border: 1px solid var(--light-primary);
        }

        .content-container {
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: center;

          .image-wrapper {
            position: relative;
            .count-capsule-badge {
              min-width: 20px;
              top: auto !important;
              left: auto !important;
              bottom: 0 !important;
              right: 0 !important;
            }
            .image-container {
              position: relative;
              color: rgba(0, 0, 0, 0.45);
              width: 72px;
              height: 72px;
              border-radius: 100%;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgba(0, 0, 0, 0.04);
              font-size: 29.25px;
              font-style: normal;
              font-weight: 600;
              line-height: 34.5px;
              border: 1px solid rgba(0, 0, 0, 0.04);
  
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }

          }


          .user-info {
            text-align: center;
            .designation-button {
              border-radius: 14px;
              border: 0.5px solid #FFBC0D;
              background: rgba(255, 188, 13, 0.10);
              padding: 7px 10px;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 10px;
              width: fit-content;
              text-align: center;
              margin-top: 8px;
            }
            .company-name {
              color: var(--light-colorText);
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
            }
            .user-name {
              color: var(--light-colorSecondary);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
  .button {
    &.primary {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 100px;
      margin-bottom: 40px;
      button {
        width: 200px;
      }
    }
  }
}
