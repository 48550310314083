.message-box {
  display: flex;
  gap: 12px;
  max-width: 80%;

  .profile-icon-text {
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
    border: 1px solid var(--light-colorBorder);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  .message_side_left {
    width: 100%;
    min-width: 32px;
    max-width: 32px;
    height: 100%;
    max-height: 32px;
    border-radius: 100%;
  }

  .message_side_right {
    display: flex;
    flex-direction: column;
    border: 1px solid #eaecf0;
    padding: 12px;
    gap: 12px;

    .message-detail {
      display: flex;
      gap: 8px;
      justify-content: space-between;

      .user-name {
        color: var(--light-colorText);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        // line-height: 22px;
      }
    }

    .user-message-box {
      display: flex;
      display: inline-flex;
      flex-wrap: wrap;
      // flex-direction: column;
      gap: 4px;

      .user-message {
        color: rgba(0, 0, 0, 0.80);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        word-break: break-word;
        word-wrap: break-word;
      }

      .date-and-time {
        color: var(--light-colorTextTertiary);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        text-align: end;
        margin-left: auto;
        margin-top: auto;
      }
    }
  }

  &.sent {
    justify-content: end;
    margin-left: auto;

    .message_side_right {
      background-color: #e8f1fc;
      border-radius: 8px 0px 8px 8px;
      border: none;

      .date-and-time {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  &.received {
    justify-content: flex-start;
    margin-right: auto;

    .message_side_right {
      background-color: #f9fafb;
      border-radius: 0px 8px 8px 8px;
    }
  }
}
