.add_stock {
	&__page {
		height: 100%;
		overflow: auto;
		padding-bottom: 24px;
	}
	&__form_container {
		max-width: 752px;
		width: 100%;
		margin: 30px auto 0 auto;
		.form__header {
			margin-bottom: 24px;
			button {
				&.back_button {
					background: #ededed;
					padding: 7px;
					border-radius: 4.364px;
					svg {
						width: 16px;
						height: 16px;
					}
				}
			}
		}
		.form_body {
			background-color: #fff;
			padding: 32px;
			border-radius: 24px;
			form {
				&.add_stock__form {
					.suggestion_input_container {
						position: relative;
					}
					.stock_count_input {
						height: 180px;
						padding: 10px;
						border-radius: 24px;
						background: #f9fafb;
						margin: 24px 0;
						p {
							&.caption {
								color: #000;
								font-size: 16px;
								font-style: normal;
								font-weight: 500;
								line-height: normal;
							}
						}
						input {
							color: #000;
							font-size: 48px;
							font-style: normal;
							font-weight: 600;
							line-height: normal;
							text-align: right;
							border: none;
							background-color: transparent;
							max-width: 50%;
							width: 100%;
							margin-left: auto;
							caret-color: #0169e6;
							caret-shape: bar;

							&::placeholder {
								color: #d9d9d9;
							}

							&:focus-visible {
								border: none;
								outline: none;
							}
						}
						p {
							&.size-unit {
								color: #d9d9d9;
								font-size: 48px;
								font-style: normal;
								font-weight: 590;
								line-height: normal;
								width: 50%;
							}
						}
					}
					.buttons_container {
						margin-top: 44px;
						width: 100%;
						.secondary {
							width: 100%;
							button {
								width: 100%;
								border-radius: 14px;
								background: #f2f2f7;
								height: 40px;
								border: none;
							}
						}
						.primary {
							width: 100%;
							button {
								width: 100%;
								height: 40px;
							}
						}
					}
				}
			}
		}
	}
}

.more_dropdown_container {
	max-height: 35vh;
	overflow: auto;
	border-radius: 14px;
	border: 1px solid var(--Background-colorBgContainer, #fff);
	background: var(--Background-colorBgElevated, #fff);
	box-shadow:
		0px 6px 16px 0px rgba(0, 0, 0, 0.08),
		0px 3px 6px -4px rgba(0, 0, 0, 0.12),
		0px 9px 28px 8px rgba(0, 0, 0, 0.05);
	.ant-popover-inner-content {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
	.dropdown_item {
		max-width: 200px;
		button {
			color: rgba(0, 0, 0, 0.65);
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: 1;
			border-radius: 8px;
			border: 1px solid #e8ecf2;
			padding: 8px 12px;
			background-color: #fff;
			max-width: 200px;
			min-width: 150px;
			width: 100%;
			text-align: left;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 6px;
			span {
				max-width: 100px;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
			&:hover {
				background-color: #f1f4f9;
			}

			.count {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 5px 7px;
				min-width: 20px;
				min-height: 20px;
				height: 20px;
				border-radius: 50px;
				border: 1px solid #dfe4ed;
			}

			.title {
				max-width: max-content;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		&.active {
			button {
				border: 1px solid #000;
				background: #000;
				color: #fff;

				.count {
					background-color: #fff;
					color: #000;
					border-radius: 50px;
				}
			}
		}
	}
}
