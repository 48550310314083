.download-reciept-modal {
  width: 668px !important;

  .download-reciept-header-container {
    .download-reciept-header {
      color: var(--light-colorDarkText);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
    }

    .download-reciept-info {
      color: #475467;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .download-reciept-modal-content {
    margin-top: 20px;

    .download-reciept-frame {
      height: 40px;

      span {
        color: var(--light-colorText);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
      }
    }

    .divider {
      width: 100%;
      margin: 8px 0px;
      height: 1px;
      background-color: #F0F0F0;
    }
  }
}
