.bulk-import-page-container {
	padding-bottom: 10px;
	height: 100%;
	width: 100%;
	// overflow-y: auto;
	.header__text {
		margin-bottom: 32px;

		.main-title {
			color: var(--colors-text-text-primary-900);
			font-family: "SF Pro Text";
			font-size: 28px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
		.caption {
			color: #465468;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			margin-top: 4px;
		}
	}

	.content-container {
		display: grid;
		grid-template-columns: minmax(0, 1fr) 1fr;
		gap: 24px;

		@media (max-width: 1024px) {
			grid-template-columns: 1fr;
		}

		.content-box {
			max-height: calc(100vh - 220px);
			display: flex;
			flex-direction: column;
			gap: 24px;
			overflow-y: auto;

			.title {
				color: #000;
				font-family: "Plus Jakarta Sans";
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}

			.image-info {
				border-radius: 24px;
				background: #f9fafb;
				height: 100%;
				overflow-y: auto;
				padding: 32px;

				.stepper-box {
					position: relative;
					padding: 24px;

					&:not(:last-child):before {
						position: absolute;
						content: "";
						width: 2px;
						height: 100%;
						background: #eaecf0;
						left: 39.5px;
					}

					.download-sample-file {
						margin-top: 20px;
						svg {
							path {
								stroke: #ff9500;
							}
						}
					}

					.sample-csv-img {
						width: 100%;
						margin-top: 15px;
					}

					.stepper-count {
						position: relative;
						z-index: 2;
						padding: 6.4px;
						display: flex;
						width: 32px;
						height: 32px;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						gap: 12.8px;
						border-radius: 16px;
						border: 1.28px solid #ffbc0d;
						background: #fff;
					}

					.title {
						font-family: "SF Pro Text";
					}
				}
			}

			@media (max-width: 1024px) {
				max-height: fit-content;
			}
		}

		.file-upload-content-box {
			height: calc(100vh - 220px);
			max-height: calc(100vh - 220px);
			min-height: calc(100vh - 220px);
			overflow-y: auto;
			display: flex;
			flex-direction: column;
			gap: 24px;

			.upload-container {
				flex-grow: 1;
				border-radius: 14px;
				border: 2px dashed #000;
				padding: 10px;
				display: grid;
				place-items: center;
				position: relative;
				background: white;

				.upload-circle-wrapper {
					width: 400px;
					height: 400px;
					border-radius: 9999px;
					// position: relative;

					@media (max-width: 1536px) {
						width: 300px;
						height: 300px;
					}

					.upload-circle {
						border-radius: 9999px;
						width: 100%;
						height: 100%;
						background: #f9fafb;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						gap: 24px;

						.upload-icon svg {
							height: 30px;
							width: 30px;
							path {
								stroke: #000;
							}
						}

						p.text {
							color: #64748b;
							text-align: center;
							font-family: "SF Pro Text";
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
						}

						label {
							border: 1px solid rgba(0, 0, 0, 0.25);
							background-color: transparent;
							width: 180px;
							cursor: pointer;
							text-align: center;
						}

						&.drag-active {
							box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
						}

						&.file-upload-error {
							gap: 20px;
							background: rgba(245, 34, 45, 0.05);

							svg {
								width: 40px;
								height: 40px;
							}

							.size-error {
								color: #f5222d;
								line-height: 20px;
								margin-top: 8px;
							}
						}

						p.error-text {
							color: #f5222d;
							font-weight: 500;
						}

						&.file-loading {
							background: rgba(255, 188, 13, 0.05);
						}

						&.file-success {
							gap: 15px;
							background: rgba(23, 178, 106, 0.05);
							.text-wrapper {
								width: 80%;
								word-break: break-all;
								p {
									margin-bottom: 5px;
								}
							}

							p.text {
								padding: 0 20px;
							}
						}

						p.under-line-text {
							text-decoration: underline;
							font-weight: 500;
							color: #000;
							text-underline-offset: 5px;
							cursor: pointer;
						}
					}

					.file-info-container {
						position: absolute;
						width: 90%;
						bottom: 10px;
						left: 50%;
						transform: translateX(-50%);
						// top: 310px;
						// display: flex;
						// justify-content: center;

						p.text {
							color: #64748b;
							text-align: center;
							font-family: "SF Pro Text";
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
							a {
								color: #4272f6;
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
	}

	&:has(.review-container) {
		overflow-y: auto;
		position: relative;
	}

	.review-container {
		.sticky-top {
			position: sticky;
			z-index: 2;
		}

		p.title {
			color: #000;
			font-family: "Plus Jakarta Sans";
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}

		.error-container {
			margin-top: 24px;
			border-radius: 8px;
			border: 1px solid #ffa39e;
			// color : #F5222D;
			background: #fff2f0;
			padding: 8px;
			margin-bottom: 24px;

			p.error-text {
				font-size: 14px;
				color: #f5222d;
				font-weight: 600;
			}

			p.error-resolved-text {
				font-size: 12px;
				color: #000;
				font-weight: 500;

				span {
					color: #f5222d;
				}
			}

			button {
				background: transparent;
				color: #f5222d;
				border: 1px solid #ffa39e;
				font-size: 14px;
				font-weight: 600;
				cursor: pointer;

				&:disabled {
					color: #ffa39e;
				}
			}
		}
		.review-table-container {
			max-width: 100%;
			overflow-x: auto;
			height: calc(100vh - 300px);
			max-height: calc(100vh - 280px);
			overflow-y: auto;
			margin-bottom: 10px;
			margin-top: 24px;
			position: relative;
			scroll-behavior: smooth;
			box-shadow: 0px 10px 20px 0px rgba(16, 24, 40, 0.09);
			.review-table {
				border-spacing: 0;
				thead {
					position: sticky;
					top: 0;
					z-index: 2;
					background: #f9fafb;
				}

				th,
				td {
					// padding: 12px 24px;
					padding: 8px;
					text-align: left;
					font-size: 14px;
					font-weight: 500;
					color: #000;
					min-width: 250px;
					position: relative;
					border: 1px solid #f2f2f7;

					&.empty {
						background: #f9fafb;
					}
					input {
						outline: none;
						border: 0px;
					}

					.input {
						padding: 4px 8px;
						width: 100%;
						border-radius: 8px;
						background: #fff;
						outline: none;
						border: 1px solid transparent;
						font-size: 15px;

						&:focus {
							border: 1px solid #465468;
						}

						&:focus-within {
							border: 1px solid #465468;
						}

						&.error {
							border: 1px solid #ffa39e;
							padding-right: 28px;

							&:focus {
								border: 1px solid #ffa39e;
							}
						}

						&.select-input {
							padding: 0;
							position: relative;

							& input {
								padding: 4px 8px;
								background: transparent;
							}

							.dropDown {
								position: absolute;
								top: 110%;
								right: 0;
								max-height: 200px;
								width: 200px;
								background: white;
								z-index: 2;
								overflow-y: auto;
								padding: 10px;
								box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
								li {
									padding: 5px;
									cursor: pointer;

									&:hover {
										background: #fff2f0;
									}
								}
							}
						}
					}

					.error-tooltip {
						position: absolute;
						top: 50%;
						translate: 0 -50%;
						width: 20px;
						height: 20px;
						right: 14px;
						svg {
							color: #f5222d;
						}
					}

					&:has(.select-input) {
						padding-right: 35px;

						.error-tooltip {
							right: 8px;
						}
					}
				}

				.border-t {
					td {
						border-top: 8px solid #f2f2f7;
					}
				}
			}
		}
	}

	@media (max-width: 1024px) {
		overflow-y: scroll;
	}
}

.waiting-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 48px;
	color: #000;
	text-align: center;

	.lottie-container {
		border-radius: 14px;
		border: 0.5px solid #ffbc0d;
		background: rgba(255, 188, 13, 0.1);
	}
	.main-title {
		font-family: "SF Pro Text";
		font-size: 24px;
		font-weight: 600;
		line-height: 24px;
		margin-top: 20px;
	}
	.sub-title {
		color: #344056;
		font-family: "SF Pro Text";
		font-size: 14px;
		font-weight: 400;
		margin-top: 16px;
	}
}
