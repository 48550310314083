.about-us-wrapper {
  .content-wrapper {
    p {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;
      color: #4E5774;
      @media (max-width: $tablet) {
        font-size: 16px;
      }
    }
    .about-content {
      margin-top: 24px;
      text-align: left;
      @media (max-width: $tablet) {
        margin-top: 16px;
      }
    }
    
    .why-choose-us {
      margin-top: 24px;
      @media (max-width: $tablet) {
        margin-top: 16px;
      }

      .about-sub-header {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        font-family: "Plus Jakarta Sans";
        @media (max-width: $tablet) {
          font-size: 18px;
        }
      }
      
      .why-choose-content {
        margin-top: 16px;
        padding: 0 20px;
        
        li {
          list-style: disc;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.2;
          color: #4E5774;
          margin-bottom: 8px;
          @media (max-width: $tablet) {
            font-size: 16px;
            margin-bottom: 4px;
          }
        }
      }
    }

    .about-other-content {
      margin-top: 44px;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media (max-width: $tablet) {
        font-size: 16px;
        margin-top: 20px;
      }
    }
  }
}
