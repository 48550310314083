.more_customer_section {
	button {
		&.customer_icon {
			width: 32px;
			height: 32px;
			background-color: white;
			border-radius: 100%;
			margin-left: -6px;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
	
			svg {
				width: 50%;
				height: 50%;
			}
	
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
	
			&.icon-collepse {
				background-color: #fff7e6;
				color: #fa8c16;
				position: relative;
				overflow: visible;
			}
		}
		&.view_all_button {
			background: transparent;
			color: #0169e6;
			font-size: 14px;
			line-height: 22px;
			font-weight: 600;
		}
	}
}