.selected-table-row-area-container  {
    .area-content-container {
        max-width: 700px;
        margin: auto;
        padding: 8px;
        background-color: #0C111D;
        position: fixed;
        top: 3vh;
        left: calc(50% - 350px);
        z-index: 1900;
        width: 100%;
        .selected-items-text-container {
            color: #FFF;
            padding: 5px 7px;
            border-radius: var(--Components-Button-Global-borderRadiusSM, 7px);
            border: var(--Components-Button-Global-lineWidth, 1px) solid var(--Components-Button-Component-defaultColor, rgba(0, 0, 0, 0.88));
            background: var(--Component-colors-Alpha-alpha-black-10, rgba(0, 0, 0, 0.10));

            /* Button effect */
            // box-shadow: 0px 2px 5px 0px rgba(255, 255, 255, 0.15) inset, 0px -8.759px 31.627px 0px rgba(255, 255, 255, 0.15) inset, 0px -5.255px 17.517px 0px rgba(255, 255, 255, 0.15) inset;
            // backdrop-filter: blur(6.130982398986816px);
            button {
                background-color: transparent;
                color: #FFF;
            }
        }
        .actions-container {
            button {
                background-color: transparent;
                color: #FFF;
                font-family: "SF Pro Text";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
                padding: 4px 12px;
                gap: 3px;
                display: flex;
                align-items: center;
                border: 1px solid transparent;
                &.delete-button {
                    color: #F5222D;
                }
                &:hover {
                    border-radius: var(--Components-Button-Global-borderRadiusSM, 7px);
                    border: var(--Components-Button-Global-lineWidth, 1px) solid var(--Components-Button-Component-defaultColor, rgba(0, 0, 0, 0.88));
                    background: var(--Component-colors-Alpha-alpha-black-10, rgba(0, 0, 0, 0.10));

                    /* Button effect */
                    // box-shadow: 0px 2px 5px 0px rgba(255, 255, 255, 0.15) inset, 0px -8.759px 31.627px 0px rgba(255, 255, 255, 0.15) inset, 0px -5.255px 17.517px 0px rgba(255, 255, 255, 0.15) inset;
                    // backdrop-filter: blur(6.130982398986816px);
                }
                &:focus {
                    border: none;
                }
            }
        }
    }
}