.button {
    width: fit-content;
    &.text {
        background-color: transparent;
        button {
            background-color: inherit;
            height: 100%;
            color: inherit;
            line-height: 1.5;
            padding: 4px 12px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &.primary {
            color: var(--light-primary);
            background-color: transparent;
        }
        &.red {
            color: var(--light-colorError);
            background-color: transparent;
            path {
                stroke: var(--light-colorError);
            }
        }
    }
    &.primary:where(:not(.text)) {
        border-radius: 14px;
        button {
            height: 46px;
            background-color: var(--light-primary);
            color: var(--light-colorTextLightSolid);
            transition: all 0.2s ease-in-out;
            padding: 9px 15px;
            color: var(--light-colorTextLightSolid);
            word-break: keep-all;
            white-space: nowrap;
            border-radius: inherit;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            svg {
                width: 20px;
                height: 20px;
                path {
                    fill: #FFBC0D;
                }
            }
        }
        &.large {
            width: 120px;
            height: var(--Size-Height-controlHeightLG, 40px);
            padding: 0px var(--Components-Button-Component-paddingInlineLG, 15px);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;
        }
    }
    &.secondary:where(:not(.text)) {
        border-radius: 14px;
        button {
            height: 44px;
            background-color: #fff;
            color: var(--light-colorText);
            /* Button effect */
            transition: all 0.2s ease-in-out;
            padding: 9px 15px;
            word-break: keep-all;
            white-space: nowrap;
            border-radius: inherit;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            border: 1px solid var(--light-colorBorder);
            svg {
                width: 20px;
                height: 20px;
            }
        }
        &.large {
            width: 120px;
            height: var(--Size-Height-controlHeightLG, 40px);
            padding: 0px var(--Components-Button-Component-paddingInlineLG, 15px);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;
        }
    }
    &.error {
        background-color: var(--light-button-colorError);
        button {
            background-color: inherit;
            color: var(--light-button-colorTextLightSolid);
        }
    }
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        &:disabled {
            opacity: 0.6;
            cursor: default;
        }
    }
}