.place-order-page {
  .order-page-main-container {
    flex-direction: row;
    // border-radius: 12px;
    width: 100%;
    max-height: calc(100vh - 130px);
    overflow: auto;

    // orders section
    .orders-container {
      gap: 10px;
      align-self: stretch;
      max-width: 100%;
      width: 100%;
      .vendor-details-cart-button-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 6px 12px;
        width: 100%;
        border-radius: 12px;
        background: var(--Colors-Foreground-fg-white, #fff);
        box-shadow: 0px 4px 8px -2px rgba(82, 82, 84, 0.1),
          0px 2px 4px -2px rgba(210, 210, 210, 0.5);
        position: sticky;
        top: 0px;
        z-index: 999;
        height: 62px;
        align-items: center;
        .vendor-details {
          display: flex;
          align-items: center;
          button {
            &.back-button {
              background: transparent;
              svg {
                transform: rotate(180deg);
                width: 24px;
                height: 24px;
                path {
                  stroke: #00000080;
                }
              }
            }
          }
          .profile-box {
            width: 36px;
            min-width: 36px;
            height: 36px;
            min-height: 36px;
            overflow: hidden;
            border-radius: 100%;
            background-color: rgba(0, 0, 0, 0.04);
            color: var(--light-colorTextTertiary);
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 34.5px;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
            }
          }
          .profile-title {
            color: var(--light-colorDarkText);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
          }
          .profile-name {
            font-size: 12px;
            line-height: 150%;
            color: #33333380;
          }
        }
        button {
          &.cart-button {
            svg {
              path {
                stroke: #fff;
              }
            }
          }
        }
      }
      .page-scroll::-webkit-scrollbar {
        width: 10px !important;
        height: 8px !important;
        background-color: #efefef !important;
        border-radius: 12px !important;
      }

      .page-scroll::-webkit-scrollbar-thumb {
        background: #b8b8b8 !important;
        border-radius: 12px !important;
      }

      .page-scroll {
        padding: 0 10px;
        overflow: auto;
        // max-height: calc(100vh - 120px);
        gap: 10px;
        scroll-behavior: smooth;

        .recent-order-box {
          display: flex;
          flex-direction: column;
          padding: 16px 0;
          align-items: flex-start;
          gap: 10px;
          align-self: stretch;
          width: 100%;

          .title {
            color: #101828;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0.18px;
            white-space: nowrap;
          }

          .list-of-orders {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 10px;
            width: 100%;
          }
        }

        .suggested-product-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          align-self: stretch;
          border-radius: 12px;
          border: 1.5px solid var(--light-colorTextLightSolid);
          background: rgba(255, 255, 255, 0.6);
          backdrop-filter: blur(2px);

          .suggested-sub-container {
            display: flex;
            // padding: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;

            .tab-box {
              width: 100%;
            }
            .title {
              color: #101828;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 28px;
              letter-spacing: 0.18px;
              white-space: nowrap;
            }

            .list-of-products {
              display: flex;
              align-items: flex-start;
              flex-wrap: wrap;
              gap: 10px;
              align-self: stretch;
              max-width: 100%;
              flex-shrink: 0;
            }

            .ant-tabs-tab {
              padding: 0 4px 12px 4px;
              &.ant-tabs-tab-active {
                .tab-title {
                  svg {
                    path {
                      fill: var(--light-primary);
                    }
                  }
                }
              }
            }
            .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
              margin: 0 0 0 16px;
            }
          }
        }

        .products-lists-container {
          display: flex;
          padding: 16px 0;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
          padding-bottom: 24px;
          height: fit-content;
          background-color: white;
          border-radius: 14px;
          .header-container {
            flex-direction: row;
            flex-wrap: nowrap;
            .flex {
              width: 100%;
            }
            .sorting-selection {
              display: flex;
              align-items: flex-start;
              span {
                white-space: nowrap;
                line-height: 32px;
                color: #667085;
              }
              span {
                &.ant-select-selection-item {
                  color: #344054;
                  font-weight: 700;
                }
              }
            }
            .search-bar {
              max-width: 400px;
              width: 100%;
            }
          }

          .title {
            color: #101828;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0.18px;
            white-space: nowrap;
          }

          .actions-box {
            display: flex;
            padding: 12px 16px;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            border-radius: 12px;
            border: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
            background: var(--Colors-Background-bg-secondary_alt, #f9fafb);

            .date-picker {
              border-radius: 12px;
              border: 1px solid #d0d5dd;
              background: #fff;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              height: 40px;
            }

            .filter-button {
              height: 40px;
              display: flex;
              padding: 10px 14px;
              justify-content: center;
              align-items: center;
              gap: var(--spacing-xs, 4px);
              color: #344054;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px; /* 142.857% */
              letter-spacing: 0.14px;
              border-radius: 12px;
              border: 1px solid #d0d5dd;
              background: #fff;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }
          }

          .product-lists {
            display: flex;
            align-items: flex-start;
            gap: var(--spacing-xl, 10px);
            flex-wrap: wrap;
            width: 100%;
            .no-data-wrapper {
              height: 200px;
            }
          }
        }
      }
    }
  }
  .card {
    margin-top: 0 !important;
    &.sidebar-container {
      min-height: calc(100vh - 125px) !important;
      max-height: calc(100vh - 125px) !important;
      height: 100%;
      padding: 0;
      min-width: 230px;
      width: 230px;
      max-width: 230px;
      background-color: transparent;
      border-radius: 12px;
      border: 1.5px solid var(--light-colorBorder);
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(2px);
      padding: 10px 0px;
      position: sticky;
      top: 20px;
      overflow-x: hidden;
      .sidebar {
        overflow-y: auto;
        width: 100%;
        position: relative;

        &-title {
          font-size: 18px;
          color: #101828;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0.18px;
          padding-left: 16px;
        }

        .search-bar {
          max-width: calc(100% - 32px);
          margin: 14px auto 4px auto;
          input {
            overflow: hidden;
            color: var(--Colors-Text-text-placeholder, #667085);
            text-overflow: ellipsis;
            font-size: 11.636px;
            font-style: normal;
            font-weight: 400;
            line-height: 17.455px; /* 150% */
            letter-spacing: 0.116px;
          }
        }

        .search-bar:focus-within {
          border: 0.682px solid
            var(--Component-colors-Utility-Brand-utility-brand-100, #b2d5ff);
        }

        .group-list {
          list-style: none;
          padding: 0;
          margin: 0;
          width: 100%;
          margin-top: 4px;

          .group-item {
            display: flex;
            justify-content: space-between;
            font-size: 15px;
            line-height: 146%;
            font-weight: 600;
            align-items: flex-start;
            width: 100%;
            padding: 8px 20px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            position: relative;
            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 2px;
              height: 100%;
              background-color: transparent;
            }
            &.active {
              &::before {
                background-color: var(--light-primary);
              }
            }
            &:hover {
              background-color: rgba($color: #000000, $alpha: 0.02);
            }
            &.active,
            &:hover {
              // background-color: #e9ecef;
              color: var(--light-primary);
              .group-item-name-count .group-item-count {
                background: #e6f4ff; // Change the background color on hover
                color: var(--light-primary); // Change the text color on hover
              }
            }

            .group-item-name-count {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;

              .group-item-name {
                border-left: 2px solid transparent;
                transition: all 0.2s ease;
                word-break: break-all;

                &.active {
                  color: #000000;
                  position: relative;

                  &::after {
                    content: '';
                    border-left: 2px solid #000000;
                    position: absolute;
                    left: -22px;
                    height: 100%;
                    top: 0;
                  }
                }
              }

              .group-item-count {
                font-size: 14px;
                height: 23px;
                min-width: 23px;
                width: fit-content;
                padding: 2px 6px;
                background: #d9d9d9;
                text-align: center;
                border-radius: 50px;
                color: #000000a6;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 8px;
                line-height: 23px;
                transition: all 0.2s ease;
              }
            }

            .threeDotsBox {
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 4px;
              border-radius: 4px;
              transition: all 0.2s ease;

              svg {
                transition: all 0.2s ease;
              }

              &:hover {
                background-color: #ffffff;

                svg {
                  path {
                    stroke: var(--light-primary);
                  }
                }
              }

              &:focus {
                background-color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}
