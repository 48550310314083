.dropdown {
    border: 1px solid #333333;
    height: fit-content;
    position: relative;
    &__label {
        // padding: 4px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    &__value {
        // min-width: 100px;
    }
    button {
        &.dropdown__icon {
            background-color: transparent;
            width: 16px;
            height: 16px;
        }
    }
    .options-container {
        background-color: #f2f2f7;
        position: absolute;
        top: 33px;
        width: 100%;
        overflow: auto;
        max-height: 50vh;
        &__item {
            text-align: left;
            width: 100%;
            padding: 8px 8px;
            cursor: pointer;
            background-color: transparent;
            &:hover {
                background-color: #f1f4f9;
            }

            &.active {
                background-color: #f1f4f9;
            }
        }
    }
}