.email-input-wrapper {
    label {
        &.email-label {
            .label-text {
                color: #344056;
                font-family: "SF Pro Text";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 4px;
            }
            .input-container {
                background-color: #F9FAFB;
                overflow: hidden;
                padding: 10px 12px;
                border: 1px solid var(--light-colorBorder);
                &:hover {
                    border-color: var(--light-primary);
                }
                &:focus {
                    border-color: var(--light-primary);
                }
                .whatsapp-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }
                input {
                    &.email-input {
                        color: var(--light-primary);
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px; /* 200% */
                        letter-spacing: 0.12px;
                        width: 100%;
                        border: none;
                        background-color: transparent;
                        &:hover, &:focus {
                            border: none;
                            outline: none;
                        }
                        &::placeholder {
                            color: #667085;
                            font-family: "SF Pro Text";
                        }
                    }
                }
                .verify-button {
                    button {
                        &.verify {
                            color: #0169E6;
                            font-family: "SF Pro Text";
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px; /* 200% */
                            letter-spacing: 0.12px;
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
}