.access-table-wrapper {
    margin-top: 32px;
    h3 {
        &.title-3 {
            border-bottom: 1px solid #F2F2F7;
            padding-bottom: 20px;
            color: var(--colors-text-text-primary-900, #101828);
            font-family: "SF Pro Text";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px; /* 155.556% */
        }
    }
    .custom-table-wrapper {
        border-radius: 12px;
        overflow: hidden;
        border: 1px solid #F2F2F7;
        margin-top: 24px;
        .table-head {
            height: 44px;
            background-color: #F9FAFB;
            padding: 12px 24px;
            border-bottom: 1px solid #F2F2F7;
            p {
                &.caption {
                    line-height: 18px;
                    color: var(--light-primary);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                }
            }
        }
        .table-body {
            overflow: auto;
            .table-row {
                height: 72px;
                padding: 24px 24px;
                border-bottom: 1px solid #F2F2F7;
                overflow: auto;
                &:last-of-type {
                    border-bottom: none;
                }
                .table-data {
                    width: 100%;
                    &:first-of-type {
                        max-width: 25%;
                    }
                    &.flex {
                        position: relative;
                        width: 100%;
                        &.w-70 {
                            width: 100%;
                            position: relative;
                        }
                        &:first-of-type {
                            max-width: fit-content;
                            min-width: 18%;
                            width: 100%;
                        }
                        &:nth-child(2) {
                            max-width: fit-content;
                            min-width: 22%;
                            width: 100%;
                        }
                        &:nth-child(3) {
                            max-width: fit-content;
                            min-width: 25%;
                            width: 100%;
                        }
                        &:nth-child(4) {
                            max-width: fit-content;
                            min-width: 35%;
                            width: 100%;
                        }
                    }
                    label {
                        cursor: pointer;
                        span {
                            &.label-text {
                                white-space: nowrap;
                                color: #344056;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 20px; /* 142.857% */
                            }
                        }
                    }
                    input {
                        width: 16px;
                        height: 16px;
                        background: #FFF;
                        border: 1px solid #333;
                        border-radius: 3px;
                        position: absolute;
                        opacity: 0;
                        overflow: hidden;
                        & + label {
                            position: relative;
                            cursor: pointer;
                            padding: 0;
                            display: flex;
                            align-items: center;
                        }
                        & + label:before {
                            content: '';
                            display: inline-block;
                            vertical-align: text-top;
                            width: 16px;
                            height: 16px;
                            border: 1px solid var(--light-primary);
                            border-radius: 4px;
                        }
                        &:hover + label:before {
                            background: transparent;
                            border-radius: 4px;
                            border: 1px solid var(--light-primary);
                        }
                        &:checked + label:before {
                            background: var(--light-primary);
                            border-radius: 4px;
                        }
                        &:checked + label:after {
                            content: '';
                            position: absolute;
                            left: 3px;
                            top: 11px;
                            background: #FFF;
                            width: 2px;
                            height: 2px;
                            box-shadow: 
                                2px 0 0 #FFF,
                                4px 0 0 #FFF,
                                4px -2px 0 #FFF,
                                4px -4px 0 #FFF,
                                4px -6px 0 #FFF,
                                4px -8px 0 #FFF,
                                4px -10px 0 #FFF;
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }
    }
}

.w-50 {
    max-width: 50%;
    width: 100%;
}

.w-30 {
    max-width: 25%;
    min-width: 25%;
    width: 100%;
}

.w-70 {
    max-width: 75%;
    min-width: 75%;
    width: 100%;
}