.invitation-message-wrapper {
  &.order-message {
    height: 42px;
    color: #FFF;
    display: flex;
    align-items: center;
    padding: 6px 8px;
    justify-content: center;
    gap: 9px;
    border-radius: 12px;
    z-index: 999999;
    width: 844px;
    position: fixed;
    top: -80px;
    left: 0;
  }

  &.accepted {
    background-color: #17B26A;
  }

  &.rejected {
    background-color: #F5222D;
  }
}
.new-order-details-modal {
  width: 844px !important;
  .slick-next,
  .slick-prev,
  .slick-dots {
    position: fixed;
  }
  .slick-next,
  .slick-prev {
    width: 50px;
    height: 50px;
    &::before {
      font-size: 40px;
    }
  }
  .slick-next {
    right: -75px;
  }
  .slick-prev {
    left: -75px;
  }
  .slick-dots {
    top: -25px;
    left: 0;
    display: flex !important;
    flex-wrap: nowrap;
    bottom: auto;
    max-width: 100%;
    overflow: auto;
    li {
      width: 100%;
      height: 4px;
      button {
        width: 100%;
        height: 4px;
        pointer-events: none;
        &::before {
          width: 100%;
          border-radius: 2px;
          content: '';
          background-color: white;
          height: 4px;
        }
      }
    }
  }
  .customer-details {
    padding: 4px 0;
    .image-box {
      width: 44px;
      height: 44px;
      border-radius: 100%;
      overflow: hidden;
      .profile-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 100% 100%;
      }
    }

    .company-name {
      display: flex;
      align-items: start;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      color: var(--light-colorText);
      text-align: center;
    }
    .user-name {
      display: flex;
      align-items: start;
      color: var(--light-colorSecondary);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .close-icon {
    width: 44px;
    height: 44px;
    // padding: 8px;
    cursor: pointer;
    &:hover {
      background-color: rgb(235, 235, 235);
      border-radius: 8px;
    }
  }
  .reference-info {
    .reference-id {
      color: var(--light-colorText);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      .id {
        color: var(--light-primary);
      }
    }

    .order-date {
      color: var(--light-colorText);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .model-container {
    display: flex !important;
    min-height: 56vh;
    justify-content: space-between;
    .order-details-container {
      max-height: 52vh;
      overflow: auto;
      .new-order-details-table {
        .ant-table-thead {
          position: sticky;
          top: 0;
          background-color: inherit;
          z-index: 9999;
        }
        table {
          
          border: 1px solid var(--light-colorBorderSecondary);
        }

        th {
          color: var(--light-colorTextTertiary);
          background-color: #fff;
          padding: 12px 8px;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }

        td {
          padding: 8px 8px;
        }

        .product-name-box {
          .product-image-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 54px;
            height: 54px;
            min-width: 54px;
            min-height: 54px;
            border-radius: 5.838px;
            border: 0.844px solid #eaecf0;
            overflow: hidden;
            .product-image {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }

            svg {
              width: 24px;
              height: 24px;

              path {
                fill: #a9a9a9;
              }
            }
          }
          .product-name {
            color: var(--light-colorText);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            word-wrap: break-word;
            word-break: break-word;
          }
        }
        .unit {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          color: var(--light-colorTextTertiary);
          word-wrap: break-word;
          word-break: break-word;
        }

        .size-text {
          color: var(--light-colorText);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          word-wrap: break-word;
          word-break: break-word;
        }

        .qty-text {
          .quantity {
            color: var(--light-colorText);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }
          .orderUnit {
            color: var(--light-colorTextTertiary);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }

        .price-per-unit-text {
          white-space: nowrap;
          .price {
            color: var(--light-colorText);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
          }
        }

        .subtotal-text {
          color: var(--light-colorText);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          text-align: right;
          display: block;
          span {
            &.price {
              text-align: right;
            }
          }
        }

        .ant-table-cell {
          &::before {
            display: none;
          }
        }
      }

      .bill-details {
        margin-top: 10px;
        border-radius: 8px;
        border: 1px solid var(--light-colorBorderSecondary);
        background-color: rgba(0, 0, 0, 0.02);
        padding: 16px;

        .section-header {
          color: var(--light-colorText);
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }

        .sub-total {
          color: var(--light-colorText);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          margin-bottom: 8px;
        }

        .total-discount {
          color: var(--light-primary);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          padding-bottom: 8px;
          border-bottom: 1px solid var(--light-colorBorderSecondary);
        }

        .grand-total {
          margin-top: 8px;
          color: var(--light-colorText);
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
      }

      .dispatch-details-box {
        margin-top: 10px;
        gap: 10px;

        .transportation-details-box {
          width: 100%;
          border-radius: 12px;
          border: 1px solid var(--light-colorBorderSecondary);
          padding: 12px;
          max-height: 150px;
          overflow: auto;

          svg {
            min-width: 24px;
            min-height: 24px;
          }

          .comments-header {
            color: var(--light-colorText);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }

          .comment-info {
            color: var(--light-colorSecondary);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            word-break: break-all;
            max-height: 100px;
            overflow: auto;
          }
        }

        .attachments-container {
          width: 100%;
          padding: 12px;
          border: 1px solid var(--light-colorBorderSecondary);
          border-radius: 12px;
          .attach-icon {
            svg {
              width: 24px;
              height: 24px;
              path {
                stroke: black;
              }
            }
          }

          .content-container {
            .attachment-header-text {
              color: var(--light-colorText);
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
            }

            .attached-files {
              .image-box {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 54px;
                height: 54px;
                border-radius: 4.32px;
                overflow: hidden;
                border: 0.47px solid #eaecf0;
                img {
                  object-fit: contain;
                  width: 100%;
                  height: 100%;
                  object-position: center;
                }

                svg {
                  width: 50px;
                  height: 35px;
                }
              }
            }
          }
        }
      }

      .address-section {
        margin-top: 10px;
        border-radius: var(--radius-md, 8px);
        border: 1px solid
          var(--Color-Neutral-Borders-colorBorderSecondary, #f0f0f0);
        padding: 12px;
        .address-text {
          width: 100%;
          .address-input {
            width: 100%;
            h4 {
              &.title-4 {
                color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px; /* 157.143% */
              }
            }
            input {
              color: var(
                --Color-Neutral-Text-colorTextLabel,
                rgba(0, 0, 0, 0.65)
              );
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 166.667% */
              border: none;
              outline: none;
              width: 100%;
            }
          }
        }
      }
    }
    .footer-action {
      .reject-btn {
        width: 144px;
        height: 48px;
        border-radius: 12px;
        background-color: #ff4d4f;
        padding: 0px 22.5px;
        color: #fff;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        svg {
          path {
            stroke: #fff;
          }
        }
        &:disabled {
          opacity: 0.6;
          cursor: default;
        }
      }
      .accept-btn {
        width: 144px;
        height: 48px;
        border-radius: 12px;
        background-color: #17b26a;
        // padding: 0px 22.5px;
        color: #fff;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        svg {
          width: 22px;
          height: 22px;
          path {
            stroke: #fff;
          }
        }

        &:disabled {
          opacity: 0.6;
          cursor: default;
        }
      }
    }
  }
}
