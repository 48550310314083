.order-summary-item {
  &__label {
    color: #465468;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    gap: 7.067px;
    align-items: center;
  }

  &__color {
    display: flex;
    width: 14px;
    height: 14px;
    background-color: #FFBC0D;
    border-radius: 4px;
  }

  
  &__orders {
    display: flex;
    align-items: center;
    gap: 7.07px;  
  }
  &__value {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;

    &--info {
      color: #7C8DB5;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }
}