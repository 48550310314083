.products-price-list-page {
  max-width: calc(100% - 270px);
  height: 100%;

  .page-header {
    h3 {
      &.page-title {
        color: var(--light-colorText);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;

        /* 158.333% */
        span {
          &.total-count {
            display: inline-flex;
            width: 92.273px;
            height: 33.991px;
            justify-content: center;
            align-items: center;
            color: rgba(0, 0, 0, 0.65);
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            border-radius: 8px;
            border: 1.5px solid #FFF;
            background: rgba(255, 255, 255, 0.60);
            backdrop-filter: blur(2px);
          }
        }
      }
    }
  }

  .price-table {
    overflow: hidden;
    padding-top: 0;
    height: 100%;
    max-height: calc(100vh - 100px);

    .table-container {
      .products-price-info-table {
        overflow: hidden;
        max-height: calc(100vh - 250px);
        height: 100%;

        .ant-table-body {
          overflow: auto !important;
        }
      }

      // .table-container .products-price-info-table
    }

    .ant-table-wrapper {
      width: 100%;
      overflow: auto;
      .ant-table-body {
        table {
          border-radius: 0px;
          border-top: 0px !important;
        }
      }
    }

    .selected-table-row-area-container {
      position: fixed;
      top: 0;
      z-index: 9999;
      width: 100%;

      // height: 100%;
      .area-content-container {
        // position: sticky;
      }
    }
  }

  .ant-table-thead {
    .ant-table-cell {
      padding: 0 16px;
      height: 44px;
      border-bottom: 1px solid #eaecf0;
      background: #fff;
      color: #465468;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      text-align: end;
      
      &::before {
        display: none;
      }
      &:nth-of-type(1) {
        text-align: start;
        padding-left: 10px !important;
      }
      &:nth-of-type(2) {
        text-align: start;
        padding-left: 10px !important;
      }
      &:nth-of-type(4) {
        padding-right: 16px !important;
      }

      &:nth-of-type(6),
      &:nth-of-type(7) {
        padding-right: 10px !important;
        text-align: end;
      }

      &:nth-of-type(5) {
        padding-right: 10px !important;
      }

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        background-color: #f9fafb;
      }
    }
  }

  .ant-table-tbody {
    tr {
      &.ant-table-row {
        &.ant-table-row-selected {
          td {
            &:nth-of-type(1),
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4) {
              background: #e6f7ff !important;
            }
          }
        }
      }

      td {
        &.ant-table-cell {
          height: 65px !important;
          &:nth-of-type(2) {
            text-align: start;
            padding-left: 8px !important;
          }
          &:nth-of-type(3) {
            color: #94A2B8;

            button {
              color: #94A2B8;
              cursor: default;
            }
          }

          &:nth-of-type(3),
          &:nth-of-type(4) {
            padding-right: 16px !important;
          }
          &:nth-of-type(4) {
            padding-left: 16px !important;
          }
          &:nth-of-type(7) {
            input {
              width: 100%;
            }
          }

          &:nth-of-type(3) {
            cursor: not-allowed !important;
          }

          &:nth-of-type(1),
          &:nth-of-type(2),
          &:nth-of-type(3) {
            background: #fcfcfd;
            padding-left: 10px !important;
          }
        }
      }
      .ant-table-cell {
        .table-input {
          border: 1px solid transparent;
          background: transparent;
          margin-top: -0.5px;
          z-index: 1;
          height: 64px !important;
          margin-bottom: -0.5px;
          width: 100%;
          &.text-end {
            input {
              text-align: end;
            }
          }
    
          &:hover {
            height: 64px !important;
            border: 1px solid var(--light-colorBorder);
            z-index: 1;
          }
    
          &:focus {
            border: 1px solid var(--light-primary);
            z-index: 1;
          }
    
          &:focus-within {
            border: 1px solid var(--light-primary);
            z-index: 1;
          }
        }
    
        .ant-input-number-handler-wrap {
          display: none;
        }
    
        .ant-input-number-affix-wrapper {
          padding: 0;
          border: 1px solid transparent;
          height: 100%;
    
          input {
            height: 100%;
          }
        }
    
        .ant-input-number-input-wrap {
          height: 100%;
    
          input {
            padding: 0;
            height: 100%;
          }
        }
    
        .ant-input-number {
          padding: 4px 11px;
          height: 100%;
          border: 1px solid transparent;
    
          &:hover {
            border: 1px solid var(--light-colorBorder);
          }
    
          &:focus {
            border: 1px solid var(--light-primary);
            z-index: 999;
          }
    
          &:focus-within {
            border: 1px solid var(--light-primary);
            z-index: 999;
          }
        }
      }
    }
  }

  .ant-table-cell {
    padding: 0 !important;
    button {
      &.productSize-text {
        background-color: transparent;
        width: 100%;
        height: 100%;
        text-align: start;
      }
    }

    .ant-input-group-addon {
      padding: 0 !important;

      .ant-select {
        margin: 0;
        height: 100%;
      }
    }
  }


}