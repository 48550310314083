.orders-setting-page {
    padding-top: 0;
    overflow: auto;
    .orders-setting-wrapper {
        .orders-setting-header {
            margin-bottom: 24px;
            h3 {
                .title-3 {
                    color: #000;
                    font-family: "Plus Jakarta Sans";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-bottom: 8px;
                }
            }
            p {
                &.caption {
                    color: #344056;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
        .orders-setting-body {
            h4 {
                &.title-4 {
                    color: var(--colors-text-text-primary-900, #101828);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px; /* 155.556% */
                    padding-bottom: 20px;
                    border-bottom: 1px solid #EAECF0;
                    margin-bottom: 16px;
                }
            }
            .date-pickers-container {
                max-width: 755px;
                width: 100%;
                margin-bottom: 32px;
                p {
                    &.label {
                        color: #344056;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 32px;
                    }
                }
                .date-picker {
                    width: 50%;
                }
            }
            .order-prefix-section {
                max-width: 755px;
                width: 100%;
                margin-bottom: 32px;
                .order-prefix-section-view-box {
                    padding: 20px;
                    background-color: #F9FAFB;
                    width: 100%;
                    height: 246px;
                    p {
                        &.prefix-text {
                            color: #000;
                            text-align: center;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px; /* 150% */
                        }
                    }
                    .order-number-container {
                        margin-top: 32px;
                        p {
                            color: #000;
                            font-size: 48px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 100%;
                            &.order-number, .gray {
                                color: rgba(0, 0, 0, 0.30);
                            }
                        }
                    }
                    .help-text {
                        margin-top: 20px;
                        p {
                            &.caption {
                                color: rgba(0, 0, 0, 0.30);
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
                        }
                    }
                }
            }
            .inputs-container {
                max-width: 755px;
                width: 100%;
                .input {
                    .input-container {
                        label {
                            input {
                                height: 32px;
                            }
                        }
                    }
                }
                .seprator-dropdown {
                    max-width: 90px;
                    width: 100%;
                    label {
                        span {
                            color: #344056;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
            }
            button {
                &.submit-button {
                    height: 32px;
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-top: 44px;
                }
            }
        }
    }
}