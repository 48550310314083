.sales-overview-chart {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-right: 1px solid #e4e7ec;
  position: relative;

  &__revenue-color {
    display: flex;
    width: 14px;
    height: 14px;
    background-color: #101729;
    border-radius: 4px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__chart {
    height: 100%;

    .custom-tooltip {
      background-color: #ffbc0d;
      padding: 12px;
      border-radius: 6px;
      color: #000000;

      p {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        text-align: start;

        &.custom-tooltip__date {
          color: #000;
          width: 100%;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
        b {
          font-size: 14px;
          font-weight: 700;
        }
      }
      &__date {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        text-align: center;
      }
      p {
        &.bold {
          color: #000;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 24px */
        }
      }
    }
  }
}
