.error-boundary-wrapper {
  background-color: rgb(243, 244, 246);

  .error-boundary {
    width: 100%;
    max-width: 450px;
    padding: 48px;
    background-color: #fff;
    box-shadow:
      0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px -1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;

    h4 {
      font-weight: 700;
      margin-bottom: 0.5rem;
      color: #283c50;
      font-size: 24px;
    }

    p {
      font-size: 12px;
      color: rgba(33, 37, 41, 0.75);
      margin-bottom: 16px;
    }
    button {
      margin-top: 20px;
      padding: 10px 0;
    }
  }
}
