.add-products {
	// padding-bottom: 40px;
	.ant-upload {
		&.ant-upload-select {
			&:not(.ant-upload-disabled) {
				&:hover {
					border-color: var(--light-primary) !important;
				}
			}
		}
	}

	&.edit-product {
		.table-container {
			.products-price-info-table {
				max-height: 80vh;

				.ant-table-content {
					max-height: 70vh;
					overflow: auto !important;
				}
			}
		}
	}

	.ant-input-outlined {
		box-shadow: none !important;
		&:hover {
			box-shadow: none !important;
			border-color: var(--light-primary) !important;
		}
		&:focus {
			box-shadow: none !important;
			border-color: var(--light-primary) !important;
		}
	}

	.add-products-container {
		gap: 10px;
		height: calc(100vh - 135px);
		overflow: auto;
	}

	.add-product-cards-container {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		flex-direction: row;
		border-radius: 12px;
		height: fit-content !important;

		.customTagAlias {
			padding: 1px 5px;
			gap: 4px;
			background-color: rgba(0, 0, 0, 0.06);
			border-radius: 4px;
			margin: 1px;
			margin-right: 4px;

			display: flex;

			.delete-selected-icon {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.card {
		.product-details-container::-webkit-scrollbar {
			width: 6px !important;
			height: 8px !important;
			background-color: #efefef !important;
			border-radius: 12px !important;
			margin-top: 10px;
			margin-bottom: 10px;
			padding: 10px;
			padding-bottom: 10px;
		}

		.product-details-container::-webkit-scrollbar-thumb {
			background: #b8b8b8 !important;
			border-radius: 12px !important;
		}
		.product-details-container {
			padding: 0 10px;
			max-width: 40%;
			width: 100%;
			max-height: 50vh;
			overflow: auto;
			display: flex;
			padding: 0px 20px 20px 20px;
			flex-direction: column;
			align-items: flex-start;
			gap: var(--spacing-xl, 16px);
			align-self: stretch;
			border-radius: 12px;
			border: 1px solid var(--Colors-Border-border-tertiary, #edf0f7);
			background: var(--light-bg-light, #ffffff);
			height: fit-content;
			.help-text {
				svg {
					width: 10px;
					height: 10px;
				}
				display: flex;
				align-items: center;
				font-size: 10px;
				color: var(--light-colorSecondary);
			}
			&:nth-of-type(2) {
				max-width: calc(60% - 16px);
			}
			.title-section {
				width: 100%;
				position: sticky;
				top: 0;
				background: white;
				padding-top: 15px;
				z-index: 99;
				height: 50px;
			}

			.description-container {
				span {
					font-size: 12px;
					font-weight: 400;
				}
			}

			@media screen and (max-width: 1450px) {
				max-width: 50%;
			}
		}
		.product-form-container {
			width: 100%;

			.info-custom-field-suggestion-box {
				display: flex;
				flex-wrap: wrap;
				gap: 10px;
			}

			.info-custom-field-btn {
				background: none;
				width: fit-content;
				display: flex;
				align-items: center;
				height: 20px;
				padding: 0 7px;
				border: 1px dashed var(--light-primary);
				border-radius: 14px;
				height: 20px;
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: 22px;
				color: var(--light-primary);

				svg {
					width: 14px;
					height: 14px;
					path {
						fill: #00000073;
					}
				}
			}
			// product-name-input
			.input {
				.input-container {
					span {
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 22px; /* 183.333% */
						letter-spacing: 0.12px;
						color: var(--light-colorText);
					}
					.require-icon {
						color: var(--light-colorError);
					}
				}
			}

			.title {
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 22px; /* 183.333% */
				letter-spacing: 0.12px;
				color: var(--light-colorText);
			}
			input {
				font-size: 12px;
				padding: 8px 8px;
				height: 32px;
				border-radius: 14px;
			}
			textarea {
				font-size: 12px;
				padding: 8px 8px;
				border-radius: 14px;
			}
			.ant-select-selector {
				border-radius: 14px;
				box-shadow: none !important;
			}
		}
		.more-field {
			border-width: 1px;
		}
	}
}
.products-list {
	.ant-table-expanded-row {
		.ant-table-container {
			min-height: auto;
			.ant-table-content {
				height: 100%;
				table {
					height: 100%;
				}
			}
		}
	}
	.ant-table-container {
		// min-height: calc(100vh - 297px);
		.ant-table-content {
			height: 100%;
			table {
				height: 100%;
			}
		}
	}
	.ant-table-placeholder {
		height: 100%;
	}
}
.table-container {
	width: 100%;
	padding-top: 10px;
	border-radius: 14px;
	position: sticky;
	top: 20px;
	height: fit-content !important;
	// max-height: calc(100vh - 125px);

	.ant-table-content {
		max-height: 70vh;
		overflow: auto !important;
	}

	.add-size-btn {
		button {
			svg {
				width: 16px;
				height: 16px;
			}
			border: 1px solid #e6f1ff;
			border-radius: 13px;
			background: #fff;
			border-radius: 14px;
			padding: 4px 15px;
			font-size: 15px;
			font-style: normal;
			font-weight: 600;
			line-height: 22px; /* 146.667% */
			letter-spacing: 0.15px;
		}
	}

	.table-footer {
		.add-size-btn {
			margin-top: 10px;
		}
	}

	.products-price-info-table {
		width: 100%;
		position: relative;
		overflow: auto;
		padding-bottom: 20px;

		.ant-table-tbody {
			z-index: 10;
			position: relative;
		}

		.pagination-wrapper {
			margin-top: 16px;
			.ant-pagination {
				.ant-pagination-item-active {
					border-color: #000000;
					background-color: #000000;
					a {
						color: #ffffff;
					}
				}
				.anticon-double-right,
				.anticon-double-left {
					&.ant-pagination-item-link-icon {
						color: #000000;
					}
				}
				.ant-pagination-item {
					height: 32px;
					width: 32px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				button {
					&.pagination-button {
						background-color: #ffffff;
						height: 32px;
						padding: 8px 20px;
						color: #000000;
						font-weight: 600;
						border: 1px solid #d9d9d9;
						border-radius: 6px;
					}
					&:disabled {
						opacity: 0.6;
						cursor: default;
					}
				}
			}
		}

		.product-table-header {
			margin-bottom: 10px;
			// position: sticky;
			padding-top: 20px;
			padding-bottom: 6px;
			// top: 0;
			background-color: inherit;
			z-index: 9999;

			.left-header {
				gap: 10px;
			}

			.right-header {
				.add-custom-columns {
					.custom-field-btn {
						button {
							background: none;
							font-size: 14px;
							font-style: normal;
							font-weight: 700;
							line-height: 22px; /* 157.143% */
							letter-spacing: 0.14px;
							color: var(--light-primary);
						}
					}

					.create-custom-field-selection {
						border: 1px solid #eaecf0;
						border-radius: 14px;
						width: 200px;
					}
				}
			}
		}

		.ant-table-wrapper {
			table {
				// border-radius: 12px;
				border: 1px solid #eaecf0;
				// height: calc(100vh - 280px);
				// overflow: auto;
				// max-height: 83vh;
				position: relative;

				.ant-table-thead {
					// position: sticky !important;
					// top: 0 !important;
					background: white;
					// z-index: 12;
				}
			}
		}

		tr {
			&.ant-table-row {
				td {
					&:last-of-type {
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}
			&.ant-table-row {
				&:last-of-type {
					td {
						&.ant-table-cell {
							// border-bottom: none;
						}
					}
				}
			}
		}

		.ant-table-cell {
			.table-input {
				border: 1px solid transparent;
				background: transparent;
				margin-top: -0.5px;
				z-index: 999;
				height: 46px !important;
				margin-bottom: -0.5px;
				box-shadow: none !important;
				&:hover {
					border: 1px solid var(--light-colorBorder);
					background: transparent;
					margin-top: -0.5px;
					z-index: 900;
					height: 46px !important;
					margin-bottom: -0.5px;
					box-shadow: none !important;
				}
				&:focus {
					border: 1px solid var(--light-primary);
					outline: none;
					z-index: 999;
					box-shadow: none !important;
				}
				&:focus-within {
					border: 1px solid var(--light-primary);
					outline: none;
					z-index: 999;
					box-shadow: none !important;
				}
			}

			.ant-input-number-handler-wrap {
				display: none;
			}
			.ant-input-number-affix-wrapper {
				padding: 0;
				border: 1px solid transparent;
				height: 100%;
				input {
					height: 100%;
				}
			}

			.ant-input-number-input-wrap {
				height: 100%;
				input {
					padding: 0;
					height: 100%;
				}
			}
			.ant-input-number {
				padding: 4px 11px;
				height: 100%;
				border: 1px solid transparent;
				&:hover {
					border: 1px solid var(--light-colorBorder);
				}
				&:focus {
					border: 1px solid var(--light-primary);
					outline: none;
					z-index: 999;
				}
				&:focus-within {
					border: 1px solid var(--light-primary);
					outline: none;
					z-index: 999;
				}
			}
		}
		.edit-table-input-wrapper {
			display: flex;
			align-items: center;
			height: 100%;
			.product-image {
				max-width: 44px;
				height: 44px;
				width: 100%;
				overflow: hidden;
				background-color: #f2f2f7;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 4px;
				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}

				svg {
					width: 24px;
					height: 24px;

					path {
						fill: #a9a9a9;
					}
				}
			}

			.product-name-wrapper {
				cursor: pointer;
				&:hover {
					.product-name {
						color: var(--light-primary);
					}
				}
			}
			.ant-table-cell {
				.table-input {
					border: 1px solid transparent;
					&:hover {
						border: 1px solid var(--light-colorBorder);
					}
					&:focus {
						border: 1px solid var(--light-primary);
						outline: none;
						z-index: 999;
					}
				}
			}
			.table-buttons-container {
				display: none;
				align-items: center;
				gap: 4px;
				&.isOpen {
					display: flex;
				}
				button {
					&.edit-button {
						border-radius: 6px;
						border: 1.091px solid var(--Colors-Border-border-primary, #d0d5dd);
						background: var(--Colors-Background-bg-primary, #fff);
						box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
						padding: 5.455px;
						width: 24px;
						height: 24px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					&.expand-size {
						border-radius: var(--radius-xs, 4px);
						border: 1px solid var(--Colors-Border-border-primary, #d0d5dd);
						background: var(--Colors-Background-bg-primary, #fff);
						box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
						padding: 3px 6px;
						color: #344054;
						span {
							white-space: nowrap;
							svg {
								color: #667085;
								width: 12px;
								height: 12px;
							}
						}
					}
				}
			}
		}
		.ant-input-group-wrapper {
			height: 100%;
			&.error .ant-input-wrapper .ant-input {
				border: 1px solid red;
			}

			.ant-input-wrapper {
				height: 100%;
				.ant-input {
					height: 100%;
					&:focus {
						box-shadow: none !important;
						border: 1px solid var(--light-primary) !important;
					}
					&:focus-within {
						box-shadow: none !important;
						border: 1px solid var(--light-primary) !important;
					}
					&:hover {
						box-shadow: none !important;
						border: 1px solid var(--light-primary) !important;
					}
				}
			}
		}
	}

	.editable-cell {
		height: 100%;
		display: flex;
		align-items: center;
	}

	.ant-table-thead {
		position: sticky;
		top: 0;
		background: white;
		z-index: 12;
	}
	.ant-table-row > .ant-table-cell-row-hover {
		background-color: transparent;
	}
	tr:first-child > *:first-child {
		// border-top-left-radius: 12px;
	}
	tr:last-child > *:last-child {
		// border-bottom-right-radius: 12px;
	}
	.ant-table {
		border-radius: 12px;
		.ant-table-container,
		.ant-table-content {
			border-radius: 12px;
			.productSize-text {
				display: flex;
				align-items: center;
				padding: 4px 12px;
				font-size: 14px;
				font-weight: 400;
				font-style: normal;
				height: 100%;
				background-color: transparent;
				border-radius: 6px !important;

				&.error {
					border: 1px solid var(--light-colorError);
				}
			}

			.size-unit-style {
				min-width: 112px;
				.ant-select-selection-search {
					color: #000;
				}
				.custom-label-render {
					color: #000;
				}
			}
		}
		.ant-table-thead {
			.ant-table-cell {
				padding: 0 16px;
				height: 44px;
				// border-bottom: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
				// background: var(--Colors-Background-bg-primary, #fff);
				// color: var(
				//   --Color-Neutral-Text-colorTextDescription,
				//   rgba(0, 0, 0, 0.45)
				// );
				font-family: "SF Pro Text";
				font-size: 13px;
				font-style: normal;
				font-weight: 600;
				line-height: 20px;
			}
		}
		.ant-table-cell {
			padding: 0;
			height: 44px;
			// .antd-input {
			//   height: 46px;
			//   label {
			//     gap: 0;
			//     height: 44px;
			//     .suffix-input {
			//       height: 46px !important;
			//       max-width: 427px;
			//       line-height: 44px;
			//       top: 0;
			//       span {
			//         line-height: 44px;
			//       }
			//       .hidden-span-2 {
			//         height: 44px;
			//         line-height: 44px;
			//       }
			//       .ant-input-group-wrapper {
			//         height: 46px;
			//       }
			//     }
			//   }
			// }
			button {
				&.productSize-text {
					background-color: transparent;
					width: 100%;
					height: 100%;
					text-align: start;
				}
			}
			.ant-input-group-addon {
				padding: 0 !important;
				.ant-select {
					margin: 0;
					height: 100%;
				}
			}
		}
	}
	.select-dropdown-box {
		height: 100%;
		input {
			padding-right: 20px !important;
		}

		label {
			height: 100%;

			.ant-select {
				height: 100%;
				.ant-select-selector {
					border: 1px solid transparent;
					background-color: transparent !important;
					&:hover {
						border: 1px solid var(--light-primary);
					}
					&:focus-within {
						z-index: 9999;
						border: 1px solid var(--light-primary) !important;
						outline: none;
						box-shadow: none !important;
					}
					&:focus {
						border: 1px solid var(--light-primary) !important;
						outline: none;
						box-shadow: none !important;
						&:hover {
							outline: none;
							border: 1px solid var(--light-primary) !important;
							box-shadow: none !important;
						}
					}
				}
			}
		}
	}
}

.ant-input-affix-wrapper {
	&.error {
		border: 1px solid var(--light-colorError) !important;
	}
}

// Add Product Modal
.add-product-modal {
	.modal-container {
		display: flex;
		flex-direction: column;
		.ant-input-affix-wrapper {
			padding: 0 10px 0 0;

			// input {
			//   padding: 8px;
			//   border: none;
			//   &:focus {
			//     border: none !important;
			//     outline: none !important;
			//   }
			// }
		}
		.ant-input-group {
			.ant-input-group-addon {
				.ant-select-open {
					.ant-select-selector {
						color: var(--light-primary);
					}
				}
				.ant-select-focused {
					.ant-select-selector {
						color: var(--light-primary);
					}
				}
			}
		}
		.productNameInput {
			input {
				color: var(--light-colorText);
				height: 32px !important;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px; /* 171.429% */
				letter-spacing: 0.14px;
			}
		}
		.modal-header {
			text-align: center;
			color: var(--light-colorText);
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: 38px;
		}
		label {
			span {
				font-size: 12px;
				font-weight: 600;
				border-radius: 14px;
			}
		}
		input {
			font-size: 14px;
			padding: 8px 8px;
			height: 32px;
			border-radius: 14px;
		}

		.alt-unit-btn {
			width: fit-content;
			background: none;
			color: var(--light-primary);
			font-size: 15px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
			padding: 0 7px;
		}

		.ant-select-selector {
			border-radius: 14px;

			&:hover,
			&:focus-within {
				border: 1px solid var(--light-primary) !important;
				box-shadow: none !important;
			}
		}

		.ant-select-dropdown {
			width: 170px !important;
			text-align: start !important;
		}
		.rate-box {
			width: 200px;
		}

		.size-input-style {
			.ant-input-affix-wrapper {
				// padding: 0;
				height: 32px;
				padding: 0 10px 0 0;
				&:hover,
				&:focus,
				&:focus-within {
					border-color: #000000;
					outline: none !important;
					box-shadow: none !important;
				}
				input {
					border: none;
					outline: none;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px;
					padding-right: 15px;
					width: 100%;
				}
				.ant-input-suffix {
					color: var(--light-darkColor25);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px;
				}
			}

			&.error .ant-input-affix-wrapper {
				border: 1px solid var(--light-colorError);
			}
		}
	}
}

.unit-dropdown-styles {
	color: var(--light-colorText);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;

	.sub-label {
		color: var(--light-colorTextTertiary) !important;
	}
}

.select-dropdown-box {
	width: 100%;
	label {
		display: flex;
		flex-direction: column;
		gap: 4px;
		width: 100%;

		.ant-select-selection-item {
			font-size: 14px !important;
		}
		.ant-select-selection-placeholder {
			font-size: 14px !important;
		}
		input {
			font-size: 14px !important;
		}
	}

	&.error {
		.ant-select-selector {
			border: 1px solid var(--light-colorError) !important;
			background-color: transparent;

			&:focus {
				border: 1px solid var(--light-colorError) !important;
				outline: none;
			}
		}
	}
}

.ant-input-group-wrapper {
	input {
		background: transparent;
	}
	&.errorUnit {
		.ant-input-group-addon {
			border: 1px solid var(--light-colorError) !important;
			border-left: none !important;
		}
	}
}

.ant-input {
	&.error {
		border: 1px solid var(--light-colorError) !important;
		&:focus {
			border: 1px solid var(--light-colorError) !important;
			outline: none;
		}
	}
}

.alt-unit-box {
	.delete-button {
		cursor: pointer;
		background: none;
		background-color: transparent;
	}

	.alt-header-text {
		color: var(--light-colorDarkText);
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
	}
	.alt-info {
		color: var(--light-colorSecondary);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 22px;
	}

	.alt-content {
		.alt-equalto {
			height: 28px;
		}
	}
}

.ant-drawer-mask {
	backdrop-filter: blur(10px);
}

.drawer-body {
	font-size: 12px;
}

.manage-input-container {
	display: flex;
	align-items: center;
	padding-right: 12px;
	border: 1px solid var(--light-primary);
	border-radius: 14px;
	width: 100%;
	height: 44px;
	margin: 2px 0;
	outline: none;

	input {
		border-radius: 14px;
	}

	input {
		border-radius: 8px;
		padding: 12px;
		flex: 1;
		border: none;
		outline: none;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		// line-height: 22px;
	}
	.icon-container {
		display: flex;
		gap: 5px;

		.save-field-btn {
			color: var(--light-primary);
			background: none;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px;
		}
	}
}
