.input-with-search {
  display: flex;
  height: 36px;
  align-items: center;
  border: 1px solid #f0f0f0;
  border-radius: 14px;
  padding: 0 7.27px 0 10.2px;
  gap: 8px;
  max-width: 100%;
  width: 100%;
  background: #f9fafb;
  position: relative;

  &:focus-within {
    border: 0.682px solid #b2d5ff;
  }
  .search-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
  }

  input {
    border: none;
    outline: none;
    flex: 1;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #667085;
    background: transparent;

    &::placeholder {
      color: #667085;
      font-size: 12px;
    }
  }

  .result-container {
    width: 100%;
    max-height: 40vh;
    overflow: auto;
    background-color: #fff;
    position: absolute;
    top: 33px;
    left: 0;
    border-radius: 8px;
    border: 1px solid #eaecf0;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    z-index: 9999;
    padding-bottom: 4px;

    .no-data-found {
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 14px;
      }
    }

    .search-history-header {
      display: flex;
      padding: 16px 8px 8px 8px;
      justify-content: space-between;

      .title {
        color: var(--light-colorText);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      .sub-header {
        color: var(--light-colorTextTertiary);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;

        .group-name {
          color: var(--light-primary);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }

      button {
        background-color: transparent;
        color: var(--light-colorError);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
    .results-list {
      padding: 1px 6px;
      display: flex;
      align-items: center;
      flex-direction: column;

      li {
        padding: 10px 8px 10px 8px;
        width: 100%;
        border-radius: 6px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;

        svg {
          path {
            fill: #00000073;
          }
        }

        .name {
          width: 100%;
        }

        .remove-icon {
          background-color: transparent;
          svg {
            width: 16px;
            height: 16px;

            path {
              stroke: #000000;
            }
          }
        }

        .img-box {
          width: 32px;
          height: 32px;
          min-width: 32px;
          min-height: 32px;
          border-radius: 4px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.02);
        }
      }
    }
  }
}
