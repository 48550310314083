@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "react-loading-skeleton/dist/skeleton.css";

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	/* scroll-behavior: smooth; */ /*do not use*/
	font-family: "SF Pro Text";
}

*::-webkit-scrollbar {
	width: 0px;
	height: 0;
}

@font-face {
	font-family: 'SF Pro Text';
	src: url('assets/fonts/SFPro/SF-Pro-Text-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
  
@font-face {
	font-family: 'SF Pro Text';
	src: url('assets/fonts/SFPro/SF-Pro-Text-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
  
@font-face {
	font-family: 'SF Pro Text';
	src: url('assets/fonts/SFPro/SF-Pro-Text-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}
  
@font-face {
	font-family: 'SF Pro Text';
	src: url('assets/fonts/SFPro/SF-Pro-Text-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
  
@font-face {
	font-family: 'SF Pro Text';
	src: url('assets/fonts/SFPro/SF-Pro-Text-Heavy.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

/* Fallback for non-WebKit browsers (Firefox etc.) */
.ant-table-body {
	scrollbar-width: thin; /* Width of the scrollbar */
	scrollbar-color: #888 #f1f1f1; /* Thumb and track colors */
}

/* If you want to avoid using `scrollbar-color` in Firefox, provide the best fallback */
.ant-table-body {
	scrollbar-width: thin; /* Just set to thin, the colors will default */
}

body {
	background-color: #ffffff;
	/* padding: 0 10px; */
	/* background-color: #dfddea76; */
	padding: 0 0px;
}

body.hidden {
	overflow: hidden;
}

li {
	list-style: none;
}

a {
	text-decoration: none;
	color: inherit;
}

button {
	/* outline: none; */
	border: none;
	cursor: pointer;
}

.flex {
	display: flex;
}

.flex-col {
	flex-direction: column;
}

.justifyCenter {
	justify-content: center;
}

.justifyBetween {
	justify-content: space-between;
}

.justifyStart {
	justify-content: flex-start;
}

.justifyEnd {
	justify-content: flex-end;
}

.alignStart {
	align-items: flex-start;
}

.alignCenter {
	align-items: center;
}

.textCenter {
	text-align: center;
}

.textEnd {
	text-align: end;
}

.textStart {
	text-align: start;
}

.alignEnd {
	align-items: flex-end;
}

.relative {
	position: relative;
}

.h-screen {
	height: 100vh;
}

.w-screen {
	width: 100vw;
}

.h-100 {
	height: 100%;
}

.w-100 {
	width: 100%;
}

.cursorPointer {
	cursor: pointer;
}

.container {
	width: 100%;
	margin: auto;
	max-width: 100%;
	height: 100%;
}
@media (min-width: 1920px) {
	.container {
		padding: 30px 20px 0 20px;
		max-width: 1920px;
	}
}
/* @media (max-width: 1680px) {
  .container {
    max-width: 100%;
  }
}

@media (max-width: 1280px) {
  .container {
    max-width: 1180px;
  }
} */

@media (max-width: 992px) {
	.container {
		max-width: 100%;
	}
}

/* .container-xsm {
  max-width: 1180px;
  width: 100%;
  margin: auto;
  padding: 0 10px;
} */

.direction-column {
	flex-direction: column;
}

.direction-row {
	flex-direction: row;
}

.border-dashed {
	border-style: dashed;
}

.ml-auto {
	margin-left: auto;
}

/* :root {
    --primary: #1890ff;
    --primary100: #e6f7ff;
    --primary200: #bae7ff;
    --primary300: #91d5ff;
    --primary400: #69c0ff;
    --primary500: #40a9ff;
    --primary600: #1890ff;
    --primary700: #096dd9;
    --primary800: #0050b3;
    --primary900: #003a8c;
    --primary1000: #002766;
  }
  
  /* Dark Theme */
body.dark-theme {
	--primary: #333333;
	--primary100: #f0f0f0;
	--primary200: #d9d9d9;
	--primary300: #bfbfbf;
	--primary400: #a6a6a6;
	--primary500: #8c8c8c;
	--primary600: #737373;
	--primary700: #595959;
	--primary800: #404040;
	--primary900: #262626;
	--primary1000: #0d0d0d;
}

/* Use CSS Variables in your SCSS */
.my-component {
	color: var(--primary);
	background-color: var(--red);
}

.another-component {
	border-color: var(--primary500);
	font-family: var(--fontFamily);
}

.ant-modal-wrap.ant-modal-centered {
	padding: 10px;
}
