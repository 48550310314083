.ant-popconfirm {
  .ant-popconfirm-buttons {
    button {
      &.ant-btn-default {
        &:hover {
          color: var(--light-primary) !important;
          outline: none !important;
          box-shadow: none !important;
          border-color: var(--light-primary) !important;
        }
        &:active {
          color: var(--light-primary) !important;
          border-color: var(--light-primary) !important;
          outline: none !important;
          box-shadow: none !important;
        }
        &:focus-visible {
          outline: none !important;
          box-shadow: none !important;
          color: var(--light-primary) !important;
          border-color: var(--light-primary) !important;
        }
      }
    }
  }
}
.products-list-container {
  display: flex;
  gap: 10px;
 .sidebar-container {
    max-width: 256px;
    min-width: 256px;
    height: 100%;
    border-radius: 12px;
    border: 1.5px solid #e7e7e7;
    background: rgba(255, 255, 255, 0.6);
    overflow-x: hidden;
    width: 100%;
  }
}

.products-list {
  width: 100%;
  flex: 1;
  max-width: calc(100% - 266px);
  padding: 0;
  height: 100%;
  margin-top: 0;
  height: 100%;
  overflow: hidden;
  &.active-list {
    max-width: 100%;
  }
  .table-container {
    max-height: calc(100vh - 100px);
    overflow: hidden;
    height: 100%;
    position: relative;
    .ant-table-body {
      overflow: auto !important;
      height: 100%;
    }
    .products-price-info-table {
      overflow: hidden;
      max-height: calc(100vh - 250px);
      height: 100%;

      .ant-table-body {
        overflow: auto !important;
      }

      .ant-table-expanded-row-fixed {
        margin: 0;
      }
    }
  }
}

.products-list-table {
  .down-arrow {
    svg {
      rotate: 45%;
    }
  }

  .arrow-icon {
    cursor: pointer;
    width: fit-content;
  }

  width: 100%;

  .table-header-container {
    width: 100%;
    margin-bottom: 10px;
    
    .search-wrapper {
      max-width: 339px;
      width: 100%;
    }

    input {
      overflow: hidden;
      color: var(--Colors-Text-text-placeholder, #667085);
      text-overflow: ellipsis;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16.364px;
      letter-spacing: 0.12px;
    }
  }

  .price-per-unit-text {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    text-align: end;
  }

  .ant-table-wrapper {
    width: 100%;
    overflow: auto;
    .ant-table-body {
      table {
        border-radius: 0px;
        border-top: 0px !important;
      }
    }
  }

  &.table-container {
    width: 100%;
    padding-top: 10px;
    border-radius: 12px;
    height: 100% !important;
    overflow: hidden;

    .ant-table-thead {
      position: sticky;
      top: 0;
      background-color: inherit;
      z-index: 999;
    }

    .ant-row {
      height: 100%;

      .ant-col {
        height: 100%;

        .ant-form-item-control-input {
          height: 100%;

          .ant-form-item-control-input-content {
            height: 100%;

            input {
              color: var(--light-colorText);
              height: 100%;
              border: 1px solid transparent;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              background-color: #ffffff;

              &:hover {
                border: 1px solid var(--light-colorBorder);
              }

              &:focus {
                outline: none;
                border: 1px solid var(--light-primary);
                z-index: 999;
              }
            }
          }
        }
      }
    }

    .ant-table-row>.ant-table-cell-row-hover {
      background: rgba(0, 0, 0, 0.02) !important;

      .ant-form-item-control-input-content {
        input {
          background-color: rgba(0, 0, 0, 0.02) !important;
        }
      }

      .table-buttons-container {
        display: flex;
      }
    }

    .ant-table-row.ant-table-row-selected>.ant-table-cell {
      background-color: #f2f7fe !important;

      .table-buttons-container {
        display: flex;
      }

      .ant-table-cell-row-hover {
        background: #f2f7fe !important;

        .ant-form-item-control-input-content {
          input {
            background-color: #f2f7fe !important;
          }
        }
      }

      .ant-form-item-control-input-content {
        input {
          background-color: #f2f7fe;
        }
      }
    }

    tr:first-child>*:first-child {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    .ant-table {
      border-radius: 12px;

      .ant-table-container,
      .ant-table-content {
        border-radius: 12px;
      }

      .ant-table-thead {
        .ant-table-cell {
          padding: 0 16px;
          height: 44px;
          // border-bottom: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
          // background: var(--Colors-Background-bg-primary, #fff);
          // color: var(--Color-Neutral-Text-colorTextDescription,
              // rgba(0, 0, 0, 0.45));
          /* SM/Strong */
          font-family: 'SF Pro Text';
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          text-align: end;
          &:nth-of-type(2) {
            text-align: start;
          }
          &:nth-of-type(3) {
            text-align: start;
            padding-left: 0;
          }
          &:nth-of-type(4) {
            text-align: start;
          }
        }
      }

      .ant-table-cell {
        height: 52px;
        text-align: start;

        &:nth-of-type(4) {
          text-align: start;
        }

        &:nth-of-type(9) {
          display: flex;
          align-items: center;
          justify-content: end;
        }

        .table-input {
          height: 100% !important;
          &.text-end {
            input {
              text-align: end;
              font-weight: 500;
            }
          }
          .ant-input-prefix {
            margin: 0 !important;
          }

          &:hover {
            height: 100% !important;
          }
        }

        &:nth-of-type(1) {
          padding-right: 8px;
          padding-left: 16px;
        }

        &:nth-of-type(2) {
          padding-right: 8px;
          padding-left: 0;
        }
        &:last-of-type {
          // padding-right: 8px;
          padding: 0 10px;
        }

        button {
          &.productSize-text {
            display: flex;
            // justify-content: end;
            background-color: transparent;
            width: 100%;
            height: 100%;
            text-align: start;
          }
        }
      }

      button:not(.ant-switch, .save-field-btn) {
        &:focus {
          border: 1px solid var(--light-primary);
          outline: none;
          z-index: 999;
          border-radius: 6px;
        }
      }
    }
  }

  .ant-table-expanded-row {
    .ant-table-wrapper {
      width: 100%;
      height: fit-content !important;
    }

    .ant-table {
      margin-inline: 0 !important;
      margin-block: 0 !important;

      .ant-table-thead {
        display: none;
      }
    }

    .ant-table-cell {
      padding: 0 !important;

      .expanded-table {
        .ant-table-wrapper {
          border: none;
        }

        .ant-table-content {
          .ant-table-cell {
            // padding: 0 16px !important;

            &:first-of-type {
              padding: 0 !important;
              padding-left: 12px !important;
            }

            &:nth-child(2) {
              padding-right: 8px !important;
              padding-left: 0 !important;
            }
          }
        }
      }

      height: 36px !important;

      td {
        &.ant-table-cell {
          &:last-of-type {
            padding: 0 10px !important;
          }
        }
      }
    }
  }
}

td.ant-table-cell {
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-checked) {
    display: none;
  }

  // &:last-of-type {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }

  &.ant-table-cell-row-hover {
    .ant-checkbox-wrapper {
      display: inline-flex;

      &.ant-checkbox-wrapper-checked {
        display: inline-flex;
      }
    }
  }
}

.expanded-table {
  .ant-table {
    border-radius: 0px !important;

    .ant-table-container,
    .ant-table-content {
      border-radius: 0px !important;
    }
  }
}

.expanded-table {
  .ant-table {
    border-radius: 0px !important;

    .ant-table-container,
    .ant-table-content {
      border-radius: 0px !important;
    }
  }
}

.product-name-input-container {
  display: flex;
  align-items: center;
  padding-right: 12px;
  border: 1px solid var(--light-primary);
  border-radius: 8px;
  width: 100%;
  height: 53px;
  margin: -2px 0;
  outline: none;
  background-color: #FFFFFF;

  input {
    border-radius: 8px;
    padding: 12px;
    flex: 1;
    border: none;
    outline: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .icon-container {
    display: flex;
    gap: 5px;

    button {
      &.save-field-btn {
        color: var(--light-primary);
        background: none;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        outline: none;
        border: none;

        &:focus {
          outline: none;
          border: none;
        }
      }
    }
  }
}

.bulk-rate-inputs {
  .ant-input-group-addon {
    .ant-select-focused {
      .ant-select-selector {
        color: var(--light-primary) !important;
      }
    }
  }
}