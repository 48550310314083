.product-card-container {
	display: flex;
	width: calc((100% / 7) - 9.75px);
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	border: 1px solid var(--light-colorBorder);
	background: #fff;
	position: relative;

	@media (max-width: 1920px) {
		width: calc((100% / 6) - (10px - (10px / 6)));
	}

	@media (max-width: 1600px) {
		width: calc((100% / 5) - (10px - (10px / 5)));
	}
	@media (max-width: 1440px) {
		width: calc((100% / 4) - (10px - (10px / 4)));
	}

	.no-images-icon {
		svg {
			width: 34px !important;
			height: 34px !important;
		}
	}

	.heart-box {
		background-color: transparent;
		display: flex;
		width: 29.21px;
		height: 29.21px;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		position: absolute;
		cursor: pointer;
		right: 10px;
		top: 10px;
		border-radius: 121.693px;
		border: 1.217px solid rgba(255, 255, 255, 0.6);
		background: rgba(255, 255, 255, 0.6);
		backdrop-filter: blur(2.4338624477386475px);
		z-index: 99;
		svg {
			path {
				transition: all 0.2s ease-in;
			}
		}
		&.active {
			svg {
				path {
					fill: red;
				}
			}
		}
	}
	&:hover {
		box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.1);
	}

	.image-container {
		position: relative;
		height: 140px;
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10;
		background-color: #f2f2f7;
		border-top-left-radius: inherit;
		border-top-right-radius: inherit;
		&.bg-light {
			background-color: #ffffff;
		}
		svg {
			width: 29px;
			height: 40px;
		}
		h3 {
			color: #667085;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
			object-position: center;
		}
		.offer {
			background-image: url("../../svg/OfferBg.svg");
			z-index: 9;
			position: absolute;
			bottom: 7.3px;
			left: 7.3px;
			width: 47px;
			height: 47px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			font-size: 10.952px;
			font-style: normal;
			font-weight: 800;
			line-height: 12.169px;
			text-align: center;
		}
	}
	.unavailable-product {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: #ffffff40;
		z-index: 100;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		span {
			&.badge {
				padding: 2px 6px;
				color: #fff;
				text-align: center;
				font-family: "SF Pro Text";
				font-size: 13.091px;
				font-style: normal;
				font-weight: 500;
				line-height: 19.636px; /* 150% */
				position: absolute;
				margin-top: 50px;
				// transform: translate(calc(50% - 180px), 50px);
				background-color: #646363;
				border-radius: 4px;
				max-width: 180px;
			}
		}
	}
	.product-image {
		height: 140px;
		align-self: stretch;
		width: 100%;
		img {
			object-fit: cover;
		}
	}

	.product-content {
		padding: 8px 8px;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 9.735px;
		align-self: stretch;

		.common-padding {
			padding: 0px 0px;
			width: 100%;
		}

		.product-name {
			color: #101828;
			font-family: "SF Pro Text";
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: 18px;
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.product-size-dropdown {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 6.091px;
			padding: 4px 8px;
			align-self: stretch;
			border-radius: 8px;
			// border: 0.761px solid var(--Colors-Border-border-primary, #d0d5dd);
			// background: #f2f2f790;
			border: 1px solid var(--light-border-common);
			// box-shadow: 0px 0.761px 1.523px 0px rgba(16, 24, 40, 0.05);
			width: 100%;
			cursor: pointer;
			transition: all 0.2s ease;
			&:hover {
				box-shadow: 0px 0.761px 1.523px 0px rgba(16, 24, 40, 0.1);
			}
		}

		.product-size-txt {
			color: #101828;
			font-size: 12.183px;
			font-style: normal;
			font-weight: 500;
			line-height: 18.274px;
			&.single-size {
				display: flex;
				align-items: center;
				height: 28px;
			}
		}

		.price-action {
			justify-content: space-between;
			align-items: center;
			align-self: stretch;

			.price-box {
				flex-direction: column;
				.old-price {
					color: #98a2b3;
				}
				.price {
					color: #101828;
					font-family: "SF Pro Text";
					font-size: 14px;
					font-style: normal;
					font-weight: 700;
					line-height: 20px; /* 142.857% */
					letter-spacing: -1px;
				}
			}

			.addButton {
				display: flex;
				border-radius: 8px;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: 20px; /* 142.857% */
				color: #000000;
				width: 68px;
				height: 32px;
				padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
				justify-content: center;
				align-items: center;
				gap: var(--spacing-xs, 4px);
				border: 1px solid #000000;
				background-color: #fff;
				transition: all 0.2s ease;
				&:hover {
					background-color: #000000;
					color: #fff;
				}
			}

			.product-plus-minus-button {
				width: 100px;
				height: 32px;
				input {
					width: 100%;
				}
			}
		}

		.bulkrate-button {
			color: var(--light-primary);
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 22px;
			background: transparent;

			path {
				stroke: var(--light-primary);
			}

			// .bulkrate-list-popover-container {
			//   width: 317px;
			//   padding: 8px 0;
			// }
		}
	}
}

.heart-tooltip {
	.ant-tooltip-arrow {
		&::after {
			background-color: red;
		}
	}

	.ant-tooltip-inner {
		background-color: red;
	}
}
