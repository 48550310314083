.adjust_stock_form_page {
    height: 100%;
    .form {
        &__container {
            max-width: 752px;
            margin: auto;
            height: 100%;
            padding-top: 32px;
            overflow: auto;
            padding-bottom: 32px;
        }
        &__header {
            button {
                &.back_button {
                    background: #EDEDED;
                    padding: 7px;
                    border-radius: 4.364px;
                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
            span {
                &.button_text {
                    color: #000;
                    font-family: "SF Pro Text";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
            &.title_section {
                margin: 0 0 24px 0;
                h3 {
                    &.title-3 {
                        color: #000;
                        font-family: "Plus Jakarta Sans";
                        // font-size: 40px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }
            }
        }
        &__body {
            border-radius: 24px;
            background: #FFF;
            padding: 32px;
            .product_name_container {
                .product_info {
                    .product_image_container {
                        width: 40px;
                        height: 40px;
                        min-width: 40px;
                        min-height: 40px;
                        overflow: hidden;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            object-position: center;
                        }
                    }
                    p {
                        &.product_name {
                            color: #000;
                            font-family: "SF Pro Text";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 22px; /* 157.143% */
                        }
                    }
                }
            }
        }
        &__tabs_section {
            .tabs_content {
                .adjusted_qty {
                    &__section {
                        margin-top: 26px;
                    }
                    &__text {
                        color: #344056;
                        font-family: "SF Pro Text";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                    &__button {
                        border-radius: 12px;
                        padding: 8px 12px;
                        margin-top: 8px;
                        &.red {
                            background: var(--Color-Error-colorErrorBG, #FFF1F0);
                            border: 1px solid var(--Color-Error-colorErrorBorder, #FFA39E);
                            span {
                                &.qty_text {
                                    color: var(--Color-Error-colorErrorText, #F5222D);
                                }
                            }
                            svg {
                                path {
                                    fill: #F5222D;
                                }
                            }
                        }
                        &.green {
                            border-radius: 14px;
                            background: var(--Color-Error-colorErrorBG, #ECFDF3);
                            border: 1px solid var(--Color-Error-colorErrorBorder, #ABEFC6);
                            span {
                                &.qty_text {
                                    color: var(--Color-Error-colorErrorText, #17B26A);
                                }
                            }
                            svg {
                                path {
                                    fill: #17B26A;
                                }
                            }
                        }
                        span {
                            &.qty_text {
                                font-family: "SF Pro Text";
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                            }
                        }
                    }
                }
                .textarea-input {
                    margin-top: 16px;
                }
            }
        }
        &__footer {
            width: 100%;
            .button {
                max-width: 50%;
                width: 100%;
                height: 40px;
                border-radius: 14px;
                button {
                    width: 100%;
                    height: 100%;
                    border-radius: inherit;
                }
            }
        }
    }
}

.adjustment_reason_section {
    margin-top: 26px;
    .select-dropdown-box {
        label {
            color: #344056;
            font-family: "SF Pro Text";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .ant-select-selector {
        border-radius: 8px !important;
    }
}

.more_dropdown_container {
    max-height: 35vh;
    overflow: auto;
    border-radius: 14px;
    border: 1px solid var(--Background-colorBgContainer, #FFF);
    background: var(--Background-colorBgElevated, #FFF);
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    .ant-popover-inner-content {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .dropdown_item {
        button {
            color: rgba(0, 0, 0, 0.65);
            font-family: "SF Pro Text";
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 1;
            border-radius: 8px;
            border: 1px solid #E8ECF2;
            padding: 8px 12px;
            background-color: #FFF;
            min-width: 150px;
            text-align: left;

            &:hover {
                background-color: #F1F4F9;
            }
        }
        &.active {
            button {
                border: 1px solid #000;
                background: #000;
                color: #FFF;
            }
        }
    }
}