.products_list {
	&__page {
		max-width: 752px;
		width: 100%;
		margin: auto;
		margin-top: 32px;
		max-height: calc(100vh - 110px);
		overflow: auto;
	}
	&__header {
		margin-bottom: 32px;
		button {
			&.back_button {
				background: #ededed;
				padding: 7px;
				border-radius: 4.364px;
				svg {
					width: 16px;
					height: 16px;
				}
			}
		}
		span {
			&.button_text {
				color: #000;
				font-family: "SF Pro Text";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
		}
		.submit_buttons_container {
			.button {
				&.primary {
					border-radius: 12px;
					button {
						padding: 10px 30px;
						color: #fff;
						font-family: "SF Pro Text";
						font-size: 16px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
					}
				}
				&.secondry {
					background-color: transparent;
					button {
						padding: 10px 20px;
						color: #000;
						background-color: transparent;
						font-family: "SF Pro Text";
						font-size: 16px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
						border: 1px solid transparent;
						border-radius: 12px;
						transition: all 0.2s ease;
						&:hover {
							border-color: #000;
						}
					}
				}
			}
		}
	}
	&__container {
		margin-bottom: 8px;
		position: sticky;
		top: 0;
		.title_section {
			h3 {
				&.title-3 {
					color: #000;
					font-family: "Plus Jakarta Sans";
					font-size: 40px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				}
			}
		}
		.search {
			&__container {
				max-width: 107px;
				border-radius: 14px;
				border: 1px solid #e8ecf2;
				background: #fff;
				width: 100%;
				box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
				transition: all 0.2s ease-out;
				.search-bar {
					background-color: #fff;
					padding: 6px;
					&:focus-within {
						border-color: 1px solid #000;
					}
					.search-icon {
						padding: 6px;
						width: 28px;
						height: 28px;
						min-width: 28px;
						min-height: 28px;
						border-radius: 10px;
						border: 1px solid #e6f1ff;
						background: rgba(1, 105, 230, 0.05);
						svg {
							width: 16px;
							height: 16px;
							path {
								stroke: #0169e6;
							}
						}
					}
					input {
						width: 100%;
					}
				}
				&.active {
					max-width: 100%;
				}
			}
		}
		.list {
			&__item {
				button {
					border-radius: 14px;
					background-color: #fff;
					border: 1px solid #e8ecf2;
					padding: 6px;
					gap: 6px;
					&.active {
						border: 1px solid #000;
						background-color: #000;
						span {
							&.item {
								&__text {
									color: #fff;
									font-family: "SF Pro Text";
									font-size: 14px;
									font-style: normal;
									font-weight: 600;
									line-height: normal;
								}
								&__count {
									border-radius: 12.5px;
									border: 1px solid #ffbc0d;
									background: #fff;
									color: #000;
									font-family: "SF Pro Text";
									font-size: 12px;
									font-weight: 600;
									line-height: 1;
									padding: 7px 5px;
								}
							}
						}
					}
					span {
						&.item {
							border-radius: 14px;
							border: 1px solid #e8ecf2;
							&__text {
								color: rgba(0, 0, 0, 0.65);
								font-family: "SF Pro Text";
								font-size: 14px;
								font-style: normal;
								font-weight: 600;
								line-height: normal;
							}
							&__count {
								border-radius: 12.5px;
								border: 1px solid #dfe4ed;
								background: #fff;
								color: #000;
								font-family: "SF Pro Text";
								font-size: 12px;
								font-weight: 600;
								line-height: 1;
								padding: 7px 5px;
							}
						}
					}
				}
			}
			&__container {
				position: sticky;
				top: 100px;
			}
		}
		.tabs {
			&__products_list {
				margin-top: 8px;
				background-color: #fff;
				padding: 24px;
				border-radius: 24px;
				width: 100%;
				.products_list_header {
					padding-bottom: 8px;
					button {
						&.select-button {
							background-color: transparent;
							color: var(--Colors-Brand-Primary-colorPrimary, #0169e6);
							font-family: "SF Pro Text";
							font-size: 12px;
							font-style: normal;
							font-weight: 500;
							line-height: 22px; /* 183.333% */
						}
					}
				}
			}
		}
		ul {
			&.product {
				&__list_items {
					max-height: calc(100vh - 330px);
					overflow: auto;
					li {
						&.item {
							border-radius: 14px;
							border: 1px solid #f2f2f7;
							&.selected {
								border-color: #000;
							}
							input[type="checkbox"] {
								min-width: 15px;
								min-height: 15px;
								width: 15px;
								height: 15px;
								position: relative;
								cursor: pointer;
								&:checked:not(.partial_selected) {
									&::before {
										content: "";
										position: absolute;
										left: -0.5px;
										width: 16px;
										height: 16px;
										top: -0.5px;
										background-color: #000;
										border-radius: 4px;
										cursor: pointer;
									}
									&::after {
										content: "";
										position: absolute;
										left: 1.6px;
										top: 6.5px;
										background-color: #fff;
										border-radius: 4px;
										transform: rotate(-45deg);
										background: #fff;
										width: 3px;
										height: 3px;
										box-shadow:
											2px 0 0 #fff,
											4px 0 0 #fff,
											4px -2px 0 #fff,
											4px -4px 0 #fff,
											4px -6px 0 #fff,
											4px -8px 0 #fff,
											4px -10px 0 #fff;
										transform: rotate(45deg);
										cursor: pointer;
									}
								}
								&.selected {
									&::before {
										content: "";
										position: absolute;
										left: -0.5px;
										width: 16px;
										height: 16px;
										top: -0.5px;
										background-color: #000;
										border-radius: 4px;
										cursor: pointer;
									}
									&::after {
										content: "";
										position: absolute;
										left: 1.6px;
										top: 6.5px;
										background-color: #fff;
										border-radius: 4px;
										transform: rotate(-45deg);
										background: #fff;
										width: 3px;
										height: 3px;
										box-shadow:
											2px 0 0 #fff,
											4px 0 0 #fff,
											4px -2px 0 #fff,
											4px -4px 0 #fff,
											4px -6px 0 #fff,
											4px -8px 0 #fff,
											4px -10px 0 #fff;
										transform: rotate(45deg);
										cursor: pointer;
									}
								}
								&.partial_selected {
									&::before {
										content: "";
										position: absolute;
										left: -0.5px;
										width: 16px;
										height: 16px;
										top: -0.5px;
										background-color: #000;
										border-radius: 4px;
									}
									&::after {
										content: "";
										position: absolute;
										left: 2.5px;
										width: 10px;
										height: 2.5px;
										top: 6.25px;
										background-color: #fff;
										border-radius: 4px;
									}
								}
							}
							label {
								padding: 16px;
								cursor: pointer;
								.product {
									&__info_container {
									}
									&__image_container {
										max-width: 40px;
										max-height: 40px;
										width: 100%;
										height: 100%;
										overflow: hidden;
										border-radius: 9px;
										img {
											width: 100%;
											height: 100%;
											object-fit: contain;
											object-position: center;
										}
									}
									&__name {
										color: #000;
										font-family: "SF Pro Text";
										font-size: 14px;
										font-style: normal;
										font-weight: 600;
										line-height: 22px; /* 157.143% */
									}
									&__size_show_button {
										background-color: transparent;
										color: var(
											--Colors-Brand-Primary-colorPrimary,
											#0169e6
										);
										font-family: "SF Pro Text";
										font-size: 12px;
										font-style: normal;
										font-weight: 500;
										line-height: 20px; /* 166.667% */
									}
									&__stock_info {
										color: rgba(0, 0, 0, 0.65);
										font-family: "SF Pro Text";
										font-size: 12px;
										font-style: normal;
										font-weight: 400;
										line-height: 20px; /* 166.667% */
									}
								}
							}
						}
					}
				}
				&__size_list_container {
					max-height: 300px;
					overflow: auto;
				}
			}
		}
		.product {
			&__size {
				width: 100%;
				label {
					p {
						&.size_name {
							color: #000;
							font-family: "SF Pro Text";
							font-size: 14px;
							font-style: normal;
							font-weight: 600;
							line-height: 20px; /* 142.857% */
						}
						&.size_info_text {
							color: rgba(0, 0, 0, 0.65);
							font-family: "SF Pro Text";
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px;
						}
					}
				}
			}
		}
	}
}
