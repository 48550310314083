.action {
	&__more_options {
		min-width: 190px;
		gap: 4px;
	}
	&__list_item {
		background-color: transparent;
		cursor: pointer;
		color: rgba(0, 0, 0, 0.88);
		font-family: "SF Pro Text";
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 22px; /* 157.143% */
		display: flex;
		align-items: center;
		gap: 5px;
		padding: 10px;
		transition: all 0.2s ease;
		border-radius: 14px;
		&:hover {
			background-color: #f1f4f9;
		}

		&.active {
			background-color: #f1f4f9;
		}

		&:disabled {
			cursor: not-allowed;
			color: rgba(0, 0, 0, 0.4);
		}
	}

	&__icon {
		width: 20px;
		height: 20px;
		svg {
			width: 20px;
			height: 20px;
		}
	}
}

button {
	&.action-icon-button {
		width: 20px;
		height: 20px;
		background-color: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			transform: rotate(90deg);
		}

		&:disabled {
			cursor: not-allowed;
		}
	}
}
