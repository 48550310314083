.product-details-wrapper {
	.product-details-container {
		height: 100%;
		background: #fff;
		border-radius: 12px;
		width: 100%;
		overflow: auto;

		.back-button-container {
			display: flex;
			align-items: center;
			gap: 8px;
			width: 100%;
			background-color: #fff;
			padding-bottom: 12px;

			.back-btn {
				display: flex;
				align-items: center;
				gap: 8px;
				background-color: transparent;
				svg {
					width: 20px;
					height: 20px;
					// transform: rotate(90%);s
					rotate: 90deg;
					path: {
						stroke: #000000e0;
					}
				}

				span {
					color: var(--light-colorDarkText);
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 22px;
				}
			}
		}

		.product-details-content-box {
			max-height: 77vh;
			margin: auto;
			width: 100%;
		}

		.image-container {
			position: relative;
			height: 140px;
			width: 100%;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 10;
			background-color: #f2f2f7;
			border-top-left-radius: inherit;
			border-top-right-radius: inherit;
			border-radius: 16.271px;
			border: 1.356px solid var(--light-colorBorder);
			width: 542.37px;
			height: 542.37px;

			&.bg-light {
				background-color: #ffffff;
			}
			svg {
				width: 29px;
				height: 40px;
			}
			h3 {
				color: #667085;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
			}
			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
				object-position: center;
			}
			.offer {
				background-image: url("../../svg/OfferBg.svg");
				z-index: 9;
				position: absolute;
				bottom: 7.3px;
				left: 7.3px;
				width: 47px;
				height: 47px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				font-size: 10.952px;
				font-style: normal;
				font-weight: 800;
				line-height: 12.169px;
				text-align: center;
			}

			.heart-box {
				background-color: transparent;
				display: flex;
				width: 32px;
				height: 32px;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				position: absolute;
				right: 18.794px;
				cursor: pointer;
				top: 18px;
				border-radius: 121.693px;
				border: 1.085px solid
					var(--Component-colors-Alpha-alpha-black-10, rgba(0, 0, 0, 0.1));
				background: var(
					--Component-colors-Alpha-alpha-white-50,
					rgba(255, 255, 255, 0.5)
				);
				// backdrop-filter: blur(2.4338624477386475px);
				z-index: 99;

				svg {
					width: 24px;
					height: 24px;
					path {
						transition: all 0.2s ease-in;
					}
				}
				&.active {
					svg {
						path {
							fill: red;
						}
					}
				}
			}
		}

		.other-images-container {
			display: flex;
			align-items: flex-start;
			gap: 19.525px;

			.image-box {
				width: 80.271px;
				height: 80.271px;
				border-radius: 8.678px;
				border: 1.085px solid var(--light-colorBorderSecondary);
				overflow: hidden;
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				&.active {
					border: 1.085px solid var(--light-primary);
				}

				.product-image {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}

				&:hover {
					border: 1.085px solid var(--light-primary);
				}
			}
		}

		.details-container {
			width: 100%;
			min-width: 450px;
			// max-width: 800px;
			max-height: 80vh;
			overflow: auto;
			position: relative;

			.product-name-description {
				.product-name {
					color: var(--light-colorText);
					font-size: 31px;
					font-style: normal;
					font-weight: 600;
					line-height: 38px;
				}

				.product-info {
					color: var(--light-colorSecondary);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px;
				}
			}

			.product-details-box {
				gap: 10px;

				.product-details-header {
					font-size: 18px;
					font-style: normal;
					font-weight: 600;
					line-height: 24px;
				}

				.content-header {
					color: var(--light-colorDarkText);
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 22px;
				}

				.content-info {
					color: var(--light-colorSecondary);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px;
				}

				.view-more-btn {
					color: var(--light-primary);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px;
					width: fit-content;
					background-color: transparent;

					svg {
						path {
							stroke: var(--light-primary);
						}
					}
				}
			}

			.up-arrow {
				svg {
					rotate: 180deg;
				}
			}

			.select-sizes-container {
				.product-sizes-container {
					&:nth-child(2) {
						margin-top: -8px;
					}
				}
				.header-box {
					position: sticky;
					top: 0;
					left: 0;
					background: #fff;
					z-index: 999;
					padding-bottom: 16px;

					.select-size-header {
						color: var(--light-colorText);
						font-size: 18px;
						font-style: normal;
						font-weight: 600;
						line-height: 24px;
					}

					.search-bar {
						border-radius: 8px;
					}
				}

				.product-sizes-container {
					.product-size-details-box {
						width: 100%;
						padding: 10px;
						display: flex;
						flex-direction: column;
						gap: 16px;
						min-height: 60px;
						border-radius: 12px;
						background: rgba(0, 0, 0, 0.02);
						border: 1px solid transparent;
						max-height: 246px;
						overflow: auto;

						&:hover,
						&.active-size {
							border: 1px solid var(--light-primary);
							background: #fff;
						}

						.size-main-details-box {
							padding: 0 10px;
							display: flex;
							align-items: center;
							justify-content: space-between;

							.product-size-name {
								min-width: 180px;
								color: var(--light-colorText);
								font-size: 14px;
								font-style: normal;
								font-weight: 600;
								line-height: 22px;
							}

							.product-size-price-box {
								min-width: 150px;

								height: 40px;
								min-height: 100%;
								display: flex;
								align-items: center;
								justify-content: end;
								color: var(--light-colorText);
								font-size: 14px;
								font-style: normal;
								font-weight: 600;
								line-height: 22px;
								position: relative;

								.size-unit {
									color: var(--light-colorTextTertiary);
								}

								.discounted-price {
									color: var(--light-darkColor25);
									position: absolute;
									top: 0px;
									left: 0;
									font-size: 10px;
									font-style: normal;
									font-weight: 600;
									line-height: 18px;
								}
							}

							.bulkrate-btn {
								min-width: 81px;
								background-color: transparent;
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								line-height: 22px;
								color: var(--light-primary);

								svg {
									width: 16px;
									height: 16px;
									path {
										stroke: var(--light-primary);
									}
								}
							}

							.counter-button-container {
								height: 40px;
								.counter-button {
									height: 100%;
								}
							}

							.add-size-button {
								width: 127px;
								border: 1px solid var(--light-primary);
								background: #fff;
								border-radius: 8px;
								height: 40px;
								padding: 4px 15px;
								color: var(--light-primary);
							}

							.unavailable-size {
								display: flex;
								align-items: center;
								background-color: #ffffff50;
								span {
									&.badge {
										background-color: #c3c3c3;
										color: #fff;
										text-align: center;
										font-family: "SF Pro Text";
										font-size: 11px;
										font-style: normal;
										font-weight: 500;
										line-height: 19.636px; /* 178.512% */
										padding: 2px 6px;
										border-radius: 4px;
										// margin-right: 10px;
									}
								}
							}
						}

						.size-details-container {
							padding-left: 10px;
							padding-bottom: 10px;
							display: flex;
							flex-direction: column;
							gap: 16px;

							.size-details-header {
								color: var(--light-colorText);
								font-size: 16px;
								font-style: normal;
								font-weight: 600;
								line-height: 24px;
							}

							.sizes-container {
								max-height: 118px;
								overflow: auto;
							}

							.size-item-name {
								width: 142px;
								color: var(--light-colorDarkText);
								font-size: 14px;
								font-style: normal;
								font-weight: 600;
								line-height: 22px;
							}

							.size-item-value {
								color: var(--light-colorSecondary);
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								line-height: 22px;
							}
						}
					}
				}
			}
		}
	}
}
