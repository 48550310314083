.bulkrate-popup {
  .invitation-message-wrapper {
    &.bulkrate-message {
      background-color: #F5222D;
      height: 42px;
      color: #FFF;
      display: flex;
      align-items: center;
      padding: 6px 8px;
      justify-content: center;
      gap: 9px;
      border-radius: 12px;
      z-index: 9999999;
      width: 100%;
      margin-left: 0;
      position: fixed;
      top: -80px;
      left: 0;
    }
  }
}
.modal-bulkrate-box {
  margin-top: 20px;
  .ant-list-items{
    max-height: 500px;
    overflow: auto;
    .ant-list-item {
      border-block-end: none;
      padding: 0;
  
      .dragable-icon {
        cursor: pointer;
        height: 100%;
      }
  
      .draggable-item {
        min-width: 100%;
      }
    }
  }

  .bulk-rate-inputs {
    position: relative;
    min-height: 96px;
    max-height: 116px;
    padding: 16px;
    border-radius: 8px;
    align-items: flex-start;

    .equal-to {
      // height: 100%;
      margin-top: 30px;
      // margin-bottom: 5px;
    }

    .status-box {
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      border: 1px solid #f0f0f0;
      border-radius: 6px;
      gap: 4px;
      padding: 4px 6px;
      font-size: 12px;
      color: var(--light-colorSecondary);
      font-style: normal;
      font-weight: 500;
      line-height: 18px;

      .status-active-icon {
        border-radius: 100%;
        width: 8px;
        height: 8px;
        background-color: var(--light-colorSuccess);
      }

      .status-inactive-icon {
        border-radius: 100%;
        width: 8px;
        height: 8px;
        background-color: var(--light-colorError);
      }
    }

    .action-buttons {
      gap: 2px;
      display: none;
      position: absolute;
      top: 5px;
      right: 4px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);

      .action-buttons {
        display: flex;
      }

      .delete-box {
        display: block;

        &:hover path {
          stroke: #f5222d;
        }
      }
    }
  }
  .add-bulkrate-button {
    color: var(--light-primary);
    background: none;
    margin-top: 8px;
    margin-left: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}
