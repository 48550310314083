@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

.landing-page-wrapper {
	margin: 0;
	.landing-page-content {
		min-height: 100vh;
	}
	header {
		&.header-container {
			height: 80px;
			position: sticky;
			top: 0px;
			background-color: white;
			align-content: center;
			z-index: 999;
			@media (max-width: $tablet) {
				position: relative;
			}
			.container {
				min-height: auto;
				max-width: 1920px;
			}
			span {
				&.beta-chip {
					padding: 6px 12px;
					border-radius: 12px;
					border: 1px solid #ffbc0d;
					color: #000;
					text-align: center;
					font-size: 12px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}
			}
			.logo-container {
				width: 151px;
				overflow: hidden;
				height: auto;
				@media (max-width: $tablet) {
					width: 120px;
				}
				img {
					width: 100%;
					height: 100%;
				}
			}
			nav {
				&.navbar {
					@media (max-width: $tablet) {
						display: none;
					}
					ul {
						&.navlinks-list {
							li {
								&.navlink-item {
									a {
										color: #02015a;
										font-size: 16px;
										font-style: normal;
										font-weight: 600;
										line-height: 21.6px; /* 135% */
										letter-spacing: -0.48px;
										transition: all 0.2s ease;
										&.active {
											color: #ffbc0f;
										}
										&:hover:not(.active) {
											color: #000;
										}
									}
								}
							}
						}
					}
				}
			}
			.login-button {
				@media (max-width: $tablet) {
					display: none;
				}
			}
			.desktop-header {
				@media (max-width: $tablet) {
					display: none;
				}
			}
			button {
				&.hamburger-icon {
					display: none;
					@media (max-width: $tablet) {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 32px;
						overflow: hidden;
						background-color: transparent;
						width: 32px;
						height: 32px;
					}
				}
			}
			.mobile-navigation {
				display: none;
				&.active {
					@media (max-width: $tablet) {
						display: block;
						width: 100%;
						height: 100vh;
						background-color: #fdbb0e;
						z-index: 1000;
						position: absolute;
						left: 0;
						top: 0;
						padding: 0 10px;
					}
					.h-80 {
						height: 80px;
					}
					.navbar {
						width: 100%;
						padding: 0px 4px;
						@media (max-width: $tablet) {
							display: flex;
							flex-direction: column;
						}
						.login-button {
							display: flex;
							margin-top: 24px;
							width: fit-content;
							padding: 10px 32px;
							border-radius: 8px;
						}
						.navlinks-list {
							flex-direction: column;
							align-items: flex-start;
							width: 100%;
							gap: 24px;
							li {
								&.navlink-item {
									text-align: left;
									width: 100%;
									a {
										width: 100%;
										transition: all 0.2s ease-in-out;
										padding: 8px;
										cursor: pointer;
										display: block;
										border-radius: 8px;
										&:hover {
											background-color: #000;
											color: #fff;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.container {
		padding: 0px 10px;
		padding-bottom: 0;
		min-height: calc(100vh - 60px);
		@media (min-width: 1440px) {
			max-width: 1280px;
		}
		@media (max-width: $tablet) {
			min-height: fit-content;
		}
	}
	.primary-button {
		background: #000;
		color: var(--Neutral-10, #fff);
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 150%; /* 24px */
		padding: 16px 41px;
		@media (max-width: 768px) {
			padding: 12px 24px;
			border-radius: 8px !important;
		}
	}
	.landing-page-container {
		overflow: auto;
		height: 100%;
		section {
			&.section-container {
				min-height: auto;
				padding: 80px 10px;
				&:first-of-type {
					padding-top: 60px;
					min-height: calc(100vh - 81px);
					@media (max-width: $tablet) {
						min-height: fit-content;
					}
				}
				@media (max-width: $tablet) {
					padding: 32px 10px;
				}
				&:nth-of-type(7) {
					background: linear-gradient(
						180deg,
						#ecf1f7 0%,
						rgba(243, 247, 251, 0) 100%
					);
				}
				h1 {
					&.heading-text {
						color: #000;
						text-align: center;
						font-family: "Plus Jakarta Sans";
						font-size: 72px;
						font-style: normal;
						font-weight: 700;
						line-height: 105%; /* 75.6px */
						letter-spacing: -3.6px;
						max-width: 70%;
						margin: auto;

						span {
							&.text-bg {
								background-color: #fdbb0e;
								padding: 0px 23.841px 0px 11.921px;
								height: 84px;
								@media (max-width: 768px) {
									height: 66px;
									display: inline;
								}
							}
						}
						@media (max-width: 1280px) {
							max-width: 100%;
						}
						@media (max-width: $tablet-landscap) {
							font-size: 64px;
						}
						@media (max-width: $tablet) {
							font-size: 48px;
							letter-spacing: -0.2px;
							br {
								display: none;
							}
						}
						@media (max-width: $mobile) {
							font-size: 32px;
						}
					}
				}
				h4 {
					&.title-4 {
						color: #4e5774;
						text-align: center;
						font-size: 20px;
						font-style: normal;
						font-weight: 400;
						line-height: 135%; /* 27px */
						letter-spacing: -0.5px;
						max-width: 60%;
						margin: 24px auto 16px auto;
						@media (max-width: $tablet-landscap) {
							font-size: 20px;
						}
						@media (max-width: $tablet) {
							font-size: 18px;
							margin: 16px auto 12px auto;
							letter-spacing: 0px;
							max-width: 100%;
						}
						@media (max-width: $mobile) {
							font-size: 16px;
							margin: 12px auto 10px auto;
						}
					}
				}
				.customers-list {
					position: relative;
					margin: auto;
					.customer-images {
						position: relative;
						img {
							border-radius: 100%;
							border: 2px solid #fff;
							width: 36px;
							height: 36px;
							object-position: center;
							object-fit: cover;
							&:not(:first-of-type) {
								position: absolute;
							}
						}
					}
					span {
						&.text {
							color: #9ca3bd;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 18.2px; /* 130% */
							letter-spacing: -0.56px;
						}
					}
				}
				form {
					&.booking-form {
						margin-top: 3vw;
						@media (max-width: 768px) {
							flex-direction: column;
						}
						.input-container {
							border: 1px solid rgba(0, 0, 0, 0.3);
							background: #fff;
							overflow: hidden;
							max-width: 371px;
							width: 100%;
							padding: 15px 16px;
							height: 60px;
							@media (max-width: 768px) {
								max-width: 100%;
								padding: 8px 16px;
								height: 46px;
								border-radius: 8px !important;
							}
							span {
								&.whatsapp-icon {
									width: 30px;
									height: 30px;
									svg {
										width: 100%;
										height: 100%;
									}
								}
							}
							input {
								border: none;
								outline: none;
								font-size: 22px;
								font-style: normal;
								font-weight: 600;
								line-height: 150%; /* 24px */
								&::placeholder {
									color: #757b8a;
									font-size: 16px;
									font-weight: 500;
								}
								@media (max-width: 768px) {
									font-size: 18px;
								}
							}
						}
						button {
							&.form-button {
								height: 60px;
								@media (max-width: 768px) {
									height: 46px;
									border-radius: 8px !important;
								}
							}
						}
					}
				}
				.hero-image {
					max-width: 1202px;
					width: 100%;
					margin: auto;
					margin-top: 3vw;
					@media (max-width: 1440px) {
						margin-top: 7vw;
					}
					@media (max-width: 768px) {
						margin-top: 15vw;
					}

					img {
						width: 100%;
						&.mobile {
							display: none;
						}
						&.desktop {
							display: block;
						}
						@media (max-width: 992px) {
							&.mobile {
								display: block;
							}
							&.desktop {
								display: none;
							}
						}
					}
				}
				.section-title {
					.badge-text {
						margin: auto;
						margin-bottom: 16px;
					}
				}
				.badge-text {
					border-radius: 14px;
					background: #ffbc0f;
					width: fit-content;
					padding: 8px 14px;
					font-size: 12px;
					font-style: normal;
					font-weight: 700;
					line-height: 13.2px; /* 110% */
					letter-spacing: 0.24px;
					text-transform: uppercase;
				}
				.section-row {
					max-width: 900px;
					margin: auto;
					padding: 50px 0;
					@media (max-width: $tablet) {
						padding: 12px 0;
					}
					.flex {
						&.gap-12 {
							gap: 16px;
						}
						&:first-of-type {
							flex-direction: column;
							.text-container,
							.image-container {
								max-width: 100%;
								width: 100%;
								overflow: hidden;
								@media (max-width: $tablet) {
									&.justifyEnd {
										justify-content: flex-start;
									}
								}
								img {
									width: 100%;
									max-width: 500px;
									object-fit: contain;
								}
							}
						}
					}
					.text-container,
					.image-container {
						max-width: 50%;
						width: 100%;
						overflow: hidden;
						img {
							width: 100%;
							max-width: 500px;
							object-fit: contain;
						}
					}
					&:nth-of-type(2) {
						.image-container {
							img {
								max-width: 332px;
								width: 100%;
							}
						}
					}
					&:nth-of-type(3) {
						.image-container {
							img {
								max-width: 400px;
								width: 100%;
								max-height: 430px;
							}
						}
					}
					&:nth-of-type(5) {
						.image-container {
							img {
								max-width: 376px;
								width: 100%;
							}
						}
					}
					.rowReverse {
						flex-direction: row-reverse;
					}
					.text-container {
						span {
							&.badge-text {
								margin-bottom: 16px;
								display: inline-block;
								@media (max-width: $tablet) {
									margin-bottom: 8px;
								}
							}
						}
						h2 {
							&.title-2 {
								color: #000;
								font-family: "Plus Jakarta Sans";
								font-size: 40px;
								font-style: normal;
								font-weight: 700;
								line-height: 100%; /* 40px */
								@media (max-width: $tablet) {
									font-size: 28px;
								}
							}
						}
						h5 {
							&.title-5 {
								color: #68708c;
								font-size: 18px;
								font-style: normal;
								font-weight: 400;
								line-height: 140%; /* 25.2px */
								letter-spacing: -0.3px;
								margin-top: 16px;
								@media (max-width: $tablet) {
									margin-top: 4px;
									font-size: 16px;
								}
							}
						}
					}
				}
				ul {
					&.feature-list-items {
						margin-top: 40px;
						@media (max-width: $tablet) {
							margin-top: 24px;
						}
						.flex {
							.list-container {
								max-width: 50%;
								@media (max-width: $tablet) {
									max-width: 100%;
								}
								.tablet {
									&.active {
										cursor: pointer;
										border-radius: 14px;
										@media (max-width: $tablet) {
											background-color: #ffbc0f;
											li {
												&.feature-item {
													&.active {
														background-color: inherit;
													}
												}
											}
										}
									}
								}
								li {
									&.feature-item {
										padding: 24px;
										cursor: pointer;
										border-radius: 14px;
										transition: all 0.2s ease-in-out;
										@media (max-width: $tablet) {
											padding: 16px;
										}
										&:hover {
											background: #ffbc0f;
										}
										&.active {
											background: #ffbc0f;
										}
										h3 {
											&.title-3 {
												color: #02015a;
												font-size: 26px;
												font-style: normal;
												font-weight: 500;
												line-height: 31.2px; /* 120% */
												letter-spacing: -1.04px;
												@media (max-width: $tablet) {
													font-size: 20px;
												}
											}
										}
										p {
											&.caption {
												color: #4e5774;
												font-size: 18px;
												font-style: normal;
												font-weight: 400;
												line-height: 25.2px; /* 140% */
												letter-spacing: -0.3px;
												@media (max-width: $tablet) {
													font-size: 16px;
												}
											}
										}
									}
								}
							}
							.feature-image {
								max-width: 50%;
								border-radius: 14px;
								overflow: hidden;
								@media (max-width: $tablet) {
									max-width: 100%;
								}
								&.tablet {
									display: none;
									@media (max-width: $tablet) {
										padding-bottom: 16px;
										display: block;
									}
								}
								&.desktop {
									@media (max-width: $tablet) {
										display: none;
									}
								}
								img {
									max-width: 584px;
									width: 100%;
									object-fit: contain;
									border-radius: 14px;
									@media (max-width: $tablet) {
										max-width: 100%;
										max-height: 500px;
										object-position: center center;
									}
								}
							}
						}
					}
				}
				.box-container {
					border-radius: 14px;
					background: #000000;
					padding: 60px 80px;
					@media (max-width: $tablet) {
						padding: 16px;
						.image-container {
							display: none;
						}
					}
					h2 {
						&.title-2 {
							color: #ffbc0d;
							font-size: 52px;
							font-style: normal;
							font-weight: 500;
							line-height: 110%; /* 57.2px */
							letter-spacing: -2px;
							max-width: 66%;
							@media (max-width: $tablet) {
								font-size: 32px;
								letter-spacing: -1px;
								max-width: 100%;
							}
						}
					}
					p {
						&.caption {
							color: #ecf1f7;
							font-size: 18px;
							font-style: normal;
							font-weight: 400;
							line-height: 25.2px; /* 140% */
							letter-spacing: -0.3px;
							max-width: 66%;
							@media (max-width: $tablet) {
								font-size: 16px;
								letter-spacing: 0px;
								max-width: 100%;
							}
						}
					}
					.image-container {
						max-width: 200px;
						max-height: 200px;
						height: 100%;
						width: 100%;
						overflow: hidden;
						img {
							width: 100%;
							height: 100%;
							object-position: center;
							object-fit: contain;
						}
					}
				}
				.details-form-section {
					width: 100%;
					margin: auto;
					margin-top: 40px;
					@media (max-width: 1440px) {
						max-width: 550px;
					}
					@media (max-width: $tablet) {
						margin-top: 24px;
					}
					h1 {
						&.heading-text {
							color: var(--Text-Dark, #111029);
							font-feature-settings:
								"liga" off,
								"clig" off;
							font-family: "Plus Jakarta Sans";
							font-size: 48px;
							font-style: normal;
							font-weight: 700;
							line-height: 83.444px; /* 173.841% */
							letter-spacing: -1.55px;
							text-align: center;
						}
					}
					form {
						&.details-form {
							border-radius: 16px;
							border: 1px solid
								var(--Color-Neutral-Borders-colorBorder, #d9d9d9);
							background: var(--Primary-0, #fff);
							padding: 32px;
							@media (max-width: $tablet) {
								padding: 16px;
							}
							.flex {
								@media (max-width: $tablet) {
									flex-direction: column;
									.input {
										.flex {
											flex-direction: row;
										}
									}
								}
							}
							.input {
								.input-container {
									label {
										span {
											&:not(.require-icon) {
												color: var(
													--Color-Neutral-Text-colorText,
													rgba(0, 0, 0, 0.88)
												);
												font-family: "SF Pro Text";
												font-size: 12px;
												font-style: normal;
												font-weight: 400;
												line-height: 22px; /* 183.333% */
											}
										}
										input {
											height: 32px;
											padding: 4px 8px;
											font-size: 14px;
											font-style: normal;
											font-weight: 400;
											line-height: 24px; /* 171.429% */
											letter-spacing: 0.14px;
											&::placeholder {
												color: rgba(0, 0, 0, 0.25);
											}
										}
									}
								}
							}
							.select-dropdown-box {
								label {
									span {
										font-size: 14px;
										line-height: 1.2;
										font-weight: 600;
										&:not(.require-icon) {
											color: var(
												--Color-Neutral-Text-colorText,
												rgba(0, 0, 0, 0.88)
											);
											font-family: "SF Pro Text";
											font-size: 12px;
											font-style: normal;
											font-weight: 400;
											line-height: 22px; /* 183.333% */
										}
										&.ant-select-selection-placeholder {
											color: rgba(0, 0, 0, 0.25);
										}
									}
								}
							}
						}
					}
				}
				// .testimonial-wrapper {
				//     width: 100%;
				//     overflow: auto;
				//     .testimonial-cards-container {
				//         width: 100%;
				//         overflow: auto auto;
				//         transform-origin: top right;
				//         transform: rotate(-90deg) translateY(-1280px);
				//         display: flex;
				//         flex-direction: column;
				//         justify-content: space-between;
				//     }
				// }
				.testimonial-card {
					padding: 40px;
					max-width: 668px;
					width: 100%;
					background-color: #fff;
					border-radius: 14px;
				}
				.slick-slide {
					margin: 0 24px;
					cursor: grab;
				}
			}
		}
	}
	section {
		&.hero-section {
			text-align: center;
			margin-top: 6vw;
			padding-bottom: 20px;

			@media (max-width: 1440px) {
				// margin-top: 10vw;
			}
			@media (max-width: 768px) {
				margin-top: 10vh;
			}
			h1 {
				&.hero-heading {
					color: var(--Text-Dark, #111029);
					font-feature-settings:
						"liga" off,
						"clig" off;
					font-family: "Plus Jakarta Sans";
					font-size: 76.291px;
					font-style: normal;
					font-weight: 700;
					line-height: 83.444px; /* 109.375% */
					letter-spacing: -1.55px;
					margin-bottom: 16px;

					@media (max-width: 992px) {
						line-height: 100px;
					}
					@media (max-width: 768px) {
						font-size: 60.291px;
						line-height: 72px;
					}
					span {
						&.text-bg {
							background-color: #fdbb0e;
							padding: 0px 23.841px 0px 11.921px;
							height: 84px;
							@media (max-width: 768px) {
								height: 66px;
							}
						}
					}
				}
			}
			p {
				&.hero-description {
					max-width: 640px;
					width: 100%;
					margin: auto;
					color: #757b8a;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 160%; /* 25.6px */
				}
			}
		}
		&.details-form-section {
			max-width: 50%;
			width: 100%;
			margin: auto;
			min-height: calc(100vh - 180px);
			margin-top: 40px;
			@media (max-width: 1440px) {
				max-width: 550px;
			}
			h1 {
				&.heading-text {
					color: var(--Text-Dark, #111029);
					font-feature-settings:
						"liga" off,
						"clig" off;
					font-family: "Plus Jakarta Sans";
					font-size: 48px;
					font-style: normal;
					font-weight: 700;
					line-height: 83.444px; /* 173.841% */
					letter-spacing: -1.55px;
					text-align: center;
				}
			}
			form {
				&.details-form {
					margin-top: 26px;
					border-radius: 16px;
					border: 1px solid var(--Color-Neutral-Borders-colorBorder, #d9d9d9);
					background: var(--Primary-0, #fff);
					padding: 32px;
					@media (max-width: $tablet) {
						padding: 16px;
					}
					.input {
						.input-container {
							label {
								span {
									&:not(.require-icon) {
										color: var(
											--Color-Neutral-Text-colorText,
											rgba(0, 0, 0, 0.88)
										);
										font-family: "SF Pro Text";
										font-size: 12px;
										font-style: normal;
										font-weight: 400;
										line-height: 22px; /* 183.333% */
									}
								}
								input {
									height: 32px;
									padding: 4px 8px;
									font-size: 14px;
									font-style: normal;
									font-weight: 400;
									line-height: 24px; /* 171.429% */
									letter-spacing: 0.14px;
									&::placeholder {
										color: rgba(0, 0, 0, 0.25);
									}
								}
							}
						}
					}
					.select-dropdown-box {
						label {
							span {
								font-size: 14px;
								line-height: 1.2;
								font-weight: 600;
								&:not(.require-icon) {
									color: var(
										--Color-Neutral-Text-colorText,
										rgba(0, 0, 0, 0.88)
									);
									font-family: "SF Pro Text";
									font-size: 12px;
									font-style: normal;
									font-weight: 400;
									line-height: 22px; /* 183.333% */
								}
								&.ant-select-selection-placeholder {
									color: rgba(0, 0, 0, 0.25);
								}
							}
						}
					}
				}
			}
		}
	}
	footer {
		margin: 0 -10px;
		padding: 8px 50px;
		z-index: 999;
		height: 60px;
		@media (max-width: $tablet) {
			padding: 8px 16px;
			height: auto;
		}
		&.footer {
			background-color: #fdbb0e;
			@media (max-width: $tablet) {
				flex-direction: column;
			}
			.container {
				max-width: 1920px;
				min-height: auto;
				@media (max-width: $tablet) {
					flex-direction: column;
					gap: 8px;
				}
			}
			.footer-left {
				align-items: center;
				gap: 50px;

				@media (max-width: $tablet) {
					flex-direction: column;
					gap: 0px;
				}

				button {
					background-color: transparent;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					// line-height: 41.57px; /* 296.931% */
					letter-spacing: 0.14px;
				}

				p {
					&.copyrights-text,
					&.call-text {
						font-family: "Plus Jakarta Sans";
						color: #000;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						letter-spacing: 0.14px;
						@media (max-width: $tablet) {
							line-height: 2;
						}
					}
				}
			}
		}
	}
	.privacy-policy-page {
		padding: 80px 20px 5px;
		p {
			line-height: 1.4;
		}
		hr {
			margin: 5px 0;
		}
		h3 {
			margin: 10px 0;
		}
	}
}
