@import "./noDataPage.scss";

.connection-page {
	width: 100%;
	height: 100%;
	// height: calc(100vh - 90px) !important;
	position: relative;

	.connection-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;

		.page-title {
			display: flex;
			align-items: flex-start;

			button {
				&.total-connection-count {
					display: flex;
					height: 33.991px;
					padding: 0px 8px;
					justify-content: center;
					align-items: center;
					border-radius: var(--Style-borderRadiusLG, 8px);
					border: 1.5px solid #fff;
					background: rgba(255, 255, 255, 0.6);
					backdrop-filter: blur(2px);
				}
			}
		}

		.invite-section {
			display: flex;
			position: relative;

			// align-items: flex-end;
			.clock-button {
				border-radius: 6px;
				border: var(--Components-Button-Global-lineWidth, 1px) solid
					var(--Components-Button-Component-defaultBorderColor, #d9d9d9);
				background: var(--Components-Button-Component-defaultBg, #fff);
				// box-shadow: 0px 2px 5px 0px rgba(255, 255, 255, 0.15) inset, 0px -8.759px 31.627px 0px rgba(255, 255, 255, 0.15) inset, 0px -5.255px 17.517px 0px rgba(255, 255, 255, 0.15) inset;
				// backdrop-filter: blur(6.130982398986816px);
				width: 36px;
				height: 36px;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 4px;
			}

			.list-of-connections {
				display: flex;
				position: relative;

				button {
					&.connection-icon {
						width: 32px;
						height: 32px;
						background-color: white;
						border-radius: 100%;
						// border: 1px solid #33333350;
						margin-left: -6px;
						display: flex;
						align-items: center;
						justify-content: center;
						overflow: hidden;

						svg {
							width: 50%;
							height: 50%;
						}

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							object-position: center;
						}

						&.icon-collepse {
							background-color: #fff7e6;
							color: #fa8c16;
							position: relative;
							overflow: visible;
						}
					}
				}

				.notification-indicator {
					width: 10px;
					height: 10px;
					border-radius: 100%;
					right: -6px;
					top: 1%;
					position: absolute;
					background-color: var(--light-colorError);
					border: 2px solid var(--light-colorTextLightSolid);
				}
			}

			.button {
				&.invite-button {
					button {
						height: 44px;
					}
				}
			}
		}
	}

	.connection-content {
		overflow: auto;
		gap: 10px;
		width: 100%;
		height: 100%;
		position: relative;
		margin-top: 10px;
		max-height: calc(100vh - 199px);

		.connection-list-sidebar-wrapper {
			border: 1px solid #e7e7e7;
			min-width: 260px;
			max-width: 260px;
			height: 100%;
			border-radius: 14px;

			.sidebar-body {
				overflow: auto;
				width: 100%;

				.search-bar {
					margin: 0 10px;
					max-width: calc(100% - 20px);
					margin-top: 16px;
				}

				.ant-tabs {
					.ant-tabs-nav {
						gap: 16px;
					}
					.ant-tabs-nav-list {
						.ant-tabs-tab {
							margin-left: 0 !important;
							padding: 20px 16px 10px 16px;
						}
					}
				}

				.connection-tabs-container {
					padding: 0 10px;
					height: calc(100vh - 331px);
					overflow: auto;
					position: relative;

					button {
						background-color: transparent;
					}

					.user-details-row {
						display: flex;
						align-items: flex-start;
						justify-content: space-between;
						padding: 10px 8px;
						border-radius: 8px;
						margin-bottom: 10px;

						&:last-of-type {
							margin-bottom: 0;
						}

						cursor: pointer;
						width: 100%;

						.user-details {
							h4 {
								&.title-4 {
									color: var(--light-colorText);
									font-size: 14px;
									font-style: normal;
									font-weight: 600;
									line-height: 22px;
									/* 157.143% */
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
									max-width: 111px;
									width: 100%;
								}
							}
							p {
								&.caption {
									color: var(
										--Color-Neutral-Text-colorTextLabel,
										rgba(0, 0, 0, 0.65)
									);
									font-size: 12px;
									font-style: normal;
									font-weight: 400;
									line-height: 20px; /* 166.667% */
								}
							}

							&.customer,
							&.vendor {
								h4 {
									&.title-4 {
										max-width: 180px;
									}
								}
							}

							p {
								color: var(--light-primary);
							}
						}

						&:hover {
							background-color: rgba(0, 0, 0, 0.04);

							.user-details {
								h4 {
									&.title-4 {
										color: var(--light-primary);
									}
								}

								p {
									color: var(--light-primary);
								}
							}
						}

						&.active {
							.user-details {
								h4 {
									&.title-4 {
										color: var(--light-primary);
									}
								}
							}

							background-color: rgba(0, 0, 0, 0.04);
						}

						.user-data {
							display: flex;
							align-items: flex-start;
							gap: 8px;

							.user-profile-icon {
								min-width: 44px;
								width: 44px;
								height: 44px;
								border-radius: 100%;
								overflow: hidden;
								display: flex;
								align-items: center;
								justify-content: center;
								background-color: #0000000a;
								outline: 2px solid #f2f2f7;

								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
									object-position: center;
								}
							}

							.user-details {
								font-size: 14px;
								font-style: normal;
								font-weight: 600;
								line-height: 22px;
								/* 157.143% */
								text-align: left;
							}
						}

						.user-role {
							font-size: 12px;
							font-style: normal;
							font-weight: 500;
							line-height: 22px;
							border-radius: 4px;

							/* Button effect */
							// box-shadow: 0px 2px 5px 0px rgba(255, 255, 255, 0.15) inset, 0px -8.759px 31.627px 0px rgba(255, 255, 255, 0.15) inset, 0px -5.255px 17.517px 0px rgba(255, 255, 255, 0.15) inset;
							// backdrop-filter: blur(6.130982398986816px);
							&.vendor {
								padding: 6px;
								border-radius: 12px;
								background: rgba(161, 64, 255, 0.05);
								color: #a140ff;
								font-size: 10px;
								font-weight: 500;
								line-height: 7px;
							}

							&.customer {
								padding: 6px;
								color: #0169e6;
								font-size: 10px;
								font-weight: 500;
								line-height: 7px;
								border-radius: 12px;
								background: rgba(1, 105, 230, 0.05);
							}

							&.block {
								color: var(--Color-Error-colorErrorText, #f5222d);
								font-size: 10px;
								font-weight: 500;
								line-height: 7px;
								padding: 6px;
								border-radius: 12px;
							}

							&.both {
								color: #009656;
								font-size: 10px;
								font-weight: 500;
								line-height: 7px;
								padding: 6px;
								border-radius: 12px;
								background: rgba(0, 150, 86, 0.05);
							}
						}
					}

					// p {
					//     &.caption {
					//         color: var(--Color-Neutral-Text-colorTextLabel, rgba(0, 0, 0, 0.65));
					//         /* SM/Normal */
					//         font-family: "SF Pro Text";
					//         font-size: 12px;
					//         font-style: normal;
					//         font-weight: 400;
					//         line-height: 20px; /* 166.667% */
					//     }
					// }
				}
			}

			.sidebar-heading {
				width: 100%;
				padding-bottom: 8px;
				border-bottom: 1px solid #d9d9d9;
				margin-bottom: 24px;
				padding: 10px 10px;

				h2 {
					&.title-2 {
						font-size: 16px;
						font-style: normal;
						font-weight: 600;
						line-height: 24px;
						/* 150% */
						letter-spacing: 0.16px;
					}
				}

				.sorting-selection {
					display: flex;
					height: 24px;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					flex-direction: row-reverse;
					gap: 8px;
					border-radius: 4px;
					border: var(--Components-Button-Global-lineWidth, 1px) solid
						var(--Components-Button-Component-defaultBorderColor, #d9d9d9);
					background: var(--Components-Button-Component-defaultBg, #fff);

					.ant-select-selector {
						padding: 0 7px 0 24px;
						border: none;

						.ant-select-selection-item {
							padding: 0;
						}
					}

					.ant-select-arrow {
						left: 6px;
					}
				}
			}
		}

		.connection-details {
			max-width: calc(100% - 286px);
			width: 100%;
			overflow: auto;
			height: 100% !important;

			&.w-100 {
				max-width: 100%;
			}

			.card {
				position: relative;
				width: 100%;
				height: fit-content;

				.details-card {
					position: relative;
					display: flex;
					align-items: flex-start;
					background-color: #fff;
					border-radius: 8px;
					padding: 24px 16px;
					width: 100%;

					.profile-image-container {
						min-width: 196px;
						min-height: 196px;
						width: 196px;
						height: 196px;
						padding: 8px;

						.profile-image {
							border-radius: 100%;
							overflow: hidden;
							width: 100%;
							height: 100%;
							background: rgba(0, 0, 0, 0.04);
							outline: 2px solid #f2f2f7;

							img {
								width: 100%;
								height: 100%;
								object-fit: contain;
								object-position: center;
							}

							svg {
								width: 70%;
								height: 70%;
							}
						}
					}

					.profile-description {
						width: 100%;

						.product-summary-section {
							display: flex;
							justify-content: space-between;
							width: 100%;

							p {
								&.caption {
									margin-top: 4px;
								}
							}

							button {
								&.notification-button {
									width: 40px;
									height: 40px;
									display: flex;
									align-items: center;
									justify-content: center;
									font-size: 16px;
									border-radius: 8px;
								}
							}
						}
					}

					.address-connection-section {
						display: flex;
						justify-content: space-between;
						margin: 17px 0;

						.address-text {
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px;
							/* 166.667% */
							color: #00000045;
						}

						.connections-count {
							background-color: transparent;
							font-size: 14px;
							font-style: normal;
							font-weight: 600;
							line-height: 22px;
							/* 157.143% */
							color: #00000045;
						}
					}

					.orders-message-container {
						display: flex;
						justify-content: space-between;

						.orders-details {
							display: flex;
							gap: 24px;

							p {
								&.caption {
									font-size: 14px;
									font-style: normal;
									font-weight: 400;
									line-height: 22px;
									/* 157.143% */
									white-space: nowrap;
								}
							}
						}

						.messages-container {
							display: flex;
							align-items: flex-start;
							justify-content: flex-end;
							width: 100%;

							.button {
								width: fit-content !important;
								height: 36px;
								border-radius: 8px;
								&.placeorder-button {
									padding-right: 0;
								}

								button {
									height: 100%;
									border-radius: 8px;
								}
							}

							.message-button {
								border-radius: 8px;
								border: var(--Components-Button-Global-lineWidth, 1px)
									solid
									var(
										--Components-Button-Component-defaultBorderColor,
										#d9d9d9
									);
								background: var(
									--Components-Button-Component-defaultBg,
									#fff
								);
								// box-shadow: 0px 2px 5px 0px rgba(255, 255, 255, 0.15) inset, 0px -8.759px 31.627px 0px rgba(255, 255, 255, 0.15) inset, 0px -5.255px 17.517px 0px rgba(255, 255, 255, 0.15) inset;
								// backdrop-filter: blur(6.130982398986816px);
								padding: 7px 15px;
								height: 36px;
								display: flex;
								align-items: center;
								justify-content: center;
								gap: 4px;
							}

							.square-button {
								height: 36px;
								min-width: 36px;
								max-width: 36px;
								border-radius: 8px;
								border: 1px solid #d9d9d9;
								background: #fff;
								// box-shadow: 0px 2px 5px 0px rgba(255, 255, 255, 0.15) inset, 0px -8.759px 31.627px 0px rgba(255, 255, 255, 0.15) inset, 0px -5.255px 17.517px 0px rgba(255, 255, 255, 0.15) inset;
								// backdrop-filter: blur(6.130982398986816px);
							}
						}
					}
				}

				&.orders-content {
					margin-top: 10px;
					height: calc(100vh - 224px);
					overflow: auto;

					.pagination-wrapper {
						margin-top: 16px;

						.ant-pagination {
							.ant-pagination-item-active {
								border-color: #000000;
								background-color: #000000;

								a {
									color: #ffffff;
								}
							}

							.anticon-double-right,
							.anticon-double-left {
								&.ant-pagination-item-link-icon {
									color: #000000;
								}
							}

							.ant-pagination-item {
								height: 32px;
								width: 32px;
								display: flex;
								align-items: center;
								justify-content: center;
							}

							button {
								&.pagination-button {
									background-color: #ffffff;
									height: 32px;
									padding: 8px 20px;
									color: #000000;
									font-weight: 600;
									border: 1px solid #d9d9d9;
									border-radius: 6px;
								}
								&:disabled {
									opacity: 0.6;
									cursor: default;
								}
							}
						}
					}

					.ant-tabs-nav-wrap {
						border-bottom: 1px solid var(--light-colorBorder);
					}

					.ant-tabs-tab {
						padding: 0 4px 12px 4px;
					}

					.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
						margin-left: 16px;
					}

					.ant-tabs-nav {
						gap: 16px;
						// width: fit-content;
						&::before {
							border: none;
						}
					}

					.ant-tabs {
						width: 100%;
						height: 100%;
					}

					.tabs-right-order-actions {
						.search-bar {
							min-width: 339px;
							height: 36px;
						}
					}

					.date-picker {
						.ant-picker {
							width: 260px;
							height: 36px;
						}
					}

					.orders-table {
						.ant-table-body {
							overflow: auto !important;
							table {
								border: 1px solid var(--light-colorBorderSecondary) !important;
								border-radius: 0px;
								border-top: 0px !important;
							}
						}

						th {
							color: var(--light-colorTextTertiary);
							//   background-color: #fff;
							padding: 12px 8px;
							font-size: 12px;
							font-style: normal;
							font-weight: 600;
							line-height: 20px;
						}

						td {
							&:first-child {
								padding: 16px 8px;
							}

							padding: 16px 8px;
						}

						.customer-details-box {
							.img-box {
								width: 44px;
								height: 44px;
								border-radius: 100%;
								overflow: hidden;
								display: flex;
								align-items: center;
								justify-content: center;

								.profile-img {
									width: 100%;
									height: 100%;
									object-fit: cover;
									object-position: center;
								}
							}

							.customer-info {
								gap: 0px;
								color: var(--light-colorText);
								font-size: 12px;
								font-style: normal;
								font-weight: 600;
								line-height: 20px;
								cursor: pointer;
								width: 100%;

								.user-name {
									color: var(--light-colorSecondary);
									font-weight: 400;
								}
							}
						}

						.ordered-items-btn {
							border-radius: 4px;
							border: 1px solid var(--light-colorBorder);
							background: #fff;
							padding: 2px 4px 2px 6px;
							width: fit-content;
						}
					}
				}
			}
		}

		.no-data-page {
			height: 100%;
		}
	}
}

.block-or-remove-container {
	width: 181px;

	.ant-popover-inner {
		padding: 6px;
	}

	.block,
	.remove {
		padding: 9px 10px;
		border-radius: 6px;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 22px;
		display: flex;
		align-items: center;
		gap: 12px;
		cursor: pointer;
		width: 100%;
		background: none;

		&:hover {
			background: rgba(0, 0, 0, 0.02);
		}

		&:disabled {
			cursor: default;
		}
	}

	.remove {
		color: var(--light-colorError);

		svg {
			path {
				stroke: var(--light-colorError);
			}
		}
	}
}

.request-notification-popover {
	max-width: 396px;
	width: 100%;
	min-height: fit-content;
	// max-height: 50vh;
	height: auto;
	border-radius: 12px;
	border: 1.5px solid #fff;
	background: #fff;
	// overflow: auto;
	border: 1px solid var(--light-colorBorder);
	padding: 12px;

	.ant-popover-arrow {
		&::after {
			border-right: 1px solid var(--light-colorBorder);
			border-bottom: 1px solid var(--light-colorBorder);
			box-shadow: none;
			background: #fff;
		}
	}

	.ant-popover-inner {
		box-shadow: none;
		border-radius: none;
		background-color: transparent;
	}

	.ant-popover-content {
		max-height: calc(50vh - 24px);
		overflow: auto;
		border-radius: 12px;
		height: 100%;

		.ant-popover-inner {
			height: 100%;
			padding: 0;

			.no-data-found-wrapper {
				min-height: 40vh;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		.notification-time {
			color: var(--light-colorTextTertiary);
			padding: 10px 10px 4px 10px;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
		}

		.notification-details-row {
			align-items: center;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px 8px;
			border-radius: 8px;
			width: 100%;

			&:hover {
				background-color: rgba(0, 0, 0, 0.04);
			}

			&:active {
				background-color: rgba(0, 0, 0, 0.04);
			}

			.user-data {
				display: flex;
				align-items: flex-start;
				gap: 8px;

				.user-profile-icon {
					min-width: 44px;
					width: 44px;
					height: 44px;
					border-radius: 100%;
					overflow: hidden;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #0000000a;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.user-details {
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 22px;
					/* 157.143% */
				}
			}

			.status {
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				/* 166.667% */
				border-radius: 4px;
				padding: 2px 8.5px;

				&.pending {
					color: var(
						--Components-Button-Global-colorTextDisabled,
						rgba(0, 0, 0, 0.25)
					);
					border: var(--Components-Button-Global-lineWidth, 1px) solid
						var(--Components-Button-Component-borderColorDisabled, #d9d9d9);
					background: rgba(0, 0, 0, 0.04);
					transition: all 0.1s;
					width: 87px;

					.hover {
						display: none;
					}

					&:hover {
						border-radius: 4px;
						border: var(--Components-Button-Global-lineWidth, 1px) solid
							var(--Color-Error-colorError, #f5222d);
						background: #fff;
						color: #f5222d;

						&:where(:not(.hover)) .flex {
							display: none;
						}

						.hover {
							display: flex;
							justify-content: center;
						}

						&:disabled {
							color: #f5222d96;
							border: 1px solid #f5222d96;
							background: var(
								--Components-Button-Component-defaultBg,
								#fff
							);
							cursor: default;
						}
					}
				}
			}

			.buttons-container {
				button {
					&.request-button {
						font-size: 12px;
						font-style: normal;
						font-weight: 500;
						line-height: 22px;
						border-radius: 4px;
						border-radius: 4px;
						border: var(--Components-Button-Global-lineWidth, 1px) solid
							var(--Components-Button-Component-defaultBorderColor, #d9d9d9);
						background: var(--Components-Button-Component-defaultBg, #fff);
						padding: 1px 7px;

						&:disabled {
							cursor: default;
						}
					}

					&.active {
						background: var(--Components-Button-Global-colorPrimary, #000000);
						color: #ffffff;
						border-color: var(--light-primary);

						&:disabled {
							background-color: #000000b5;
							cursor: default;
						}
					}
				}
			}
		}
	}
}

.new-request-tooltip {
	z-index: 999 !important;
}

.invite-modal {
	max-width: 530px !important;
	width: 100% !important;
	border-radius: 12px;
	border: 1.5px solid #fff;
	background: rgba(255, 255, 255, 0.4);
	backdrop-filter: blur(2px);
	padding: 0;
	z-index: 999999;

	.modal-header {
		.modal-text-container {
			h3 {
				&.title-3 {
					font-size: 20px;
					font-style: normal;
					font-weight: 600;
					line-height: 30px;
					/* 150% */
				}
			}

			p {
				&.caption {
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px;
					/* 142.857% */
				}
			}
		}
	}

	.invite-form-body {
		width: 100%;
		margin-top: 20px;

		.custom-input-container {
			position: relative;
			display: flex;
			align-items: center;
			border: 1px solid var(--light-colorBorder);
			border-radius: 8px;
			padding: 4px 8px;
			transition:
				border-color 0.3s,
				box-shadow 0.3s;
			height: 32px;
			background-color: rgba(0, 0, 0, 0.02);
			gap: 8px;

			svg {
				width: 16px;
				height: 16px;
			}
		}

		.ant-input-outlined {
			&:focus,
			&:focus-within {
				border: none;
				outline: none !important;
			}
		}

		.custom-input-container:hover {
			border: 1px solid var(--light-primary);
		}

		.custom-input-container:focus-within {
			border-color: var(--light-primary);
			box-shadow: none;
		}

		.whatsapp-icon {
			width: 20px;
			height: 20px;
			margin-right: 8px;
		}

		.custom-input {
			flex: 1;
			border: none;
			outline: none;
			box-shadow: none;
			padding: 0;
		}

		.custom-input {
			background-color: transparent;

			.ant-input {
				border: none;
				box-shadow: none !important;
				text-align: start;

				&::placeholder {
					color: #00000040;
				}
			}

			&.error {
				.ant-input {
					border: 1px solid red;
				}
			}
		}

		.custom-input .ant-input:focus {
			border: none;
			box-shadow: none !important;
		}

		input {
			&.wp-number {
				// height: 32px;
				// padding: 8px;
				// width: 100%;
				// border-radius: var(--Style-borderRadiusLG, 8px);
				// border: 1px solid var(--Color-Neutral-Borders-colorBorder, #D9D9D9);
				// background: var(--Color-Neutral-Fill-colorFillQuaternary, rgba(0, 0, 0, 0.02));
				// border-radius: 8px;
				// outline: none;
				&:focus {
					// border: 3px solid rgba(1, 105, 230, 0.10);
				}
			}
		}

		.invitation-buttons-container {
			display: flex;
			justify-content: space-between;
			margin-top: 18px;

			button {
				&.invitation-link-button {
					background-color: transparent;
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 22px;
					/* 157.143% */
					color: rgba(0, 0, 0, 0.88);
				}

				&.copy-link {
					border-radius: 8px;
					border: var(--Components-Button-Global-lineWidth, 1px) solid
						var(--Components-Button-Component-defaultBorderColor, #d9d9d9);
					background: var(--Components-Button-Component-defaultBg, #fff);

					/* Button effect */
					// box-shadow: 0px 2px 5px 0px rgba(255, 255, 255, 0.15) inset, 0px -8.759px 31.627px 0px rgba(255, 255, 255, 0.15) inset, 0px -5.255px 17.517px 0px rgba(255, 255, 255, 0.15) inset;
					// backdrop-filter: blur(6.130982398986816px);
					padding: 2px 15px;
					height: 36px;
					color: rgba(0, 0, 0, 0.88);
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 22px;
					/* 157.143% */
				}
			}
		}

		.accordian-container {
			margin-top: 20px;

			button {
				&.accordian-header {
					background-color: transparent;
					width: 100%;

					h4 {
						&.title-4 {
							color: var(
								--Color-Neutral-Text-colorText,
								rgba(0, 0, 0, 0.88)
							);
							/* Base/Strong */
							font-family: "SF Pro Text";
							font-size: 14px;
							font-style: normal;
							font-weight: 600;
							line-height: 22px;
							/* 157.143% */
						}
					}

					button {
						&.collapse-button {
							color: #00000073;
							font-size: 12px;
							font-style: normal;
							font-weight: 500;
							line-height: 22px;
							/* 183.333% */
							gap: 4px;
							transition: all 0.2s ease;
							background-color: transparent;

							&.active {
								svg {
									transform: rotate(180deg);
								}
							}
						}
					}
				}
			}

			.no-data-found {
				height: 80px;
				background-color: rgba(0, 0, 0, 0.04);
				margin-top: 16px;
				border-radius: 12px;
			}
		}

		.search-result-body {
			margin-top: 23px;

			p {
				&.heading {
					color: var(
						--Color-Neutral-Text-colorTextDescription,
						rgba(0, 0, 0, 0.45)
					);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px;
					/* 157.143% */
				}
			}
		}

		.numbers-list-wrapper {
			overflow: auto;
			max-height: 300px;

			.numbers-list {
				margin-top: 15px;

				.filterd-number {
					justify-content: space-between;
					align-items: center;
					background-color: #fff;
					padding: 8px;

					.mobile-number {
						align-items: center;

						.image-container {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 44px;
							height: 44px;
							border-radius: 100%;
							background: var(
								--Color-Neutral-Fill-colorFillTertiary,
								rgba(0, 0, 0, 0.04)
							);
							overflow: hidden;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
								object-position: center;
							}
						}

						p {
							&.caption {
								color: var(
									--Color-Neutral-Text-colorText,
									rgba(0, 0, 0, 0.88)
								);
								font-size: 12px;
								font-style: normal;
								font-weight: 600;
								line-height: 20px;
								/* 166.667% */
							}
						}
					}

					.buttons-container {
						button {
							&.request-button {
								font-size: 12px;
								font-style: normal;
								font-weight: 500;
								line-height: 22px;
								border-radius: 4px;
								border-radius: 4px;
								border: var(--Components-Button-Global-lineWidth, 1px)
									solid
									var(
										--Components-Button-Component-defaultBorderColor,
										#d9d9d9
									);
								background: var(
									--Components-Button-Component-defaultBg,
									#fff
								);
								padding: 1px 7px;
							}

							&.active {
								background: var(
									--Components-Button-Global-colorPrimary,
									#000000
								);
								color: #ffffff;
								border-color: #000000;
							}
						}
					}

					button {
						padding: 1px 7px;
						font-size: 12px;
						font-style: normal;
						font-weight: 500;
						line-height: 22px;

						/* 183.333% */
						&.invite-button {
							border-radius: 4px;
							border: var(--Components-Button-Global-lineWidth, 1px) solid
								var(
									--Components-Button-Component-defaultBorderColor,
									#d9d9d9
								);
							background: var(
								--Components-Button-Component-defaultBg,
								#fff
							);
							// box-shadow: 0px 2px 5px 0px rgba(255, 255, 255, 0.15) inset, 0px -8.759px 31.627px 0px rgba(255, 255, 255, 0.15) inset, 0px -5.255px 17.517px 0px rgba(255, 255, 255, 0.15) inset;
							// backdrop-filter: blur(6.130982398986816px);
						}

						&.connect-button {
							color: #000000;
							border-radius: 4px;
							border: 1px solid var(--light-primary);
							background: var(
								--Components-Button-Component-defaultBg,
								#fff
							);

							&:disabled {
								color: #0000009b;
								border: 1px solid #0000009b;
								background: var(
									--Components-Button-Component-defaultBg,
									#ffffffe1
								);
								cursor: default;
							}
						}
					}

					&:hover {
						background-color: rgba(0, 0, 0, 0.04);

						button {
							&.invite-button {
								transition: all 0.1s ease;

								&:hover {
									background-color: var(--light-primary);
									color: #ffffff;
									border-color: var(--light-primary);
								}
							}

							&.connect-button {
								transition: all 0.1s ease;

								&:hover {
									background-color: #000000;
									color: #ffffff;
									border-color: #000000;

									&:disabled {
										color: #0000009b;
										border: 1px solid #0000009b;
										background: var(
											--Components-Button-Component-defaultBg,
											#ffffffe1
										);
										cursor: default;
									}
								}
							}
						}
					}

					.status {
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px;
						/* 166.667% */
						border-radius: 4px;
						padding: 2px 8.5px;

						&.pending {
							color: var(
								--Components-Button-Global-colorTextDisabled,
								rgba(0, 0, 0, 0.25)
							);
							border: var(--Components-Button-Global-lineWidth, 1px) solid
								var(
									--Components-Button-Component-borderColorDisabled,
									#d9d9d9
								);
							background: var(
								--Components-Button-Global-colorBgContainerDisabled,
								rgba(0, 0, 0, 0.04)
							);
							transition: all 0.1s;
							width: 87px;

							.hover {
								display: none;
							}

							&:hover {
								border-radius: 4px;
								border: var(--Components-Button-Global-lineWidth, 1px)
									solid var(--Color-Error-colorError, #f5222d);
								background: var(
									--Components-Button-Component-defaultBg,
									#fff
								);
								color: #f5222d;

								&:where(:not(.hover)) .flex {
									display: none;
								}

								.hover {
									display: flex;
									justify-content: center;
								}

								&:disabled {
									color: #f5222d96;
									border: 1px solid #f5222d96;
									background: var(
										--Components-Button-Component-defaultBg,
										#fff
									);
									cursor: default;
								}
							}
						}
					}
				}
			}
		}
	}
}

.invite-modal-wrapper {
	position: relative;
	z-index: 9999;

	.ant-modal-wrap,
	.ant-modal-mask {
		z-index: 1002;
	}

	.ant-modal .ant-modal-content {
		padding: 20px;
	}

	.invitation-message-wrapper {
		position: absolute;
		top: -70px;
		background-color: #17b26a;
		height: 48px;
		color: #fff;
		display: flex;
		align-items: center;
		width: calc(100% + 20px);
		left: -10px;
		padding: 6px 8px;
		justify-content: center;
		gap: 9px;
		border-radius: 12px;

		&.error {
			background-color: #f5222d;
		}
	}
}
