.textarea-input {
    display: flex;
    align-self: stretch;
    width: 100%;
    .textarea-container {
        width: 100%;
        label {
            display: flex;
            flex-direction: column;
            gap: 4px;
            width: 100%;
            span {
                color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 183.333% */
                &.require-icon {
                    color: var(--light-colorError);
                }
            }
            textarea {
                width: 100%;
                height: 100%;
                resize: none;
                padding: 5px var(--Size-Padding-paddingSM, 12px);
                justify-content: center;
                align-items: center;
                gap: var(--Size-Padding-paddingSM, 12px);
                border-radius: var(--Border-Radius-borderRadius, 8px);
                border: 1px solid #D9D9D9;
                outline: none;
                background: #FFFFFF;
                color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
                font-family: "SF Pro Text";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
                letter-spacing: 0.14px;
                transition: all 0.1s ease-in-out;
    
                &:focus {
                    border: 1px solid var(--light-primary);
                    outline: none;
                }
            }
        }
        .char-count {
            text-align: end;
            font-weight: 600;
        }
    }
    &.error {
        textarea {
            border-color: var(--light-colorError) !important;
        }
    }
}