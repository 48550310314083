.region-sales {
  &__header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fbfbfd;
    border-bottom: 1px solid #f2f2f7;

    span {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }

    .dashboard-dropdown {
      .options-container {
        right: 0;
      }
    }
  }
  &__cotainer {
    height: 475px;
    border-radius: 24px;
    border: 1px solid #f2f2f7;
    background-color: #fff;
    overflow: hidden;

    .india-map {
      min-width: 350px;
      max-width: 350px;
      width: 100%;
      svg {
        width: 100%;
        height: 100%;

        a {
          cursor: pointer;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          &.hasCount {
            position: relative;
            
            circle {
              fill: #FFBC0D;
              position: absolute;
              top: 0;
            }
          }
          path {
            fill: url(#myGradient);
            stroke: #BEBEBE;
          }

          &:hover, &.active {
            path {
              fill: #FFBC0D;
            }
          }
        }
      }
    }
  }

  &__data {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    height: calc(100% - 69px);

    .bar-chart {
      min-width: 330px;
      width: 100%;
      position: relative;

      .spin-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.map-tooltip {
  display: flex;
  flex-direction: column;
  .state-name {
    border-bottom: 1px solid #fff;
  }
}