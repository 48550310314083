.status-tabs {
	display: flex;

	label {
		height: 40px;
		display: flex;
		align-items: center;
		padding: 4px 16px;
		// border-radius: 8px;
		border: 1px solid var(--light-colorBorderSecondary);
		border-right: 1px solid var(--light-colorBorderSecondary);
		border-left: none;
		white-space: nowrap;

		&:first-child {
			border-left: 1px solid var(--light-colorBorderSecondary);
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
		}
		&:last-child {
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
		}

		.status {
			color: #344054;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 22px;
		}

		cursor: pointer;
		font-size: 14px;
		background-color: white;

		input {
			display: none;
		}

		span.dot {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			margin-right: 6px;
		}

		.green {
			background-color: #52c41a; // active status color
		}

		.blue {
			background-color: #d9d9d9; // inactive status color
		}

		.orange {
			background-color: #fa8c16; // draft status color
		}

		.red {
			background-color: #f5222d;
		}

		&.active {
			background-color: #e6e7e8;
		}
	}
}
