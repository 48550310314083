.error-page, .error404-page {
    height: 100vh;
    img {
        max-width: 250px;
        width: 100%;
        object-fit: contain;
        object-position: center;
    }
    h2 {
        &.title-2 {
            font-size: 48px;
            line-height: 1.5;
        }
    }
    button {
        &.home-button {
            padding: 12px 14px;
            border-radius: 14px;
            font-size: 14px;
            line-height: 1.33;
            letter-spacing: 0;
            color: #FFFFFF;
            background-color: var(--light-primary);
            margin-top: 16px;
        }
    }
}