.get-premium-wrapper {
    padding: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 14px;
    margin-top: 50px;
    .content {
        .form-header {
            .heading-2 {
                color: #000;
                font-family: "Plus Jakarta Sans";
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            p {
                &.caption {
                    margin-top: 26px;
                    color: #000;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }
    .plans-container {
        .plan {
            border-radius: 11.692px;
            border: 0.731px solid #D7DEEA;
            padding: 14.615px 23.385px 14.615px 17.538px;
            background: #FFF;
            .left-content {
                img {
                    width: 49.551px;
                    height: 49.999px;
                }
                .plan-text {
                    .heading-3 {
                        color: #000;
                        font-size: 14.615px;
                        font-style: normal;
                        font-weight: 510;
                        line-height: 150%; /* 21.923px */
                    }
                    .caption {
                        color: #707F8F;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 510;
                        line-height: normal;
                    }
                }
            }
            .right-content {
                .button {
                    background-color: #FFF;
                    border-radius: 10px;
                    border: 1px solid #D7DEEA;
                    background: #FFF;
                    transition: all 0.2s ease;
                    overflow: hidden;
                    &:hover {
                        border-color: #000;
                    }
                    button {
                        padding: 10px 40px;
                        color: #000;
                        font-size: 10.231px;
                        font-style: normal;
                        font-weight: 510;
                        line-height: 14.615px; /* 142.857% */
                        background-color: inherit;
                    }
                }
            }
            &.premium {
                border-radius: 11.692px;
                border: 0.731px solid #FFBC0D;
                background: linear-gradient(0deg, #FFF8E7 0%, #FFF8E7 100%), #FFF;
            }
        }
    }
    .plans-content {
        .heading-3 {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 8px;
        }
        ul {
            &.list-items {
                li {
                    &.list {
                        color: #000;
                        font-size: 14px;
                        font-style: normal;
                        line-height: 20px;
                        font-weight: 400;
                        line-height: normal;
                        margin-left: 24px;
                        position: relative;
                        &::before {
                            content: "";
                            display: inline-block;
                            width: 4px;
                            height: 4px;
                            background-color: #000;
                            border-radius: 100%;
                            position: absolute;
                            top: 8px;
                            left: -18px;
                        }
                        &::after {
                            content: "";
                            display: inline-block;
                            width: 12px;
                            height: 12px;
                            background-color: rgba($color: #000000, $alpha: 0.1);
                            border-radius: 100%;
                            position: absolute;
                            top: 4px;
                            left: -22px;
                        }
                    }
                }
            }
        }
    }
    .img-container {
        // img {
        //     width: 60px;
        //     height: 60px;
        // }
    }
    .logout-button {
        margin-top: 25px;
        .error-button {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;
            button {
                border-radius: 14px;
                background: rgba(237, 26, 11, 0.05);
                width: 100%;
                height: 40px;
                padding: 10px;
                color: #ED1A0B;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; /* 171.429% */
            }
        }
    }
    .buttons-container {
        margin-top: 16px;
        .primary {
            button {
                padding: 4px 20px;
                height: 38px;
            }
        }
    }
}