.notification-drawer {
	.my-drawer-content {
		.my-drawer-header {
			padding: 12px 0 0 !important ;
			border-bottom: none;
			.ant-drawer-header-title {
				flex-direction: row-reverse;

				.ant-drawer-title {
					color: var(--light-colorDarkText);
					font-size: 18px;
					font-style: normal;
					font-weight: 500;
					line-height: 28px;

					.notification-header-container {
						padding: 0 10px;
						width: 100%;

						.title {
							color: var(--light-colorDarkText);
							font-size: 20px;
							font-style: normal;
							font-weight: 600;
							line-height: 30px;
						}

						.count {
							min-width: 17px;
							min-height: 22px;
							height: 22px;
							padding: 6px;
							width: fit-content;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 17px;
							border: 0.848px solid transparent;
							background: var(--Colors-Brand-Primary-colorPrimary, #000000);
							color: var(--Colors-Neutral-colorWhite, #fff);
							text-align: center;
							font-size: 14px;
							font-style: normal;
							font-weight: 700;
							line-height: 17.965px;
						}

						.actions-container {
							display: flex;
							gap: 10px;
							align-items: center;

							.mark-all-read {
								background: transparent;
								font-size: 12px;
								font-style: normal;
								font-weight: 600;
								line-height: 20px;
								color: var(--light-primary);
							}

							.setting-btn,
							.close-btn {
								background-color: transparent;
								border-radius: 4px;
								svg {
									width: 24px;
									height: 24px;
								}
								&:hover {
									background-color: rgba(0, 0, 0, 0.05);
								}
							}
						}
					}
				}
			}
		}
		// padding: 8px;
		border-radius: 16px;
		.my-drawer-body {
			padding: 0 0 10px;

			.fields-container {
				padding: 24px 12px 12px 12px;

				.section-title {
					color: var(--light-colorText);
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 24px; /* 150% */
					letter-spacing: 0.16px;
				}

				.fields-box {
					.field {
						padding: 12px;
						height: 48px;

						&:hover {
							border-radius: 8px;
							background-color: rgba(0, 0, 0, 0.04);
							.delete-box {
								display: block;

								&:hover path {
									stroke: #f5222d;
								}
							}
						}

						.field-name {
							overflow: hidden;
							color: var(--light-colorText);
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px;
							width: 100%;
						}

						.create-field {
							color: var(--light-primary);
							font-size: 14px;
							font-style: normal;
							font-weight: 600;
							line-height: 22px;
							background: none;
							&:disabled {
								color: var(--light-darkColor25);
							}
						}

						.switch-style {
							margin-left: 8px;
						}
					}
				}

				.error-message {
					font-size: 12px;
					color: var(--light-colorError);
				}
			}
		}
	}

	.tabs-container {
		height: 100%;
		.ant-tabs-content {
			height: 100%;

			.notification-container {
				&::-webkit-scrollbar {
					width: 8px;
				}

				&::-webkit-scrollbar-track {
					background: #f1f1f1;
					border-radius: 10px;
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 10px;
					background: rgba(0, 0, 0, 0.09);
				}
			}
		}
		.ant-tabs-nav {
			margin: 0;
		}

		.ant-tabs-tab {
			margin: 0;
			padding: 8px;
		}

		.notification-time {
			color: var(--light-colorTextTertiary);
			padding: 10px 10px 4px 10px;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
		}

		.ant-tabs-tabpane {
			max-height: 93vh;
			overflow: auto;
			height: fit-content;
			height: 100%;
		}
		.ant-tabs-nav-wrap {
			padding: 0 10px;
		}
		.tab-label {
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px; /* 150% */
			.count {
				width: 24px;
				height: 24px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: var(--light-colorSecondary);
				border-radius: 100%;
				background: #fff;
				border: 1.032px solid rgba(0, 0, 0, 0.06);
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: 18.581px;
			}
		}
	}
}
