.custom-date-selector {
	justify-content: space-evenly;
	overflow: auto;
	.date-item {
		width: fit-content;

		.date-button {
			color: rgba(0, 0, 0, 0.65);
			font-family: "SF Pro Text";
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 1;
			border-radius: 4px;
			border: 1px solid transparent;
			padding: 4px 8px;
			background-color: #fff;

			&:hover {
				background-color: #f1f4f9;
			}
		}
		&.active {
			.date-button {
				border: 1px solid #000;
				background: #000;
				color: #fff;
			}
		}
	}
}
