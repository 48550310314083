.success-box_wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    left: 0;
    top: 0;
    z-index: 1000;
    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #00000060;
        z-index: 1000;
    }
    .success-box {
        position: absolute;
        max-width: 428px;
        width: 100%;
        top: 20%;
        left: calc(50% - 214px);
        background-color: #FFFFFF;
        z-index: 100000;
        &__inner {
            padding: 48px;
            img {
                width: 64px;
                height: 64px;
            }
            h3 {
                &.title-3 {
                    color: #000;
                    text-align: center;
                    font-family: "Plus Jakarta Sans";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 38px; /* 158.333% */
                }
            }
        }
    }
}