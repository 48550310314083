.special-price-list-table {
  display: flex;
  flex-direction: column;
  gap: 10px;
  top: 0;
  padding-top: 0;
  position: relative;
  .customer-details-container {
    // position: sticky;
    // top: 0;
    z-index: 999;
    padding: 8px;
    border-radius: 14px;
    background-color: #ffffff;
    margin-top: 10px;
    .flex {
      .company-image {
        overflow: hidden;
        min-width: 44px;
        max-width: 44px;
        width: 44px;
        height: 44px;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0.04);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .text-container {
        h3 {
          &.title-3 {
            color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 150% */
          }
        }
        p {
          &.caption {
            color: var(
              --Color-Neutral-Text-colorTextLabel,
              rgba(0, 0, 0, 0.65)
            );
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
        }
      }
    }
  }
  .table-header-container {
    width: 100%;
    .search-bar {
      max-width: 300px;
    }
  }
  .specialPrice-button {
    background-color: transparent;
    font-size: 14px;
    font-style: normal;
    line-height: 22px; /* 157.143% */
    width: 100%;
    text-align: end;
    height: 100%;
    // padding-right: 10px;
  }
  .products-price-info-table {
    .ant-table-wrapper {
        width: 100%;
        overflow: auto;
    }

    .pagination-wrapper {
      margin-top: 16px;
      .ant-pagination {
        .ant-pagination-item-active {
          border-color: #000000;
          background-color: #000000;
          a {
            color: #ffffff;
          }
        }
        .anticon-double-right,
        .anticon-double-left {
          &.ant-pagination-item-link-icon {
            color: #000000;
          }
        }
        .ant-pagination-item {
          height: 32px;
          width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        button {
          &.pagination-button {
            background-color: #ffffff;
            height: 32px;
            padding: 8px 20px;
            color: #000000;
            font-weight: 600;
            border: 1px solid #d9d9d9;
            border-radius: 6px;
          }
          &:disabled {
            opacity: 0.6;
            cursor: default;
          }
        }
      }
    }

    .product-image {
      min-width: 50px !important;
      min-height: 50px !important;
      width: 50px !important;
      height: 50px !important;
      border-radius: 4px;
      border: 0.6px solid rgba(0, 0, 0, 0.08);
    }
    .editable-input-wrapper {
      border: 1px solid #d9d9d9;
      height: 64px;
      margin: -1px 0;
      border-radius: 8px;
      &:focus {
        border-color: var(--light-primary);
      }
      &.active {
        border-color: var(--light-primary);
      }
      input,
      .ant-input-affix-wrapper {
        border: none;
        max-width: 110px;
        outline: none;
        padding-right: 0 !important;
        .ant-input-prefix {
          font-weight: 600;
        }
        &:focus-within {
          outline: none !important;
          box-shadow: none !important;
        }
        &:focus {
          border: none !important;
          box-shadow: none !important;
          outline: none !important;
          &:hover {
            border: none !important;
            outline: none !important;
          }
        }
      }
      .action-buttons-container {
        padding-right: 8px;
        button {
          background-color: transparent;
          &.reset-button {
            border-radius: 100%;
            svg {
              width: 18px;
              height: 18px;
              path {
                fill: #333333;
              }
            }
          }
        }
      }
      .ant-select-outlined {
        &:hover {
          .ant-select-selector {
            border: none !important;
          }
        }
      }
      .size-unit-style {
        min-width: 40px;
        max-width: 70px;
        label {
          width: 100%;
        }
      }
      .ant-select {
        max-width: 70px;
        width: 100%;
        .ant-select-selector {
          border: none;
          padding-left: 0 !important;
          .ant-select-selection-item {
            text-align: center;
          }
          &:focus {
            border: none !important;
            &:hover {
              border: none !important;
              outline: none !important;
            }
          }
          &:focus-within {
            border: none !important;
            box-shadow: none !important;
            outline: none !important;
          }
          &:hover {
            border: none;
          }
        }
      }
    }
  }
}
