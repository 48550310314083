.stock-history-drawer {
	.my-drawer-content {
		border-radius: 14px;

		.divider {
			border-color: #8d8d8d;
			border-width: 2px;
		}

		//header
		.my-drawer-header {
			padding: 16px 16px 0;
			border: 0;

			.title {
				color: #101828;
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: 30px;
			}

			.close-btn {
				height: 24px;
				width: 24px;
				justify-content: center;
				background: transparent;
				svg {
					height: 24px;
					width: 24px;
				}
			}

			.search-wrapper {
				margin-top: 10px;
				.input-wrapper {
					height: 42px;
					&:focus-within:not(:disabled),
					&:hover:not(.is-disabled) {
						border: 1px solid #0169e6;
					}
				}

				.start-adornment {
					border-radius: 10px;
					border: 1px solid #e6f1ff;
					background: rgba(1, 105, 230, 0.05);
					margin: 6px;
					padding: 6px;
					& > span {
						display: flex;
						align-items: center;
					}
					svg {
						height: 16px;
						width: 16px;
						path {
							stroke: #0169e6;
						}
					}
				}

				.divider {
					color: #cbd5e0;
				}

				.end-adornment {
					span {
						svg {
							height: 20px;
							width: 20px;
							color: #465468;
						}
						&.filter_icon {
							svg {
								path {
									stroke: #465468;
								}
							}
						}
						&.active {
							svg {
								path {
									stroke: #0169e6;
								}
							}
						}
					}
				}
			}
		}

		//body

		.my-drawer-body {
			margin: 10px;
			margin-right: 5px;
			padding: 0 10px 6px 0;

			&::-webkit-scrollbar {
				width: 8px;
			}

			&::-webkit-scrollbar-track {
				background: #f1f1f1;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				background: rgba(0, 0, 0, 0.2);
			}

			.time-label {
				color: #00000073;
				color: var(--light-colorTextTertiary);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				padding-top: 10px;
			}
			.stock-history-card {
				padding: 16px 0;
				line-height: normal;
				font-style: normal;
				border-bottom: 0.33px solid rgba(84, 84, 86, 0.34);
				.main-title {
					color: #000;
					font-size: 15px;
					font-weight: 600;
					margin-bottom: 10px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 200px;
				}

				.title {
					margin-top: 10px;
					color: rgba(0, 0, 0, 0.65);
					font-size: 11px;
					font-weight: 400;
				}

				.status-title {
					font-size: 12px;
					font-weight: 500;
					&.violate {
						color: #bc54f0;
					}

					&.blue {
						color: #0169e6;
					}
				}

				.count {
					font-size: 14px;
					font-weight: 600;
					&.green {
						color: #17b26a;
					}

					&.red {
						color: #ff453a;
					}
				}

				.size-text {
					color: rgba(60, 60, 67, 0.6);
					font-size: 14px;
					font-weight: 600;
					margin-left: 10px;
				}
				.remark {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 200px;
				}
				.download-text {
					cursor: pointer;
					margin-top: 10px;
					color: #000;
					font-size: 12px;
					font-weight: 500;
					background-color: transparent;

					svg {
						path {
							stroke: #000;
						}
					}
				}
			}
		}
		.no-data-wrapper {
			height: 150px;
		}
	}
}

.stock-analysis-drawer {
	.search-wrapper {
		&.child-search {
			margin-top: 5px;
		}
		.input-wrapper {
			height: 40px;

			&:focus-within:not(:disabled),
			&:hover:not(.is-disabled) {
				border: 1px solid #0169e6;
			}
		}

		.start-adornment {
			border-radius: 10px;
			border: 1px solid #e6f1ff;
			background: rgba(1, 105, 230, 0.05);
			margin: 5px;
			& > span {
				display: flex;
				align-items: center;
			}

			svg {
				height: 16px;
				width: 16px;
				path {
					stroke: #0169e6;
				}
			}
		}

		.end-adornment {
			.cross-icon {
				display: flex;
				align-items: center;
				svg {
					height: 24px;
					width: 24px;
					path {
						fill: #d0d5dd;
					}
				}
			}
		}
	}
	.my-drawer-content {
		border-radius: 14px;

		//header
		.my-drawer-header {
			padding: 16px 16px;
			border: 0;

			.close-btn {
				height: 24px;
				width: 24px;
				justify-content: center;
				background: transparent;
				svg {
					height: 24px;
					width: 24px;
				}
			}

			.alert-button {
				background: transparent;
				border-radius: 14px;
				border: 0.5px solid #0169e6;
				display: flex;
				padding: 6px 10px;
				align-items: center;
				gap: 4px;
				color: #0169e6;

				span {
					font-size: 11px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}

				.edit-icon {
					svg {
						path {
							stroke: #0169e6;
						}
					}
				}
			}

			.img-container {
				max-width: 60px;
				height: 60px;
				width: 60px;
				overflow: hidden;
				background-color: #f2f2f7;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 10px;
				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}

			.title-container {
				color: #000;
				font-style: normal;

				.product-title {
					font-size: 18px;
					font-weight: 700;
					line-height: 22px;
					width: 170px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.sub-title {
					color: rgba(0, 0, 0, 0.65);
					font-size: 12px;
					font-weight: 500;
					line-height: 20px;
				}
			}
		}

		.my-drawer-body {
			margin-right: 6px;
			padding: 0 10px 16px 16px;
			position: relative;

			.divider {
				border-color: #8d8d8d;
				border-width: 2px;
			}

			&::-webkit-scrollbar {
				width: 8px;
			}

			&::-webkit-scrollbar-track {
				background: #f1f1f1;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				background: rgba(0, 0, 0, 0.09);
			}

			//product size list
			.size-container {
				max-width: 100%;
				width: 100%;
				gap: 7.5px;
				.size_item {
					height: 100%;

					button {
						color: rgba(0, 0, 0, 0.65);
						font-size: 12px;
						font-style: normal;
						font-weight: 600;
						line-height: 1;
						border-radius: 14px;
						border: 1px solid #e8ecf2;
						padding: 12px 10px;
						background-color: #fff;
						width: 100px;
						min-width: 100px;
						height: 45px;

						.title {
							max-width: max-content;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						&:hover {
							background-color: #f1f4f9;
						}

						&.more-button {
							display: flex;
							align-items: center;
							justify-content: center;
							gap: 3px;

							svg {
								path {
									fill: rgba(0, 0, 0, 0.65);
								}
							}
						}
					}
					&.active {
						button {
							border: 1px solid #000;
							background: #000;
							color: #fff;

							svg {
								path {
									fill: #fff;
								}
							}
						}
					}
				}
			}

			//product count
			.count-analytics-container {
				margin-top: 6px;
				gap: 16px;
				.count-analytics-class {
					flex: 1;
					padding: 14px;
					border-radius: 16px;
					border: 0.5px solid #d0d5dd;
					background: #fff;
					font-style: normal;

					.count {
						color: #000;
						font-size: 16px;
						font-weight: 700;
						margin-bottom: 8px;
					}

					.title {
						color: #64748b;
						font-size: 12px;
						font-weight: 400;
					}

					.svg-container {
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 10px;
						gap: 10px;
						border-radius: 14px;
						width: 40px;
						height: 40px;
						min-height: 40px;
						min-width: 40px;

						&.light-purple {
							background: #f6ecff;
						}
						&.light-warning {
							border-radius: 14px;
							background: #fffbe6;
						}
					}
				}
			}

			//low stock alert

			.low-stock-alert-container {
				margin-top: 8px;
				padding: 8px;
				gap: 8px;
				border-radius: 14px;
				border: 1px solid #ffa39e;
				background: #fff2f0;

				svg {
					width: 24px;
					height: 24px;
					color: #f5222d;
				}
				.title {
					color: #f5222d;
					font-size: 14px;
					font-weight: 600;
					line-height: 20px;
				}

				.sub-title {
					color: #465468;
					font-size: 12px;
					font-weight: 400;
					line-height: 20px;
				}
			}

			.date-selector {
				margin-top: 10px;
			}

			.stock-chart-container {
				margin: 10px 0;

				.custom-tooltip {
					background-color: #000;
					border-radius: 25.2px;
					padding: 3.6px 12.96px;
					color: #fff;
				}
			}

			.transaction-history-container {
				padding-bottom: 5px;
				position: sticky;
				top: 0;
				background-color: white;

				.title {
					color: #101828;
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}
			}

			//history list

			.history-time {
				padding-top: 10px;
				color: rgba(0, 0, 0, 0.45);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 22px;
			}

			.history-card-wrapper {
				.img-container {
					max-width: 40px;
					height: 40px;
					width: 40px;
					overflow: hidden;
					background-color: #f2f2f7;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 22px;
					img {
						object-fit: cover;
						width: 100%;
						height: 100%;
					}
				}
				.card-content-wrapper {
					flex: 1;
					padding: 16px 0;
					border-bottom: 0.33px solid rgba(84, 84, 86, 0.34);

					.title {
						color: #000;
						font-size: 14px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 140px;
						margin-bottom: 8px;
					}
					.sub-title {
						color: #64748b;
						font-size: 10px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}

					.stock-adjust-title {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 100px;
					}

					.count {
						font-size: 14px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;

						&.green {
							color: #17b26a;
						}

						&.red {
							color: #ff453a;
						}
					}

					.size-text {
						color: rgba(60, 60, 67, 0.6);
						font-size: 14px;
						font-weight: 600;
						margin-left: 5px;
					}

					.status-title {
						margin-top: 8px;
						font-size: 12px;
						font-weight: 500;
						text-align: right;
						font-style: normal;
						line-height: normal;

						&.green {
							color: #17b26a;
						}

						&.red {
							color: #ff453a;
						}

						&.orange {
							color: #faab0e;
						}

						&.violate {
							color: #bc54f0;
						}

						&.blue {
							color: #0169e6;
						}
					}
				}
			}

			.no-data-wrapper {
				height: 150px;
			}
		}
	}
}

.single-month-date-range {
	width: 100%;
	margin-top: 8px;
	height: 40px;
	border-radius: 12px;
	border-color: #0169e6;
	outline: none;
	&:hover {
		border-color: #0169e6 !important;
	}
	.ant-picker-suffix {
		color: #0169e6;
	}
}

.single-month {
	.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
		visibility: visible !important;
	}

	.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
		visibility: visible !important;
	}

	.ant-picker-panels > *:last-child {
		display: none;
	}

	.ant-picker-panel-container,
	.ant-picker-footer {
		width: 280px !important;
	}

	.ant-picker-footer-extra > div {
		flex-wrap: wrap !important;
	}
}

.analysis-size-dropdown-container {
	max-height: 35vh;
	overflow: auto;
	border-radius: 14px;
	border: 1px solid var(--Background-colorBgContainer, #fff);
	background: var(--Background-colorBgElevated, #fff);
	box-shadow:
		0px 6px 16px 0px rgba(0, 0, 0, 0.08),
		0px 3px 6px -4px rgba(0, 0, 0, 0.12),
		0px 9px 28px 8px rgba(0, 0, 0, 0.05);
	.ant-popover-inner-content {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
	.dropdown_item {
		max-width: 200px;
		button {
			color: rgba(0, 0, 0, 0.65);
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: 1;
			border-radius: 8px;
			border: 1px solid #e8ecf2;
			padding: 8px 12px;
			background-color: #fff;
			max-width: 180px;
			min-width: 150px;
			width: 100%;
			gap: 6px;
			span {
				max-width: 100px;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
			&:hover {
				background-color: #f1f4f9;
			}

			.title {
				max-width: max-content;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		&.active {
			button {
				border: 1px solid #000;
				background: #000;
				color: #fff;
			}
		}
	}
}
