.partially-recived-modal {
  max-width: 552px !important;
  width: 552px !important;
  position: relative;

  .ant-modal-content {
    min-height: 300px;
  }

  .partially-recived-container {
    .header-wrapper {
      p {
        color: var(--light-colorText);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;

        span {
          color: var(--light-colorText);
        }
      }
    }

    .partially-recived-data {
      width: 100%;
      border-radius: 12px;
      border: 1px solid #f0f0f0;
      background: #fff;
      margin-top: 20px;
      max-height: 50vh;
      overflow: auto;

      &:nth-child(1) {
        .partially-recived-card {
          border-top: 1px solid #f0f0f0;
        }
      }

      .partially-recived-card {
        padding: 8px;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #f0f0f0;
        // border-bottom: 1px solid #f0f0f0;
        
        &:nth-child(1) {
          border-top: none;
        }

        &:nth-last-child(1) { 
          border-bottom: none;
        }

        .partially-recived-card-content {
          display: flex;
          flex-direction: column;
          padding: 8px;

          .partially-dispatched-title {
            color: var(--light-colorText);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
          }

          .date-and-time {
            color: var(--light-colorTextTertiary);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }

        .order-status {
          padding: 8px;
          display: flex;
          align-items: center;

          .switch {
            max-height: 24px;
          }
        }
      }
    }
  }
}
