.recent-order-modal {
    // padding: 20px;

    .ant-modal-close {
        background: #fff;
    }
    .recent-order-cart-page {
        min-height: 40vh;
        max-height: 80vh;
        height: 100%;
        overflow: auto;
        .cart-header {
            h2 {
                &.title-2 {
                    color: #101828;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 30px; /* 150% */
                }
            }
        }
        .cart-body {
            display: flex;
            flex-direction: column;
            gap: 20px;
            .cart-body-section1 {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                p {
                    &.caption {
                        color: rgba(0, 0, 0, 0.45);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 22px; /* 157.143% */
                    }
                }
                button {
                    &.add-more-button {
                        border-radius: 8px;
                        border: 1px solid var(--light-primary);
                        background: #FFFFFF;
                        color: #000000;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 22px; /* 157.143% */
                        height: 40px;
                        padding: 4px 15px;
                    }
                    &.remove-button {
                        color: #F5222D;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 157.143% */
                        background-color: transparent;
                        margin-left: 10px;
                    }
                }
            }
            .cart-body-section2 {
                .product-img {
                    min-width: 54px;
                    min-height: 54px;
                    width: 54px;
                    height: 54px;
                    max-height: 54px;
                    max-width: 54px;
                    overflow: hidden;
                    border-radius: 5.838px;
                    border: 1px solid var(--light-colorBorder);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        object-position: center;
                        object-fit: cover;
                        min-width: 54px;
                        min-height: 54px;
                        width: 54px;
                        height: 54px;
                        max-height: 54px;
                        max-width: 54px;
                    }
                }
                .order-unit {
                    white-space: nowrap;
                }
                .ant-table-wrapper .ant-table-tbody>tr>td {
                    padding: 7.5px 16px;
                    color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
                    /* Base/Strong */
                    font-family: "SF Pro Text";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 22px; /* 157.143% */
                    span {
                        white-space: nowrap;
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 280px;
                    }
                }
                .counter-button {
                    height: 40px;
                }
                .ant-table-wrapper {
                    .ant-table {
                        .ant-table-container {
                            .ant-table-content {
                                overflow: auto;
                                max-height: 43vh;
                                table {
                                    min-width: 1086px;
                                    border: 1px solid #F0F0F0;
                                    border-radius: 12px;
                                    overflow: hidden;
                                    overflow: auto;
                                    max-height: 43vh;
                                    position: relative;
                                    .ant-table-thead {
                                        position: sticky !important;
                                        top: 0 !important;
                                        background: white;
                                        z-index: 12;
                                      }
                                    .rate-per-unit {
                                        button {
                                            &.price-fluc {
                                                border: 0.833px solid var(--Colors-Brand-Success-colorSuccessBgHover, #ABEFC6);
                                                background: var(--Colors-Brand-Success-colorSuccessBg, #ECFDF3);
                                                padding: 0px 4px;
                                                border-radius: 9999px;
                                                color: #17B26A;
                                                font-size: 12px;
                                                font-style: normal;
                                                font-weight: 600;
                                                line-height: 20px; /* 166.667% */
                                                svg {
                                                    width: 16px;
                                                    height: 16px;
                                                }
                                            }
                                        }
                                    }
                                    p {
                                        &.discount {
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 22px; /* 157.143% */
                                            // color: #000000;
                                        }
                                    }
                                    .sub-total {
                                        button {
                                            &.remove-item {
                                                padding: 10px 8px;
                                                background-color: transparent;
                                                height: 32px;
                                                transition: all 0.2s ease;
                                                border-radius: 4px;
                                                svg {
                                                    path {
                                                        stroke: var(--light-colorError);
                                                    }
                                                }
                                                &:hover {
                                                    background-color: #FFFFFF;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .unavailable-size {
                    .badge {
                        width: fit-content;
                        color: #fff;
                        padding: 2px 6px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 500;
                        border-radius: 4px;
                        background-color: #C3C3C3;
                    }
                }
            }
            .cart-body-section3 {
                .form-section {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    width: 100%;
                    position: relative;
                    .address-section {
                        border-radius: var(--radius-md, 8px);
                        border: 1px solid var(--Color-Neutral-Borders-colorBorderSecondary, #F0F0F0);
                        padding: 12px;
                        z-index: 999;
                        .address-text {
                            width: 100%;
                            .location-icon-box {
                                padding: 2px 4.5px 1.25px 4.5px;
                                width: 24px;
                                height: 24px;
                                svg {
                                    width: 15px;
                                    height: 20.75px;
                                }
                            }
                            .address-input {
                                width: 100%;
                                h4 {
                                    &.title-4 {
                                        color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 22px; /* 157.143% */
                                    }
                                }
                                input {
                                    color: var(--Color-Neutral-Text-colorTextLabel, rgba(0, 0, 0, 0.65));
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 20px; /* 166.667% */
                                    border: none;
                                    outline: none;
                                    width: 100%;
                                    background-color: transparent;
                                }
                            }
                            .change-address {
                                background: transparent;
                                color: var(--Colors-Brand-Primary-colorPrimary, #000000);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 22px; /* 157.143% */
                            }
                            .form-buttons {
                                display: flex;
                                align-items: center;
                                gap: 13px;
                                button {
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 22px; /* 157.143% */
                                    background-color: transparent;
                                    &.cancel {
                                        color: rgba(0, 0, 0, 0.88);
                                    }
                                    &.save{
                                        color: #000000;

                                        &:disabled {
                                            opacity: 0.6;
                                            cursor: default;
                                        }
                                    }
                                    &.add-address {
                                        color: #000000;
                                        white-space: nowrap;
                                    }
                                }
                            }
                        }
                        .address-options {
                            width: 100%;
                            position: absolute;
                            left: 0;
                            // top: 66px;
                            transform: translate(0%, 10%);
                            background-color: #FFF;
                            border: 1px solid var(--Color-Neutral-Borders-colorBorderSecondary, #F0F0F0);
                            border-radius: 0 0 8px 8px;
                            border-top: none;
                            overflow: hidden;
                            .address-item {
                                width: 100%;
                                padding: 12px 16px;
                                background-color: #FFF;
                                height: 51.5px;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                &:hover {
                                    background-color: rgba(0, 0, 0, 0.04);
                                    .action-buttons {
                                        display: flex;
                                        align-items: center;
                                        button {
                                            display: flex;
                                            align-items: center;
                                        }
                                    }
                                    .address {
                                        button {
                                            &.badge {
                                                display: flex;
                                            }
                                        }
                                    }
                                }
                                .address {
                                    gap: 2px;
                                    label {
                                        &.address-label {
                                            display: flex;
                                            align-items: center;
                                            gap: 16px;
                                            cursor: pointer;
                                            input {
                                                width: 20px;
                                                min-width: 20px;
                                                height: 20px;
                                                min-height: 20px;
                                                outline: none;
                                            }
                                            span{
                                                word-break: break-all;
                                            }
                                        }
                                    }
                                    button {
                                        &.badge, &.badge-default {
                                            align-items: center;
                                            justify-content: center;
                                            border-radius: 4px;
                                            border: 1px solid var(--Colors-Brand-Primary-colorPrimaryBgHover, #E6F1FF);
                                            background: var(--Colors-Brand-Primary-colorPrimaryBg, #F2F7FE);
                                            color: var(--Colors-Brand-Primary-colorPrimary, #000000);
                                            text-align: center;
                                            font-size: 10px;
                                            font-style: normal;
                                            font-weight: 500;
                                            line-height: 18px; /* 180% */
                                            padding: 2px 6px;
                                            transition: all 0.1s ease;
                                            &:hover {
                                                border: 1px solid var(--Colors-Brand-Success-colorSuccessBgHover, #ABEFC6);
                                                background: var(--Colors-Brand-Success-colorSuccessBg, #ECFDF3);
                                                color: var(--Colors-Brand-Success-colorSuccessActive, #067647);
                                            }
                                        }
                                        &.badge {
                                            display: none;
                                        }
                                        &.active {
                                            border: 1px solid var(--Colors-Brand-Success-colorSuccessBgHover, #ABEFC6);
                                            background: var(--Colors-Brand-Success-colorSuccessBg, #ECFDF3);
                                            color: var(--Colors-Brand-Success-colorSuccessActive, #067647);
                                        }
                                    }
                                }
                                .action-buttons {
                                    display: none;
                                    button {
                                        background-color: transparent;
                                        svg {
                                            width: 16px;
                                            height: 16px;
                                            path {
                                                stroke: #00000073;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .address-form {
                            gap: 10px;
                            display: flex;
                            flex-direction: column;
                            position: absolute;
                            left: 0;
                            // top: 66px;
                            transform: translate(0%, 10%);
                            width: 100%;
                            padding: 16px;
                            background: #FFFFFF;
                            background-color: #FFF;
                            border: 1px solid var(--Color-Neutral-Borders-colorBorderSecondary, #F0F0F0);
                            border-radius: 0 0 8px 8px;
                            border-top: none;
                            overflow: hidden;
                            padding-top: 0;
                            .input {
                                label {
                                    span {
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 22px; /* 183.333% */
                                        letter-spacing: 0.12px;
                                        &:not(.require-icon) {
                                            color: #000000E0;
                                        }
                                    }
                                    input {
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 24px; /* 200% */
                                        letter-spacing: 0.12px;
                                        height: auto;
                                        padding: 8px 4px;
                                        color: #000000;
                                        &::placeholder {
                                            color: var(--Color-Neutral-Text-colorTextPlaceholder, rgba(0, 0, 0, 0.25));
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .description-section {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        position: relative;
                        border-radius: 8px;
                        border: 1px solid var(--Color-Neutral-Borders-colorBorderSecondary, #F0F0F0);
                        height: 132px;
                        padding: 12px;
                        textarea {
                            // border-radius: 8px;
                            width: 100%;
                            height: 100%;
                            outline: none;
                            resize: none;
                            border: none;
                            font-size: 14px;
                            line-height: 22px; /* 157.143% */
                            &::placeholder {
                                color: var(--Color-Neutral-Text-colorTextDescription, rgba(0, 0, 0, 0.45));
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 22px; /* 157.143% */
                            }
                        }

                        .attachment-icon {
                            border-radius: 4px;
                            position: absolute;
                            bottom: 8px;
                            right: 12px;
                            cursor: pointer;
                            background: #fff;

                            svg {
                                width: 20px;
                                height: 20px;
                                path {
                                    stroke : #98A2B3
                                }
                            }
                        }

                        .attachments-files-container {
                            // margin: 5px 0 0 12px;

                            .selected-file {
                                position: relative;
                                width: 50px;
                  
                                .file-preview {
                                  .file-box {
                                    width: 50px;
                                    height: 50px;
                                    border-radius: 8px;
                                    border: 0.87px solid #eaecf0;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    overflow: hidden;
                  
                                    img {
                                      object-fit: contain;
                                      width: 100%;
                                      height: 100%;
                                      object-position: center;
                                    }
                                  }
                  
                                  .file-name {
                                    color: var(--light-colorText);
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 20px;
                                    text-align: center;
                                    display: inline-block; /* Ensure it's treated as a block element */
                                    max-width: 50px; /* Set the maximum width */
                                    white-space: nowrap; /* Prevent the text from wrapping to the next line */
                                    overflow: hidden; /* Hide any overflowing content */
                                    text-overflow: ellipsis;
                                  }
                                }
                  
                                .delete-icon {
                                  background: transparent;
                                  position: absolute;
                                  top: 2px;
                                  right: 2px;
                                  z-index: 9;
                                }
                              }
                        }
                    }
                }
                .biling-details {
                    height: 200px;
                    background-color: rgba(0, 0, 0, 0.02);
                    border: 1px solid var(--Color-Neutral-Borders-colorBorderSecondary, #F0F0F0);
                    border-radius: 8px;
                    width: 100%;
                    max-width: 334px;
                    padding: 8px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .biling-text {
                        h3 {
                            &.title-3 {
                                color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 24px; /* 150% */
                            }
                        }
                        .subtotal {
                            margin-top: 18px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            p {
                                &.text {
                                    color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
                                    font-family: "SF Pro Text";
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 22px; /* 157.143% */
                                }
                                &.price {
                                    color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
                                    text-align: right;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 22px; /* 157.143% */
                                }
                            }

                        }
                        .total-discount {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 12px;
                            padding-bottom: 10px;
                            border-bottom: 1px solid var(--Color-Neutral-Borders-colorBorderSecondary, #F0F0F0);
                            p {
                                &.text {
                                    color: var(--Colors-Brand-Primary-colorPrimary, #000000);
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 22px; /* 157.143% */
                                }
                                &.price {
                                    color: var(--Colors-Brand-Primary-colorPrimary, #000000);
                                    text-align: right;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 22px; /* 157.143% */
                                }
                            }
                        }
                    }
                    .bill-total {
                        margin-top: 10px;
                        .grand-total {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            h3 {
                                &.grand-total-text {
                                    color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 24px; /* 120% */
                                }
                                &.grand-total-price {
                                    color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
                                    text-align: right;
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 24px; /* 120% */
                                }
                            }
                        }
                        button {
                            &.place-order {
                                margin-top: 22px;
                                width: 100%;
                                height: 40px;
                                border-radius: 8px;
                                color: var(--Components-Button-Component-primaryColor, #FFF);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 22px; /* 157.143% */

                                &:disabled {
                                    cursor: default;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.ant-modal-root .ant-modal-mask {
    backdrop-filter: blur(20px);
    // background-color: rgba(0, 0, 0, 0.45);
}