.product-list-group-container {
  .show-to-customer-box {
    padding: 12px;
    border-bottom: 1px solid var(--light-colorBorderSecondary);

    .text-label {
      color: var(--light-colorText);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .product-list-group {
    display: flex;
    flex-direction: column;
    .list-products-header {
      color: var(--light-colorText);
      font-size: 16px !important;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      padding: 0 12px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .product-box {
      padding: 8px 12px;
    }
    .product-image {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      border: 0.6px solid rgba(0, 0, 0, 0.08);
    }
    .product-name {
      color: #101828;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.product-list-group-drawer-footer {
  .secondary {
    width: 100%;
  }

  .primary{
    width: 100%;
  }
}
