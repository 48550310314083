.attachment_section {
	margin-bottom: 26px;
	margin-top: 26px;
	.title-text {
		color: #344056;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	.attachment_input {
		position: relative;
		margin-top: 8px;
		padding: 16px 24px;
		border-radius: var(--radius-xl, 12px);
		border: 1px dashed rgba(0, 0, 0, 0.25);
		background: var(--Colors-Background-bg-disabled_subtle, #F9FAFB);
		input[type="file"] {
			color: var(--light-primary);
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			opacity: 0;
			cursor: pointer;
		}
		button {
			&.upload_button {
				border-radius: 12px;
				border: 1px solid #0169E6;
				background: #FFF;
				height: 40px;
				padding: 10px 20px;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				color: #0169E6;
			}
		}
		.placeholder_text {
			text-align: start;
			p {
				&.text {
					color: #344056;
					text-align: start;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */

					span {
						color: #000;
						font-size: 14px;
						font-style: normal;
						font-weight: 600;
						line-height: 20px; /* 142.857% */
					}
				}
			}
		}
	}
	.attachment_uploaded_section {
		border-radius: 12px;
		border: 1px solid #EAECF0;
		background: #FFF;
		padding: 16px;
		margin-top: 8px;
		.file_container {
			.icon_container {
				min-width: 40px;
				min-height: 40px;
				width: 40px;
				height: 40px;
				object-fit: contain;
				object-position: center;
			}
			p {
				&.file_name {
					overflow: hidden;
					color: #344054;
					text-overflow: ellipsis;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px; /* 142.857% */
				}
				&.file_size {
					overflow: hidden;
					color: #475467;
					text-overflow: ellipsis;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
				}
			}
		}
		button {
			&.delete_button {
				padding: 8px;
				border-radius: 8px;
				background-color: transparent;
				transition: all 0.2s;
				svg {
					path {
						transition: all 0.2 ease;
					}
				}
				&:hover {
					background-color: #F5222D20;
					svg {
						path {
							stroke: #F5222D;
						}
					}
				}
			}
		}
	}
}