.request-box {
    width: 100%;
    &__inner {      
        padding: 8px;
    }
    &__img-container {
        overflow: hidden;
        max-width: 44px;
        max-height: 44px;
        min-width: 44px;
        min-height: 44px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f9f9f9;
        border-radius: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    &__text-container {
        h4 {
            &.title-4 {
                color: rgba(0, 0, 0, 0.88);
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px; /* 157.143% */
            }
        }
        p {
            &.caption {
                color: rgba(0, 0, 0, 0.65);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 166.667% */
            }
        }
    }
    &__action-container {
        button {
            &:disabled {
                opacity: 0.6;
                cursor: default;
            }
            &.action {
                &__btn-reject {
                    padding: 4px 8px;
                    color: #ED1A0B;
                    border-radius: 4px;
                    background-color: transparent;
                    border: 1px solid #ED1A0B;
                    transition: all 0.2s ease;
                    font-weight: 600;
                    &:hover {
                        color: #FFFFFF;
                        background-color: #ED1A0B;
                        
                        &:disabled {
                            background-color: transparent;
                            color: #ED1A0B;
                        }
                    }

                }
                &__btn-accept {
                    padding: 4px 8px;
                    color: #067647;
                    border: 1px solid #067647;
                    border-radius: 4px;
                    transition: all 0.2s ease;
                    background-color: transparent;
                    font-weight: 600;
                    &:hover {
                        color: #FFFFFF;
                        background-color: #067647;

                        &:disabled {
                            background-color: #067647;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        } 
    }
}