.commin-soon-wrapper {
  border-radius: 12px !important;
  margin-top: 10px;

  .coming-soon-container {
    border-radius: 12px;
    height: calc(100vh - 160px) !important;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    border: 1px solid #eaecf0;

    .center-container {
      display: flex;
      flex-direction: column;
      gap: 36px;
      .content-container {
        display: flex;
        flex-direction: column;
        gap: 18px;

        .title {
          color: var(--light-colorText);
          font-size: 48px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
        }

        .info {
          color: var(--light-colorSecondary);
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
        }
      }
    }
  }
}
