.app-coming-soon-page {
    .container {
        max-width: 560px;
        width: 100%;
        padding-top: 100px;
        .logo {
            max-width: 160px;
            width: 100%;
            img {
                width: 100%;
            }
        }
        .page-title {
            text-align: center;
            margin: 40px 0;
            img {
                width: 105.967px;
                height: 64px;
            }
            h2 {
                &.title-2 {
                    max-width: 340px;
                    width: 100%;
                    text-align: center;
                    font-family: "Plus Jakarta Sans";
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 40px; /* 125% */
                }
            }
        }
        p {
            &.caption {
                margin-top: 32px;
                margin-bottom: 32px;
                color: #344056;
                text-align: center;
                font-family: "SF Pro Text";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .mobile-container {
            height: 100%;
            max-width: 360px;
            overflow: hidden;
            img {
                height: 100%;
                width: 100%;
            }
        }
        .button-container {
            width: 100%;
            max-width: 343px;
            position: sticky;
            bottom: 0;
            .button {
                width: 100%;
                button {
                    width: 100%;
                }
            }
        }
        .form-container {
            width: 100%;
            max-width: 340px;
            .button {
                width: 100%;
                button {
                    width: 100%;
                    height: 40px;
                    border-radius: 14px;
                }
            }
        }
        .coming-soon-container {
            border: none;
            height: auto !important;
            margin-top: 60px;
        }
    }
}