.message-box-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .messages-container {
    padding: 16px 16px 16px 16px;
    // height: calc(100% - 76px);

    .messages-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      overflow: auto;
      height: 100%;

      .date-of-comment {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          width: fit-content;
          color: rgba(0, 0, 0, 0.88);
          padding: 4px 8px;
          border-radius: 4px;
          background: #f2f2f7;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }
      }

      .chat-info-container {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .attachment-box {
          display: flex;
          align-items: center;
          gap: 12px;

          &.sent {
            justify-content: end;
            margin-left: auto;
          }

          .message_side_left {
            width: 100%;
            min-width: 32px;
            max-width: 32px;
            height: 100%;
            max-height: 32px;
            border-radius: 100%;
          }
        }

        .attached-attachments {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          gap: 4px;
          color: var(--light-colorSecondary);
          padding: 12px;
          border: 1px solid #eaecf0;

          .date-and-time {
            color: var(--light-colorTextTertiary);
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            text-align: end;
            margin-left: auto;
            margin-top: auto;
          }

          &.left {
            background-color: #f9fafb;
            border-radius: 0px 8px 8px 8px;
          }

          &.right {
            background-color: #e8f1fc;
            border-radius: 8px 0px 8px 8px;
          }

          .attachments-box {
            padding: 10px 8px;
            display: flex;
            align-items: center;
            gap: 2px;
            border-radius: 8px;
            border: 1px solid var(--light-colorBorderSecondary);
            background: #fff;
            max-width: 308px;

            span {
              color: var(--light-colorText);
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            img {
              width: 22px;
              height: 22px;
              border-radius: 2px;
            }

            svg {
              width: 22px;
              height: 22px;
            }
          }
        }
      }
    }
  }

  .message-spin {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message-input {
    width: 100%;
    height: 76px;
    padding: 16px;
    display: flex;
    gap: 10px;
    background-color: #F2F2F7;

    input {
      border-radius: 14px;
      border: 1px solid #D9D9D9;
      padding: 10px 12px;
      width: 100%;

      &:focus {
        border: 1px solid var(--light-primary);
      }

      &:focus-visible {
        outline: none;
        border: 1px solid var(--light-primary);
      }
    }

    button {
      min-width: 44px;
      max-width: 44px;
      min-height: 44px;
      max-height: 44px;
      width: 100%;
      height: 100%;
      background-color: var(--light-primary);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}