.chip {
	display: inline-flex;
	align-items: center;
	gap: 8px;
	padding: 2px 8px;
	border-radius: 14px;
	font-family: "SF Pro Text";
	font-size: 12px;
	font-weight: 500;
	white-space: nowrap;
	line-height: 20px;
	transition: background 0.2s ease-in-out;

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 16px;
		height: 16px;
	}

	&__label {
		color: #333;
	}

	&--display {
		background-color: #f0f0f0;
	}

	&--button {
		cursor: pointer;
		background-color: #e0e0e0;

		&:hover {
			background-color: #d6d6d6;
		}

		&:active {
			background-color: #c0c0c0;
		}
	}

	&--disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	//Implement custom classes here

	&.success {
		border: 0.5px solid #abefc6;
		background: #ecfdf3;
		.chip__label {
			color: #17b26a;
		}
	}

	&.warning {
		border: 0.5px solid #ffbc0d;
		background: rgba(255, 188, 13, 0.1);
		.chip__label {
			color: #c59413;
		}
	}

	&.error {
		border: 0.5px solid #ffa39e;
		background: #fff1f0;
		.chip__label {
			color: #f5222d;
		}
	}
}
