span {
    &.count-capsule-badge {
        min-width: 17px;
        // height: 17px;
        padding: 4px 4px;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 14px;
        border: 0.855px solid #fff;
        background: var(--light-button-colorError);
        text-align: center;
        color: #fff;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 10px;
        &.absolute {
            position: absolute;
            top: -2px;
            left: 9px;
        }
        &.relative {
            position: relative;
        }
    }
}