.stock_table {
	&__wrapper {
		position: relative;
	}
	&__class_status_filter_container {
		margin-bottom: 10px;
		gap: 30px;
	}
	&__group_container {
		overflow: auto;
		width: 100%;
		max-width: 100%;
		gap: 7.5px;

		.size_item {
			height: 100%;

			button {
				color: rgba(0, 0, 0, 0.65);
				font-size: 12px;
				font-style: normal;
				font-weight: 600;
				line-height: 1;
				border-radius: 10px;
				border: 1px solid #e8ecf2;
				padding: 12px 10px;
				background-color: #fff;
				width: 100px;
				min-width: 100px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 45px;
				gap: 6px;

				.title {
					max-width: max-content;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.count {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 5px 7px;
					min-width: 20px;
					min-height: 20px;
					height: 20px;
					border-radius: 50px;
					border: 1px solid #dfe4ed;
				}
				&:hover {
					background-color: #f1f4f9;
				}

				&.more-button {
					justify-content: center;

					svg {
						path {
							fill: rgba(0, 0, 0, 0.65);
						}
					}
				}
			}
			&.active {
				button {
					border: 1px solid #000;
					background: #000;
					color: #fff;

					.count {
						background-color: #fff;
						color: #000;
						border-radius: 50px;
					}

					svg {
						path {
							fill: #fff;
						}
					}
				}
			}
		}
	}
	&__header {
		margin-bottom: 16px;
	}
	&__search-wrapper {
		min-width: 400px;
		width: 100%;
		height: 42px;

		.input-wrapper {
			height: 42px;
			&:focus-within:not(:disabled),
			&:hover:not(.is-disabled) {
				border: 1px solid #0169e6;
			}
		}

		.start-adornment {
			border-radius: 10px;
			border: 1px solid #e6f1ff;
			background: rgba(1, 105, 230, 0.05);
			margin: 6px;
			padding: 6px;
			& > span {
				display: flex;
				align-items: center;
			}
			svg {
				height: 16px;
				width: 16px;
				path {
					stroke: #0169e6;
				}
			}
		}
	}
}
