.connection_item {
	width: 100%;
	cursor: pointer;
	&:hover {
		background-color: #F9FAFB;
		border-radius: 8px;
	}
	.item {
		&__image {
			max-width: 44px;
			max-height: 44px;
			overflow: hidden;
			width: 44px;
			height: 44px;
			border-radius: 100%;
			position: relative;
			border: 1px solid #F9FAFB;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgba(0, 0, 0, 0.04);
			img {
				max-width: 44px;
				max-height: 44px;
				width: 100%;
				height: 100%;
				object-fit: contain;
				object-position: center;
				border-radius: 100%;
			}
		}
		&__content {
			h4 {
				&.company_name {
					color: #000;
					font-family: "SF Pro Text";
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 14px; /* 100% */
					width: 100%;
				}
			}
			p {
				&.other_details {
					color: rgba(0, 0, 0, 0.65);
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 166.667% */
					margin-top: 4px;
					span {
						&.name_text {
						}
					}
				}
			}
		}
	}
	.manually-added-text {
		color: var(--Colors-Brand-Primary-colorPrimary, #0169E6);
		text-align: right;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
	.blocked_chip {
		color: #F5222D;
		background-color: #FFF1F0;
		border: 1px solid #FFA39E;
		padding: 3px 4px;
		border-radius: 999px;
		font-size: 10px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
}