.file-upload {
  &.ant-upload-wrapper {
    .ant-upload-list {
      .ant-upload-list-item-container {
        .ant-upload-list-item {
          border-radius: 100%;
          padding: 0 !important;
          &::before {
            opacity: 1 !important;
            width: 100% !important;
            height: 100% !important;
            border-radius: 100%;
            background-color: transparent;
          }
          .ant-upload-list-item-thumbnail {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            overflow: hidden;
          }
        }
      }
      &.ant-upload-list-picture-card {
        
        // .ant-upload {
        //     width: 72px;
        //     height: 72px;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     border-radius: 100%;
        //     border: none;
        //     background-color: transparent;
        //     display: block !important;
        //     position: relative;
        //     .logo-text {
        //         border: 1px solid #D9D9D9 !important;
        //         border-radius: var(--Components-InputNumber-Component-controlWidth, 90px);
        //         border: 1.033px solid var(--Color-Neutral-Borders-colorBorderSecondary, #F0F0F0);
        //         background: var(--Color-Neutral-Fill-colorFillTertiary, rgba(0, 0, 0, 0.04));
        //         backdrop-filter: blur(1.3775510787963867px);
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //         width: 100%;
        //         height: 100%;
        //         text-transform: uppercase;
        //         position: absolute;
        //         left: 0;
        //         top: 0;
        //         font-weight: 600;
        //     }
        //     span {
        //         &.upload-logo-text {
        //             color: var(--light-primary);
        //             margin-left: 88px;
        //             white-space: nowrap;
        //             margin-top: 20px;
        //             display: inline-block;
        //         }
        //     }

        //     button {
        //         display: flex;
        //         align-items: center;
        //         gap: 4px;
        //         div {
        //             margin-top: 0 !important;
        //         }
        //     }
        // }
      }
      // .ant-upload-list-item-actions {
      //     opacity: 1 !important;
      //     // left: 100px;
      //     inset-inline-start: 82px !important;
      //     width: 120px !important;
      //     display: flex;
      //     align-items: center;
      //     justify-content: space-between;
      //     height: 20px;
      //     a {
      //         display: inline-block;
      //         width: 53px;
      //         .anticon-eye {
      //             &::after {
      //                 content: "Preview";
      //                 font-size: 14px;
      //                 color: var(--light-primary);
      //                 position: absolute;
      //                 top: 9px;
      //                 left: 0px;
      //             }
      //         }
      //     }
      //     button {
      //         position: absolute;
      //         left: 80px;
      //         width: 44px;
      //         top: 0px;
      //         &:hover {
      //             background-color: transparent;
      //         }
      //         .anticon-delete {
      //             svg {
      //                 display: none;
      //             }
      //             &::after {
      //                 content: "Delete";
      //                 font-size: 14px;
      //                 color: var(--light-colorError);
      //                 position: absolute;
      //                 left: 0px;
      //                 top: 9px;
      //             }
      //         }
      //     }
      // }
      // .ant-upload-list-item-action {
      //     opacity: 1 !important;
      // }
      // .ant-upload-list-item-error {
      //     border-color: transparent;
      // }
    }
  }

  .logo-text-data {
    color: var(--light-colorText);
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    word-wrap: break-word;
  }
  // &.uploaded {
  //     &.ant-upload-wrapper {
  //         .ant-upload-list {
  //             &.ant-upload-list-picture-card {
  //                 .ant-upload {
  //                     display: none !important;
  //                 }
  //             }
  //         }
  //     }
  // }
}

.img-support-text {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  line-height: normal;
}
