.no-data-page {
  width: 100%;
  height: calc(100% - 85px);
  border-radius: var(--radius-xl, 12px);
  border: 1.5px solid #fff;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(2px);
  overflow: hidden;
  // padding: 10px;
  .no-data-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: var(--radius-xl, 12px);
    border: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
    background: var(--Colors-Background-bg-primary, #fff);

    .center-box {
      height: 480px;
      width: 480px;
      position: relative;

      .bg-img {
      }
      .invite-text-container {
        position: absolute;
        bottom: 33px;
        z-index: 999;
        text-align: center;
        h4 {
          &.title-4 {
            color: #101828;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 133.333% */
          }
        }
        p {
          &.caption {
            color: #475467;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
            max-width: 352px;
            width: 100%;
            margin-top: 4px;
          }
        }
        button {
          margin: auto;
          border-radius: 8px;
          background: #000000;
          color: #ffffff;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 171.429% */
          height: 44px;
          margin-top: 24px;
        }
      }
    }
  }
}