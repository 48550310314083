.order-overview {
    &__wrapper {
        background-color: #FFF;
        border-radius: 14px;
        padding: 16px;
        margin-top: 24px;
        overflow: hidden;
    }
    &__header {
        .dropdown {
            ul {
                &.options-container {
                    &__item {
                        max-width: 24px;
                        min-width: 24px;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
        }
    }
    &__title {
        h3 {
            &.title-text {
                color: #465468;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px; /* 155.556% */
            }
        }
    }
}