.input {
  display: flex;
  width: 100%;

  // align-self: stretch;
  .input-container {
    width: 100%;
    position: relative;

    label {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;

      span {
        color: #344056;
        font-family: "SF Pro Text";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      input {
        width: 100%;
        height: 100%;
        height: 32px;
        padding: 10px 12px;
        font-size: 12px;
        line-height: 200%;
        gap: 12px;
        border-radius: 8px;
        border: 1px solid #D9D9D9;
        outline: none;
        background: var(--light-colorTextLightSolid);
        letter-spacing: 0.12px;
        transition: all 0.1s ease-in-out;

        &:focus:not(:disabled), &:hover:not(:disabled) {
          border: 1px solid var(--light-primary);
          // box-shadow: 0px 0px 10px 0px #d9d9d9;
        }

        &:disabled {
          background-color: rgba(0, 0, 0, 0.08) !important;
        }

        &::placeholder {
          color: #667085;
        }
      }

      .remove-field {
        color: var(--light-colorError);
        background: none;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
    .icon-input {
      width: 100%;
      height: 100%;
      height: 32px;
      padding: 4px 0px 4px 10px;
      font-size: 14px;
      line-height: 171.429%;
      gap: 12px;
      border-radius: 8px;
      border: 1px solid #D9D9D9;
      outline: none;
      background: var(--light-colorTextLightSolid);
      letter-spacing: 0.12px;
      transition: all 0.1s ease-in-out;
      overflow: hidden;
      &:hover:not(.disabled), &:focus:not(.disabled) {
        border: 1px solid #000;
      }
      &.disabled {
        background-color: rgba(0, 0, 0, 0.08);
        input {
          &:disabled {
            background-color: transparent !important;
          }
        }
      }
      svg {
        width: 16px;
        height: 16px;
      }
      input {
        border: none;
        padding: 0 12px;
        background-color: transparent;
        padding-left: 0;
        &:disabled {
          background-color: transparent;
          color: rgba(0, 0, 0, 0.25);
        }

        &:focus, &:hover {
          border: none !important;
          // box-shadow: 0px 0px 10px 0px #d9d9d9;
        }
      }
    }
    .input-wrapper {
      width: 100%;
      height: 100%;
      height: 32px;
      padding: 4px 12px 4px 0px;
      font-size: 14px;
      line-height: 171.429%;
      gap: 12px;
      border-radius: 8px;
      border: 1px solid #D9D9D9;
      outline: none;
      background: var(--light-colorTextLightSolid);
      letter-spacing: 0.12px;
      transition: all 0.1s ease-in-out;
      justify-content: space-between;
      input {
        border: none;
        padding: 0 12px;
        &:focus:not(:disabled), &:hover:not(:disabled) {
          border: none;
          // box-shadow: 0px 0px 10px 0px #d9d9d9;
        }
      }
      .verify-button {
        button {
          &.verify {
            color: #0169E6;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 200% */
            letter-spacing: 0.12px;
            background-color: transparent;
            margin-left: auto;
          }
        }
      }
    }

    .suffix_text {
      position: absolute;
      right: 16px;
      top: calc(50% - 10px);
      line-height: 20px;
    }

    .prefix_text {
      position: absolute;
      left: 12px;
      top: calc(50% - 10px);
      line-height: 20px;
    }

    p {
      &.error-message {
        color: var(--light-colorError);
        font-size: 14px;
        line-height: 1.5;
      }

      &.total-char-count {
        margin-left: auto;
        display: block;
        text-align: end;
        text-align: right;
        margin-top: 4px;
        font-size: 12px;
        font-weight: 600;
      }
    }

    .suggesion-container {
      max-height: 190px;
      overflow: auto;
      width: 100%;
      background-color: #fff;
      position: absolute;
      top: 58px;
      left: 0;
      border-radius: 8px;
      border: 1px solid #eaecf0;
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      padding: 4px 0;
      z-index: 1001;

      ul {
        padding: 1px 6px;
        display: flex;
        align-items: center;
        flex-direction: column;

        li {
          color: var(--light-colorDarkText);
          padding: 10px 8px 10px 8px;
          width: 100%;
          border-radius: 6px;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;

          &:hover {
            background-color: rgba(0, 0, 0, 0.02);
          }
          &.active {
            background-color: rgba(0, 0, 0, 0.02);
          }
        }
      }
    }
  }

  &.error {
    .input-wrapper {
      border: 1px solid var(--light-colorError) !important;
      input {
        border: none !important;

        &:focus {
          border: none !important;
          // -webkit-box-shadow: 0px 0px 5px 1px rgba(255, 8, 8, 0.2);
          // -moz-box-shadow: 0px 0px 5px 1px rgba(255, 8, 8, 0.2);
          // box-shadow: 0px 0px 5px 1px rgba(255, 8, 8, 0.2);
        }
      }
    }
    .icon-input {
      border: 1px solid var(--light-colorError) !important;
    }
    label {
      input {
        border: 1px solid var(--light-colorError) !important;

        &:focus {
          border: 1px solid red;
          // -webkit-box-shadow: 0px 0px 5px 1px rgba(255, 8, 8, 0.2);
          // -moz-box-shadow: 0px 0px 5px 1px rgba(255, 8, 8, 0.2);
          // box-shadow: 0px 0px 5px 1px rgba(255, 8, 8, 0.2);
        }
      }
    }
    .icon-input {
      input {
        border: none !important;
      }
    }
  }
}