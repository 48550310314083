
.tabs_header {
	margin: 26px 0;
	// padding: 12px 32px 0px 32px;
	ul {
		&.tab_list_items {
			overflow: auto;
			li {
				&.tab_item {
					button {
						color: rgba(0, 0, 0, 0.65);
						font-family: "SF Pro Text";
						font-size: 12px;
						font-style: normal;
						font-weight: 600;
						line-height: 1;
						border-radius: 14px;
						border: 1px solid #E8ECF2;
						padding: 12px 20px;
						background-color: #FFF;
						min-width: 90px;
						white-space: nowrap;

						&.more_button {
							span {
								overflow: hidden;
								color: #000;
								text-overflow: ellipsis;
								font-size: 12px;
								font-style: normal;
								font-weight: 700;
								line-height: normal;
							}
							svg {
								width: 8px;
								height: 8px;
							}
						}
						span {
							max-width: 50px;
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
							display: block;
						}
						&:hover {
							background-color: #F1F4F9;
						}
					}
					&.active {
						button {
							border: 1px solid #000;
							background: #000;
							color: #FFF;
						}
					}
				}
			}
		}
	}
}