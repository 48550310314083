.product-settings-page {
    padding-top: 0;
    margin-top: 0px !important;
    .product-settings-header {
        margin-bottom: 24px;
        h3 {
            .title-3 {
                color: #000;
                font-family: "Plus Jakarta Sans";
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 8px;
            }
        }
        p {
            &.caption {
                color: #344056;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
    .product-settings-body {
        .product-settings-table {
            .table {
                border: 1px solid #F2F2F7;
                overflow: hidden;
                .table-header {
                    background-color: #F9FAFB;
                    border-bottom: 1px solid #F2F2F7;
                    p {
                        &.table-heading {
                            color: var(--Colors-Neutral-colorTextBase, #000);
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 18px; /* 150% */
                            padding: 12px 24px;
                        }
                    }
                }
                .table-body {
                    .table-row {
                        height: 72px;
                        border-bottom: 1px solid #F2F2F7;
                        padding: 12px 24px;
                        
                        &:last-of-type {
                            border-bottom: none;
                        }
                        p {
                            &.table-data {
                                color: var(--Colors-Neutral-colorTextBase, #000);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 22px; /* 157.143% */
                            }
                        }
                    }
                }
            }
            .no-data-wrapper {
                height: 200px;
            }
        }
    }
}