.auth-bg-images-container {
  span {
    &.bg1 {
      position: fixed;
      top: 0%;
      right: 10%;
      filter: blur(80px);
      object-fit: contain;
      z-index: -2;
    }
    &.bg2 {
      position: fixed;
      top: 0%;
      left: -10%;
      opacity: 0.7;
      filter: blur(80px);
      object-fit: contain;
      z-index: -2;
    }
    &.bg3 {
      position: fixed;
      top: 5%;
      left: 20%;
      filter: blur(80px);
      object-fit: contain;
      z-index: -2;
    }
    &.bg4 {
      position: fixed;
      top: 15%;
      right: 5%;
      opacity: 0.8;
      filter: blur(80px);
      object-fit: contain;
      z-index: -2;
    }
    &.glass-overlay {
      position: fixed;
      width: 100%;
      height: 100vh;
      left: 0;
      top: 0;
      background: rgba(223, 221, 234, 0.85);
      backdrop-filter: blur(100px);
      z-index: -1;
    }
  }
}
.main-container {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  // align-items: center;

  // Header
  .header-container {
    align-items: center;
    .logo-container {
      align-items: center;
      gap: 14.5px;

      .title {
        color: #101729;
        text-align: center;
        font-size: 41.889px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 2.094px;
      }
    }

    .get-help-text {
      color: #465468;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      svg {
        width: 22px;
        height: 22px;
        circle {
          stroke: #465468;
        }
        path {
          transition: all 0.2s ease-in-out;
          stroke: #465468;
        }
      }

      .dark-theme & {
        color: var(--dark-colorTextTertiary);
      }

      &:hover {
        color: var(--light-primary);
        svg {
          path {
            stroke: var(--light-primary);
          }
        }

        .dark-theme & {
          color: var(--dark-primary);
        }
      }
    }
  }

  // Footer
  .footer-container {
    .safe-box {
      gap: 10px;
      svg {
        path {
          fill: #009656;
        }
      }

      .text-box {
        align-items: flex-start;
        gap: 5px;
        span {
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          &.secure-text {
            color: #000000;
          }
        }
      }
    }

    .copyright-text {
      color: var(--light-colorTextTertiary);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 8px;

      .dark-theme & {
        color: var(--dark-colorTextTertiary);
      }
    }
  }

  .auth-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 150px);
    overflow: auto;

    .auth-card {
      display: flex;
      border-radius: 12px;
      border: 1.5px solid var(--light-colorTextLightSolid);
      background: var(--light-lightColor40);
      backdrop-filter: blur(2px);
      flex-direction: row;
      padding: 10px;

      .dark-theme & {
        background: var(--dark-lightColor40);
        border: 1.5px solid var(--dark-colorTextLightSolid);
      }
    }

    .white-box {
      padding: 48px;
      border-radius: 12px;
      border: 1px solid var(--light-colorBorderSecondary);
      background: var(--light-colorBgContainer);
      overflow: hidden;

      .dark-theme & {
        border: 1px solid var(--dark-colorBorderSecondary);
        background: var(--dark-colorBgContainer);
      }

      input {
        color: var(--light-primary);
      }
    }

    .wp-number-box {
      max-width: 495px;
      width: 495px;
      height: 450px;
      display: flex;
      justify-content: center;
      align-items: center;

      .wp-content {
        .wp-text {
          color: var(--light-primary);
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 44px; /* 137.5% */
          letter-spacing: -0.64px;
          span {
            color: var(--light-primary);

            .dark-theme & {
              color: var(--dark-primary);
            }
          }
        }
        .wp-login-form {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 12px;

          button {
            width: 100%;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            color: #fff;
          }
        }
        .input-box {
          width: 100%;
          border-bottom: 1px solid var(--light-colorBorder);
          display: flex;
          align-items: center;
          padding: 12px;
          gap: 12px;
          transition: all 0.2s ease;
          position: relative;

          svg {
            max-width: 24px;
            height: 20px;
          }

          &:visited {
            border-color: var(--light-primary);
          }

          &:focus {
            border-color: var(--light-primary);
          }

          &::after {
            display: block;
            content: '';
            border-bottom: solid 1px var(--light-colorError);
            transform: scaleX(0);
            transition: transform 250ms ease-in-out;
            width: 100%;
            position: absolute;
            bottom: -1px; // bottom according to border height
            left: 0;
          }
          &.error {
            &::after {
              transform: scaleX(1);
            }
          }
          .dark-theme & {
            border-bottom: 1px solid var(--dark-colorBorder);
          }

          .input-field {
            color: var(--light-colorText);
            width: 100%;
            border: none;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */

            .dark-theme & {
              color: var(--dark-colorText);
            }
          }
          .input-field:focus-visible {
            border: none;
            outline: none;
          }
        }

        .button {
          width: 100%;
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }

    .carousel-container {
      width: 520px;
      height: 450px;

      li {
        button {
          &::before {
            content: "";
          }
        }
      }

      .ant-carousel,
      .slick-slider {
        height: 100%;
      }

      .carousel-card {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 450px;
        text-align: center;
        .content-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 32px;
          align-self: stretch;
          justify-content: center;
          width: 434px;

          .review-text {
            color: var(--light-colorText);
            font-size: 21px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;

            .dark-theme & {
              color: var(--dark-colorText);
            }
          }

          .user-details-box {
            display: flex;
            flex-direction: column;
            align-items: center;

            .user-content {
              display: flex;
              flex-direction: column;
              gap: 4px;
            }

            .user-profile {
              width: 64px;
              height: 64px;
              border-radius: 999px;
            }

            .reviewer-name {
              color: var(--light-colorText);
              font-size: 17px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;

              .dark-theme & {
                color: var(--dark-colorText);
              }
            }

            .designetion {
              color: var(--light-colorTextTertiary);
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;

              .dark-theme & {
                color: var(--dark-colorTextTertiary);
              }
            }
          }
        }
      }
    }

    // Otp section css
    .otp-container {
      text-align: center;
      transition: all 0.5s ease-in-out;
      height: 450px;
      .title-2 {
        margin-bottom: 12px;
      }
      // .otp-sent-text {
      //   margin-bottom: 48px;
      // }
      div {
        .otp-input {
          text-align: center;
          width: 72px !important;
          height: 90px !important;
          border-radius: var(--Border-Radius-borderRadius, 13px);
          border: 1px solid var(--light-colorBorder);
          margin: 48px 0;
          font-size: 21px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
          &.red-border {
            border: 1px solid var(--light-colorError);
            color: var(--light-colorError);

            .dark-theme & {
              border: 1px solid var(--dark-colorError);
              color: var(--dark-colorError);
            }
          }

        
          &:focus, &:hover {
            border-color: var(--light-primary);
            outline: none;

            .dark-theme & {
              border-color: var(--dark-primary);
              outline: none;
            }
          }

          &.green-border {
            border-color: var(--light-colorSuccess);
            outline: none;
            color: var(--light-colorSuccess);
          }


          &:hover {
            .dark-theme & {
              border-color: var(--dark-primary);
            }
          }

          &.error {
            border-color: var(--light-colorError);
            outline: none;
            color: var(--light-colorError);
          }
        }
      }
      .otp-sent-text {
        .change-number {
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          background-color: transparent;
          color: #0169E6;

          .dark-theme & {
            color: var(--dark-primary);
          }
        }
      }
      .resend-text {
        button {
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          background-color: transparent;
          color: #0169E6;

          .dark-theme & {
            color: var(--dark-primary);
          }
        }
      }
      .otp-error-text {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        color: var(--light-colorError);
        margin-bottom: 16px;

        .dark-theme & {
          color: var(--dark-colorError);
        }
      }
    }

    // Verified Box
    .verify-box-container {
      text-align: center;
      width: 384px;
      .title-2 {
        margin-top: 24px;
        margin-bottom: 12px;
      }

      .welcome-text {
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #344054;
      }

      .primary {
        margin-top: 32px;
        width: 100%;
      }
    }
  }
}
