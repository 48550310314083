.profile-settings-page {
  .input-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .wh-heading {
      color: var(--light-colorText);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }

    .custom-input-container {
      position: relative;
      display: flex;
      align-items: center;
      border: 1px solid var(--light-colorBorder);
      border-radius: 8px;
      padding: 4px 8px;
      transition: border-color 0.3s, 
      box-shadow 0.3s;
      height: 32px;
      background-color: rgb(255, 255, 255);
      gap: 8px;

      svg {
        width: 16px;
        height: 16px;
      }

      .wh-action-btn {
        color: var(--light-primary);
        background-color: transparent;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    .ant-input-outlined {
      &:focus,
      &:focus-within {
        border: none;
        outline: none !important;
      }
    }

    // .custom-input-container:hover {
    //   border: 1px solid var(--light-primary);
    // }

    .custom-input-container:focus-within {
      border-color: var(--light-primary);
      // box-shadow: 0 0 0 3px rgba(1, 105, 230, 0.1);
    }

    .whatsapp-icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    .custom-input {
      flex: 1;
      border: none;
      outline: none;
      box-shadow: none;
      padding: 0;
    }

    .custom-input {
      background-color: transparent;
      .ant-input {
        border: none;
        box-shadow: none !important;
        &::placeholder {
          color: #00000040;
        }
      }
      &.error {
        .ant-input {
          border: 1px solid red;
        }
      }
    }

    .custom-input .ant-input:focus {
      border: none;
      box-shadow: none !important;
    }
  }

  .first-last-name-container {
    padding-bottom: 16px;
    border-bottom: 1px solid var(--light-colorBorder);
    .button {
      button {
        height: 30px;
        border-radius: 8px;
      }
    }
  }

  .profile-setting-card-container {
    align-items: center;
  }

  .blank-space {
    min-width: 62.94px;
  }

}
.phone-number-alert-popup {
  h2 {
    &.title-2 {
      color: #000;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 100% */
      margin-bottom: 16px;
    }
  }
  p {
    &.caption {
      color: #344056;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
