@use "./mixins.scss";
@import "variables.scss";
@import "./components/style.scss";
@import "./pages/style.scss";
@import "./pages/auth.scss";

:root {
	@include generate-color-vars($light-colors, "light");
	@include generate-button-colors-vars($light-buttons-colors, "light-button");
}

.dark-theme {
	@include generate-color-vars($dark-colors, "dark");
	@include generate-button-colors-vars($dark-buttons-colors, "dark-button");
}

.page-loader {
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10000;
	left: 0;
	top: 0;
	&::after {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		// background-color: #34405450;
	}
}

button {
	&.primary {
		display: flex;
		padding: 0px var(--Components-Button-Component-paddingInlineLG, 15px);
		// flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 8px;
		background: var(--light-button-colorPrimary);
		color: var(--light-colorTextLightSolid);
		/* Button effect */
		// box-shadow: 0px 2px 5px 0px rgba(255, 255, 255, 0.15) inset,
		//   0px -8.759px 31.627px 0px rgba(255, 255, 255, 0.15) inset,
		//   0px -5.255px 17.517px 0px rgba(255, 255, 255, 0.15) inset;
		// backdrop-filter: blur(6.130982398986816px);
		&:disabled {
			opacity: 0.5;
			cursor: default;

			.ant-spin-dot-holder {
				.ant-spin-dot {
					&.ant-spin-dot-spin {
						color: #fff;
					}
				}
			}
		}
	}
	&.large {
		height: 44px;
	}
	&.large40 {
		height: 40px;
	}
	&.text {
		background-color: transparent;
		line-height: 1.5;
		transition: all 0.2s ease-in-out;
		color: #000000;
		svg {
			path {
				transition: all 0.2s ease-in-out;
				fill: #000000;
			}
		}
	}
	&.secondary {
		background: #fff;
		border: 1px solid var(--light-colorBorder);
		color: var(--light-colorText);
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;

		&:disabled {
			cursor: default;
		}
	}
	&.bg-light {
		color: #344054;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px; /* 142.857% */
		display: flex;
		padding: 10px 14px;
		justify-content: center;
		align-items: center;
		gap: 4px;
		border: 1px solid #d0d5dd;
		background: #ffffff;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	}
	&.total-count-button {
		background-color: #eaecf0;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 7px 7.64px;
		border-radius: 14px;
		border: 1px solid var(--light-colorBorder);
		background: rgba(0, 0, 0, 0.04);
		backdrop-filter: blur(2px);
		color: var(--Color-Neutral-Text-colorTextLabel, rgba(0, 0, 0, 0.65));
		text-align: center;
		font-family: "SF Pro Text";
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
	}
	&.primary-text {
		color: var(--light-button-colorPrimary);
		background-color: transparent;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 22px; /* 183.333% */
	}
}

.transparent-card {
	display: flex;
	border-radius: 12px;
	// border: 1.5px solid var(--light-colorTextLightSolid);
	background: var(--light-lightColor40);
	backdrop-filter: blur(20px);
	flex-direction: row;
	padding: 0px !important;

	.dark-theme & {
		background: var(--dark-lightColor40);
		border: 1.5px solid var(--dark-colorTextLightSolid);
	}
}

input {
	color: var(--light-primary);
}

.new-chip {
	min-width: 14px;
	// height: 17px;
	padding: 4px 4px;
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 14px;
	background: var(--light-button-colorError);
	text-align: center;
	color: #fff;
	font-size: 10px;
	font-style: normal;
	font-weight: 500;
	line-height: 10px;
}

.char-count {
	color: var(--light-colorText);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 166.667% */
	letter-spacing: 0.12px;
}

.require-icon {
	color: var(--light-colorError) !important;
	font-size: 14px !important;
}

.ant-drawer-content-wrapper {
	padding: 8px;
	box-shadow: none !important;
}

.switch-style {
	&.ant-switch {
		min-width: 39.484px;
	}
	&.ant-switch.ant-switch-checked {
		background-color: #34c759;
	}
	&.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
		background-color: #34c759;
	}
}

.text-end {
	text-align: end;
}

.delete-box {
	display: none;
	padding: 4px;
	border-radius: 4px;
	width: 24px;
	height: 24px;
	&:hover {
		background: #fff;
		cursor: pointer;
	}
}

.error-message {
	color: var(--light-colorError);
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 1.5;
}

.center {
	text-align: center;
}

.overflow-auto {
	overflow: auto;
}

// .ant-input-outlined {
//   border: 1px solid var(--light-colorBorder);
//   &:hover {
//     border: 1px solid var(--light-colorBorder);
//   }
//   &:focus,
//   &:focus-within {
//     border: 1px solid var(--light-primary);
//     // outline: 3px solid rgba(1, 105, 230, 0.1) !important;
//   }
// }

.gray-text {
	color: #33333350;
}

.ant-table {
	table {
		border: 1px solid #e4e7ec !important;
		border-bottom: none !important;
		height: 100%;
	}

	th {
		color: #475467;
		background-color: #f9fafb !important;
		padding: 12px 8px;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
	}

	td {
		&:first-child {
			padding: 6px 16px;
		}
		padding: 16px 8px;
		border-bottom: 1px solid #e4e7ec !important;
	}
}

.icon-button {
	background-color: transparent;
	width: 32px;
	height: 32px;
	svg {
		width: 100%;
		height: 100%;
	}
	img {
		width: 100%;
		height: 100%;
	}
}

.info-heading-title {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	font-family: "Plus Jakarta Sans";
	color: #111029;
	font-size: 48px;
	font-style: normal;
	font-weight: 700;
	line-height: 83.444px; /* 173.841% */
	letter-spacing: -1.55px;
}

.ant-spin-dot-holder {
	.ant-spin-dot {
		&.ant-spin-dot-spin {
			color: var(--light-primary);
		}
	}
}

.m-auto {
	margin: auto;
}

.ant-picker-outlined {
	&:hover {
		border-color: #000000;
	}
	&:focus,
	&:focus-within {
		border-color: #000000;
		box-shadow: none;
	}
}

.scroll-visible {
	&::-webkit-scrollbar {
		width: 8px;
	}
	
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
		border-radius: 10px;
	}
	
	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.09);
	}
}

input[type="checkbox"] {
	min-width: 15px;
	min-height: 15px;
	width: 15px;
	height: 15px;
	position: relative;
	cursor: pointer;
	border-color: #D0D5DD;
	&:checked {
		&::before {
			content: "";
			position: absolute;
			left: -0.5px;
			width: 16px;
			height: 16px;
			top: -0.5px;
			background-color: #0169E6;
			border-radius: 4px;
			cursor: pointer;
		}
		&::after {
			content: "";
			position: absolute;
			left: 1.6px;
			top: 6.5px;
			background-color: #fff;
			border-radius: 4px;
			transform: rotate(-45deg);
			background: #fff;
			width: 3px;
			height: 3px;
			box-shadow:
				2px 0 0 #fff,
				4px 0 0 #fff,
				4px -2px 0 #fff,
				4px -4px 0 #fff,
				4px -6px 0 #fff,
				4px -8px 0 #fff,
				4px -10px 0 #fff;
			transform: rotate(45deg);
			cursor: pointer;
		}
	}
}

@include mixins.gap(16);
@include mixins.padding(100);
@include mixins.title(5);
@include mixins.BorderRadius(100);
@include mixins.FontWeight(9);
