.altUnit-modal-container {

  .alt-content{
    margin-top: 24px;
    input {
      padding-right: 25px !important;
    }
  }

  .footer{
    margin-top: 32px;
  }
}
