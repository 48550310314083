.order-comment-container {
	width: 100%;
	max-width: 400px;
	min-width: 400px;
	height: calc(100vh - 238px);
	max-height: calc(100vh - 238px);
	margin-top: auto;

	.comment-heading {
		color: var(--light-colorText);
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		// padding-top: 34px;
	}

	.main-chat-container {
		border-radius: 12px;
		border: 1px solid #f0f0f0;
		background: #fff;
		// max-height: calc(100vh - 265px);
		max-height: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
}

.no-comment-container {
	background-image: url("../../svg/noCommentBg.svg");
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	// background-size: cover;
	background-position: center;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	span {
		position: relative;
		top: 70px;
	}
}
