.stock-dashboard-page {
	padding: 16px 0 0 0;
	height: 100%;
	overflow: auto;
	.stock {
		&__header {
			margin-bottom: 10px;
			.left_container {
				h2 {
					&.page__title_heading {
						color: #000;
						font-size: 24px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
						margin-bottom: 10px;
					}
				}
			}
			.right_container {
				.button {
					button {
						svg {
							path {
								stroke: black;
								stroke-opacity: 1;
								stroke-width: 1;
							}
						}
					}
				}
			}
		}

		&__products_table {
			height: calc(100vh - 100px);
			position: sticky;
			top: 0;
		}
	}
}
