@import './statCard.scss';
@import './requestBox.scss';
@import './dropdown.scss';
@import './revenueChart.scss';
@import './completeProfile.scss';
@import './connectionCard.scss';
@import './recentActivity.scss';
@import './orderOverview.scss';
@import './orderSummary.scss';
@import './salesOverviewChart.scss';
@import './mostOrderedProduct.scss';
@import './orderFrequency.scss';
@import './productAnalysis.scss';
@import './productInfoCard.scss';
@import './regionSales.scss';
@import './listofCustomers.scss';
@import './getPremium.scss';
@import './userDashboardScreen.scss';

.dashboard-page-container {
  overflow: auto;
  height: 100%;
  padding-bottom: 30px;
  .dashboard-header {
    margin-bottom: 32px;
    // margin-top: 32px;
    .header {
      &__text {
        h2 {
          &.title-2 {
            color: var(--colors-text-text-primary-900, #101828);
            // font-family: "Plus Jakarta Sans";
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: 38px; /* 126.667% */
          }
        }
        p {
          &.caption {
            color: #465468;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            margin-top: 4px;
          }
        }
      }
    }
    .requests {
      p {
        &.error {
          color: #ed1a0b;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      &__items {
        position: relative;
        .request {
          &__item {
            width: 44px;
            height: 44px;
            min-width: 44px;
            max-width: 44px;
            border-radius: 100%;
            overflow: hidden;
            border: 1px solid #e4e7ec;
            background-color: #f2f2f7;
            cursor: pointer;
            &:not(:first-of-type) {
              position: absolute;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }
    }
  }
  .stat-cards {
    &__container {
      .stat-card {
        padding: 10px 20px;
        display: flex;
        gap: 20px;
        border-radius: 14px;
        border: 1px solid #f2f2f7;
        background: var(--White, #fff);
      }
    }
  }
  .dashboard {
    &__charts {
      margin-top: 24px;
      .left__container {
        max-width: calc(100% - 490px);
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
      .right__container {
        max-width: 550px;
        width: 100%;
        @media (max-width: 1440px) {
          max-width: 470px;
        }
        .dark-box {
          &__container {
            background-color: #000;
            width: 100%;
            height: 100%;
            border-radius: 14px;
            overflow: hidden;
          }
        }
      }
    }
  }
  .dashboard-dropdown {
    .dropdown {
      //   min-width: 92px;
      width: fit-content;
      background-color: #f1f4f9;
      border: 0.5px solid #e4e7ec;
      border-radius: 8px;
      position: relative;
      &__label {
        position: relative;
        padding: 7px 10px;
        height: 26px;
        justify-content: space-between;
        gap: 10px;
      }
      &__value {
        color: var(--Primary, #000);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
      }
      .options-container {
        border: 1px solid #e4e7ec;
        background-color: #fff;
        border-radius: 14px;
        z-index: 2000;
        width: 180px;
        padding: 10px;

        &__item {
          border-radius: 14px;
          padding: 10px;
          transition: all 0.1s;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.88);
          &:hover {
            background-color: #f1f4f9;
          }
        }
      }
    }
  }
}

.receivedRequest-Popup {
  max-width: 376px;
  width: 100%;
  background: #fff;
  border: 1px solid #d9d9d9;
  border: 1px solid var(--light-colorBorder);
  border-radius: 12px;
  height: auto;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  padding: 12px;

  .request-box {
    margin-top: 8px;
  }
  .ant-popover-content {
    height: 100%;
    max-height: calc(50vh - 24px);
    overflow: auto;
  }

  .ant-popover-arrow {
    &::after {
      border: 1px solid var(--light-colorBorder);
      background: #fff;
    }
  }
}

.loading-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1999;
}
