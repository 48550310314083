.active-switch-button {
    display: flex;
    align-items: center;
    padding: 4px 6px;
    border-radius: var(--radius-sm, 6px);
    border: 1px solid var(--Color-Neutral-Borders-colorBorderSecondary, #F0F0F0);
    background: var(--Colors-Neutral-colorWhite, #FFF);
    gap: 4px;

    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    span {
        &.switch-light {
            width: 8px;
            height: 8px;
            border-radius: 100%;
            color: var(--colors-text-text-secondary-700, #344054);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
        }
        &.draft {
            background-color: rgba(247, 144, 9, 1);
        }
        &.active {
            background-color: #17B26A;
        }
        &.inactive {
            background-color: #00000026;
        }
    }
    p {
        &.switch-text {
            color: var(--Color-Neutral-Text-colorTextLabel, rgba(0, 0, 0, 0.65));
            text-align: center;
            /* Text xs/Medium */
            font-family: "SF Pro Text";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 150% */
        }
    }
}