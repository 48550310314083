.create-company-profile-page {
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
}
.create-company-profile-card-outer {
  max-width: 700px;
  width: 100%;
  height: fit-content;
  margin: auto;
  border-radius: 12px;
  border: 1.5px solid #fff;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(2px);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  .profile-card {
    border-radius: 12px;
    border: 1px solid var(--Colors-Border-border-tertiary, #edf0f7);
    background: #fff;
    width: 100%;
    padding: 20px;

    .back-to-choose-company {
      background: none;
    }
    .card-header {
      h2 {
        &.title-2 {
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 38px; /* 158.333% */
        }
      }
      p {
        &.caption {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px; /* 175% */
          text-align: center;
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
    form {
      .company-profile-section {
        margin-top: 32px;
        padding-bottom: 24px;
        border-bottom: 1px solid var(--light-colorBorderSecondary);
        .image-upload-input {
          height: 72px;
          // border: 1px solid #F0F0F0;
          margin: 10px 0;
          .file-upload {
            max-height: 72px;
            max-width: 72px;
            &.ant-upload-wrapper {
              max-height: 72px;
              max-width: 72px;
              .ant-upload-list {
                max-height: 72px;
                max-width: 72px;
                .ant-upload {
                  max-height: 72px;
                  max-width: 72px;

                  &:hover {
                    border-color: var(--light-primary);
                  }
                }
                .ant-upload-list-item-container {
                  max-height: 72px;
                  max-width: 72px;
                  .ant-upload-list-item {
                    max-height: 72px;
                    max-width: 72px;
                  }
                }
              }
            }
          }
        }
        h3 {
          &.title-3 {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 171.429% */
            color: #101828;
          }
        }
        p {
          &.caption {
            text-overflow: ellipsis;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 183.333% */
            overflow: hidden;
          }
        }
      }
      .inputs-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 12px;
        gap: 24px;
        .input {
          // max-width: 306px;
          width: 100%;
          height: auto;
          label {
            span {
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 183.333% */
            }
            input {
              padding: 4px 8px;
              height: auto;
              font-style: normal;
              font-weight: 400;
              border-radius: 8px;
              height: 32px;
              // border: 1px solid var(--light-colorBorder);
              outline: none;
              &:focus {
                outline: none;
                border: 1px solid var(--light-primary);
              }
              &:disabled {
                background-color: rgba(0, 0, 0, 0.04);
              }
            }
          }
        }
        .select-input-wrapper {
          // max-width: 306px;
          .ant-select-selector {
            border: 1px solid #d9d9d9;
          }
          .ant-select-status-error {
            .ant-select-selector {
              border: 1px solid var(--light-colorError);
            }
          }

          .company-type-input {
            .ant-select-selection-item-content {
              font-size: 12px;
            }

            .ant-select-selector {
              padding: 1px 25px 1px 8px !important;
            }
          }
        }
      }
      .user-profile-section {
        margin-top: 24px;
        h3 {
          &.title-3 {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 171.429% */
          }
        }
        p {
          &.caption {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 183.333% */
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .user-form {
          display: flex;
          flex-wrap: wrap;
          margin-top: 10px;
          gap: 10px 24px;
          .input {
            max-width: 306px;
            width: 100%;
            height: auto;
            &:last-of-type {
              max-width: 100%;
            }
            label {
              span {
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 183.333% */
              }
              input {
                padding: 4px 8px;
                height: auto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 200% */
                letter-spacing: 0.12px;
                border-radius: 8px;
              }
            }
          }
        }
      }

      .logout-button {
        width: fit-content;
        border-radius: 8px;
        margin-top: 16px;
        button {
          width: 100%;
          padding: 0;
          font-weight: 600;
        }
      }
      .large-button {
        width: 120px;
        border-radius: 8px;
        margin-left: auto;
        margin-top: 16px;
        button {
          width: 100%;
        }

        .ant-spin-dot-holder .ant-spin-dot.ant-spin-dot-spin {
          color: #fff;
        }
      }
    }
  }
}

.img-crop-modal {
  z-index: 1010 !important;
}

.terms-and-conditions-modal-wrapper {
  .ant-modal-mask {
    z-index: 1008;
  }
  .ant-modal-wrap {
    &.ant-modal-centered {
      z-index: 1009;
    }
  }
  .terms-and-conditions-modal {
    .ant-modal-content {
      .ant-modal-title {
        color: #000;
        text-align: center;
        font-family: "Plus Jakarta Sans";
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .ant-modal-body {
        height: 60vh;
        overflow: auto !important;
      }
      .ant-modal-footer {
        place-items: center;
        margin-top: 15px;
        .button {
          width: 100%;
          button {
            width: 100%;
          }
        }
      }
    }
  }
}
.image-cropper {
  .ant-modal-mask {
    z-index: 1001;
  }
}
.logout-popup {
  .ant-modal-mask {
    z-index: 1001;
  }
  .ant-modal-wrap {
    z-index: 1002;
  }
}