.confirmation-popup {
  width: 450px !important;

  .ant-modal-content {
    min-height: fit-content;
  }

  .content-container {
    padding: 20px 20px 0 20px;
    
    img {
      width: 60px;
      height: 60px;
    }
    .icon-box {
      padding: 12px;
      border-radius: 14px;
      border: 0.5px solid #FFBC0D;
      background: rgba(255, 188, 13, 0.10);

      svg {
        width: 24px;
        height: 24px;
        path {
          stroke: var(--light-primary);
          stroke-opacity: 1;
        }
      }
    }

    .confirmation-header {
      color: var(--light-colorText);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    .confirmation-info {
      color: var(--light-colorSecondary);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
  .action-container {
    button {
      width: 100%;
    }
  }
}
