.product {
	&__size_container {
		margin: 24px 0;
		overflow: auto;
		.size_item {
			width: fit-content;
			button {
				color: rgba(0, 0, 0, 0.65);
				font-size: 12px;
				font-style: normal;
				font-weight: 600;
				line-height: 1;
				border-radius: 14px;
				border: 1px solid #e8ecf2;
				padding: 12px 20px;
				background-color: #fff;
				min-width: 90px;
				white-space: nowrap;
				span {
					max-width: 50px;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					display: block;
				}
				&:hover {
					background-color: #f1f4f9;
				}
			}
			&.active {
				button {
					border: 1px solid #000;
					background: #000;
					color: #fff;
				}
			}
		}
	}
	&__image {
		min-width: 60px;
		min-height: 60px;
		width: 60px;
		height: 60px;
		overflow: hidden;
		border-radius: 8px;
		border: 1px solid #f2f2f7;
		background-color: #f2f2f7;
		img {
			width: 100%;
			height: 100%;
			object-fit: 100% 100%;
			object-position: center;
		}
	}
	&__name {
		color: #000;
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: 22px; /* 122.222% */
		max-width: 450px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	&__size {
		color: rgba(0, 0, 0, 0.65);
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 166.667% */
	}
	&__change_button {
		border-radius: 8px;
		border: 1px solid #0169e6;
		padding: 2px 8px;
		gap: 4px;
		background-color: transparent;
		color: #0169e6;

		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 166.667% */
	}
}

.product_list {
	&__container {
		width: 252px;
		max-height: calc(56px * 5);
		overflow: auto;
		border-radius: 14px;
		border: 1px solid var(--Background-colorBgContainer, #fff);
		background: var(--Background-colorBgElevated, #fff);

		/* boxShadowSecondary */
		box-shadow:
			0px 6px 16px 0px rgba(0, 0, 0, 0.08),
			0px 3px 6px -4px rgba(0, 0, 0, 0.12),
			0px 9px 28px 8px rgba(0, 0, 0, 0.05);
	}
	width: 100%;
	transition: all 0.3s ease;
	padding: 8px;
	border-radius: 8px;
	background-color: transparent;
	&:hover {
		background-color: #f1f4f9;
	}
	.image-container {
		min-width: 40px;
		min-height: 40px;
		width: 40px;
		height: 40px;
		border-radius: 8px;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			object-fit: 100% 100%;
			object-position: center;
		}
	}
	p {
		&.product_name {
			color: #000;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 22px; /* 157.143% */
			max-width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}
