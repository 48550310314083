.place-order-access-page {
    .content-container {
        min-height: calc(100vh - 110px);
        gap: 16px;
        img {
            width: 153.31px;
            height: auto;
            object-fit: contain;
        }
        h2 {
            &.title-2 {
                max-width: 278px;
                width: 100%;
                text-align: center;
                color: #000;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
        p {
            &.caption {
                max-width: 278px;
                width: 100%;
                text-align: center;
                color: #64748B;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .placeorder-button {
            height: 38px;
            background-color: #000;
            overflow: hidden;
            margin-top: 8px;
            button {
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 510;
                line-height: normal;
                height: 100%;
                background-color: inherit;
                padding: 10px;
                gap: 10px;
            }
            svg {
                width: 18px;
                height: 18px;
            }
        }
    }
}