.order-frequency {
  padding: 20px 36px 20px 36px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 477px;
  max-width: 477px;
  border-right: 1px solid #e4e7ec;
  width: 100%;

  &__header {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  &__content {
    min-height: 314px;
    max-height: 314px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;

    &--chart {
      width: 100%;
      height: 100%;
      position: relative;

      .order-increase-count {
        position: absolute;
        top: 43%;
        left: 27%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .value {
          color: #000;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
        }

        .order-increase-text {
          color: #465468;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
      }
      
    }

    &--counts {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 17.668px;
    }
  }
}
