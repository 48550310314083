.count_indicator_chip_container {
	padding: 6px 12px;
	text-align: center;
	width: fit-content;
	&.success {
		border-radius: 12px;
		border: 1px solid var(--Colors-Brand-Success-colorSuccessBgHover, #ABEFC6);
		background: var(--Colors-Brand-Success-colorSuccessBg, #ECFDF3);
		p {
			&.count {
				color: var(--Colors-Brand-Success-colorSuccess, #17B26A);
			}
		}
	}
	&.error {
		border-radius: 12px;
		border: 1px solid var(--Color-Error-colorErrorBorder, #FFA39E);
		background: var(--Color-Error-colorErrorBG, #FFF1F0);
		p {
			&.count {
				color: #F5222D;
			}
		}
	}
	p {
		&.count {
			color: #000;
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			text-align: center;
		}
	}
}