.layout {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    // overflow: auto;
    background-color: #F9FAFB;

    .content {
        // margin-top: 10px;
        padding: 0px 10px;
        padding-bottom: 0px;
        height: 100%;
        max-height: calc(100% - 80px);
        overflow: hidden;
    }
}