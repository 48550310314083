//Sub Header
.page {
  padding-top: 30px;
}
.header-container {
  align-content: flex-start;
  gap: 20px 16px;
  align-self: stretch;
  flex-wrap: wrap;
  .add-product, .save-product, .add-vender {
    word-break: keep-all;
    white-space: nowrap;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    min-width: 138px;
  }
  .save-product {
    &:disabled {
     // background-color: ;
     opacity: 1;
     background-color: var(--light-primary);
    } 
   }
  .profile-box {
    align-items: center;
    flex: 1 0 0;

    .profile-title {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 155.556% */
      letter-spacing: 0.18px;
      color: #101828;
    }
  }

  .cart-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.14px;
  }
}