.select_customer_popup {
	.ant-modal-wrap {
		display: flex;
		align-items: center;
	}
	.search-wrapper {
		margin-top: 10px;
		width: calc(100% - 2px);
		margin-left: auto;
		display: flex;
		align-items: center;

		.search-icon-container {
			display: flex;
			align-items: center;
		}
		.clear-icon-container {
			width: 24px;
			height: 24px;
			svg {
				width: 24px;
				height: 24px;
				fill: #A2A3B9;
				path {
					stroke: #A2A3B9;
				}
			}
		}
		.input-wrapper {
			height: 40px;
			border-radius: 14px;
			overflow: hidden;
			&:focus-within:not(:disabled),
			&:hover:not(.is-disabled) {
				border-color: #0169e6;
				background: #fff;
				/* FocusBlue */
				box-shadow: 0px 0px 0px 2px var(--Colors-blue-1, #e6f0fd);
			}
		}

		.start-adornment {
			border-radius: 10px;
			border: 1px solid #e6f1ff;
			background: rgba(1, 105, 230, 0.05);
			margin: 5px;

			svg {
				height: 16px;
				width: 16px;
				path {
					stroke: #0169e6;
				}
			}
		}
	}
	.ant-modal-header {
		height: 140px;
		margin-bottom: 0;
		.selected_area {
			margin-top: 16px;
			.selected_text {
				color: #0169e6;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 22px; /* 157.143% */
			}
			.select_all_input {
				width: fit-content;
				margin-left: auto;
				padding-right: 8px;
				input[type="checkbox"] {
					width: 16px !important;
					height: 16px !important;
					cursor: pointer;
					border-radius: 4px;
				}
				label {
					cursor: pointer;
					color: var(--Colors-Brand-Primary-colorPrimary, #0169e6);
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 22px; /* 157.143% */
					background-color: transparent;
					outline: none;
				}
			}
		}
	}
	.ant-modal {
		max-width: 560px;
		width: 100% !important;
		top: auto;
		padding-bottom: 0;
	}
	.ant-modal-content {
		max-height: 741px;
		height: 90vh;
		padding: 20px 20px 0 20px;
		position: relative;
		border-radius: 14px;
		overflow: hidden;
	}
	.ant-modal-footer {
		margin-top: 0;
		height: 86px;
		position: absolute;
		bottom: 0;
		width: 100%;
		left: 0;
	}
	&__footer {
		padding: 20px;
		background-color: #ffffff;
		.secondary {
			background-color: #f2f2f7;
			color: #000;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			button {
				background-color: inherit !important;
				border: none;
			}
		}
		button {
			width: 100%;
			color: #000;
			font-weight: 500 !important;
		}
	}
	&__sticky_section {
		position: sticky;
		top: 0px;
		background-color: #ffffff;
		z-index: 100;
		padding-top: 20px;
	}
	&__header {
		h3 {
			&.title-3 {
				color: var(--colors-text-text-primary-900, #101828);
				/* Text xl/Semibold */
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: 30px; /* 150% */
			}
		}
		p {
			&.caption {
				color: var(--colors-text-text-tertiary-600, #475467);
				/* SM/Normal */
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 166.667% */
			}
		}
	}
	.ant-modal-body {
		overflow: auto;
		height: 100%;
		padding-bottom: 236px;
	}
	&__body {
		margin-top: 16px;

		ul {
			&.list-items {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				margin-top: 16px;
				.blocked_button {
					border-radius: 9999px;
					border: 1px solid var(--Color-Error-colorErrorBorder, #FFA39E);
					background: #FFF;
					color: #F5222D;
					padding: 8px 12px;
					height: 40px;
					svg {
						path {
							stroke: #F5222D;
							fill: #FFF;
						}
					}
					&:hover {
						border: 1px solid #FFA39E;
						background: #FFF;
						color: #F5222D;
					}
				}
				li {
					&.item {
						transition: all 0.2s ease;
						padding-right: 8px;
						cursor: pointer;
						.connection_item {
							padding-right: 0;
						}
						&:hover {
							background-color: #f9fafb;
							border-radius: 8px;
						}
						label {
							input[type="checkbox"] {
								min-width: 16px !important;
								min-height: 16px !important;
								width: 16px !important;
								height: 16px !important;
								cursor: pointer;
								border-radius: 4px;
								&:checked {
									border-radius: 14px;
									min-width: 14px !important;
									min-height: 14px !important;
									width: 14px !important;
									height: 14px !important;
								}
							}
						}
					}
				}
			}
		}
	}
}
