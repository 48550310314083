.pricing-card-wrapper {
  align-items: end;

  .most-popular {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-primary);
    height: 54px;
    gap: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #fff;
  }
  .pricing-card {
    min-width: 338.67px;
    max-width: 338.67px;
    width: 100%;
    border-radius: 12px;
    border: 1px solid var(--light-colorBorder);
    display: flex;
    padding: 0px 0px 16px 0px;
    flex-direction: column;
    overflow: hidden;
    flex-wrap: wrap;

    &.popular-card {
      border: 2px solid var(--light-primary);
    }
    .card-header-box {
      display: flex;
      padding: 24px 24px 0px 24px;
      flex-direction: column;
      gap: 32px;

      .pricing-header {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .pricing-title {
          color: var(--light-primary);
          font-size: 25px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
        }

        .info-text {
          color: #18181b;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
      }

      .price-box {
        .plan-price {
          color: #18181b;
          font-size: 31px;
          font-style: normal;
          font-weight: 600;
          line-height: 38px;
        }
      }
    }

    .card-content-box {
      padding: 0 16px;
    }

    .action-button {
      width: 100%;
      margin: 40px 0;

      &.custom-button {
        background-color: #fff;
        border: 1px solid var(--light-primary);
        color: var(--light-colorText);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }

    .plan-details-box {
      padding: 0 8px;
      .details-header {
        color: #18181b;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
      }
      .details-list {
        display: flex;
        align-items: center;
        gap: 16px;
        margin: 16px 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
}
