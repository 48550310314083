.product_details_dashboard {
	&__page {
		padding-top: 32px;
		overflow: auto;
		height: 100%;
	}
	&__container {
		.details {
			&__header {
				.product {
					&__container {
					}
					&__image {
						min-width: 61px;
						min-height: 61px;
						width: 61px;
						height: 61px;
						overflow: hidden;
						border-radius: 4px;
						img {
							width: 100%;
							height: 100%;
							object-fit: 100% 100%;
							object-position: center;
						}
					}
					&__actions_container {
						.stock_button {
							button {
								height: 46px;
								border-radius: 12px;
								svg {
									stroke: #000;
								}
							}
						}
						.export_button, .alert_button {
							button {
								height: 46px;
								border-radius: 14px;
								background: #F2F2F7;
								padding: 10px;
								svg {
									path {
										stroke: #000;
									}
								}
							}
						}
					}
				}
			}
		}
		.product_size_container {
			margin-top: 32px;
			overflow: hidden;
			width: 100%;
			ul {
				&.product_sizes_list {
					width: 100%;
					overflow: auto;
					li {
						&.size_list_item {
							button {
								&.size_button {
									background-color: #FFF;
									padding: 8px 12px;
									border-radius: 14px;
									border: 1px solid #000;
									transition: all 0.3s ease;
									color: rgba(0, 0, 0, 0.65);
									font-size: 12px;
									font-style: normal;
									font-weight: 600;
									line-height: normal;
									&:hover {
										background-color: #000;
										color: #FFF;
									}
								}
							}
						}
					}
				}
			}
		}
		.product_details_card {
			margin-top: 32px;
			.card_wrapper {
				border-radius: 14px;
				border: 1px solid #F2F2F7;
				background: var(--White, #FFF);
				padding: 10px 20px;
				.icon_container {
					border-radius: 14px;
					border: 0.5px solid #FFBC0D;
					background: rgba(255, 188, 13, 0.10);
					min-width: 44px;
					min-height: 44px;
					height: 44px;
					width: 44px;
					display: flex;
					align-items: center;
					justify-content: center;
					svg {
						width: 24px;
						height: 24px;
					}
				}
				.text_container {
					h3 {
						&.title-3 {
							color: var(--Primary, #000);
							font-family: "Plus Jakarta Sans";
							font-size: 28px;
							font-style: normal;
							font-weight: 600;
							line-height: 150%; /* 42px */
						}
					}
					p {
						&.text {
							color: #465468;
							font-size: 16px;
							font-style: normal;
							font-weight: 500;
							line-height: 24px; /* 150% */
						}
					}
				}
			}
		}
		.dashboard_analytics_section {
			position: relative;
			height: 100%;
			width: 100%;
			margin-top: 32px;
			.analytics_section {
				width: 100vw;
				max-width: calc(100vw - 749px);
				position: sticky;
				top: 0;
				max-height: 480px;
				height: 100%;
			}
			.sticky_section {
				width: 749px;
				max-width: 749px;
				height: 100%;
				padding-bottom: 16px;
				.table_section {
					&__title {
						h3 {
							&.title-3 {
								color: #000;
								font-size: 20px;
								font-style: normal;
								font-weight: 600;
								line-height: 28px; /* 140% */
							}
						}
					}
					&__container {
						margin-top: 16px;
						.accordian {
							&__list {
								border: 1px solid #F2F2F7;
								border-radius: 24px;
								overflow: auto;
							}
							&__container {
								
							}
							&__header {
								padding: 16px 20px;
								border-bottom: 1px solid #F2F2F7;

							}
							&__title_text {
								color: #000;
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								line-height: 20px; /* 142.857% */
							}
							&__toggle_button {
								width: 16px;
								height: 16px;
								background-color: transparent;
							}
							&__body {
								.ant-table-wrapper {
									table {
										border-radius: 0px;
										border: none !important;
										border-top: 1px solid #e4e7ec !important;
										.ant-table-thead {
											tr {
												.ant-table-cell {
													height: 44px;
													padding: 8px 16px;
													color: #000;
													font-size: 12px;
													font-style: normal;
													font-weight: 500;
													line-height: 18px; /* 150% */
													&:first-of-type {
														padding-left: 24px;
														border-start-start-radius: 0px;
													}
													&:last-of-type {
														padding-right: 24px;
														border-end-end-radius: 0px;
														text-align: end;
														text-align: right;
													}
												}
											}
										}
										.ant-table-tbody {
											tr {
												.ant-table-cell {
													&:nth-of-type(2) {
														border-bottom: 1px solid #F2F2F7;
														background: rgba(236, 253, 243, 0.30);
													}
													&:nth-of-type(3) {
														border-bottom: 1px solid #F2F2F7;
														background: rgba(255, 241, 240, 0.30);
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
				.dashboard-dropdown {
					.dropdown {
					  //   min-width: 92px;
					  width: fit-content;
					  background-color: #f1f4f9;
					  border: 0.5px solid #e4e7ec;
					  border-radius: 8px;
					  position: relative;
					  &__label {
						position: relative;
						padding: 7px 10px;
						height: 26px;
						justify-content: space-between;
						gap: 10px;
					  }
					  &__value {
						color: var(--Primary, #000);
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 150%; /* 18px */
					  }
					  .options-container {
						border: 1px solid #e4e7ec;
						background-color: #fff;
						border-radius: 14px;
						z-index: 2000;
						width: 180px;
						padding: 10px;
				
						&__item {
						  border-radius: 14px;
						  padding: 10px;
						  transition: all 0.1s;
						  font-size: 14px;
						  font-style: normal;
						  font-weight: 600;
						  line-height: 22px;
						  color: rgba(0, 0, 0, 0.88);
						  &:hover {
							background-color: #f1f4f9;
						  }
						}
					  }
					}
				}
			}
		}
	}
}