.subscription-details-container {
    .message-toaster {
        padding: 8px 12px;
        border-radius: 12px;
        border: 1px solid var(--Color-Error-colorErrorBorder, #FFA39E);
        background: var(--Color-Error-colorErrorBG, #FFF1F0);
        margin: 0 0 24px 0;
        .icon {
            width: 24px;
            height: 24px;
        }
        .message-text {
            span {
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 111.111% */
                &.title {
                    color: #F5222D;
                    margin-right: 4px;
                }
                &.date {
                    color: #000;
                }
            }
        }
        .button {
            &.success {
                button {
                    background-color: #009656;
                    color: #FFF;
                    border-radius: 14px;
                }
            }
            button {
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 142.857% */
                padding: 6px 10px;
                height: 30px;
            }
        }
    }
    section {
        &.plan-details-section {
            .left-section {
                max-width: 50%;
                width: 100%;
                background-color: #F9FAFB;
                padding: 20px;
                border-radius: 24px;
                .header-container {
                    h3 {
                        &.title-3 {
                            color: var(--colors-text-text-primary-900, #101828);
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 28px; /* 155.556% */
                        }
                    }
                    span {
                        &.joining-date {
                            color: var(--sementics-color-fg-default, #18181B);
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 14px; /* 116.667% */
                            background: rgba(255, 188, 13, 0.15);
                        }
                    }
                    .outline {
                        border: 1px solid #000;
                        button {
                            color: #000;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px; /* 166.667% */
                            background-color: transparent;
                            width: 100%;
                            padding: 2px 10px;
                        }
                    }
                }
                .body-container {
                    margin: 24px 0 24px 0;
                    .plan {
                        img {
                            width: 50px;
                            height: 50px;
                            object-fit: contain;
                            object-position: center;
                        }
                        .plan-data {
                            h3 {
                                &.title-3 {
                                    color: #000;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 20px; /* 111.111% */
                                }
                            }
                            p {
                                &.caption {
                                    margin-top: 8px;
                                    color: var(--sementics-color-fg-default, #18181B);
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 14px; /* 116.667% */
                                }
                            }
                        }
                    }
                    .plan-pricing-details {
                        span {
                            color: var(--sementics-color-fg-default, #18181B);
                            font-style: normal;
                            line-height: 20px; /* 100% */
                            &.price {
                                font-size: 20px;
                                font-weight: 600;
                            }
                            &.small-text {
                                font-size: 12px;
                                font-weight: 400;
                            }
                        }
                        p {
                            &.next-plan-date {
                                margin-top: 8px;
                                color: var(--sementics-color-fg-default, #18181B);
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 14px; /* 116.667% */
                                &.error {
                                    color: #F5222D;
                                }
                            }
                        }
                    }
                }
                .buttons-container {
                    .button {
                        width: 100%;
                        button {
                            width: 100%;
                        }
                        &.primary {
                            &.success {
                                button {
                                    background-color: #009656;
                                    color: #FFF;
                                    border-radius: 14px;
                                }
                            }
                        }
                        &.outlined {
                            border-radius: 14px;
                            overflow: hidden;
                            button {
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 24px; /* 171.429% */
                                height: 100%;
                                background-color: transparent;
                                padding: 8px 16px;
                                width: 100%;
                            }
                            &.success {
                                border: var(--Components-Button-Global-lineWidth, 1px) solid #009656;
                                background-color: transparent;
                                button {
                                    color: #009656;
                                }
                            }
                            &.error {
                                background-color: transparent;
                                border: 1px solid #F5222D;
                                button {
                                    color: #F5222D;
                                }
                            }
                        }
                    }
                }
            }
            .right-section {
                max-width: 50%;
                width: 100%;
                border-radius: 24px;
                border: 1px solid #F2F2F7;
                background: #FFF;
                padding: 20px;
                h3 {
                    &.section-title {
                        color: var(--colors-text-text-primary-900, #101828);
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 28px; /* 155.556% */
                    }
                }
                .section-body {
                    margin-top: 24px;
                    h3 {
                        &.company-title {
                            color: var(--colors-text-text-primary-900, #101828);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 28px; /* 175% */
                            margin-bottom: 4px;
                        }
                    }
                    p {
                        &.address-line, &.gst-number, &.pan-number {
                            color: rgba(0, 0, 0, 0.65);
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 125%; /* 15px */
                        }
                    }
                }
            }
        }
        &.invoice-details-section {
            margin-top: 24px;
            h2 {
                &.title-2 {
                    color: #000;
                    font-family: "Plus Jakarta Sans";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
            p {
                &.caption {
                    margin-top: 8px;
                    color: #344056;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
            .invoice-table {
                margin-top: 24px;
                overflow: hidden;
                border: 1px solid #F2F2F7;
                .table-header {
                    background-color: #F9FAFB;
                    height: 44px;
                    .table-row {
                        height: 100%;
                        .table-head {
                            max-width: 25%;
                            width: 100%;
                            height: 100%;
                            align-content: center;
                            padding: 12px 24px;
                        }
                    }
                }
                .table-body {
                    .table-row {
                        height: 72px;
                        border-bottom: 1px solid #F2F2F7;
                        &:last-of-type {
                            border-bottom: none;
                        }
                        .table-data {
                            max-width: 25%;
                            width: 100%;
                            height: 100%;
                            align-content: center;
                            padding: 12px 24px;
                            a {
                                color: #0169E6;
                                border-bottom: 1px solid #0169E6;
                            }
                            .button {
                                button {
                                    background-color: transparent;
                                    padding: 2px 10px;
                                    border: 1px solid #000;
                                    border-radius: 14px;
                                    height: 32px;
                                    display: flex;
                                    align-items: center;
                                    gap: 8px;
                                    color: #000;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}