.sending-order-modal {
  min-width: 494px;
  position: relative;
  border-radius: 30px !important;

  .ant-modal-content {
    border-radius: 30px;
    padding: 0 !important;

    .content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 36px;
      padding-top: 88px;

      .progress-wrapper {
        width: 100%;
        position: relative;

        .progress-bar {
          width: 100%;
          height: 8px;
          background-color: #009656;
          border-radius: 14px;
          position: relative;

          &::after {
            content: "";
            width: 14px;
            height: 14px;
            position: absolute;
            right: -7px;
            top: -3px;
            border-radius: 100%;
            background-color: #009656;
            display: block;
          }

          &::before {
            content: "";
            width: 30px;
            height: 30px;
            position: absolute;
            right: -15px;
            top: -11px;
            border-radius: 100%;
            background-color: #CCEADD;
            display: block;
          }
        }
      }

      .timer-count-down {
        margin-top: 11px;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #64748B
      }

      .sending-order-text {
        margin: auto;
        margin-top: 27px;
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        color: #000;
      }
    }

    .placed-order-wrapper {
      padding: 16px;

      .order-placed-text {
        color: #000;
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .order-ref-id {
        margin-top: 6px;
        color: #64748B;
        font-size: 12px;
        font-style: normal;
        font-weight: 510;
        line-height: normal;
      }
    }

    .action-button {
      text-align: center;
      padding: 20px;
      font-size: 14px;
      font-style: normal;
      font-weight: 510;
      width: 100%;
      line-height: 14.615px;
      background-color: transparent;
      border-top: 1px solid #D7DEEA;

      &:disabled {
        opacity: 0.8;
        cursor: default;
      }
    }
  }
}