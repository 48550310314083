@import './statusSwitch.scss';

.orders-page {
  height: 100% !important;
  .header-section {
    margin-top: 40px;
  }
  .orders-container {
    height: 100%;
    overflow: auto;
    button {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5;
      svg {
        width: 14px;
        height: 14px;
      }
    }
    .manage-order-heading {
      h2 {
        &.title-3 {
          color: #000;
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          font-family: 'Plus Jakarta Sans';
        }
      }
      p {
        &.caption {
          color: #64748b;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
    }
    .order-card-container {
      padding: 20px 0px;
      display: flex;
      overflow: auto;

      .no-data-wrapper {
        height: 110px !important;
      }

      .order-card {
        min-width: 127px;
        width: 127px;
        max-width: 127px;
        .product-image-box {
          position: relative;

          .product-image-button {
            border: 3px solid #0169e6;
            border-radius: 100%;
            background: transparent;
            // animation: popup 3s ease-in-out infinite;
            .product-image-container {
              width: 70px;
              height: 70px;
              border: 2px solid transparent;
              border-radius: 100%;
              overflow: hidden;
              display: flex;
              align-items: center;
              background-color: #fff;
              justify-content: center;
              .order-image {
                width: calc(100% - 2px);
                height: calc(100% - 2px);
                border-radius: 100%;
                object-fit: cover;
                object-position: center;
              }
              .profile-icon {
                color: var(--light-colorTextTertiary);
                font-size: 29.25px;
                font-style: normal;
                font-weight: 600;
                line-height: 34.5px;
              }

              @media only screen and (min-width: $desktop) {
                width: 100px;
                height: 100px;
              }
              svg {
                width: 24px;
                height: 24px;

                path {
                  fill: #a9a9a9;
                }
              }
            }

            &.isSeen {
              border: 3px solid #cbd5e0;
            }
          }
          .new-label {
            padding: 4px 8px;
            border-radius: 4px;
            background-color: var(--light-colorError);
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            position: absolute;
            // transform: translateY(-5px);
            bottom: -5px;
            height: 15px;
            color: var(--light-colorTextLightSolid);
            z-index: 999;
            // animation: upDown 3s ease-in-out infinite;
            line-height: 15px;
          }
        }

        .company-name {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 127px;
          text-align: center;
        }

        .order-info {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: var(--light-colorSecondary);
        }
      }
    }

    // orders data

    .orders-data-container {
      // padding-bottom: 10px;
      max-height: calc(100vh - 30px);
      height: 100%;
      // height: calc(100% - 218px);
      // gap: 10px;
      // width: 100%;
      // height: 100%;
      // height: calc(100vh - 300px);

      .orders-list-table {
        .ant-table-body {
          overflow: auto !important;
        }
      }

      .caption-amount {
        text-align: end;
      }

      .ant-tabs-content-holder {
      }

      .order-header {
        color: var(--light-colorText);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
      }

      .manage-orders {
        margin-top: 10px;
        border-radius: 12px;
        border: 1px solid #fff;
        width: 100%;
        height: 100%;
        position: sticky;
        top: 0px;
        overflow: hidden;
        // height: 69vh;

        .pagination-wrapper {
          margin-top: 16px;
          .ant-pagination {
            .ant-pagination-item-active {
              border-color: #000000;
              background-color: #000000;
              a {
                color: #ffffff;
              }
            }
            .anticon-double-right,
            .anticon-double-left {
              &.ant-pagination-item-link-icon {
                color: #000000;
              }
            }
            .ant-pagination-item {
              height: 32px;
              width: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            button {
              &.pagination-button {
                background-color: #ffffff;
                height: 32px;
                padding: 8px 20px;
                color: #000000;
                font-weight: 600;
                border: 1px solid #d9d9d9;
                border-radius: 6px;
              }
              &:disabled {
                opacity: 0.6;
                cursor: default;
              }
            }
          }
        }

        .ant-tabs {
          width: 100%;
          position: sticky;
          top: 0;
          height: 100%;
          .ant-tabs-nav {
            gap: 16px;
          }
          .ant-tabs-nav-wrap {
            border-bottom: 1px solid #f2f2f7;
          }
          .ant-tabs-tab-btn {
            a {
              &:hover {
                color: #000000;
              }
            }
          }
          .ant-tabs-ink-bar {
            background: #ffbc0d !important;
          }
        }

        .ant-table {
          .ant-table-content {
          }
          table {
            .ant-table-thead {
              position: sticky;
              top: 0px;
              background-color: inherit;
              z-index: 9999;
            }
          }
        }

        .tab-label {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
          .count {
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--light-colorSecondary);
            border-radius: 100%;
            background: #fff;
            border: 1.032px solid rgba(0, 0, 0, 0.06);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18.581px;
          }
        }

        .ant-tabs-tab {
          padding: 0 20px 12px 20px;
        }
        .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
          margin-left: 16px;
        }
        .ant-tabs-nav {
          // width: fit-content;
          &::before {
            border: none;
          }
        }

        .tabs-right-order-actions {
          .search-bar {
            min-width: 339px;
            height: 36px;
          }
        }

        .date-picker {
          .ant-picker {
            width: 260px;
            height: 36px;
            background-color: #f9fafb;
            border: 1px solid #e7e7e7;
          }
        }
        .ant-tabs-content,
        .ant-tabs-tabpane {
          height: 100%;
        }
        .orders-table {
          height: 100%;
          .ant-table-expanded-row-fixed {
            margin: 0;
            padding: 0;
            width: auto !important;
          }

          .ant-spin-nested-loading,
          .ant-spin-container,
          .ant-table-empty,
          .ant-table-container,
          .ant-table-content {
            height: 100%;
          }
          .ant-table-thead {
            th {
              &.ant-table-cell-scrollbar {
                border-right: 1px solid #e4e7ec;
                border-top-right-radius: 8px;
              }
            }
          }
          .ant-table-body {
            overflow: auto !important;
            table {
              border: 1px solid #e4e7ec;
              border-bottom: none;
              height: 100%;
              border-top: 0px !important;
              border-radius: 0 !important;
            }
          }

          th {
            color: #475467;
            background-color: #f9fafb;
            padding: 12px 8px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }

          td {
            &:first-child {
              padding: 6px 16px;
            }
            padding: 16px 8px;
            border-bottom: 1px solid #e4e7ec;
          }

          .customer-details-box {
            .img-box {
              width: 44px;
              height: 44px;
              min-width: 44px;
              min-height: 44px;
              border-radius: 100%;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              outline: 2px solid #f2f2f7;
              .profile-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }

            .customer-info {
              color: var(--light-colorText);
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
              cursor: pointer;
              width: 100%;
              .user-name {
                color: var(--light-primary);
                font-weight: 600;
              }
            }
          }

          .ordered-items-btn {
            border-radius: 4px;
            border: 1px solid #cbd5e0;
            background: #fff;
            // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            padding: 2px 4px 2px 6px;
            font-weight: 400;
            transition: all 0.2s ease-in-out;
            svg {
              transition: all 0.2s ease-in-out;
              path {
                transition: all 0.2s ease-in-out;
                stroke: #000;
              }
            }
            &:hover {
              background: #000;
              border: 1px solid #000;
              color: #ffbc0d;
              svg {
                path {
                  stroke: #ffbc0d;
                }
              }
            }
            // &:focus {
            //   background: #000;
            //   border: 1px solid #000;
            //   color: #FFBC0D;
            //   svg {
            //     path {
            //       stroke: #FFBC0D;
            //     }
            //   }
            // }
            // &:focus-within {
            //   background: #000;
            //   border: 1px solid #000;
            //   color: #FFBC0D;
            //   svg {
            //     path {
            //       stroke: #FFBC0D;
            //     }
            //   }
            // }
          }
        }
      }
    }
  }
}

.order-item-popover {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 305px 85px 0px rgba(0, 0, 0, 0),
    0px 195px 78px 0px rgba(0, 0, 0, 0.01),
    0px 110px 66px 0px rgba(0, 0, 0, 0.05),
    0px 49px 49px 0px rgba(0, 0, 0, 0.09), 0px 12px 27px 0px rgba(0, 0, 0, 0.1);
  .ant-popover-content {
    .ant-table-container {
      // padding: 10px;
    }
    .ant-table {
      table {
        .ant-table-thead {
          position: sticky;
          top: 0px;
          background-color: inherit;
          z-index: 9999;
        }
      }
    }
  }
  .ant-popover-inner {
    padding: 0 !important;
    border-radius: 8px;
  }

  .order-items-table {
    max-height: 35vh;
    overflow: auto;
    table {
      border: 1px solid var(--light-colorBorderSecondary);
    }

    th {
      color: #475467 !important;
      background-color: #f9fafb !important;
      padding: 12px 8px !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }

    td {
      &:first-child {
        padding: 8px !important;
      }
      padding: 10px 8px !important;
    }

    .product-name-box {
      .product-image-container {
        width: 54px;
        height: 54px;
        min-width: 54px;
        min-height: 54px;
        border-radius: 5.838px;
        border: 1px solid #eaecf0;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        .product-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        svg {
          width: 24px;
          height: 24px;

          path {
            fill: #a9a9a9;
          }
        }
      }
      .product-name {
        color: var(--light-colorText);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        word-wrap: break-word;
        word-break: break-word;
      }
    }
    .serial-number {
      color: #64748b;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      word-wrap: break-word;
      word-break: break-word;
    }
    .unit {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      color: var(--light-colorTextTertiary);
      word-wrap: break-word;
      word-break: break-word;
    }

    .size-text {
      color: var(--light-colorSecondary);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      word-wrap: break-word;
      word-break: break-word;
    }

    .qty-text {
      .quantity {
        color: var(--light-colorText);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
      .orderUnit {
        color: var(--light-colorTextTertiary);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .price-per-unit-text {
      .price {
        color: var(--light-colorText);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
      }
    }

    .subtotal-text {
      color: var(--light-colorText);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }

    .ant-table-cell {
      &::before {
        display: none;
      }
    }
  }
}

@keyframes popup {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.94);
  }
}

@keyframes upDown {
  0%,
  100% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(3px);
  }
}
