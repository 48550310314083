.verify-otp-model { 
  width: 540px !important;
  height: auto !important;
  .otp-container {
    text-align: center;
    transition: all 0.5s ease-in-out;
    height: 408px;
    display: flex;
    align-items: center;
    justify-content: center;
    .title-2 {
      margin-bottom: 12px;
    }
    // .otp-sent-text {
    //   margin-bottom: 48px;
    // }
    div {
      .otp-input {
        text-align: center;
        width: 72px !important;
        height: 90px !important;
        border-radius: var(--Border-Radius-borderRadius, 13px);
        border: 1px solid var(--light-colorBorder);
        margin: 48px 0;
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        &.red-border {
          border: 1px solid var(--light-colorError);
          color: var(--light-colorError);
  
          .dark-theme & {
            border: 1px solid var(--dark-colorError);
            color: var(--dark-colorError);
          }
        }
  
      
        &:focus {
          border-color: var(--light-primary);
          outline: none;
  
          .dark-theme & {
            border-color: var(--dark-primary);
            outline: none;
          }
        }
  
        &.green-border {
          border-color: var(--light-colorSuccess);
          outline: none;
          color: var(--light-colorSuccess);
        }
  
  
        &:hover {
          .dark-theme & {
            border-color: var(--dark-primary);
          }
        }
  
        &.error {
          border-color: var(--light-colorError);
          outline: none;
          color: var(--light-colorError);
        }
      }
    }
    .otp-sent-text {
      .change-number {
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        background-color: transparent;
        color: var(--light-primary);
  
        .dark-theme & {
          color: var(--dark-primary);
        }
      }
    }
    .resend-text {
      button {
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        background-color: transparent;
        color: var(--light-primary);
  
        .dark-theme & {
          color: var(--dark-primary);
        }
      }
    }
    .otp-error-text {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      color: var(--light-colorError);
      margin-bottom: 16px;
  
      .dark-theme & {
        color: var(--dark-colorError);
      }
    }
  }
}
