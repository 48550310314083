.toaster-container {
	padding: 8px;
	background-color: #fff1f0;
	border: 1px solid #ffa39e;
	margin-top: 10px;
	.flex {
		.icon-container {
			.icon {
				width: 24px;
				height: 24px;
				border-radius: 100%;
			}
		}
		.text-container {
			display: flex;
			align-items: center;
			.title-text {
				.title {
					color: var(--Color-Error-colorErrorText, #f5222d);
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 20px; /* 142.857% */
				}
				.description {
					color: rgba(0, 0, 0, 0.88);
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 166.667% */
				}
			}
			.more-texts {
				.label-text {
					color: var(--Color-Error-colorErrorText, #f5222d);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
				}
				.desc-text {
					color: rgba(0, 0, 0, 0.88);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px; /* 157.143% */
					word-break: break-all;
				}
			}
		}
	}
	&.error {
		.flex {
			.icon-container {
				.icon {
					background-color: #f5222d;
				}
			}
		}
	}
}
