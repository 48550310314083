.contact-page-wrapper {
  max-width: 560px;
  width: 100%;
  margin: auto;
  .details-form-section {
    margin-top: 100px;
    .details-form {
      margin-top: 40px;
    }
    .desciption-area {
      color: var(--light-colorText);
      font-family: "SF Pro Text";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;

      textarea {
        border-radius: 8px;
        border: 1px solid var(--light-colorBorder);
        padding: 4px 8px;
        &:focus {
          border: 1px solid var(--light-primary);
          outline: none;
        }
      }
    }

  }
}