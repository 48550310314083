.download-app-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh !important;
    width: 100%;
    .img-container {
        width: 200px;
        height: 200px;
        margin: auto;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .button {
        margin: auto;
    }
}