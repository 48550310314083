.vendor-card-wrapper {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 20px;
    max-width: calc((100% / 5) - 8px);
    width: 100%;
    border-radius: 12px;
    border: 1px solid var(--Color-Neutral-Borders-colorBorder, #d9d9d9);
    transition: all 0.3s ease;
    background: rgba(0, 0, 0, 0.02);
    @media (max-width: 1600px) {
        max-width: calc((100% / 4) - 8px);
    }
    @media (max-width: 1280px) {
        max-width: calc((100% / 3) - 8px);
    }
    button {
        &.more-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: transparent;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            &:focus {
                border-radius: var(--radius-xs, 4px);
                background: #FFF;
            }
        }
    }
    .vendor-card {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .image-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 72px;
            min-width: 72px;
            max-width: 72px;
            height: 72px;
            border-radius: 100%;
            background-color: rgba(0, 0, 0, 0.04);
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
            }

            .profile-icon {
                color: var(--light-colorTextTertiary);
                font-size: 29.25px;
                font-style: normal;
                font-weight: 600;
                line-height: 34.5px; 
            }
        }
        .info-container {
            margin-top: 10px;
            .business-info {
                margin-bottom: 8px;
                h3 {
                    &.business-name {
                        color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
                        /* Base/Strong */
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 22px; /* 157.143% */
                    }
                }
                p {
                    &.vendor-name {
                        color: var(--Color-Neutral-Text-colorTextLabel, rgba(0, 0, 0, 0.65));
                        /* SM/Normal */
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px; /* 166.667% */
                    }
                }
            }
            p {
                &.address-info {
                    color: var(--Color-Neutral-Text-colorTextDescription, rgba(0, 0, 0, 0.45));
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px; /* 150% */
                }
            }
        }
        .buttons-container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 16px;
            gap: 8px;
            .cart-button {
                position: relative;
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                padding: 4px 8px;
                height: 40px;
                border-radius: 8px;
                width: 53px;
                border: var(--Components-Button-Global-lineWidth, 1px) solid
                    var(--Components-Button-Component-defaultBorderColor, #d9d9d9);
                background: var(--Components-Button-Component-defaultBg, #fff);
                span {
                    &.cart-count {
                        position: absolute;
                        min-width: 17px;
                        min-height: 17px;
                        padding: 3.394px;
                        width: fit-content;
                        height: 17px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 17px;
                        background: var(--Colors-Brand-Primary-colorPrimary, #000000);
                        top: 6px;
                        left: 18px;
                        color: var(--Colors-Neutral-colorWhite, #fff);
                        text-align: center;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 15.273px; /* 163.636% */
                    }
                }
            }
            .order-now-button {
                border-radius: 8px;
                border: var(--Components-Button-Global-lineWidth, 1px) solid
                    var(--Components-Button-Component-defaultBorderColor, #d9d9d9);
                background: var(--Components-Button-Component-defaultBg, #fff);
                height: 40px;
                width: 120px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--Components-Button-Component-defaultColor, rgba(0, 0, 0, 0.88));
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; /* 171.429% */
                transition: all 0.2s ease;
                &:hover {
                    background-color: #000000;
                    color: #fff;
                }
            }
        }
    }
    &:hover,
    &:focus {
        background-color: #fff;
        border-color: #000000;
        button {
            &.more-icon {
                position: absolute;
                top: 10px;
                right: 10px;
                background-color: transparent;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                &:hover,
                &:focus {
                    border-radius: var(--radius-xs, 4px);
                    background: #d9d9d950;
                }
            }
        }
    }
}

.vendor-action-modal {
    .ant-popover-inner {
        padding: 0;
    }
    .more-popup-items {
        align-items: flex-start;
        padding: 6px;
        button {
            &.item {
                padding: 9px 10px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;
                border-radius: var(--radius-sm, 6px);
                background: transparent;
                width: 100%;
                color: var(--Color-Neutral-Text-colorText, rgba(0, 0, 0, 0.88));
                /* Base/Strong */
                font-family: "SF Pro Text";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px; /* 157.143% */
                &:hover {
                    background: var(--Color-Neutral-Fill-colorFillQuaternary, rgba(0, 0, 0, 0.02));
                }
            }
            &.remove {
                color: #F5222D;
                svg {
                    path {
                        stroke: #F5222D;
                    }
                }
            }
        }
    }
}