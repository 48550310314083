.stock_moment_trend_chart {
    min-height: 400px;
    width: 100%;
    background-color: #FFF;
    border-radius: 24px;
    overflow: hidden;
    border: 1px solid #F2F2F7;
    position: relative;
    // border: 1px solid var(--light-colorBorder);
	.dashboard-dropdown {
		.dropdown {
		  //   min-width: 92px;
		  width: fit-content;
		  background-color: #f1f4f9;
		  border: 0.5px solid #e4e7ec;
		  border-radius: 8px;
		  position: relative;
		  &__label {
			position: relative;
			padding: 7px 10px;
			height: 26px;
			justify-content: space-between;
			gap: 10px;
		  }
		  &__value {
			color: var(--Primary, #000);
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%; /* 18px */
		  }
		  .options-container {
			border: 1px solid #e4e7ec;
			background-color: #fff;
			border-radius: 14px;
			z-index: 2000;
			width: 180px;
			padding: 10px;
	
			&__item {
			  border-radius: 14px;
			  padding: 10px;
			  transition: all 0.1s;
			  font-size: 14px;
			  font-style: normal;
			  font-weight: 600;
			  line-height: 22px;
			  color: rgba(0, 0, 0, 0.88);
			  &:hover {
				background-color: #f1f4f9;
			  }
			}
		  }
		}
	}
    &__header {
        width: 100%;
        padding: 20px;
        background-color: #FBFBFD;
        border-bottom: 1px solid #F2F2F7;

        &--heading {
            display: flex;
            p {
                &.caption {
                    color: #000;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px; 
                }
            }
        }
        .dashboard-dropdown {
            .options-container {
              right: 0;
            }
        }
    }

    &__box {
        padding: 20px;
        width: 100%;
        h2 {
            &.title-2 {
                color: #000;
                font-size: 36px;
                font-style: normal;
                font-weight: 600;
                line-height: 44px; /* 122.222% */
                letter-spacing: -0.72px;
            }
        }
        button {
            display: flex;
            align-items: center;
            gap: 4px;
            &.button {
                
                &__green {
                    padding: 2px 10px;
                    background-color: #ECFDF3;
                    border: 1px solid #ABEFC6;
                    border-radius: 14px;

                    color: var(--Component-colors-Utility-Success-utility-success-700, #067647);
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 142.857%;
                    &--icon {
                        width: 12px;
                        height: 12px;
                        margin-right: 4px;
                    }
                }
                &__red {
                    padding: 2px 10px;
                    background-color: #FFF1F0;
                    border: 1px solid #FFA39E;
                    border-radius: 14px;

                    color: var(--Component-colors-Utility-Success-utility-success-700, #F5222D);
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 142.857%; /* 20px */
                    svg {
                        rotate: 180deg;
                    }
                    &--icon {
                        width: 12px;
                        height: 12px;
                        margin-right: 4px;

                    }
                }
            }
        }

    }
    .custom-tooltip {
        background-color: #FFBC0D;
        padding: 12px;
        border-radius: 6px;
        color: #000000;
        &__date {
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 18px */
            text-align: center;
        }
        p {
            &.bold {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; /* 24px */
            }
        }
    }
    .recharts-responsive-container {
        min-height: 300px;
    }

    &__chart {
        height: 100%;
        width: 100%;
        padding: 20px;
    }
}