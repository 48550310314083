.low_stock {
    &__page {
        height: 100%;
    }
    &__form_container {
        max-width: 752px;
        width: 100%;
        margin: 30px auto 0 auto;
        height: 100%;
        overflow: auto;
        padding-bottom: 48px;
        .form__header {
            margin-bottom: 24px;
            button {
                &.back_button {
                    background: #EDEDED;
                    padding: 7px;
                    border-radius: 4.364px;
                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
        .form_body {
            background-color: #FFF;
            padding: 32px;
            border-radius: 24px;
            .product {
                &__size_container {
                    margin: 24px 0;
                    overflow: auto;
                    .size_item {
                        width: fit-content;
                        button {
                            color: rgba(0, 0, 0, 0.65);
                            font-family: "SF Pro Text";
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1;
                            border-radius: 14px;
                            border: 1px solid #E8ECF2;
                            padding: 12px 20px;
                            background-color: #FFF;
                            min-width: 90px;
                            white-space: nowrap;
                            &:hover {
                                background-color: #F1F4F9;
                            }
                        }
                        &.active {
                            button {
                                border: 1px solid #000;
                                background: #000;
                                color: #FFF;
                            }
                        }
                    }
                }
                &__image {
                    min-width: 60px;
                    min-height: 60px;
                    width: 60px;
                    height: 60px;
                    overflow: hidden;
                    border-radius: 8px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: 100% 100%;
                        object-position: center;
                    }
                }
                &__name {
                    color: #000;
                    font-family: "SF Pro Text";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 22px; /* 122.222% */
                }
                &__size {
                    color: rgba(0, 0, 0, 0.65);
                    font-family: "SF Pro Text";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 166.667% */
                }
                &__change_button {
                    border-radius: 8px;
                    border: 1px solid #0169E6;
                    display: flex;
                    padding: 2px 8px;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 4px;
                    background-color: transparent;
                    color: #0169E6;
                    text-align: center;
                    font-family: "SF Pro Text";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 166.667% */
                }
            }
            form {
                &.low_stock__form {
                    .stock_count_input {
                        height: 180px;
                        padding: 10px;
                        border-radius: 24px;
                        background: #F9FAFB;
                        margin: 24px 0;
                        p {
                            &.caption {
                                color: #000;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }
                        }
                        input {
                            color: #000;
                            font-size: 48px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            text-align: right;
                            border: none;
                            background-color: transparent;
                            max-width: 50%;
                            width: 100%;
                            margin-left: auto;
                            caret-color: #0169E6;
                            caret-shape: bar;

                            &::placeholder {
                                color: #D9D9D9;
                            }

                            &:focus-visible {
                                border: none;
                                outline: none;
                            }
                        }
                        p {
                            &.size-unit {
                                color: #D9D9D9;
                                font-size: 48px;
                                font-style: normal;
                                font-weight: 590;
                                line-height: normal;
                                width: 50%;
                            }
                        }
                    }
                    .applicable_cutomers_select {
                        p {
                            &.label_text {
                                color: #000;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 20px; /* 142.857% */
                            }
                        }
                        ul {
                            li {
                                &.option_item {
                                    button {
                                        padding: 6px 10px;
                                        border-radius: 14px;
                                        border: 1px solid #D9D9D9;
                                        background: var(--Colors-Background-bg-primary, #FFF);
                                        box-shadow: 0px 0.457px 0.914px 0px rgba(16, 24, 40, 0.05);
                                        span {
                                            &.text {
                                                color: #000;
                                                font-size: 12px;
                                                font-style: normal;
                                                font-weight: 500;
                                                line-height: 22px; /* 183.333% */
                                            }
                                        }
                                        &.active {
                                            border: 1px solid #0169E6;
                                            background: #0169E6;
                                            box-shadow: 0px 0.457px 0.914px 0px rgba(16, 24, 40, 0.05);
                                            span {
                                                &.text {
                                                    color: #fff;
                                                }
                                            }
                                            svg {
                                                path {
                                                    fill: #FFF;
                                                }
                                                ellipse, circle {
                                                    fill: #fff;
                                                }
                                            }
                                        }
                                        &.disabled {
                                            border: 1px solid #D9D9D9;
                                            background: rgba(0, 0, 0, 0.04);
                                            box-shadow: 0px 0.457px 0.914px 0px rgba(16, 24, 40, 0.05);
                                            color: rgba(0, 0, 0, 0.25);
                                            font-size: 12px;
                                            font-style: normal;
                                            font-weight: 500;
                                            line-height: 22px; /* 183.333% */
                                            span {
                                                &.text {
                                                    color: #D9D9D9;
                                                }
                                            }
                                            svg {
                                                path {
                                                    stroke: #D9D9D9;
                                                }
                                                ellipse, circle {
                                                    stroke: #D9D9D9;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .select_customer_wrapper_overlay_backdrop {
                        position: fixed;
                        z-index: 100;
                        background-color: transparent;
                        width: 100%;
                        left: 0;
                        top: 0;
                    }
                    .select_customer_wrapper {
                        margin-top: 16px;
                        z-index: 101;
                        position: relative;
                        .search_bar_container {
                            position: relative;
                            width: 100%;
                            .search-icon-container {
                                display: flex;
                                align-items: center;
                            }
                            .clear-icon-container {
                                width: 24px;
				                height: 24px;
                                svg {
                                    width: 24px;
                                    height: 24px;
                                    fill: #A2A3B9;
                                    path {
                                        stroke: #A2A3B9;
                                    }
                                }
                            }
                            .input-wrapper {
                                height: 40px;
                                border-radius: 14px;
                                overflow: hidden;
                                &:focus-within:not(:disabled),
                                &:hover:not(.is-disabled) {
                                    border-color: #0169e6;
                                    background: #fff;
                                    /* FocusBlue */
                                    box-shadow: 0px 0px 0px 2px var(--Colors-blue-1, #e6f0fd);
                                }
                            }
                            .start-adornment {
                                border-radius: 10px;
                                border: 1px solid #e6f1ff;
                                background: rgba(1, 105, 230, 0.05);
                                margin: 5px;
                    
                                svg {
                                    height: 16px;
                                    width: 16px;
                                    path {
                                        stroke: #0169e6;
                                    }
                                }
                            }
                           
                            ul {
                                &.suggestions_list_items {
                                    z-index: 101;
                                    position: absolute;
                                    bottom: 40px;
                                    width: 100%;
                                    padding: 8px;
                                    max-height: 28vh;
                                    overflow: auto;
                                    border-radius: 14px;
                                    border: 1px solid var(--Background-colorBgContainer, #FFF);
                                    background: var(--Background-colorBgElevated, #FFF);
                                    /* boxShadowSecondary */
                                    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);

                                    li {
                                        &.customer_item {
                                            padding: 8px 12px;
                                            transition: all 0.1s;
                                            &:hover {
                                                border-radius: 8px;
                                                background: #F9FAFB;
                                            }
                                            button {
                                                &.button {
                                                    font-size: 12px;
                                                    font-style: normal;
                                                    font-weight: 500;
                                                    line-height: normal;
                                                    border-radius: 9999px;
                                                    padding: 8px 16px;
                                                    border: 1px solid #000;
                                                    background-color: #FFF;
                                                    color: #000;
                                                    min-width: 90px;
                                                    text-align: center;
                                                    width: fit-content;
                                                    transition: all 0.2s;
                                                    svg {
                                                        min-width: 16px;
                                                        min-height: 16px;
                                                        width: 16px;
                                                        height: 16px;
                                                        path {
                                                            fill: #000;
                                                        }
                                                    }
                                                    &:hover {
                                                        border: 1px solid #000;
                                                        background: #000;
                                                        color: #FFF;
                                                        svg {
                                                            path {
                                                                fill: #fff;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.added {
                                                    border: 1px solid #000;
                                                    background: #000;
                                                    border-radius: 9999px;
                                                    color: #FFF;
                                                    svg {
                                                        path {
                                                            fill: #fff;
                                                        }
                                                    }
                                                    &:hover {
                                                        background: #000;
                                                        border-color: #000;
                                                        color: #fff;
                                                        svg {
                                                            path {
                                                                stroke: #000;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.remove_button {
                                                    &:hover {
                                                        border-radius: 9999px;
                                                        border: 1px solid var(--Color-Error-colorErrorBorder, #FFA39E);
                                                        background: #FFF;
                                                        color: #F5222D;
                                                        svg {
                                                            path {
                                                                stroke: #F5222D;
                                                            }
                                                        }
                                                    }
                                                    
                                                }
                                                &.blocked_button {
                                                    border-radius: 9999px;
                                                    border: 1px solid var(--Color-Error-colorErrorBorder, #FFA39E);
                                                    background: #FFF;
                                                    color: #F5222D;
                                                    svg {
                                                        path {
                                                            stroke: #F5222D;
                                                            fill: #FFF;
                                                        }
                                                    }
                                                    &:hover {
                                                        border: 1px solid #FFA39E;
                                                        background: #FFF;
                                                        color: #F5222D;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .select_button {
                            position: relative;
                            button {
                                color: #0169E6;
                                border-radius: 12px;
                                border: 1px solid var(--Colors-Brand-Primary-colorPrimary, #0169E6);
                                background: #FFF;
                                height: 40px;
                            }
                        }
                    }
                    .selected_customer_list {
                        margin-top: 24px;
                        position: relative;
                        z-index: 101;
                        p {
                            &.selected_text {
                                color: #000;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 20px; /* 142.857% */
                                span {
                                    color: #0169E6;
                                    font-weight: 600;
                                }
                            }
                        }
                        button {
                            &.remove_all_button {
                                color: #F5222D;
                                background-color: transparent;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 20px; /* 166.667% */
                            }
                        }
                        ul {
                            &.customer_list_items {
                                margin-top: 8px;
                                max-height: 400px;
                                overflow: auto;
                                li {
                                    &.list_item {
                                        padding: 8px;
                                        border-radius: 14px;
                                        transition: all 0.2s ease;
                                        &:hover {
                                            background-color: #F9FAFB;
                                        }
                                        .img_container {
                                            width: 36px;
                                            height: 36px;
                                            min-width: 36px;
                                            min-height: 36px;
                                            border-radius: 100%;
                                            overflow: hidden;
                                            img {
                                                width: 100%;
                                                height: 100%;
                                                object-fit: 100% 100%;
                                                object-position: center;
                                            }
                                        }
                                        .name_container {
                                            h4 {
                                                &.title-4 {
                                                    color: #000;
                                                    font-size: 14px;
                                                    font-style: normal;
                                                    font-weight: 600;
                                                    line-height: 14px; /* 100% */
                                                }
                                            }
                                            p {
                                                &.caption {
                                                    color: rgba(0, 0, 0, 0.65);
                                                    font-size: 12px;
                                                    font-style: normal;
                                                    font-weight: 400;
                                                    line-height: 20px; /* 166.667% */
                                                }
                                            }
                                        }
                                        button {
                                            &.remove_button {
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                color: #8C8C8C;
                                                background-color: transparent;
                                                padding: 8px;
                                                border-radius: 4px;
                                                transition: all 0.2s ease;
                                                svg {
                                                    width: 20px;
                                                    height: 20px;
                                                }
                                                &:hover {
                                                    color: #F5222D;
                                                    background-color: #F5222D20;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .buttons_container {
                        margin-top: 44px;
                        width: 100%;
                        .secondary {
                            width: 100%;
                            button {
                                width: 100%;
                                border-radius: 14px;
                                background: #F2F2F7;
                                height: 40px;
                                border: none;
                            }
                        }
                        .primary {
                            width: 100%;
                            button {
                                width: 100%;
                                height: 40px;
                            }
                        }
                    }
                }
            }
        }
    }
}


.choose_customer_modal {
    position: relative;
    .customer_modal_container {
      max-width: 500px;
      height: fit-content;
      
      .connections-list {
        display: flex;
        flex-direction: column;
        max-height: 50vh;
        overflow: auto;
      }
  
      .search-bar {
        margin-bottom: 20px;
      }
      .no-data {
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 200px;
      }
      .button-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .clear-all-button {
          width: fit-content;
          background-color: transparent;
          &.primary {
            color: #000000;
            margin-bottom: 8px;
          }
        }
      }
      .customer_header_container {
        margin-bottom: 20px;
        .choose-customer-header {
          color: var(--light-colorDarkText);
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px;
        }
  
        .user-connections {
          color: #475467;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px; /* 157.143% */
        }
  
        .close-icon-button {
          background: none;
          padding: 8px;
        }
      }
  
      .invite-button {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 157.143% */
      }
  
      .connection-list-row {
        padding: 10px 8px;
        border-radius: 8px;
        cursor: pointer;
        width: 100%;
        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
        &:active {
          background-color: rgba(0, 0, 0, 0.04);
        }
        .user-data {
          .user-profile-icon {
            min-width: 44px;
            width: 44px;
            height: 44px;
            border-radius: 100%;
            overflow: hidden;
            background-color: rgba(0, 0, 0, 0.04);
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .user-details {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px; /* 157.143% */
  
            .company-name {
              color: var(--light-colorText);
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 22px; /* 157.143% */
            }
  
            .caption {
              color: var(--light-colorSecondary);
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            }
          }
        }
        button {
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          border-radius: 4px;
          &.pending {
            border-radius: 4px;
            border: var(--Components-Button-Global-lineWidth, 1px) solid
              var(--Components-Button-Component-borderColorDisabled, #d9d9d9);
            background: var(
              --Components-Button-Global-colorBgContainerDisabled,
              rgba(0, 0, 0, 0.04)
            );
            padding: 2px 8px;
            color: #00000025;
  
            svg {
              path {
                color: #00000015;
              }
            }
          }
  
          &.customer {
            border: var(--Components-Button-Global-lineWidth, 1px) solid
              var(--Colors-Base-Magenta-3, #ffadd2);
            background: var(--Colors-Base-Magenta-1, #fff0f6);
            color: #c41d7f;
            padding: 1px 7px;
          }
        }
      }
    }
  }
  

.unblock_customer_popup {
    .primary {
        background-color: #52c41a;
        color: #fff;
        button {
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            &:disabled {
                background-color: #53c41ac4;
            }
        }
    }
}

.no_customer_popup {
    width: 450px !important;
  
    .ant-modal-content {
      min-height: fit-content;
    }
  
    .content-container {
      padding: 20px 20px 0 20px;
      
      img {
        width: 60px;
        height: 60px;
      }
      .icon-box {
        img {
            width: 64px;
            height: 64px;
            object-fit: contain;
        }
      }
  
      .confirmation-header {
        color: var(--light-colorText);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
  
      .confirmation-info {
        color: var(--light-colorSecondary);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
    .action-container {
      button {
        max-width: 160px;
        width: 100%;
        margin: auto;
      }
    }
  }