.product-info-card {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  gap: 10px;
  padding: 0 10px;

  .product-image {
    border-radius: 4px;
    min-width: 44px;
    width: 44px;
    height: 44px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  // &:last-of-type {
  //   .product-detail {
  //     border-bottom: none;
  //   }
  // }

  .product-detail {
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    border-bottom: 1px solid #f2f2f7;
    max-width: calc(100% - 54px);

    .product-name-container {
      // max-width: calc(100% - 160px);
      width: 100%;
    }

    .product-name {
      color: #000;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      text-align: start;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }

    .product-size {
      text-align: start;
      color: #465468;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .price-or-qty {
    width: fit-content;
    white-space: nowrap;
    display: block;
    text-align: end;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
}