.order-container {
	height: 100%;

	.order-header-container {
		.basic-order-info {
			width: 100%;

			.order-id {
				color: var(--light-colorText);
				font-size: 24px;
				font-style: normal;
				font-weight: 600;
				line-height: 38px;

				.back-btn {
					background: none;
				}

				svg {
					rotate: 90deg;
					width: 20px;
					height: 20px;
					path {
						stroke: #000000e0;
					}
				}

				.id {
					color: var(--light-primary);
				}
			}

			.date-container {
				color: var(--light-colorText);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
			}
		}

		.user-info-container {
			.download-reciept-icon {
				border-radius: 8px;
				border: 1px solid var(--light-primary);
				background: #fff;
				box-shadow:
					0px 2px 5px 0px rgba(255, 255, 255, 0.15),
					0px -8.759px 31.627px 0px rgba(255, 255, 255, 0.15),
					0px -5.255px 17.517px 0px rgba(255, 255, 255, 0.15);
				backdrop-filter: blur(6.130982398986816px);
				padding: 0px 15px;
				height: 44px;
				color: var(--light-primary);
			}
		}
	}

	.orders-content {
		overflow: auto;
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		max-height: calc(100% - 115px);
		height: 100%;

		&.cancelled-order {
			max-height: calc(100% - 155px);
		}

		border-radius: 10px;
		gap: 10px;

		.ant-tabs-tab {
			padding: 0 4px 12px 4px;
		}
		.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
			margin-left: 16px;
		}
		.ant-tabs-nav {
			// width: fit-content;
			&::before {
				border: none;
			}
		}
		.ant-tabs {
			width: calc(100% - 410px);
			// height: 100%;
			// overflow: auto;
		}
		.ant-tabs-content-holder {
			height: 100%;
			overflow: auto;

			.ant-tabs-tabpane {
				height: 100%;
			}

			.ant-tabs-content {
				height: 100%;
			}
		}

		.entire-order-details-container {
			gap: 10px;
			border-radius: 12px;
			background: #fff;
			padding: 10px;
			padding-bottom: 0;
			width: 100%;
			height: 100%;
			overflow: auto;

			.rejected-status-container {
				color: var(--light-colorError);
				display: flex;
				align-items: center;
				gap: 8px;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: 20px;

				.date-style {
					color: var(--light-colorTextTertiary);
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px;
				}
			}

			.time-tracking-container {
				padding: 20px 0;
				// overflow: auto;

				.custom-step {
					.ant-steps-item-title {
						color: var(--light-darkColor25);
						font-size: 14px;
						font-style: normal;
						font-weight: 600;
						line-height: 20px;
						white-space: nowrap;
					}
					.ant-steps-item-description {
						color: var(--light-colorTextTertiary);
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px;
					}

					.ant-steps-item-icon {
						width: 32px;
						height: 32px;
						display: flex;
						align-items: center;
						justify-content: center;
						.ant-steps-icon {
							width: 24px;
							height: 24px;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
					.ant-steps-item-tail {
						margin-inline-start: 56px;
						padding: 0 10px;
					}

					&.step-accepted {
						&.ant-steps-item-finish,
						&.ant-steps-item-active {
							.ant-steps-item-title {
								color: var(--light-colorText);
							}

							.ant-steps-icon {
								background-color: black;
								border-radius: 100%;
								&::before {
									content: "";
									display: inline-block;
									width: 15px;
									height: 15px;
									background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath d='M11.3673 3.72656L5.36533 9.72855L2.63715 7.00037' stroke='white' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
									background-size: contain;
								}
								svg {
									display: none;
								}
							}
						}

						&.ant-steps-item-finish {
							.ant-steps-item-tail {
								&::after {
									height: 2px;
									background-color: var(--light-colorText);
								}
							}
						}
					}

					&.step-in-progress {
						&.ant-steps-item-active {
							.ant-steps-item-title {
								color: var(--light-warningColor);
							}

							.ant-steps-icon {
								svg {
									fill: var(--light-warningColor);
									path {
										stroke: #ffe58f;
									}
								}
							}
						}

						&.ant-steps-item-finish {
							.ant-steps-icon {
								background-color: var(--light-warningColor);
								border-radius: 100%;
								&::before {
									content: "";
									display: inline-block;
									width: 15px;
									height: 15px;
									background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath d='M11.3673 3.72656L5.36533 9.72855L2.63715 7.00037' stroke='white' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
									background-size: contain;
								}
								svg {
									display: none;
								}
							}

							.ant-steps-item-tail {
								&::after {
									height: 2px;
									background-color: var(--light-warningColor);
								}
							}
						}
					}

					&.step-dispatched {
						&.ant-steps-item-finish,
						&.ant-steps-item-active {
							.ant-steps-item-title {
								color: var(--light-primary);
							}

							.ant-steps-icon {
								background-color: var(--light-primary);
								border-radius: 100%;
								&::before {
									content: "";
									display: inline-block;
									width: 15px;
									height: 15px;
									background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath d='M11.3673 3.72656L5.36533 9.72855L2.63715 7.00037' stroke='white' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
									background-size: contain;
								}
								svg {
									display: none;
								}
							}
						}
						&.ant-steps-item-finish {
							.ant-steps-item-tail {
								&::after {
									height: 2px;
									background-color: var(--light-primary);
								}
							}
						}
					}

					&.step-delivered {
						&.ant-steps-item-finish,
						&.ant-steps-item-active {
							.ant-steps-item-title {
								color: var(--light-brandSuccess);
							}

							.ant-steps-icon {
								background-color: var(--light-brandSuccess);
								border-radius: 100%;
								width: 24px;
								height: 24px;
								&::before {
									content: "";
									display: inline-block;
									width: 15px;
									height: 15px;
									background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath d='M11.3673 3.72656L5.36533 9.72855L2.63715 7.00037' stroke='white' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
									background-size: contain;
								}
								svg {
									display: none;
								}
							}
						}
					}

					&.step-cancelled {
						&.ant-steps-item-finish,
						&.ant-steps-item-active {
							.ant-steps-item-title {
								color: var(--light-colorError);
							}

							.ant-steps-icon {
								background-color: var(--light-colorError);
								border-radius: 100%;
								width: 24px;
								height: 24px;
								&::before {
									content: "";
									display: inline-block;
									width: 15px;
									height: 15px;
									background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath d='M11.3673 3.72656L5.36533 9.72855L2.63715 7.00037' stroke='white' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
									background-size: contain;
								}
								svg {
									display: none;
								}
							}
						}
					}
				}
			}

			.Order-details-table-container {
				.Order-details-table {
					max-height: 50vh;
					overflow: auto;

					.ant-table-body {
						overflow: auto !important;
					}

					.ant-table-thead {
						position: sticky;
						top: 0;
						background-color: inherit;
						z-index: 1000;
						tr:last-child > *:last-child {
							display: flex;
							align-items: end;
							justify-content: end;
						}

						.ant-table-cell {
							&:nth-last-child(2) {
								text-align: end;
							}
						}
					}

					table {
						border: 1px solid var(--light-colorBorderSecondary);
					}

					th {
						color: var(--light-colorTextTertiary);
						background-color: #fff;
						padding: 12px 8px;
						font-size: 12px;
						font-style: normal;
						font-weight: 600;
						line-height: 20px;
						&:first-child {
							padding: 12px 16px !important;
						}

						&:last-child {
							padding: 40px !important;
						}

						&:last-of-type {
							padding: 20px !important;
						}
					}

					td {
						padding: 8px 8px;
					}

					.product-name-box {
						.product-image-container {
							width: 54px;
							height: 54px;
							min-width: 54px;
							min-height: 54px;
							border-radius: 5.838px;
							border: 0.844px solid #eaecf0;
							overflow: hidden;
							display: flex;
							justify-content: center;
							align-items: center;
							.product-image {
								width: 100%;
								height: 100%;
								object-fit: cover;
								object-position: center;
							}

							svg {
								width: 24px;
								height: 24px;

								path {
									fill: #a9a9a9;
								}
							}
						}
						.product-name {
							color: var(--light-colorText);
							font-size: 14px;
							font-style: normal;
							font-weight: 600;
							line-height: 22px;
							word-wrap: break-word;
							word-break: break-word;
						}
					}
					.unit {
						font-size: 14px;
						font-style: normal;
						font-weight: 600;
						line-height: 22px;
						color: var(--light-colorTextTertiary);
						word-wrap: break-word;
						word-break: break-word;
					}

					.size-text {
						color: var(--light-colorText);
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 22px;
						word-wrap: break-word;
						word-break: break-word;
					}

					.qty-text {
						.quantity {
							color: var(--light-colorText);
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 22px;
						}
						.orderUnit {
							color: var(--light-colorTextTertiary);
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px;
						}
					}

					.price-per-unit-text {
						.price {
							color: var(--light-colorText);
							font-size: 14px;
							font-style: normal;
							font-weight: 600;
							line-height: 22px;
						}
					}

					.subtotal-text {
						color: var(--light-colorText);
						font-size: 14px;
						font-style: normal;
						font-weight: 600;
						line-height: 22px;
						display: flex;
						justify-content: end;
					}

					.ant-table-cell {
						&::before {
							display: none;
						}
					}
				}
			}

			.address-section {
				border-radius: var(--radius-md, 8px);
				border: 1px solid
					var(--Color-Neutral-Borders-colorBorderSecondary, #f0f0f0);
				padding: 12px;
				.address-text {
					width: 100%;
					.address-input {
						width: 100%;
						h4 {
							&.title-4 {
								color: var(
									--Color-Neutral-Text-colorText,
									rgba(0, 0, 0, 0.88)
								);
								font-size: 14px;
								font-style: normal;
								font-weight: 600;
								line-height: 22px; /* 157.143% */
							}
						}
						input {
							color: var(
								--Color-Neutral-Text-colorTextLabel,
								rgba(0, 0, 0, 0.65)
							);
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px; /* 166.667% */
							border: none;
							outline: none;
							width: 100%;
							&:disabled {
								background-color: transparent;
							}
						}
					}
				}
			}

			.bill-details {
				border-radius: 8px;
				border: 1px solid var(--light-colorBorderSecondary);
				background-color: rgba(0, 0, 0, 0.02);
				padding: 16px;

				.section-header {
					color: var(--light-colorText);
					font-size: 16px;
					font-style: normal;
					font-weight: 700;
					line-height: 24px;
				}

				.sub-total {
					color: var(--light-colorText);
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 22px;
					margin-bottom: 8px;
				}

				.total-discount {
					color: var(--light-primary);
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 22px;
					padding-bottom: 8px;
					border-bottom: 1px solid var(--light-colorBorderSecondary);
				}

				.grand-total {
					margin-top: 8px;
					color: var(--light-colorText);
					font-size: 20px;
					font-style: normal;
					font-weight: 700;
					line-height: 24px;
				}
			}

			.dispatch-details-box {
				margin-top: 10px;
				gap: 10px;

				.transportation-details-box {
					width: 100%;
					border-radius: 12px;
					border: 1px solid var(--light-colorBorderSecondary);
					padding: 12px;
					max-height: 150px;
					overflow: auto;

					svg {
						min-width: 24px;
						min-height: 24px;
					}

					.comments-header {
						color: var(--light-colorText);
						font-size: 16px;
						font-style: normal;
						font-weight: 600;
						line-height: 24px;
					}

					.comment-info {
						color: var(--light-colorSecondary);
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px;
						word-break: break-all;
						max-height: 100px;
						overflow: auto;
					}
				}

				.attachments-container {
					width: 100%;
					padding: 12px;
					border: 1px solid var(--light-colorBorderSecondary);
					border-radius: 12px;
					.attach-icon {
						svg {
							width: 24px;
							height: 24px;
							path {
								stroke: black;
							}
						}
					}

					.content-container {
						.attachment-header-text {
							color: var(--light-colorText);
							font-size: 16px;
							font-style: normal;
							font-weight: 600;
							line-height: 24px;
						}

						.attached-files {
							.image-box {
								display: flex;
								justify-content: center;
								align-items: center;
								width: 54px;
								height: 54px;
								border-radius: 4.32px;
								overflow: hidden;
								border: 0.47px solid #eaecf0;
								img {
									object-fit: contain;
									width: 100%;
									height: 100%;
									object-position: center;
								}

								svg {
									width: 50px;
									height: 35px;
								}
							}
						}
					}
				}
			}
		}
	}
}
