.dispatch-details-container {
  position: relative;
  max-height: 100%;
  height: 100%;
  overflow: auto;
  border-radius: 12px;
  background: #fff;
  padding: 10px;
  gap: 10px;

  .dispatch-details-box {
    gap: 10px;

    .add-details-box {
      height: 54px;
      display: flex;
      align-items: center;
      button {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        background-color: transparent;
        color: var(--light-primary);
      }

      svg {
        path {
          fill: var(--light-primary);
        }
      }
    }

    .transportation-details-box {
      width: 100%;
      border-radius: 12px;
      border: 1px solid var(--light-colorBorderSecondary);
      padding: 12px;
      background-color: #fff;

      .transportation-header {
        color: var(--light-colorText);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      .transportation-content {
        .transportation-content-header {
          word-break: break-all;
          color: var(--light-colorText);
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }

        .details {
          color: var(--light-colorSecondary);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }

    .attachments-container {
      width: 100%;
      padding: 12px;
      border: 1px solid var(--light-colorBorderSecondary);
      border-radius: 12px;
      background-color: #fff;

      .attach-icon {
        svg {
          width: 24px;
          height: 24px;
          path {
            stroke: black;
          }
        }
      }

      .content-container {
        .attachment-header-text {
          color: var(--light-colorText);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }

        .attached-files {
          .image-box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 54px;
            height: 54px;
            border-radius: 4.32px;
            overflow: hidden;
            border: 0.47px solid #eaecf0;

            a {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            img {
              object-fit: contain;
              width: 100%;
              height: 100%;
              object-position: center;
            }
          }
        }
      }
    }
  }
  
  .partially-dispatch-container {
    border-radius: 12px;
    border: 1px solid var(--light-colorBorderSecondary);
    background-color: #fff;
    padding: 12px;
    gap: 10px;

    margin-top: 10px;

    &:nth-child(1){
      margin-top: 0px;
    }

    .partially-dispatch-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .partially-dispatch-header-left {
        p {
          color: var(--light-colorText);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
        }

        span {
          color: var(--light-colorTextTertiary);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      };

      .detail-button {
        border-radius: 8px;
        border: 1px solid var(--light-primary);
        color: var(--light-primary);
      }
      .status-switch-wrapper {
        .switch {
          max-height: 40px;
        }
      }
    }

    &.active {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .order-details-table-container {
    margin-top: 10px;
    .order-details-table {
      max-height: 70vh;
      overflow: auto;

      .ant-table-body {
        overflow: auto !important;
      }

      .ant-table-thead {
        position: sticky;
        top: 0;
        background-color: inherit;
        z-index: 9;
      }

      table {
        border: 1px solid var(--light-colorBorderSecondary);
      }

      th {
        color: var(--light-colorTextTertiary);
        padding: 12px 8px;
        background-color: #fff;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        &:first-child {
          padding: 12px 16px !important;
        }
      }

      td {
        padding: 8px 8px;
      }

      .product-name-box {
        .product-image-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 54px;
          height: 54px;
          min-width: 54px;
          min-height: 54px;
          border-radius: 5.838px;
          border: 0.844px solid #eaecf0;
          overflow: hidden;
          .product-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }

          svg {
            width: 24px;
            height: 24px;

            path {
              fill: #a9a9a9;
            }
          }
        }
        .product-name {
          color: var(--light-colorText);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          word-wrap: break-word;
          word-break: break-word;
        }
      }
      .unit {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        color: var(--light-colorTextTertiary);
        word-wrap: break-word;
        word-break: break-word;
      }

      .size-text {
        color: var(--light-colorText);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        word-wrap: break-word;
        word-break: break-word;
      }

      .qty-text {
        .quantity {
          color: var(--light-colorText);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
        .orderUnit {
          color: var(--light-colorTextTertiary);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .status-text {
        color: var(--light-primary);
        font-size: 13.091px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.636px;
      }

      .price-per-unit-text {
        .price {
          color: var(--light-colorText);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
        }
      }

      .subtotal-text {
        color: var(--light-colorText);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
      }

      .ant-table-cell {
        &::before {
          display: none;
        }
      }
    }
  }
}
