.account-setting-page {
    position: relative;
    overflow: auto;
    .settings-page-container {
        width: 100%;
        padding-top: 32px;
        // height: calc(100vh - 178px);
        position: relative;
        padding-bottom: 10px;
        // overflow: auto;
        &.full-page {
            height: 100%;
            padding-top: 0;
            padding-bottom: 0;
            .subscription-settings-body {
                .plans-container {
                    // height: calc(100vh - 205px);
                }
            }
        }
        .settings-menu-container {
            max-width: 320px;
            width: 100%;
            height: 100%;
            overflow: auto;
            position: sticky;
            top: 0;
            .setting-menu {
                width: 100%;
                border-radius: 12px;
                border: 1px solid #F2F2F7;
                margin-bottom: 32px;
                overflow: hidden;
                &:last-of-type {
                    margin-bottom: 0;
                }
                .menu-title {
                    color: var(--light-primary);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 22px; /* 137.5% */
                    padding: 12px 24px;
                    border-bottom: 1px solid #F2F2F7;
                    background-color: #F9FAFB;
                }
                ul {
                    &.menu-items
                        a {
                            &.item {
                                color: var(--light-primary);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 20px; /* 142.857% */
                                border-bottom: 1px solid #F2F2F7;
                                padding: 16px 20px;
                                transition: all 0.2s;
                                cursor: pointer;
                                &:last-of-type {
                                    border-bottom: none;
                                }
                                &:hover {
                                    background-color: rgba(1, 105, 230, 0.05);
                                }
                                &.active {
                                    background-color: rgba(1, 105, 230, 0.05);
                                }
                            }
                        }
                }
            }
        }
        .setting-details-container {
            max-width: calc(100% - 320px);
            width: 100%;
        }
    }
}