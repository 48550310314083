.no-access-modal {
    img {
        max-width: 54px;
        max-height: 54px;
        height: 100%;
        width: 100%;
        object-fit: cover;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }
    h2 {
        &.title-2 {
            color: #000;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 100% */
            margin-bottom: 16px;
        }
    }
    p {
        &.caption {
            color: #344056;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}